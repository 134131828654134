import React, { Component } from "react";
import {
  Modal,
  Card,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import {Form } from "react-bootstrap";
import "../styling/Styles.css";
// import "../commande-client-devis/ss.scss";
//import "../bon-entree/be.scss";
import "./fch.scss";
import Affchfichestock from"./affchfichestock";
import {
  Snackbar,
  IconButton,
  Divider,Fab,
} from "@material-ui/core";

import translate from "../../i18n/translate";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";

import { withStyles } from "@material-ui/styles";
import "../bon-entree/Responsivebe.css";

import { connect } from "react-redux";

import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];



const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

var event = new Date();
event.setDate(1);
event.setMonth(0);

var date2 = event.toISOString().substr(0, 10);






class AddElement extends Component {
  constructor(props) {

    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      openReleveClientModal: false,
      openMailModal: false,
      snackbaropen: false,
      snackbaropenn: false,
      snackbarmsg: "",
      tab: [],
      
      defaultdate: date,
      d1:date2,
    }; 

  }


  
  // componentDidMount() {
  //   document.getElementById("tell").innerHTML =
  //     '<a href="tel:' + this.props.tel1 + '">' + this.props.tel1 + "</a>";
  // }
  handleOpen = () => {
    this.setState({ open: true });
    
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  openReleveClient = () => {
    this.setState({ openReleveClientModal: true });
  };

  nomail = () => {
    this.setState({ snackbaropen: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosee = (event) => {
    this.setState({ snackbaropenn: false });
  };


  deleteclient(clientid) {
    if (window.confirm("êtes-vous sûr de vouloir supprimer ce client?")) {
      fetch(
        `https://www.24-crm.com/webservice/api/CLIENTs?&id=${clientid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({ snackbaropenn: true, snackbarmsg: result });
            this.props.onHide();

      
          },

          (error) => {
            this.setState({ snackbaropenn: true, snackbarmsg: "failed" });
          }
        );
      //  this.props.SelectArticle();
      //window.location.reload();
    }
  }

  call1 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel1}&number=${this.props.tel1}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };

  call2 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel2}&number=${this.props.tel2}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };
  getTotalStkfin = () => {
    let totalStkfin = 0;
   
  
    this.props.tabtab.forEach((article) => {
     // totalStkfin += parseFloat(Number(article.StkFin));
    // totalStkfin += parseFloat(Number(article.QteEntr)+Number(article.QteRet)-Number(article.QteSor));
    
     {article.libelle=="Totaux"?( 
      totalStkfin = parseFloat(Number(article.QteEntr)+Number(article.QteRet)-Number(article.QteSor))
    ):( 
      totalStkfin += parseFloat(Number(article.QteEntr)+Number(article.QteRet)-Number(article.QteSor))
    )}
    
    })
  
    return totalStkfin.toFixed(3); // arrondi à 4 chiffres après la virgule
  };

  getTotalValeur = () => {
    let TotalValeur = 0;
    
    this.props.tabtab.forEach((article) => {
      {article.libelle=="Totaux"?( TotalValeur = parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor))):( TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor)))}
    
     // TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor));
     //TotalValeur += parseFloat(Number(article.StkFinVal));
    })

    return TotalValeur.toFixed(3); // arrondi à 4 chiffres après la virgule
  };
  
  getTotalQteEntr = () => {
    let totalQteEntr = 0;
  
    this.props.tabtab.forEach((article) => {
      {article.libelle=="Totaux"?(totalQteEntr = parseFloat(Number(article.QteEntr))):(totalQteEntr += parseFloat(Number(article.QteEntr)))}
      
    })

    return totalQteEntr.toFixed(3); // arrondi à 4 chiffres après la virgule
  };
  getTotalQtesor = () => {
    let totalQtesor = 0;
  
    this.props.tabtab.forEach((article) => {
      {article.libelle=="Totaux"?( totalQtesor = parseFloat(Number(article.QteSor))):( totalQtesor += parseFloat(Number(article.QteSor)))}
     
    })
 
    return totalQtesor.toFixed(3); // arrondi à 4 chiffres après la virgule
  };

  
  getTotalQteret = () => {
    let totalQteret = 0;
   
    this.props.tabtab.forEach((article) => {
      {article.libelle=="Totaux"?(totalQteret = parseFloat(Number(article.QteRet))):(totalQteret += parseFloat(Number(article.QteRet)))}
     
      // totalQteret += parseFloat(Number(article.QteRet));
    })
 
    return totalQteret.toFixed(3); // arrondi à 4 chiffres après la virgule
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    let ReleveClientModalClose = () => this.setState({ openReleveClientModal: false });
    let MailModalClose = () => this.setState({ openMailModal: false });
    const { darck, classes } = this.props;
    // console.log('code dep',this.props.coddep);
    // console.log('code dep1',this.props.rechdatsGMM);
    
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropenn}
          autoHideDuration={2000}
          onClose={this.snackbarClosee}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosee}
              style={{
                
                color: `${darck ? "#020F64" : "#fff"}`
              }}

            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert onClose={this.snackbarClose} variant={"danger"}>
            Email non trouvable pour ce client
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Aperçu  fiche de  stock</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {/* {this.props.passager ? (
              <Alert
                style={{ width: "100%", textAlign: "center" }}
                variant={"primary"}
              >
                Client passager
              </Alert>
            ) : null} */}
            <Card>
              <Card.Body
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                }}
              >
                <h3 style={{ color: `${darck ? "#172431" : "#fff"}` }}>
                  {this.props.GMM?(<> 
 {/* //////////////////////////////////////////////////multi magasin ///////////////////////////////////////////////////////*/}
                  {this.props.datfac==null?(<>
                {this.props.codearticle} : {this.props.designationarticle}
              
                 </> ):(<> {this.props.codearticle}</>)}



                  </>):(<> 
                  
                   {/* //////////////////////////////////////////////////Mono magasin ///////////////////////////////////////////////////////*/}
                  {this.props.datfac==null?(<>
                {this.props.codearticle} : {this.props.designationarticle}
                 </> ):(<> {this.props.codearticle} </>)}</>)}
                
                </h3>
                <h3 style={{ color: `${darck ? "#172431" : "#fff"}` }}>
                  {this.props.GMM?(<> 
 {/* //////////////////////////////////////////////////multi magasin ///////////////////////////////////////////////////////*/}
                  {this.props.datfac==null?(<>
                    {this.props.lib}
              
                 </> ):(<></>)}



                  </>):(<> 
                  
                </>)}
                
                </h3>
</Card.Body></Card>
<Card>
              <Card.Body
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                }}
              >
<div className="tabbl2">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                      <th>
                         Date
                        </th>
                        <th style={{ width: "33%" }}>
                         Désignation
                        </th>
                        
                      
                        <th> Qté Entrée </th>
                        <th> Qté sortie</th>
                        <th> Qté Retour</th>
                        <th> Prix MVT </th>
                        <th>  Stk Fin</th>
                        <th >  Valeur </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                        color: `${darck ? "" : "#fff"}`,
                      }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          {t.datfac ==null?(<></>):(<>
                             &nbsp;&nbsp;<td style={{ fontSize: "12px"}}>
                           { moment(t.datfac).format("DD/MM/YYYY")}
                          </td>
                          <td style={{ fontSize: "12px", width: "35%" }}>
                          {t.libelle}
                          </td>
                         
                          <td style={{ fontSize: "12px" ,color: `${darck ? "#0D47A1" : "#fff"}`}}>
                            {t.QteEntr==0?(<>-</>):(<><span>{Number(t.QteEntr).toFixed(3)}</span></>)}
                            
                          </td>

                          <td style={{ fontSize: "12px" ,color: `${darck ? "red" : "#fff"}`}}>
                            {t.QteSor==0?(<>-</>):(<>   <span>{Number(t.QteSor).toFixed(2)}</span></>)}
                         
                          </td>
                          <td style={{ fontSize: "12px",color: `${darck ? "green" : "#fff"}` }}>
                            {t.QteRet==0?(<>-</>):(<> <span>{Number(t.QteRet).toFixed(2)}</span></>)}
                           
                          </td>

                          <td style={{ fontSize: "12px" }}>
                            {t.PrixEntr==0?(<>-</>):(<> <span>{Number(t.PrixEntr).toFixed(3)}</span></>)}
                           
                          </td>
                          <td style={{ fontSize: "12px"}}>
                            <span>{Number(t.StkFin).toFixed(3)}</span>
                          </td>
                          <td style={{ fontSize: "12px" ,color: `${darck ? "#0D47A1" : "#fff"}` }}>
                            <span> &nbsp;&nbsp;{Number(t.StkFinVal).toFixed(3)}</span>
                          </td></>)}
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>


            
              

              

                <Divider></Divider>
              
               
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "10px" }} id="brfr1">
              <Card.Body
              
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
              >
                <Row style={{ marginBottom: "-10px" }}>
                 
                    <p style={{ color: `${darck ? "#8f2d56" : "#fff"}`, marginBottom: "-5px" }}>
                      Total STK Fin :
                    </p> &nbsp;

                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.getTotalStkfin()).toFixed(3)}
                    </p>
            
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  

                 
                   <p style={{ color: `${darck ? "#8f2d56" : "#fff"}`, marginBottom: "-5px" }}>
                      Total Valeur :
                    </p> &nbsp;
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.getTotalValeur()).toFixed(3)}
                    </p>
              
                </Row>

              

              

              </Card.Body>
            </Card>

           {/* ////////Responsive////////// */}

           <Card style={{ marginTop: "10px" }} id="brfr2">
              <Card.Body
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}
              >
                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "#8f2d56" : "#fff"}`, marginBottom: "-5px" }}>
                      Total STK Fin :
                    </p> &nbsp;

                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.getTotalStkfin()).toFixed(3)}
                    </p>
                  </Col>

                
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="brf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "#8f2d56" : "#fff"}`, marginBottom: "-5px" }}>
                      Total Valeur :
                    </p> &nbsp;
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.getTotalValeur()).toFixed(3)}
                    </p>
                  </Col>
                 
                </Row>

          
                <Row style={{ marginBottom: "10px" }}>
                  <Col id="brf">
                    {/* <Divider style={{ backgroundColor: "grey" }} /> */}
                  </Col>
                </Row>

               
              </Card.Body>
            </Card>

            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                        this.props.email !== "" &&
                          action.name === "Mail" &&
                          this.openMail();
                        this.props.email === "" &&
                          action.name === "Mail" &&
                          this.nomail();
                        action.name === "Modifier" && this.openModifier();
                        action.name === "Supprimer" &&
                          this.deleteclient(this.props.codeclient) &&
                          this.props.onHide();
                        action.name === "Relevé" && this.openReleveClient();
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
                {/* <Button
                  // variant="primary"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "70px",
                    backgroundColor: "#4153AF",
                    border: "1px solid #4153AF",
                  }}
                  onClick={this.openReleveClient}
                >
                  Relevé
                </Button> */}
                   </Col>
            </Row>
          </Modal.Body>
          

       

       
        </Modal>

     
     
     
     
     
        <Affchfichestock
               show={this.state.openReleveFournisseurModal}
               // onHide={ReleveFournisseurModalClose}
                codearticle={this.props.codearticle}
                desart={this.props.des}
               tabb={this.state.tabtab}
                //tabb={this.props.tabtab}
              />
     
     {/* ///////////////////////////////////////////{this.state.MG ?(<></>):(<></>)}////////////////////////////////////////////////////// */}
     
        <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche de stock   {this.props.GMM?(<>{this.props.coddep=="0"?(<></>):this.props.rechdatsGMM?(<></>): (<>({this.props.lib})</>)}  </>):(<></>)}  
               {this.props.GMM?(<> {this.props.coddep?(<></>):(<> -{this.props.lib}</>)} </>):(<></>)}  
                  </>
              {/* {this.props.rechdatsGMM?(<>a</>):(<>({this.props.lib}) b</>)} */}
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "410px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
             {/* **** {this.props.coddep}
              /////  {this.props.MG}{this.props.lib}{" "} */}
                  de &nbsp;{ moment(this.props.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                   { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                Article  : {this.props.codearticle} 
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
         {this.props.GMM?(<>
          <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                     // fontSize: "14px",
                     // fontWeight: "bold",
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                {this.props.coddep==0 ?( <>
                  <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Mg")}</th>
                  </>)
                  :this.props.rechdatsGMM?( <>
                    <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Mg")}</th>
                    </>)
                    :(<>
                  </>)}
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
              
               
                </tr> 
                <tr style={{ borderBottom: "1px solid grey" ,height: "50px", }}>
                <th style={{ width: "0%"  }}>{translate(" ")}</th>
                <th style={{ width: "10%"  }}>{translate(" ")}</th>
                {this.props.coddep==0 ?( <>
                  <th style={{ width: "0%"}}>{translate(" ")}</th>
                  </>):
                  this.props.rechdatsGMM?( <>
                    <th style={{ width: "0%"}}>{translate(" ")}</th>
                    </>):(<>
                  </>)}
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Pr.Rev")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Valeur")}</th>
             
               
                </tr> </thead>
            <tbody>
          
               {this.props.tabtab.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                    

                    
                      {t.datfac == null? (<>
                   {t.libelle=="Totaux"?(<>
                   </>):(<>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
               
              
                
               </td>
          {/* rowspan="3"    colspan="2"*/}
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.libelle} {t.lib}</td>
           <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
              {/* ////////////////// entrée ///////////*/}
            
           </td>

            {/* ////////////////// sortie ///////////*/}
           <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
            
            
             </td>
              {/* ////////////////// retour ///////////*/}
           <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
            
             </td>
           
           <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>  </>)} 
                 
             
            
           
           </>):( <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.datfac == null? (
                 <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                  --
                  </span>
                ) :t.libelle == "Totaux"? (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                 
                   </span>
                 ) : (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                    { moment(t.datfac).format("DD/MM/YYYY")}
                  </span>
                )}
                
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
            {this.props.coddep==0 ?( <>
              <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}` ,width: "0%" ,   border: "1px solid gray"}} >
            
           {t.lib}</td> 
            </>):this.props.rechdatsGMM?( <>
              <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}` ,width: "0%" ,   border: "1px solid gray"}} >
            
           {t.lib}</td> 
            </>):(<></>)}
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)}</>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)}</>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)}</>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)}&nbsp;|&nbsp;{Number(t.StkFinVal).toFixed(3)} </td>
            </>  )}
                  
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

          <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {this.getTotalQteEntr()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {this.getTotalQtesor()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {this.getTotalQteret()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
      
           {this.getTotalStkfin()}|{this.getTotalValeur()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 
        
                
               
         </>):(<> 
          <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                     // fontSize: "14px",
                     // fontWeight: "bold",
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                <th style={{ width: "20%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "20%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "20%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "20%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
              
               
                </tr> 
                <tr style={{ borderBottom: "1px solid grey" ,height: "50px", }}>
                <th style={{ width: "0%"  }}>{translate(" ")}</th>
                <th style={{ width: "10%"  }}>{translate(" ")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Pr.Rev")} |{translate("Valeur")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Valeur")}</th>
             
               
                </tr> </thead>
            <tbody>
          
               {this.props.tabtab.map((t, i) => (

                
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                     {t.datfac == null? (<> {t.libelle=="Totaux"?(<>
                        </>):(<>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
                    
                   
                     
                    </td>
               {/* rowspan="3"    colspan="2"*/}
                <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.libelle} {t.lib}</td>
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
                   {/* ////////////////// entrée ///////////*/}
                 
                </td>
     
                 {/* ////////////////// sortie ///////////*/}
                <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
                 
                 
                  </td>
                   {/* ////////////////// retour ///////////*/}
                <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
                 
                  </td>
                
                <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
           
                  </td>  </>)}</>
                      
                      
                ):( <>
                  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
                     
                     {t.datfac == null? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        --
                        </span>
                      ) :t.libelle == "Totaux"? (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                         </span>
                       ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          { moment(t.datfac).format("DD/MM/YYYY")}
                        </span>
                      )}
                      
                      </td>
            
                  <td style={{ width: "10%" }}> {t.libelle}  </td>
                  <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,   border: "1px solid gray"}}> 
                     {/* ////////////////// entrée ///////////*/}
                     {t.libelle=="Totaux"?(<>
                      {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
                    
                     </>):
                     (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
                     |&nbsp;
                     {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
                     |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)}</>)}
                  </td>
                
                   {/* ////////////////// sortie ///////////*/}
                  <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   border: "1px solid gray"}}  >
                    {t.libelle=="Totaux"?(<>
                      {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
                    
                    </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
                    |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
                    |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)}</>)}
                   
                    </td>
                     {/* ////////////////// retour ///////////*/}
                  <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   border: "1px solid gray" }} >
                    {t.libelle=="Totaux"?(<>
                      {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
                 
                    </>):(<>
                      {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
                    |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
                    |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)}</>)}
                
                    </td>
                  
                  <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)}&nbsp;|&nbsp;{Number(t.StkFinVal).toFixed(3)} </td>
                  </>  )}
                 
                   </tr>  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
         
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label>
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
           {this.getTotalQteEntr()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  
        
           {this.getTotalQtesor()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
         
           {this.getTotalQteret()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
      
           {this.getTotalStkfin()}|{this.getTotalValeur()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col>  
               
         </>)}
              
               
           
           
               
              </div>
              <br />
          
            
            </div>
          </div>
  
        </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    codbf: state.codbf,
    codfacfrss: state.codfacfrss,
 
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,

    fiches: state.fiches,
  };
}

// function mapStateToProps(state) {
//   return {
//     fournisseurs: state.fournisseurs,
//     codbes: state.codbes,
//     codfacfrss: state.codfacfrss,
//     Auth: state.Auth,
//     codbf: state.codbf,
//     ConfigDossier: state.ConfigDossier,
//     valtimbres: state.valtimbres,
//     clients: state.clients,
//     darck: state.darck.darck,
//     articles: state.articles,
//     nomes: state.nomes,
//     sousfamilles: state.sousfamilles,
//     InfoEntreprise: state.InfoDossier,
 
   
//   };
// }

export default connect(
  mapStateToProps,
  null
)(withStyles(stylesFn)(AddElement));
