const initialState = {
    AffStock: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "AFFCHSTOCK_GET":
        return {
          ...state,
          AffStock: action.payload,
        };
      default:
        return state;
    }
  }
  