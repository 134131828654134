import React, { Component } from "react";
import { Modal, Card, Row, Col } from "react-bootstrap";
import "../styling/Styles.css";
import Typography from "@material-ui/core/Typography";
import { Label, Table } from "reactstrap";
import { connect } from "react-redux";
import { SelectAvoirClientLig } from "../../redux/actions/GetAvoirClientLig";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "./avoir.scss";
import { Divider, Fab } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import translate from "../../i18n/translate";
import "./Responsiveav.css";

const roundTo = require("round-to");

const actions = [{ icon: <PictureAsPdfIcon />, name: "PDF" }];

const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

class GetAvoirClientByIdModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      ligid: "",
      blid: "",
      tab: [],
      newtab: [],

      codearticle: 0,
      des: "",
      qte: 0,
      unite: 0,
      puht: 0,
      faudec: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      totalht: 0,

      totalqte: 0,
      netapayer: 0,
      clientimp: [],
    };
  }

  componentDidMount() {
    // this.props.SelectLig();
    this.props.SelectAvoirClientLig(
      this.props.blid,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  handleOpen = () => {
    this.setState({ open: true });
    //sélectionner un client by codeclient
    fetch(
      `https://www.24-crm.com/webservice/api/Clients?codeclient=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    // this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true, blid: this.props.blid });
    this.props.fligs.fligs.map((t) => {
      this.setState({ ligid: t.id });
    });
  };

  annuler = () => {
    window.alert("annuler");
  };

  imprimer = () => {
    window.alert("imprimer");
  };

  supprimer = () => {
    if (
      window.confirm(translate("êtes-vous sûr de vouloir supprimer cet avoir?"))
    ) {
      //supprimer l'élément de la table ligblbr
      fetch(
        `https://www.24-crm.com/webservice/Api/LigFACCLIs/${this.props.blid}?typefacc=AT&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      //supprimer l'élément de la table blbr
      fetch(
        `https://www.24-crm.com/webservice/api/FACCLIs/${this.props.blid}?typefacc=AT&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,

        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectAvoir(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          this.setState({ snackbaropen: true, snackbarmsg: result });
        });
    }
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    const { darck } = this.props;
    const actions = [
      // { icon: <PrintIcon />, name: "Imprimer" },

     //{ icon: <DeleteOutlineIcon />, name: translate("Supprimer") },
      
    ];
    const altText = this.props.ConfigDossier.coddos+".jpg";
    const { ligidd, bcidd, tabb } = this.state;
    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
            >
          <Modal.Header
            // closeButton
            // style={{ backgroundColor: "white", color: "#020F64" }}
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,}}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                {" "}
                {translate("Avoir N°")}
                {this.props.blid} {translate("du")}{" "}
                {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}{" "}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  style={{
                                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                  border: `${darck ? "" : "2px solid #fff"}`,
                                  borderBottom: `none`,
                                }}>
            <Card>
              <Card.Body style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                              }}>
                {this.props.MG ? (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                          {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Clients")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                          {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }} >
                          {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Pièce Jointe")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }} >
                        {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.pj}
                        {/* {this.props.typcom}: {this.props.numcom} */}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label  style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Magasin</Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }} >
                        {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.MG}
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }} >
                          {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Clients")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label  style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                        {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                        {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }} > 
                        {/* style={{ color: "#020f64" }} */}
                          {" "}
                          {translate("Pièce Jointe")}{" "}
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}> 
                        {/* style={{ color: "grey", fontSize: "18px" }} */}
                        {this.props.numcom}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}>
                <div className="tabf28">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                        <th>Code</th>
                        <th> {translate("Désignation")} </th>
                        <th> {translate("Quantité")} </th>
                        <th> {translate("PUHT")} </th>
                        <th> {translate("Remise")} </th>
                        <th> {translate("TVA")} </th>
                        <th> {translate("TotalHT")} </th>
                        <th> {translate("TotalTTC")} </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                        color: `${darck ? "" : "#fff"}`,
                      }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          <td>
                            <span>{t.codart}</span>
                          </td>
                          <td style={{ fontSize: "12px" , width: "37%"}}>{t.desart}</td>
                          <td>
                            <span>{t.quantite}</span>
                          </td>

                          <td>
                            <span>{Number(t.priuni).toFixed(3)}</span>
                          </td>

                          <td>
                            <span>{Number(t.remise).toFixed(2)}</span>
                          </td>
                          <td>
                            <span>{Number(t.tautva).toFixed(2)}</span>
                          </td>
                          <td>
                            <span>{Number(t.montht).toFixed(3)}</span>
                          </td>
                          <td>
                            <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="av1">
              <Card.Body style={{
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}>
                <Row style={{ marginBottom: "-10px" }}>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Total HT Brut")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }} > 
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Remise Article")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}> 
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {translate("Remise Globale")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {translate("Total HT Net")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }} >
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {translate("Total TVA")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Total TTC")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }} >
                      {/* style={{ color: "black" }} */}
                      {Number(
                        this.props.totalttc -
                          this.props.valtimbre -
                          this.props.avance
                      ).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Valeur de timbre")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}  >
                      {/* style={{ color: "black" }} */}
                      {Number(this.props.valtimbre).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }} >
                      {/* style={{ color: "black" }} */}
                      {/* {Number(this.state.totaltva).toFixed(3)} */}
                      {Number(this.props.avance).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Net à Payer")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {/* style={{ color: "black", fontWeight: "bold" }} */}
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}

            <Card style={{ marginTop: "10px" }} id="av2">
              <Card.Body  style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}>
                <Row className="rowav">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      Total  <br/>HT Brut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Remise Article")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      {translate("Remise Globale")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="av">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowav">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                     Total <br/>HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      Total <br/>TVA
                                          </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }} >
                      {/* style={{ color: "darkslateblue", marginBottom: "-5px" }} */}
                      Total <br/>TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        this.props.totalttc -
                          this.props.valtimbre -
                          this.props.avance
                      ).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="av">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowav">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }} >
                      Valeur Timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.valtimbre).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }} >
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.avance).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net <br/>à Payer
                                          </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row style={{ marginTop: "20px" }}>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "10px",
                  }}
                  FabProps={{ size: "small" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        //  action.name == "Imprimer" && this.imprimer();
                        // action.name == "Modifier" && this.openModifier();
                        action.name == "Supprimer" && this.supprimer();
                        // action.name == "Annuler" && this.annuler();
                      }}
                    />
                  ))}
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>

        {/* /////////////////// print //////////////////////// */}

        <div style={{ display: "none" }}>
          <div
            ref={(el) => (this.componentRef = el)}
            style={{ margin: "40px  35px", height: "94%" }}
          >

    {/* logoetat */}        
      {/* //<img
  // src={altText}
  src={require("../assests/img/comaf.jpg")}
  style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} /> */}


 <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  


            <h1 style={{ fontSize: "50px", fontWeight: "bolder" }}>
              {translate("Société")}: {this.props.InfoEntreprise[0].coddos}
            </h1>
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              {this.props.InfoEntreprise[0].spe}
              <br />
              {translate("Adresse")} : {this.props.InfoEntreprise[0].adr} <br />
              Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
              {this.props.InfoEntreprise[0].tel2} / Email:{" "}
              {this.props.InfoEntreprise[0].email} <br />
              {translate("Code TVA")}: {this.props.InfoEntreprise[0].codtva}{" "}
              <br />
              RIB: {this.props.InfoEntreprise[0].rib}{" "}
              {this.props.InfoEntreprise[0].matban}{" "}
              {this.props.InfoEntreprise[0].agence}{" "}
              {this.props.InfoEntreprise[0].ville}
              <br />
              {translate("Etabli par")}: {this.props.Auth.username}
            </p>
            <h5
              style={{
                marginLeft: "25px",
                flexDirection: "column",
                marginTop: "25px",
                marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >
              {translate("№ Avoir")} {this.props.blid}{" "}
              {translate("sur Facture N°")} {this.props.pj}
            </h5>
            <Row>
              <Col>
                <h6 style={{ marginLeft: "175px", marginTop: "40px" }}>
                  Date:{" "}
                  {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>
                    {" "}
                    {translate("Code client")} : {this.props.client}
                  </h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>{t.ville}</h5> : <h5>--</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6> {translate("Code TVA")} : --</h6>
                  ) : (
                    <h6>
                      {" "}
                      {translate("Code TVA")} : {t.codtva}
                    </h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "1px",
                  width: "100%",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <tr style={{ borderBottom: "1px solid grey" }}>
                    <th>Code</th>
                    <th style={{ width: "37%" }}>
                      {" "}
                      {translate("Désignation")}{" "}
                    </th>
                    <th> {translate("Quantité")} </th>
                    <th> {translate("PUHT")} </th>
                    <th>{translate("Remise")} </th>
                    <th> {translate("TVA")} </th>
                    <th> {translate("TotalHT")} </th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        height: "60px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td>{t.quantite}</td>
                      <td>{Number(t.priuni).toFixed(3)}</td>

                      <td>{Number(t.remise).toFixed(2)}</td>
                      <td>{Number(t.tautva).toFixed(2)}</td>

                      <td>{Number(t.montht).toFixed(3)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <br /> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
              <table
                          style={{
                            textAlign: "center",
                            borderStyle: "solid",
                            width: "450px",
                            borderWidth: "1px",
                          }}
                        >
                          <thead
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <tr>
                              <th>{translate("TVA")}</th>
                              <th>{translate("Assiette")}</th>
                              <th>{translate("Montant")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Initialize the totals */}
                            {(() => {
                              const tvaGroups = {};

                              // Grouping by TVA and summing the values
                              this.props.tabtab.forEach((t) => {
                                const tvaRate = Number(t.tautva).toFixed(2);

                                if (!tvaGroups[tvaRate]) {
                                  tvaGroups[tvaRate] = { assiette: 0, montant: 0 };
                                }

                                tvaGroups[tvaRate].assiette += Number(t.montht);
                                tvaGroups[tvaRate].montant += Number(t.ttclig - t.montht);
                              });

                              // Render the rows for each TVA group
                              return Object.keys(tvaGroups).map((tvaRate) => (
                                <tr key={tvaRate} style={{ height: "50px" }}>
                                  <td>{tvaRate}</td>
                                  <td>{tvaGroups[tvaRate].assiette.toFixed(3)}</td>
                                  <td>{tvaGroups[tvaRate].montant.toFixed(3)}</td>
                                </tr>
                              ));
                            })()}
                          </tbody>
                        </table>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b> {translate("Total quantité")}: </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp; {translate("Total HT Brut")}:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp; {translate("Remise Globale")}:
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp; {translate("Total HT Net")}:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp; {translate("Total TVA")}:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp; {translate("Timbre fiscal")}:
                  </td>
                  {/* <td>{this.props.droitdetimbre}</td> */}
                  <td>{Number(this.props.valtimbre).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;{translate("Net à Payer")}:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {/* {Number(this.props.totalttc).toFixed(3)}{" "} */}
                    {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                  </td>
                </tr>
              </table>
            </div>
            {/* <br />
            <br /> */}

            {/* //////////////////////// footer ///////////////////////////// */}

            {Number(this.props.remiseglobale) != 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp; {translate("Signature Client")}:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp; {translate("Signature & cachet")}:
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "25px",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature Client:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature & cachet:
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectAvoirClientLig: (a, b, c, d, e, f) =>
      dispatch(SelectAvoirClientLig(a, b, c, d, e, f)),
  };
}

function mapStateToProps(state) {
  return {
    avoirsligs: state.avoirsligs,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetAvoirClientByIdModal);
