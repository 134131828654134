import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import "../styling/Styles.css";
import "./bs.scss";

import { SelectFournisseur } from "../../redux/actions/GetFournisseur";

import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
// import { SelectArticle } from "../../redux/actions/GetArticles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { SelectBECod } from "../../redux/actions/GetBECode";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import LigBTModal from "./LigBTModal";
import { SelectBSCod } from "../../redux/actions/GetBSCod";
import { SelectBS } from "../../redux/actions/GetBS";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import "./Responsivebs.css";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddBTModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],

      nextsubmit: false,

      snackbaropen: false,
      snackbarmsg: ",",
      username: username,

      valtimbre: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,

      clicked: false,
      nomenclature: [],
      destination: "",
      numnum: "",

      rechsfrs: [],

      voiture: [],
      MT: "",

      coderep: "",
      representantlist: [],
      nomrep: "",
      GMM: "",
      magasin: [],
      MG: "",
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectBSCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectBECod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );

    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
    // this.setState({ rechs: this.props.clients.clients });
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
    });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) * this.state.netapayer +
        Number(this.state.valtimbre),
    });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  // DestinationChange = (event) => {
  //   this.setState({ type: event.target.value });
  // };
  enregistrer = (event) => {
    event.preventDefault();

    fetch(
      `https://www.24-crm.com/webservice/api/BSRS?numfac&typfac=BS&codcli=${event.target.codcli.value}&MT=${this.state.MT}&nom=${this.state.nomrep}&raisoc=${event.target.raissoc.value}&catfisc=${event.target.categoriefiscale.value}&datfac=${this.state.defaultdate}&taurem=${this.state.remiseg}&codrep=${this.state.coderep}&CodDep=${this.state.MG}&vendeur=${this.props.Auth.username}&Destination=${this.state.destination}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k, index) => {
          fetch(
            `https://www.24-crm.com/webservice/api/LigBSRS/{ID}?numfac=${res}&typfac=BS&numlig=${index}&codart=${k.codearticle}&desart=${k.des}&quantite=${k.qte}&priuni=${k.puht}&remise=${k.remisea}&codtva=${k.codtva}&tautva=${k.tva}&fodart=${k.faudec}&datfac=${this.state.defaultdate}&unite=${k.unite}&CB=${k.codbar}&montht=${k.totalht}&pmp=${k.pmp}&formqt=0&coddepp=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                console.log(result);
              },
              (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: false });
              }
            );
        });
      })
      .then((res) => {
        fetch(
          `https://www.24-crm.com/webservice/api/BSRS?NumBS=${this.state.numnum}&coddep=${this.state.destination}&NumBE=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "POST",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            this.props.onHide();
            this.props.SelectBECod(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );

            fetch(
              `https://www.24-crm.com/webservice/api/LigBEREs?FACc=${this.props.codbes.codbes.map(
                (el) => el.valeur
              )}&typfacc=BE&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                this.props.ConfigDossier.serveur
              }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                this.props.ConfigDossier.pwd_serveur
              }&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
            this.props.SelectBECod(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
          });
      })
      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectBS(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          fetch(
            `https://www.24-crm.com/webservice/api/LigBSRS?FAC=${this.state.numnum}&typfacc=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.SelectBSCod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )

      .then((res) => {
        //////////// switch update ////////////////

        fetch(
          `https://www.24-crm.com/webservice/api/Switch?code=BS2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
             // window.location.reload();
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          );
        // .then((res) => {
        //   window.location.reload();
        // });
      });
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };
  
  fournisseurHandlerChange = (event) => {
    this.setState({ codcli: event.target.value });
    fetch(
      `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsfrs: data, clicked: true }));
  };
  render() {
    const { dvnumfac, dvraisoc, rem, clientmail, val } = this.state;
    
    let addModalClose1 = () => this.setState({ addModalShow1: false });
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Bon de Transfert</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  {this.state.GMM ? (
                    <Row form>
                      <Col sm={3}>
                        <FormGroup>
                          {/* {this.props.bscods.bscods.map((t) => ( */}
                          <TextField
                            // className="card add-input"
                            id="standard-basic"
                            label="№ BS"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            // value={parseInt(t.valeur)}
                            value={parseInt(
                              this.props.bscods.bscods[0] &&
                                this.props.bscods.bscods[0].valeur
                                
                            )}
                            
                            fullWidth
                            name="codbl"
                            disabled

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                          {/* ))} */}
                        </FormGroup>
                      </Col>
                      {/* <Col sm={5}></Col> */}
                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          defaultValue={this.state.defaultdate}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.nomenclature}
                            getOptionLabel={(option) =>
                              ` ${option.code} : ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    destination: getOptionLabel.code,
                                    btnEnable: true,
                                  })
                                : this.setState({
                                    destination: "",
                                  });
                              getOptionLabel && getOptionLabel.code != "CL"
                                ? this.setState({
                                    raisonsocial: getOptionLabel.lib,
                                  })
                                : this.setState({ raisonsocial: "" });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Destination"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=DS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        nomenclature: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="destination"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}: ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                  })
                                : this.setState({
                                    MG: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                      style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row form>
                      <Col sm={4}>
                        <FormGroup>
                          {/* {this.props.bscods.bscods.map((t) => ( */}
                          <TextField
                            // className="card add-input"
                            id="standard-basic"
                            label="№ BS"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            // value={parseInt(t.valeur)}
                            value={parseInt(
                              this.props.bscods.bscods[0] &&
                                this.props.bscods.bscods[0].valeur
                            )}
                            fullWidth
                            name="codbl"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                          {/* ))} */}
                        </FormGroup>
                      </Col>
                      {/* <Col sm={5}></Col> */}
                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          defaultValue={this.state.defaultdate}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.nomenclature}
                            getOptionLabel={(option) =>
                              ` ${option.code} : ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    destination: getOptionLabel.code,
                                    btnEnable: true,
                                  })
                                : this.setState({
                                    destination: "",
                                  });
                              getOptionLabel && getOptionLabel.code != "CL"
                                ? this.setState({
                                    raisonsocial: getOptionLabel.lib,
                                  })
                                : this.setState({ raisonsocial: "" });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Destination"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=DS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        nomenclature: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="destination"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {this.state.destination == "CL" ? (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "gray" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <ThemeProvider theme={theme}>
                                    <Switch
                                      checked={this.state.gilad}
                                      onChange={this.handleChange("gilad")}
                                      value="gilad"
                                      color="primary"
                                    />
                                  </ThemeProvider>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>
                        {this.state.gilad ? (
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.codcli}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        remiseg: getOptionLabel.remise,
                                        codeclient: getOptionLabel.codcli,
                                        categoriefiscale:
                                          getOptionLabel.catfisc,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        showForfitaire:
                                          getOptionLabel.regimecli,
                                        showButtonModalPassager:
                                          getOptionLabel.passager,
                                        cemail: getOptionLabel.email,
                                        valtimbre: getOptionLabel.timbre
                                          ? this.props.valtimbre
                                          : 0,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        remiseg: 0,
                                        codeclient: "",
                                        categoriefiscale: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showForfitaire: 0,
                                        showButtonModalPassager: false,
                                        valtimbre: 0,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Code client"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.clientHandlerChange}
                                    name="codcli"
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                        style: {
                                          color: !darck ? "#fff" : null,
                                        },
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={5}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.raisoc}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        remiseg: getOptionLabel.remise,
                                        codeclient: getOptionLabel.codcli,
                                        categoriefiscale:
                                          getOptionLabel.catfisc,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        showForfitaire:
                                          getOptionLabel.regimecli,
                                        showButtonModalPassager:
                                          getOptionLabel.passager,
                                        cemail: getOptionLabel.email,
                                        valtimbre: getOptionLabel.timbre
                                          ? this.props.valtimbre
                                          : 0,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        remiseg: 0,
                                        codeclient: "",
                                        categoriefiscale: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showForfitaire: 0,
                                        showButtonModalPassager: false,
                                        valtimbre: 0,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Raison sociale"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.clientHandlerChange}
                                    name="raissoc"
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                        style: {
                                          color: !darck ? "#fff" : null,
                                        },
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {this.state.gilad ? (
                          <Col sm={5}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.raisonsocial}
                                fullWidth
                                name="raissoc"
                                disabled={
                                  this.state.codeclient === "100" ? false : true
                                }
                                onChange={this.raisocHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.codeclient}
                                fullWidth
                                name="codcli"
                                disabled

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <Row form>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fiscale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>

                        <Col sm={3}>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.voiture}
                            getOptionLabel={(option) => ` ${option.lib}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MT: getOptionLabel.code,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    MT: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Véhicule"
                               // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MT&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        voiture: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="véhicule"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>

                        <Col sm={5}>
                          {/* essai */}

                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.representantlist}
                            getOptionLabel={(option) => ` ${option.nom}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    coderep: getOptionLabel.codrep,
                                    nomrep: getOptionLabel.nom,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    nomrep: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Representant"
                               // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        representantlist: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="representant"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : this.state.destination == "FR" ? (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "grey" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <Switch
                                    checked={this.state.gilad}
                                    onChange={this.handleChange("gilad")}
                                    value="gilad"
                                    color="primary"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>
                        {this.state.gilad ? (
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                options={
                                  this.state.clicked
                                    ? this.state.rechsfrs
                                    : this.props.fournisseurs.fournisseurs
                                }
                                getOptionLabel={(option) => option.codfrs}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codfrs,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        cemail: getOptionLabel.email,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Code Frounisseur"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.fournisseurHandlerChange}
                                    name="codcli"
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={5}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechsfrs
                                    : this.props.fournisseurs.fournisseurs
                                }
                                getOptionLabel={(option) => option.raisoc}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codfrs,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        cemail: getOptionLabel.email,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Raison sociale"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.fournisseurHandlerChange}
                                    name="raissoc"
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {this.state.gilad ? (
                          <Col sm={5}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Raison sociale"
                               // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.raisonsocial}
                                fullWidth
                                name="raissoc"
                                disabled={
                                  this.state.codeclient === "100" ? false : true
                                }
                                onChange={this.raisocHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Code Fournisseur"
                               // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.codeclient}
                                fullWidth
                                name="codcli"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fiscale"
                           // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>

                        <Col sm={3}>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.voiture}
                            getOptionLabel={(option) => ` ${option.lib}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MT: getOptionLabel.code,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    MT: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Véhicule"
                              //  className="card add-input"
                                margin="dense"
                                variant="outlined"

                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MT&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        voiture: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="véhicule"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>

                        <Col sm={5}>
                          {/* essai */}

                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.representantlist}
                            getOptionLabel={(option) => ` ${option.nom}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    coderep: getOptionLabel.codrep,
                                    nomrep: getOptionLabel.nom,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    nomrep: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Representant"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                     style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        representantlist: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="representant"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  ):
                  //  this.state.destination == "01" ?(
                  //   <>
                  //     <Row form>
                  //       <Col sm={3} id="switch">
                  //         <FormGroup style={{ marginTop: "25px" }}>
                  //           {/* <Typography component="div">
                  //             <Grid
                  //               component="label"
                  //               container
                  //               alignItems="center"
                  //               spacing={1}
                  //             >
                  //               <Grid
                  //                 item
                  //                 style={{
                  //                   color: `${darck ? "gray" : "#fff"}`,
                  //                 }}
                  //               >
                  //                 Raison sociale
                  //               </Grid>
                  //               <Grid item>
                  //                 <Switch
                  //                   checked={this.state.gilad}
                  //                   onChange={this.handleChange("gilad")}
                  //                   value="gilad"
                  //                   color="primary"
                  //                   // disabled
                  //                 />
                  //               </Grid>
                  //               <Grid
                  //                 item
                  //                 style={{
                  //                   color: `${darck ? "#3f51b5" : "#fff"}`,
                  //                 }}
                  //               >
                  //                 Code
                  //               </Grid>
                  //             </Grid>
                  //           </Typography> */}
                  //         </FormGroup>
                  //       </Col>
                  //       <Col sm={3} id="switch"></Col>
                  //       {this.state.gilad ? (
                  //         <Col sm={3}>
                  //           <FormGroup>
                  //             <TextField
                  //               id="standard-basic"
                  //               label="Origine"
                  //               // className="card add-input"
                  //               margin="dense"
                  //               variant="outlined"
                  //               value={this.state.raisonsocial}
                  //               fullWidth
                  //               name="raissoc"
                  //               disabled
                  //               onChange={this.raisocHandler}
                  //               InputProps={{
                  //                 className: !darck
                  //                   ? this.props.classes.input
                  //                   : null,
                  //                 style: {
                  //                   color: !darck ? "#fff" : null,
                  //                 },
                  //               }}
                  //               InputLabelProps={{
                  //                 style: {
                  //                   color: !darck ? "#fff" : null,
                  //                 },
                  //               }}
                  //             />
                  //           </FormGroup>
                  //         </Col>
                  //       ) : (
                  //         <Col sm={3}>
                  //           <FormGroup>
                  //             <TextField
                  //               id="standard-basic"
                  //               label="Code client"
                  //               // className="card add-input"
                  //               margin="dense"
                  //               variant="outlined"
                  //               value={this.state.codeclient}
                  //               fullWidth
                  //               name="codcli"
                  //               disabled
                  //               InputProps={{
                  //                 className: !darck
                  //                   ? this.props.classes.input
                  //                   : null,
                  //                 style: {
                  //                   color: !darck ? "#fff" : null,
                  //                 },
                  //               }}
                  //               InputLabelProps={{
                  //                 style: {
                  //                   color: !darck ? "#fff" : null,
                  //                 },
                  //               }}
                  //             />
                  //           </FormGroup>
                  //         </Col>
                  //       )}
                  //       {this.state.gilad ? (
                  //         <Col sm={3}>
                  //           <FormGroup>
                  //             <Autocomplete
                  //               id="include-input-in-list"
                  //               includeInputInList
                  //               className="ajouter-client-input"
                  //               // disabled
                  //               // options={this.props.clients.clients}
                  //               options={
                  //                 this.state.clicked
                  //                   ? this.state.rechs
                  //                   : this.props.clients.clients
                  //               }
                  //               getOptionLabel={(option) => option.codcli}
                  //               onChange={(event, getOptionLabel) => {
                  //                 getOptionLabel
                  //                   ? this.setState({
                  //                       raisonsocial: getOptionLabel.raisoc,
                  //                       remiseg: getOptionLabel.remise,
                  //                       codeclient: getOptionLabel.codcli,
                  //                       categoriefiscale:
                  //                         getOptionLabel.catfisc,
                  //                       btnEnable: true,
                  //                       showTimbre: getOptionLabel.timbre,
                  //                       showForfitaire:
                  //                         getOptionLabel.regimecli,
                  //                       showButtonModalPassager:
                  //                         getOptionLabel.passager,
                  //                       cemail: getOptionLabel.email,
                  //                       valtimbre: getOptionLabel.timbre
                  //                         ? this.props.valtimbre
                  //                         : 0,
                  //                     })
                  //                   : this.setState({
                  //                       raisonsocial: "",
                  //                       remiseg: 0,
                  //                       codeclient: "",
                  //                       categoriefiscale: "",
                  //                       btnEnable: false,
                  //                       showTimbre: false,
                  //                       showForfitaire: 0,
                  //                       showButtonModalPassager: false,
                  //                       valtimbre: 0,
                  //                     });
                  //               }}
                  //               renderInput={(params) => (
                  //                 <TextField
                  //                   // {...params}
                  //                   label="Code client"
                  //                   // className="card add-input"
                  //                   margin="dense"
                  //                   variant="outlined"
                  //                   fullWidth
                  //                   name="codcli"
                  //                   // disabled
                  //                   InputProps={{
                  //                     className: !darck
                  //                       ? this.props.classes.input
                  //                       : null,
                  //                     style: {
                  //                       color: !darck ? "#fff" : null,
                  //                     },
                  //                   }}
                  //                   InputLabelProps={{
                  //                     style: {
                  //                       color: !darck ? "#fff" : null,
                  //                     },
                  //                   }}
                  //                 />
                  //               )}
                  //             />
                  //           </FormGroup>
                  //         </Col>
                  //       ) : (
                  //         <Col sm={5}>
                  //           <FormGroup>
                  //             <Autocomplete
                  //               id="include-input-in-list"
                  //               includeInputInList
                  //               className="ajouter-client-input"
                  //               // options={this.props.clients.clients}
                  //               // options={this.state.rechs}
                  //               options={
                  //                 this.state.clicked
                  //                   ? this.state.rechs
                  //                   : this.props.clients.clients
                  //               }
                  //               getOptionLabel={(option) => option.raisoc}
                  //               onChange={(event, getOptionLabel) => {
                  //                 getOptionLabel
                  //                   ? this.setState({
                  //                       raisonsocial: getOptionLabel.raisoc,
                  //                       remiseg: getOptionLabel.remise,
                  //                       codeclient: getOptionLabel.codcli,
                  //                       categoriefiscale:
                  //                         getOptionLabel.catfisc,
                  //                       btnEnable: true,
                  //                       showTimbre: getOptionLabel.timbre,
                  //                       showForfitaire:
                  //                         getOptionLabel.regimecli,
                  //                       showButtonModalPassager:
                  //                         getOptionLabel.passager,
                  //                       cemail: getOptionLabel.email,
                  //                       valtimbre: getOptionLabel.timbre
                  //                         ? this.props.valtimbre
                  //                         : 0,
                  //                     })
                  //                   : this.setState({
                  //                       raisonsocial: "",
                  //                       remiseg: 0,
                  //                       codeclient: "",
                  //                       categoriefiscale: "",
                  //                       btnEnable: false,
                  //                       showTimbre: false,
                  //                       showForfitaire: 0,
                  //                       showButtonModalPassager: false,
                  //                       valtimbre: 0,
                  //                     });
                  //               }}
                  //               renderInput={(params) => (
                  //                 <TextField
                  //                   // {...params}
                  //                   label="Raison sociale"
                  //                   // className="card add-input"
                  //                   margin="dense"
                  //                   variant="outlined"
                  //                   fullWidth
                  //                   name="raissoc"
                  //                   // disabled
                  //                   InputProps={{
                  //                     className: !darck
                  //                       ? this.props.classes.input
                  //                       : null,
                  //                     style: {
                  //                       color: !darck ? "#fff" : null,
                  //                     },
                  //                   }}
                  //                   InputLabelProps={{
                  //                     style: {
                  //                       color: !darck ? "#fff" : null,
                  //                     },
                  //                   }}
                  //                 />
                  //               )}
                  //             />
                  //           </FormGroup>
                  //         </Col>
                  //       )}
                       
                  //     </Row>

                     
                  //   </>
                  // ):
                  (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "gray" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <Switch
                                    checked={this.state.gilad}
                                    onChange={this.handleChange("gilad")}
                                    value="gilad"
                                    color="primary"
                                    disabled
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>
                        {this.state.gilad ? (
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                disabled
                                // options={this.props.clients.clients}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.codcli}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        remiseg: getOptionLabel.remise,
                                        codeclient: getOptionLabel.codcli,
                                        categoriefiscale:
                                          getOptionLabel.catfisc,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        showForfitaire:
                                          getOptionLabel.regimecli,
                                        showButtonModalPassager:
                                          getOptionLabel.passager,
                                        cemail: getOptionLabel.email,
                                        valtimbre: getOptionLabel.timbre
                                          ? this.props.valtimbre
                                          : 0,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        remiseg: 0,
                                        codeclient: "",
                                        categoriefiscale: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showForfitaire: 0,
                                        showButtonModalPassager: false,
                                        valtimbre: 0,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Code client"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    name="codcli"
                                    disabled
                                    InputProps={{
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={5}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.raisoc}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        remiseg: getOptionLabel.remise,
                                        codeclient: getOptionLabel.codcli,
                                        categoriefiscale:
                                          getOptionLabel.catfisc,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        showForfitaire:
                                          getOptionLabel.regimecli,
                                        showButtonModalPassager:
                                          getOptionLabel.passager,
                                        cemail: getOptionLabel.email,
                                        valtimbre: getOptionLabel.timbre
                                          ? this.props.valtimbre
                                          : 0,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        remiseg: 0,
                                        codeclient: "",
                                        categoriefiscale: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showForfitaire: 0,
                                        showButtonModalPassager: false,
                                        valtimbre: 0,
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Raison sociale"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    name="raissoc"
                                    disabled
                                    InputProps={{
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {this.state.gilad ? (
                          <Col sm={5}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.raisonsocial}
                                fullWidth
                                name="raissoc"
                                disabled
                                onChange={this.raisocHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.codeclient}
                                fullWidth
                                name="codcli"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <Row form>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fiscale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value="0"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: darck ? "#007bff" : "#fff",
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>

                        <Col sm={3}>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.voiture}
                            getOptionLabel={(option) => ` ${option.lib}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MT: getOptionLabel.code,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    MT: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={translate("N° facture")}
                                label="Véhicule"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              
                               
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MT&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        voiture: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="véhicule"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>

                        <Col sm={5}>
                          {/* essai */}

                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.representantlist}
                            getOptionLabel={(option) => ` ${option.nom}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    coderep: getOptionLabel.codrep,
                                    nomrep: getOptionLabel.nom,
                                    //btnEnable: true,
                                  })
                                : this.setState({
                                    nomrep: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Representant"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        representantlist: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="representant"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{ fontSize: "16px" }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addbs">
                            {this.state.GMM ? (
                              this.state.btnEnable && this.state.MG ? (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        disabled
                                        style={{
                                       
                                         color: "white",
                                          width: "40px",
                                          height: "40px",
                                          
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{ width: "40px", height: "40px", backgroundColor: "#3f51b5"}}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tabbs2">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th>
                              <th>PU HT</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>PUTTCNet</th>
                              <th>TotalHT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td
                                  style={{  color: `${darck ? "black" : "#fff"}` }}
                                >
                                  <span id="codart">{t.codearticle}</span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    width: "37%",
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  <span> {t.des} </span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td
                                  style={{
                                    color: `${darck ? "black" : "#fff"}`,
                                  }}
                                >
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="bs1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur de Timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="bs2">
                <Card.Body
                                                          style={{
                                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                                            border: `${darck ? "" : "2px solid #fff"}`,
                                                          }}
                >
                  <Row className="rowbs">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bs">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbs">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}`, marginBottom: "-5px" }}>
                        {Number(this.state.totaltva).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bs">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbs">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                       style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                       style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.netnetapayer).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bs">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbs">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur Timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      <p
                        style={{
                          color: `${darck ? "black" : "#fff"}`,
                          fontWeight: "bold",
                        }}
                      >
                        {Number(this.state.netnetapayer).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {this.state.totalqte === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      type="submit"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <LigBTModal
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          rem={rem}
          // numfaccc={
          //   this.props.bscods.bscods.map((nu) => parseInt(nu.valeur))[0]
          // }
          numfaccc={0}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) => {
      dispatch(SelectFournisseur(a, b, c, d, e));
    },
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    SelectBSCod: (a, b, c, d, e) => dispatch(SelectBSCod(a, b, c, d, e)),
    SelectBS: (a, b, c, d, e) => dispatch(SelectBS(a, b, c, d, e)),
    SelectBECod: (a, b, c, d, e) => dispatch(SelectBECod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    bscods: state.bscods,
    codbes: state.codbes,
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    fournisseurs: state.fournisseurs,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBTModal));
