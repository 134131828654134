import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Switch,
  Grid,
  Snackbar,
  IconButton,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import "../styling/Styles.css";
import { SelectClient } from "../../redux/actions/GetClients";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {SelectCaisse} from "../../redux/actions/GetCaisse";
const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class ModifierClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: this.props.passager,
      timbre: this.props.timbre,
      soustraitant: this.props.soustraitant,
      snackbaropen: false,
      snackbarmsg: ",",

      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      categoriefiscale: "",
    };
  }

  componentDidMount() {
    this.setState({ categoriefiscale: this.props.categoriefiscale });
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  ATTHandler = (event) => {
    this.setState({ attestation: event.target.value });
  };
  
  submitHandler = (event) => {
    event.preventDefault();
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
   if(this.props.numoprdebit===""){
    const requestBody1= {
      numopr: this.props.numopr,
      datopr: this.state.defaultdate || new Date().toISOString().split('T')[0],
      libelle: this.state.libelle || this.props.libelle,
      modreg: this.state.codmodreg || this.props.modreg,
      numcais: this.state.codcaisse|| this.props.numcais,
      numchq: this.state.numchq || this.props.numchq,
      matban: this.state.codbqclient|| this.props.matban,
      datech: this.state.defaultdate || new Date().toISOString().split('T')[0],
      montant: this.state.montant|| this.props.montant,
      sens:this.state.sens|| this.props.sens,
      depense:this.state.coddepense|| this.props.depense,
      heur: currentTime,
      VENDEUR: this.props.Auth.username,
      numoprdebit: '',
      Emp: this.state.libEmplss || this.props.Emp,
    
    };
    
    fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
          method: "PUT",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody1), // Convert the object to JSON
        })
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
            this.props.SelectCaisse(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
        
            );
        this.setState({ snackbaropen: true, snackbarmsg: result });
        window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
console.log('Request Body:', requestBody1);
   }else{


      fetch(`https://www.24-crm.com/webservice/api/Caisse?id=${this.props.numoprdebit}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
        method: "Delete",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
       // Convert the object to JSON
      })
    .then((res) => res.json())
    .then(
      (result) => {
        this.props.onHide();
          this.props.SelectCaisse(
          this.props.ConfigDossier.coddos,
          this.props.ConfigDossier.serveur,
          this.props.ConfigDossier.utilisateur,
          this.props.ConfigDossier.pwd_serveur,
          this.props.ConfigDossier.database
      
          );
this.setState({ snackbaropen: true, snackbarmsg: result });
      window.location.reload();
      },
      (error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "failed" });
      }
    );


    const requestBody = {
     
      numopr: this.props.numoprdebit,
      datopr: this.state.defaultdate || new Date().toISOString().split('T')[0],
      libelle: this.state.libelle || this.props.libelle,
      modreg: this.state.codmodreg || this.props.modreg,
      numcais: this.state.codcaisse|| this.props.numcais,
      numchq: this.state.numchq || this.props.numchq,
      matban: this.state.codbqclient|| this.props.matban,
      datech: this.state.defaultdate || new Date().toISOString().split('T')[0],
      montant: this.state.montant|| this.props.montant,
      sens:'d',
      depense:this.state.coddepense|| this.props.depense,
      heur: currentTime,
      VENDEUR: this.props.Auth.username,
      numoprdebit: '',
      Emp: this.state.libEmplss || this.props.Emp,
    
    };
    
    // Fetch request to the API
    fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody), // Convert the object to JSON
    })
    .then((res) => {
      if (!res.ok) {
          throw new Error("Network response was not ok");
      }
      return res.json();
    })
    .then((result) => {
      this.props.onHide();
      this.props.SelectCaisse(
          this.props.ConfigDossier.coddos,
          this.props.ConfigDossier.serveur,
          this.props.ConfigDossier.utilisateur,
          this.props.ConfigDossier.pwd_serveur,
          this.props.ConfigDossier.database
      );
    
      this.setState({ snackbaropen: true, snackbarmsg: result });
      this.props.GetCodeCaisse(
          this.props.ConfigDossier.coddos,
          this.props.ConfigDossier.serveur,
          this.props.ConfigDossier.utilisateur,
          this.props.ConfigDossier.pwd_serveur,
          this.props.ConfigDossier.database
      );})
      console.log('Request Body :', requestBody);


    const requestBody2= {
      numopr: this.props.numopr,
      datopr: this.state.defaultdate || new Date().toISOString().split('T')[0],
      libelle: this.state.libelle || this.props.libelle,
      modreg: this.state.codmodreg || this.props.modreg,
      numcais: this.state.codcaisse|| this.props.numcais,
      numchq: this.state.numchq || this.props.numchq,
      matban: this.state.codbqclient|| this.props.matban,
      datech: this.state.defaultdate || new Date().toISOString().split('T')[0],
      montant: this.state.montant|| this.props.montant,
      sens:this.state.sens|| this.props.sens,
      depense:this.state.coddepense|| this.props.depense,
      heur: currentTime,
      VENDEUR: this.props.Auth.username,
      numoprdebit: this.props.numoprdebit,
      Emp: this.state.libEmplss || this.props.Emp,
    
    };
 
    fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
          method: "PUT",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody2), // Convert the object to JSON
        })
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
            this.props.SelectCaisse(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
        
            );
  this.setState({ snackbaropen: true, snackbarmsg: result });
       // window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
    //  

      console.log('Request Body 2:', requestBody2);

   }
      
  };

  render() {
    const { darck, classes } = this.props;
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={6000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier MVT caisse {this.props.numopr}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.submitHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row>
                <Col sm={12}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              style={{ margin: "0" }}
                              // className="card add-input"
                              id="standard-basic"
                              label="N°Mvt caisse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="codcli"
                              defaultValue={this.props.numopr}
                           
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={(e) => this.setState({ datopr: e.target.value })} 
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datopr)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                    </Col>
                     {/* //////////////////////libelle//////////////////////////// */}
                    <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Libellé"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="raisoc"
                              defaultValue={this.props.libelle}
                             // value={this.props.libelle}  // Set value from state
                              onChange={(e) => this.setState({ libelle: e.target.value })} 
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                   

                      <Row form>

                      
                        <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          defaultValue={{ code: this.props.modreg}}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          defaultValue={{ code: this.props.numcais}}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisse: getOptionLabel.code,
                                  libcaisse: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codcaisse: "",
                                  libcaisse: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              // className="card add-input"
                              required

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisse}</FormHelperText>
                      </FormGroup>
                    </Col>
                      </Row>
                      </Card.Body>
                  </Card>
                </Col>
                </Row>
                <Row>
             
                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" , marginTop: "15px"}}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      {/* ///////////////////////Montant/////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Montant"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="emailcli"
                              defaultValue={Number(this.props.montant).toFixed(3)}
                              onChange={(e) => this.setState({ montant: e.target.value })} 
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        
<Col sm={4}>
    <FormGroup>
        <div>
            <label>
                <input
                    type="radio"
                    name="type" // Ensure both radio buttons share the same name
                   // value="recette"
                    defaultValue={this.props.sens}
                    onChange={(e) => this.setState({ sens: e.target.value })} 
                    checked={this.props.sens === 'd'} // Check if sens is 'd' for Recette
                   // onChange={this.handleInputChange} // Handle change
                />{' '}
                Recette
            </label>
            <br />
            <label>
                <input
                    type="radio"
                    name="type" // Ensure both radio buttons share the same name
                    // value="depense"
                    defaultValue={this.props.sens}
                    checked={this.props.sens === 'c'} // Check if sens is 'c' for Dépense
                    onChange={(e) => this.setState({ sens: e.target.value })} 
                   // onChange={this.handleInputChange} // Handle change
                />{' '}
                Dépense
            </label>
        </div>
    </FormGroup>
</Col>

                      </Row>
                        {/* ///////////////////////depense/////////////////////////// */}
                      <Row form>
                      <Col sm={12}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.depenses.depenses}
                          defaultValue={{ code: this.props.depense}}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  coddepense: getOptionLabel.code,
                                  libdepense: getOptionLabel.lib,
                                })
                              : this.setState({
                                coddepense: "",
                                  libdepense: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Dépense"
                              margin="dense"
                              fullWidth
                              name="depense"
                              variant="outlined"
                             
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libdepense}</FormHelperText>
                      </FormGroup>
                    </Col>
                    {this.props.sens==='c'?( <Row>
             <Col sm={12}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          defaultValue={{ code: this.props.numcais}}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisseDest: getOptionLabel.code,
                                  libcaisseDest: getOptionLabel.lib,
                                })
                              : this.setState({
                                codcaisseDest: "",
                                  libcaisseDest: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse Destination"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisseDest}</FormHelperText>
                      </FormGroup>
                    </Col>         
                   
                     </Row> ):null}
             
                      </Row>

                  
                   </Card.Body>
                   </Card>
                   </Col>
                 
                
             
                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" , marginTop: "15px"}}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="N°chéque"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="identcli"
                              defaultValue={this.props.numchq}
                              onChange={(e) => this.setState({ numchq: e.target.value })} 
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={(e) => this.setState({ datech: e.target.value })} 
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datech)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                    </Col>
                      </Row>

                      {/* ////////////////////////banque////////////////////////// */}

                      <Row form>
                      <Col sm={12}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.bqs.bqs}
                          defaultValue={{ code: this.props.matban}}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                codbqclient: getOptionLabel.code,
                                libbqclient: getOptionLabel.lib,
                                })
                              : this.setState({
                                codbqclient: "",
                                libbqclient: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Banque"
                              margin="dense"
                              fullWidth
                              name="depense"
                              variant="outlined"
                             
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.props.libbqclient}</FormHelperText>
                      </FormGroup>
                    </Col>
                      </Row>
                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                      <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.Emplss.Emplss}
                          defaultValue={{ code: this.props.Emp}}
                   
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                codEmplss: getOptionLabel.code,
                                libEmplss: getOptionLabel.lib,
                              
                                })
                              : this.setState({
                                codEmplss: "",
                                libEmplss: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Employé"
                              margin="dense"
                              fullWidth
                              name="depense"
                              variant="outlined"
                             
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.props.libEmplss}</FormHelperText>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="A Contacter"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="contactcli"
                              defaultValue={this.props.Emp}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                
                
                    </Card.Body>
                  </Card>
                </Col>
            </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button color="primary" style={{ width: "100%" }}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
    SelectCaisse: (a, b, c, d, e) => {
      dispatch(SelectCaisse(a, b, c, d, e));
    },
  };
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    nomenclatures: state.nomenclatures,
    css: state.css,
    bqs: state.bqs,
    Emplss:state.Emplss,
    depenses:state.depenses,
    Auth: state.Auth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierClientModal));
