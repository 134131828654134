import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import "./be.scss";
import { FormGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab, IconButton } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import EditArticleModal from "./EditArticleModal";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");

class LigBEArticle extends Component {
  constructor(props) {
    super(props);

    this.handleChangeModal = this.handleChangeModal.bind(this);

    this.state = {
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      addModalShow: false,
      // addModalClose1: false,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      codbar: "",
      mnttva: "",
      qtte: 0,
      btnEnabled: false,
      gilad: true,
      rechs: [],
      codtva: 0,
      snackbarmsg: ",",
      netnetapayer: 0,
      clicked: false,
      changeButton: false,
      pmp: "",
      taufod: 0,

      stkfin: 0,
    };
  }

  componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.setState({
      totalqte: this.props.totalqte,
      sumremisearticle: this.props.sumremisearticle,
      totalhtbrut: this.props.totalhtbrut,
      totaltva: this.props.totaltva,
      totalhtnet: this.props.totalhtnet,
      remiseglobal: this.props.remiseglobal,
      netapayer: this.props.netapayer,
      btnEnabled: this.props.btnEnabled,
      netnetapayer: this.props.netnetapayer,
      avance: this.props.avance,
      datfac: this.props.defaultdate,
      valtimbre: this.props.valtimbre,
    });
  }
  handleChangeModal = (event) => {
    if (event.key === "Enter") {
      this.setState({ addModalShow: true });
    }
  };

  articleHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  qteHandler = (event) => {
    this.setState({
      qte: event.target.value,
      puttcnet: Number(
        this.state.puht * (1 + this.state.tva / 100) +
          (this.state.puht * this.props.tauxfod) / 100
      ).toFixed(3),
      totalht: Number(
        event.target.value *
          this.state.puht *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
      mnttva:
        event.target.value *
        this.state.puht *
        ((100 - this.state.remisea) / 100) *
        (this.state.tva / 100),
      taufod: (
        event.target.value *
        ((this.state.puht * this.props.tauxfod) / 100)
      ).toFixed(3),
    });
  };

  remiseHandler = (event) => {
    this.setState({
      remisea: event.target.value,
      totalht:
        this.state.qte * this.state.puht * ((100 - event.target.value) / 100),
      puttcnet: Number(
        this.state.puht *
          (1 + this.state.tva / 100) *
          ((100 - event.target.value) / 100) +
          (this.state.puht *
            ((100 - event.target.value) / 100) *
            this.props.tauxfod) /
            100
      ).toFixed(3),
      mnttva:
        this.state.qte *
        this.state.puht *
        ((100 - event.target.value) / 100) *
        (this.state.tva / 100),
      taufod: (
        (this.state.qte *
          this.state.puht *
          ((100 - event.target.value) / 100) *
          this.props.tauxfod) /
        100
      ).toFixed(3),
    });
  };

  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100),
      puttcnet: (
        event.target.value *
          (1 + this.state.tva / 100) *
          ((100 - this.state.remisea) / 100) +
        (event.target.value * this.props.tauxfod) / 100
      ).toFixed(3),

      mnttva:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100) *
        (this.state.tva / 100),

      taufod: (
        (this.state.qte *
          event.target.value *
          ((100 - this.state.remisea) / 100) *
          this.props.tauxfod) /
        100
      ).toFixed(3),
    });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        ((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100) /
        (1 + this.props.tauxfod / 100)
      ).toFixed(3),
      totalht: (
        this.state.qte *
          ((event.target.value /
            ((100 - this.state.remisea) / 100) /
            (1 + this.state.tva / 100)) *
            ((100 - this.state.remisea) / 100)) -
        (this.state.qte *
          ((event.target.value /
            ((100 - this.state.remisea) / 100) /
            (1 + this.state.tva / 100)) *
            ((100 - this.state.remisea) / 100)) *
          this.props.tauxfod) /
          100
      ).toFixed(3),
      mnttva:
        this.state.qte *
        (
          event.target.value /
          Number((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)
        ).toFixed(3) *
        ((100 - this.state.remisea) / 100) *
        (this.state.tva / 100),

      taufod: (
        ((this.state.qte *
          ((event.target.value /
            ((100 - this.state.remisea) / 100) /
            (1 + this.state.tva / 100)) *
            ((100 - this.state.remisea) / 100)) -
          (this.state.qte *
            ((event.target.value /
              ((100 - this.state.remisea) / 100) /
              (1 + this.state.tva / 100)) *
              ((100 - this.state.remisea) / 100)) *
            this.props.tauxfod) /
            100) *
          this.props.tauxfod) /
        100
      ).toFixed(3),
    });
  };

  onCellChange = (event) => {
    this.setState({ codearticle: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );
    const SumNetNetAPayer =
      this.props.type == "F"
        ? SumNetAPayer + parseInt(this.props.valtimbre)
        : SumNetAPayer;

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbarfail: true,
      netnetapayer: SumNetNetAPayer,
    });
  };

  deleteRowMod = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.te) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      btnEnabled: true,
    });
  };

  submitHandlers = (event) => {
    event.preventDefault();
    const newtab = this.state.tab.concat({
      codearticle: this.state.codearticle,
      des: this.state.des,
      qte: this.state.qte,
      unite: this.state.unite,
      puht: this.state.puht,
      codbar: this.state.codbar,
      faudec: this.state.faudec,
      remisea: event.target.remisea.value,
      tva: this.state.tva,
      puttcnet: this.state.puttcnet,
      totalht: this.state.totalht,
      codtva: this.state.codtva,
      mnttva: this.state.mnttva,
      pmp: this.state.pmp,
    });
    const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      newtab &&
      newtab.reduce(
        (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
        0
      );
    const SumHtBrut =
      newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      newtab &&
      newtab.reduce(
        (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
        0
      );
    const SumRemiseGlobale =
      newtab &&
      newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
        0
      );
    const SumNetNetAPayer =
      this.props.type == "F"
        ? SumNetAPayer + parseFloat(this.props.valtimbre)
        : SumNetAPayer;

    this.setState({
      tab: newtab,

      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
    });

    this.setState({
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      codtva: 0,
      tva: 0,
      codbar: "",
      puttcnet: 0,
      mnttva: "",
      faudec: "N",
      stkfin: 0,
      pmp: "",
      taufod: 0,
    });
  };

  /////// modiifier les modifications /////////////
  modifiermodification = (event) => {
    // event.preventDefault();

    const newtab = this.state.tab.concat({
      codearticle: this.state.codearticle,
      des: this.state.des,
      qte: this.state.qte,
      unite: this.state.unite,
      puht: this.state.puht,
      codbar: this.state.codbar,
      faudec: this.state.faudec,
      remisea: this.state.remisea,
      tva: this.state.tva,
      puttcnet: this.state.puttcnet,
      totalht: this.state.totalht,
      codtva: this.state.codtva,
      mnttva: this.state.mnttva,
      pmp: this.state.pmp,
    });

    const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      newtab &&
      newtab.reduce(
        (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
        0
      );
    const SumHtBrut =
      newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      newtab &&
      newtab.reduce(
        (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
        0
      );
    const SumRemiseGlobale =
      newtab &&
      newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
        0
      );
    const SumNetNetAPayer =
      this.props.type == "F"
        ? SumNetAPayer + parseFloat(this.props.valtimbre)
        : SumNetAPayer;

    this.setState({
      tab: newtab,

      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      changeButton: false,
      netnetapayer: SumNetNetAPayer,
    });

    this.setState({
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      stkfin: 0,
      pmp: "",
      taufod: 0,
    });
  };

  render() {
    let editModalClose = () => this.setState({ editModalShow: false });
    let addModalClose1 = () => this.setState({ addModalShow: false });

    // let getModalClose = () => this.setState({ ADDNumSerie: false });

    // console.log(
    //   this.state.totalqte,
    //   `remise article =${this.state.sumremisearticle}`
    // );
    const sommeTotalHT = this.state.tab.reduce((total, t) => total + Number(t.puttcnet), 0);
    const sommeQuantité = this.state.tab.reduce((total, t) => total + Number(t.qte), 0);
    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            Article ajouté
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            Article supprimé
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Articles</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Row>
              <Col>
                <Card>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <form
                      autocomplete="off"
                      onSubmit={this.submitHandlers}
                      className={`${darck ? "" : classes.root}`}
                    >
                      <Row>
                        <b style={{ color: `${darck ? "" : "#fff"}` }}>Chercher article:</b>
                        <b style={{ marginTop: "10px", marginLeft: "10px" }}>
                          {this.state.faudec === "A" ? (
                            <p style={{ color: "blue" }}>Fodec: ✔</p>
                          ) : null}
                        </b>
                      </Row>

                      <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      remisea: getOptionLabel.remisefrs,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtva: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      faudec: "N",
                                      remisea: 0,
                                      tva: 0,
                                      codtva: 0,
                                      codbar: "",
                                      qte: "",
                                      pmp: "",
                                    });

                                getOptionLabel
                                  ? getOptionLabel.typfodec === "A"
                                    ? this.setState({
                                        puht:
                                          getOptionLabel.PURBHT /
                                          (1 + this.props.tauxfod / 100),
                                      })
                                    : this.setState({
                                        puht: getOptionLabel.PURBHT,
                                      })
                                  : this.setState({ puht: "" });

                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Code article"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  onKeyDown={this.handleChangeModal}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.desart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      remisea: getOptionLabel.remisefrs,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codbar: getOptionLabel.codbar,
                                      codtva: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      addModalShow: true,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      remisea: 0,
                                      codtva: 0,
                                      codbar: "",
                                      tva: 0,
                                      faudec: "N",
                                      qte: "",
                                      pmp: "",
                                      addModalShow: false,
                                    });
                                getOptionLabel
                                  ? getOptionLabel.typfodec === "A"
                                    ? this.setState({
                                        puht:
                                          getOptionLabel.PURBHT /
                                          (1 + this.props.tauxfod / 100),
                                      })
                                    : this.setState({
                                        puht: getOptionLabel.PURBHT,
                                      })
                                  : this.setState({ puht: "" });

                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Désignation article"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codbar}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      remisea: getOptionLabel.remisefrs,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codbar: getOptionLabel.codbar,
                                      codtva: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      addModalShow: true,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      remisea: 0,
                                      codbar: "",
                                      tva: 0,
                                      faudec: "N",
                                      codtva: 0,
                                      qte: "",
                                      pmp: "",
                                      addModalShow: false,
                                    });
                                getOptionLabel
                                  ? getOptionLabel.typfodec === "A"
                                    ? this.setState({
                                        puht:
                                          getOptionLabel.PURBHT /
                                          (1 + this.props.tauxfod / 100),
                                      })
                                    : this.setState({
                                        puht: getOptionLabel.PURBHT,
                                      })
                                  : this.setState({ puht: "" });

                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Code à Barre"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Code article"
                              value={this.state.codearticle}
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onClick={this.handleChangeModal}
                              fullWidth
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Désignation"
                              value={this.state.des}
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={1}>
                          <FormGroup>
                            {/* {this.state.des === "" ? (
                              <TextField
                                id="standard-basic"
                                label="Quantité"
                                type="number"
                                disabled
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{ marginTop: "16px" }}
                                margin="normal"
                                fullWidth
                                required
                              />
                            ) : ( */}
                            <TextField
                              id="standard-basic"
                              label="Qte"
                              type="number"
                              min="0"
                              value={this.state.qte}
                              onChange={this.qteHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              required
                              InputProps={{
                                inputProps: { min: 0 },
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                            {/* )} */}
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              value={`Stock: ${this.state.stkfin}`}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/*                        
                        <ADDNumSerie
                          show={this.state.addModalShow}
                          onHide={addModalClose1}
                          test={this.state.codearticle}
                        /> */}
                      </Row>

                      <Row form>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Taux Fodec"
                              value={Number(this.state.taufod).toFixed(3)}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU HT"
                              value={this.state.puht}
                              fullWidth
                              name="puht"
                              onChange={this.puhtHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Remise %"
                              value={this.state.remisea}
                              fullWidth
                              name="remisea"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onChange={this.remiseHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="TVA"
                              value={this.state.tva}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU TTC Net"
                              value={this.state.puttcnet}
                              fullWidth
                              onChange={this.puttcHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Total HT"
                              value={Number(this.state.totalht).toFixed(3)}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.changeButton ? (
                        <Center>
                          <Button
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.modifiermodification();
                            }}
                          >
                            Enregistrer les modifications
                          </Button>
                        </Center>
                      ) : this.state.des === "" ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      )}
                    </form>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <div className="tabbl2">
                      <table style={{ marginTop: "10px" }}>
                        <thead
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          <tr>
                            <th>Code</th>
                            <th style={{ width: "34%" }}>Désignation</th>
                            <th>Quantité</th>
                            <th>PU HT</th>
                            <th>Remise</th>
                            <th>TVA</th>
                            <th>PUTTCNet</th>
                            <th>TotalHT</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            textAlign: "center",
                            color: `${darck ? "" : "#fff"}`,
                          }}
                        >
                          {this.state.tab.map((t, i) => (
                            <tr key={i}>
                              <td>
                                <span>{t.codearticle}</span>
                              </td>
                              <td style={{ fontSize: "12px", width: "37%" }}>
                                <span> {t.des} </span>
                              </td>
                              <td>
                                {" "}
                                <span> {t.qte}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.puht).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.remisea).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.tva).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.puttcnet).toFixed(3)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.totalht).toFixed(2)}</span>
                              </td>
                              <td>
                                <Tooltip title="Modifier cet article">
                                  <Fab size="small">
                                    <EditIcon
                                      style={{}}
                                      onClick={() => {
                                        fetch(
                                          `https://www.24-crm.com/webservice/api/ARTICLEs?codartt=${t.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                        )
                                          .then((response) => response.json())
                                          .then((data) =>
                                            this.setState({
                                              stkfin: data.map((t) => t.stkfin),
                                            })
                                          );
                                        this.setState({
                                          codearticle: t.codearticle,
                                          des: t.des,
                                          unite: t.unite,
                                          puht: t.puht,
                                          remisea: t.remisea,
                                          tva: t.tva,
                                          faudec: t.faudec,
                                          qte: t.qte,
                                          totalht: t.totalht,
                                          puttcnet: t.puttcnet,
                                          changeButton: true,
                                        });
                                        this.deleteRowMod(i);
                                      }}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip title="Supprimer cet article">
                                  <Fab size="small">
                                    <DeleteIcon
                                      style={{}}
                                      onClick={() => this.deleteRow(i)}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
  {/*Pour l'affichager de la totale de quantité et somme prixTTC */}
                    <Row style={{ marginBottom: "-15px", marginTop: "10px" ,marginLeft: "10px" }}>
  <Col style={{ marginRight: "2px", marginLeft: "450px" }}><p style={{ color: `${darck ? "#008000" : "#008000"}`, fontWeight: "bold", fontSize: "13px", margin: "0" }}>Total QTé : </p><p style={{ color: darck ? "#008000" : "#008000", fontWeight: "bold", margin: "0 -10px 0 0" }}>{sommeQuantité.toFixed(3)} </p> </Col>
  <Col style={{ marginRight: "2px", marginLeft: "-20px" }}><p style={{ color: `${darck ? "#008000" : "#008000"}`, fontWeight: "bold", fontSize: "13px", margin: "0" }}>Total prix :</p><p style={{ color: darck ? "#008000" : "#008000", fontWeight: "bold", margin: "0" }}>{sommeTotalHT.toFixed(3)}  </p></Col>
</Row> 
              
              
              
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {/* {
              // !this.state.btnEnabled ? (
              //   <Button disabled variant="contained" style={{ width: "30%" }}>
              //     Enregistrer
              //   </Button>
              // ) : (
              this.state.tab.map((t, i) => ( */}
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgb(0, 8, 126)",
                color: "white",
                width: "30%",
              }}
              onClick={() => {
                this.props.submitHandler(
                  this.state.tab,
                  this.state.totalqte,
                  this.state.sumremisearticle,
                  this.state.totalhtbrut,
                  this.state.totaltva,
                  this.state.totalhtnet,
                  this.state.remiseglobal,
                  this.state.netapayer,
                  this.state.btnEnabled,
                  this.state.netnetapayer
                );
                // this.enregistrer();
                this.props.onHide();
              }}
            >
              Enregistrer
            </Button>
            {/* <Col sm={2} style={{ marginTop: "-15px" }}>
              <div id="" style={{ textAlign: "center" }}>
                <button
                  className="icon-btn add-btn"
                  onClick={() => this.setState({ addModalShow: true })}
                >
                  <div className="add-icon"></div>
                  <div className="btn-txt">Ajouter BE</div>
                </button>
              </div>
            </Col> */}
            {/* ))
              // )
            } */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(LigBEArticle));