import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import translate from "../../i18n/translate";

const PrivateRoute = ({ Auth, component: Component, ...rest }) => {
  if (Auth.isAuth && Auth.fct == "100") {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <div> {translate("Non Autorisé!")} </div>;
  }
};
const mapStateToProps = (state) => {
  return {
    Auth: state.Auth,
  };
};
export default connect(mapStateToProps, null)(PrivateRoute);
