import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import "../styling/Styles.css";
import "./avoir.scss";
import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import { SelectAVCod } from "../../redux/actions/GetCodAV";
import { SelectArticle } from "../../redux/actions/GetArticles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SelectBLBRCod } from "../../redux/actions/GetBLBRcod";
import { SelectBLBR } from "../../redux/actions/GetBLBR";
import translate from "../../i18n/translate";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import "./Responsiveav.css";
import LigAVArticle from "./LigAVArticle";
// const roundTo = require("round-to");

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddAvoirClientModal extends Component {
  constructor(props) {
    super(props);
    // const username = localStorage.getItem("username");

    this.state = {
      name: "",
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      timbre: null,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      pmp: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],
      avance: 0,
      AVANCE: 0,
      regimecli: "",
      nextsubmit: false,

      snackbaropen: false,
      snackbarmsg: ",",
      valtimbre:0,
      netnetapayer: 0,
      clicked: false,
      numfac: "",
      typfac: "",
      typfacTab: [],
      numfacTab: [],
      facOrBl: "",
      numnum: 0,
      magasin: [],
      MG: "",
      GMM: "",
      DEP: "",
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectAVCod(
    this.props.ConfigDossier.coddos,
     this.props.ConfigDossier.serveur,
     this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
   );
    // this.props.SelectBLBRCod(
    //   this.props.ConfigDossier.coddos,
    //   this.props.ConfigDossier.serveur,
    //   this.props.ConfigDossier.utilisateur,
    //   this.props.ConfigDossier.pwd_serveur,
    //   this.props.ConfigDossier.database
    // );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
    this.setState({ tab: [] });
  }
  componentDidUpdate(prevprops, prevstate) {
    if (prevstate.facOrBl != this.state.facOrBl) {
      fetch(
        `https://www.24-crm.com/webservice/api/LigFACCLIs?type=${this.state.typfac}&numfac=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            tab: data.map((el) => {
              return {
                codearticle: el.codart,
                qte: el.quantite,
                des: el.desart,
                puht: el.priuni,
                remisea: el.remise,
                tva: el.tautva,
                puttcnet: el.PUTTCNET,
                totalht: el.montht,
                unite: el.unite,
                faudec: el.typfodec,
                codav: el.codav,
                pmp: el.pmp,
              };
            }),
          })
        )
        .then((res) => {
          const SumQte =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte), 0);
          const SumRemiseArticle =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
              0
            );
          const SumHtBrut =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
          const SumTva =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseInt(v.qte) *
                  parseFloat(v.puht) *
                  ((100 - parseFloat(v.remisea)) / 100) *
                  ((100 - parseFloat(this.state.remiseg)) / 100) *
                  (parseFloat(v.tva) / 100),
              0
            );
          const SumHtNet =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseFloat(v.totalht) *
                  ((100 - parseFloat(this.state.remiseg)) / 100),
              0
            );
          const SumRemiseGlobale =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + v.totalht * (this.state.remiseg / 100),
              0
            );
          const SumNetAPayer =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                (v.totalht * ((100 - this.state.remiseg) / 100) +
                  v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
              0
            );

          const Avance = (SumNetAPayer * this.state.regimecli) / 100;

          const SumNetNetAPayer = SumNetAPayer + Avance;

          this.setState({
            totalqte: SumQte,
            sumremisearticle: SumRemiseArticle,
            totalhtbrut: SumHtBrut,
            totaltva: SumTva,
            totalhtnet: SumHtNet,
            remiseglobal: SumRemiseGlobale,
            netapayer: SumNetAPayer,
            avance: Avance,
            btnEnabled: true,
            netnetapayer: SumNetNetAPayer,
          });
        });
    }
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer,
    pmp,
    avance
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
      pmp: pmp,
      avance: avance,
    });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      netnetapayer:
        ((100 - event.target.value) / 100) *
        (this.state.netapayer + this.state.avance),
    });
  };

  raisocHandler = (event) => {
    //Exécuter la procédure FacCliandBLByCodCli
    fetch(
      `https://www.24-crm.com/webservice/api/FACCLIs?codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          numfacTab: result,
        });
      });

    this.setState({ raisonsocial: event.target.value });
  };












  enregistrer = (event) => {
    // console.log("tet");
    event.preventDefault();
    // let id = event.target.codfacs.value;
    fetch(
      `https://www.24-crm.com/webservice/api/FACCLIs?numfac=&typfac=AC&codcli=${
        this.state.codeclient
      }&raisoc=${this.state.raisonsocial}&catfisc=${
        this.state.categoriefiscale
      }&datfac=${this.state.defaultdate}&timbre=${
        this.state.showTimbre
      }&smhtb=${this.state.totalhtbrut}&smremart=${
        this.state.sumremisearticle
      }&smremglo=${this.state.remiseglobal}&smhtn=${
        this.state.totalhtnet
      }&smtva=${this.state.TOTTVA}&mntbon=${parseFloat(
        this.state.netnetapayer
      )}&reste=${parseFloat(this.state.netnetapayer)}&pj=''&tauxNASS=${
        this.state.regimecli
      }&smNASS=${this.state.AVANCE}&codrep=${
        this.props.Auth.code
      }&numcom=${
        event.target.facture.value
          ? event.target.facture.value.split(":")[1]
          : "NULL"
      }&typcom=${
        event.target.facture.value
          ? event.target.facture.value.split(":")[0]
          : 0
      }&valtimbre=${this.state.valtimbre}&Vendeur=${
        this.props.Auth.username
      }&CodDep=${this.state.MG}&coddos=${
        this.props.ConfigDossier.coddos
      }&km&serveur=${this.props.ConfigDossier.serveur}&user=${
        this.props.ConfigDossier.utilisateur
      }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
        this.props.ConfigDossier.database
      }`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
          this.setState({ numnum: result });
          this.state.tab.map((k, index) => {
            fetch(
              ` https://www.24-crm.com/webservice/api/LigFACCLIs/{ID}?numfac=${
                this.state.numnum
              }
              &typfac=AC&numlig=${index}&codart=${k.codearticle}&quantite=${
                k.qte
              }&fodart=${k.faudec}&desart=${k.des}&datfac=${
                this.state.defaultdate
              }&priuni=${k.puht}&remise=${k.remisea}&unite=${
                k.unite
              }&codtva=3&tautva=${k.tva}&montht=${k.totalht}&ttclig=${
                k.puttcnet * k.qte
              }&CB=${k.codbar}&formqt=0&pmp=${k.pmp}&coddepp=${
                this.state.MG
              }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                this.props.ConfigDossier.serveur
              }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                this.props.ConfigDossier.pwd_serveur
              }&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  console.log(result);
                },
                (error) => {
                  this.setState({ snackbaropen: true, snackbarmsg: "failed" });
                }
              );
          });
          //update solde
          fetch(
            `https://www.24-crm.com/webservice/api/REGCLIs?codeclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.SelectAVCod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )
      // .then(
      //   (result) => {
      //     this.props.onHide();

      //     this.props.SelectBLBR(
      //       this.props.Auth.username,
      //       this.props.ConfigDossier.coddos,
      //       this.props.ConfigDossier.serveur,
      //       this.props.ConfigDossier.utilisateur,
      //       this.props.ConfigDossier.pwd_serveur,
      //       this.props.ConfigDossier.database
      //     );
      //     //Exécuter la procédure SommeTTCBL
      //     fetch(
      //       `https://www.24-crm.com/webservice/api/Lig?FAC=${this.state.numnum}
      //       &typfacc=BR&coddos=${this.props.ConfigDossier.coddos}
      //       &serveur=${this.props.ConfigDossier.serveur}
      //       &user=${this.props.ConfigDossier.utilisateur}
      //       &pwd=${this.props.ConfigDossier.pwd_serveur}
      //       &database=${this.props.ConfigDossier.database}`,
      //       {
      //         method: "POST",
      //         header: {
      //           Accept: "application/json",
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     );

      //     this.setState({
      //       snackbaropen: true,
      //       snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
      //     });
      //     this.props.SelectBLBRCod(
      //       this.props.ConfigDossier.coddos,
      //       this.props.ConfigDossier.serveur,
      //       this.props.ConfigDossier.utilisateur,
      //       this.props.ConfigDossier.pwd_serveur,
      //       this.props.ConfigDossier.database
      //     );
      //     // window.location.reload();
      //   },
      //   (error) => {
      //     this.setState({ snackbaropen: true, snackbarmsg: "failed" });
      //   }
      // )
      .then((res) => {
        //////////// switch update ////////////////
        fetch(
          `https://www.24-crm.com/webservice/api/Switch?code=AC2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          );

       window.location.reload();
      });
  };

















  clientHandlerChange = (event) => {
    this.setState({ codeclient: event.target.value });
    //get client by codcli
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };
  ligModalClose = () => this.setState({ ligModalShow: false });
  addModalClose1 = () => this.setState({ addModalShow1: false });

  render() {
    // const { dvnumfac, dvraisoc, rem, clientmail } = this.state;

    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b> Ajouter Avoir Client</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                  <Col sm={4}>
                        <FormGroup>
                          {
                            this.props.codav.codav && (
                              // this.props.codbls.codbls.map((t) => (
                              <TextField
                                // className="card add-input"
                                id="standard-basic"
                                label={translate("№ AV")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                // value={t.valeur}
                                value={
                                 this.props.codav.codav[0] &&
                                 this.props.codav.codav[0].valeur
                                }
                                fullWidth
                                name="codbl"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )
                            // ))
                          }
                        </FormGroup>
                      </Col>







                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        type="date"
                        fullWidth
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    {this.state.GMM ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            value={{
                              code: this.state.MG,
                              lib: this.state.DEP,
                            }}
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}  ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                    DEP: getOptionLabel.lib,
                                  })
                                : this.setState({
                                    MG: "",
                                    DEP: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null, }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,},
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              {translate("Raison Social")}
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Code client")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Raison Social")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager &&
                      !this.state.numfac ? (
                        <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="raissoc"
                            value={this.state.raisonsocial || ""}
                            onChange={this.raisocHandler}
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      ) : this.state.showButtonModalPassager &&
                        this.state.numfac ? (
                          <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              disabled
                              fullWidth
                              name="raissoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              // disabled={
                              //   this.state.codeclient === "100" ? false : true
                              // }
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label={translate("Code client")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  {/* <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              {translate("Raison Social")}
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Code client")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Raison Social")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager &&
                      !this.state.numfac ? (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Raison Social")}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.raisonsocial || ""}
                              name="raissoc"
                              // disabled={
                              //   this.state.codeclient === "100" ? false : true
                              // }
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : this.state.showButtonModalPassager &&
                        this.state.numfac ? (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Raison Social")}
                              margin="normal"
                              //variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              disabled
                              name="raissoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Raison Social")}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              // disabled={
                              //   this.state.codeclient === "100" ? false : true
                              // }
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label={translate("Code client")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row> */}
                  <Row form>
                    {this.state.showButtonModalPassager &&
                    !this.state.numfac ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={this.state.cat}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel.label == "Assujetti"
                                ? this.setState({ categoriefiscale: 0 })
                                : getOptionLabel.label == "Non Assujetti"
                                ? this.setState({ categoriefiscale: 1 })
                                : getOptionLabel.label == "Exonéré TVA/FODEC"
                                ? this.setState({ categoriefiscale: 2 })
                                : getOptionLabel.label == "Exonéré TVA"
                                ? this.setState({ categoriefiscale: 3 })
                                : getOptionLabel.label == "Suspenssion"
                                ? this.setState({ categoriefiscale: 4 })
                                : getOptionLabel.label == "Cession à Quai"
                                ? this.setState({ categoriefiscale: 5 })
                                : this.setState({ categoriefiscale: 6 });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Cat Fiscale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="catfisc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : this.state.showButtonModalPassager &&
                      this.state.numfac ? (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label={translate("Cat Fiscale")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Non Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA/FODEC")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Suspenssion")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cession à Quai")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Réduction TVA")}{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              {translate("Cat Fiscale")}{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label={translate("Cat Fiscale")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Non Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA/FODEC")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Suspenssion")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cession à Quai")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Réduction TVA")}{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              {translate("Cat Fiscale")}{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    )}

                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label={translate("Remise Globale %")}
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="remise"
                        value={this.state.remiseg}
                        onChange={this.remiseglobalChange}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {/* <Col sm={3}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.numfacTab}
                          // getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  typfac: getOptionLabel.typfac,
                                })
                              : this.setState({
                                  typfac: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              id="standard-basic"
                              label="F/BL"
                              margin="normal"
                              //variant="outlined"
                              fullWidth
                              name="type"
                              // value={this.state.remiseg}
                              // onChange={this.remiseglobalChange}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col> */}
                    {this.state.codeclient ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.numfacTab}
                            getOptionLabel={(option) =>
                              `${option.typfac}: ${option.numfac}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    facOrBl: getOptionLabel,
                                    numfac: getOptionLabel.numfac,
                                    typfac: getOptionLabel.typfac,
                                  })
                                : this.setState({
                                    numfac: "",
                                    typfac: "",
                                    facOrBl: "",
                                  });

                              {
                                getOptionLabel && this.state.GMM
                                  ? this.setState({ MG: getOptionLabel.CodDep })
                                  : this.setState({ MG: "", DEP: "" });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("N° facture")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  facture par code client
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/FACCLIs?codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "POST",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        numfacTab: result,
                                        typfacTab: result.map(
                                          (el) => el.typfac
                                        ),
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="facture"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                    {/* <PageviewIcon style={{ marginTop: "6%" }} /> */}

                    {/* <Col sm={2}>
                      <FormGroup style={{ marginTop: "40px" }}>
                        {this.state.showTimbre ? (
                          <p style={{ color: "grey" }}>Timbre: ✔</p>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "40px" }}>
                        {this.state.showForfitaire === 1 ? (
                          <p style={{ color: "grey" }}>Forfitaire: ✔</p>
                        ) : null}
                      </FormGroup>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label={translate("Liste des Articles")}
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addbr">
                            {this.state.GMM ? (
                              this.state.btnEnable && this.state.MG ? (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={translate(
                                        "Ajouter article"
                                      )}
                                    >
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={translate(
                                        "Ajouter article"
                                      )}
                                    >
                                      <Fab
                                        disabled
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={translate(
                                      "Ajouter article"
                                    )}
                                  >
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={translate(
                                      "Ajouter, Editer et supprimer article"
                                    )}
                                  >
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tabbl2">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>
                                {" "}
                                {translate("Désignation")}{" "}
                              </th>
                              <th> {translate("Quantité")} </th>
                              <th> {translate("PUHT")} </th>
                              <th> {translate("Remise")} </th>
                              <th> {translate("TVA")} </th>
                              <th> {translate("PUTTCNet")} </th>
                              <th> {translate("TotalHT")} </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              color: `${darck ? "" : "#fff"}`,
                            }}
                          >
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}


                                  <span>{t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="br1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Brut")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Article")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Globale")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Net")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total TVA")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total TTC")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Valeur de timbre")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Net à Payer")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="br2">
                <Card.Body   style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <Row className="rowbr">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Brut")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Article")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Globale")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="br">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbr">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Net")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total TVA")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                       Total <br/> TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="br">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbr">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur Timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Net à Payer")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {!(this.state.tab.length > 0) ? (
                    <Button
                      variant="contained"
                      //type="submit"
                      onClick={() => this.test()}
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      {translate("Enregistrer tous")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      type="submit"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                    >
                      {translate("Enregistrer tous")}
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

    <LigAVArticle
          submitHandler={this.submitHandler}
          tabArticle={this.state.tab}
          show={this.state.ligModalShow}
          onHide={this.ligModalClose}
          rem={this.state.remiseglobal}
         numfaccc={
           this.props.codav.codav &&
           this.props.codav.codav.map((nu) => parseInt(nu.valeur))
         }
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          regimecli={this.state.regimecli}
          avance={this.state.avance}
          GMM={this.state.GMM}
          MG={this.state.MG}
        /> 
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    SelectAVCod: (a, b, c, d, e) => dispatch(SelectAVCod(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectBLBRCod: (a, b, c, d, e) => dispatch(SelectBLBRCod(a, b, c, d, e)),
    SelectBLBR: (f, a, b, c, d, e) => dispatch(SelectBLBR(f, a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
    dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    codav: state.codav,
    clients: state.clients, 
    articles: state.articles,
    // blbrs: state.blbrs,
    // codblbr: state.codblbr,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    valtimbres: state.valtimbres,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddAvoirClientModal));
