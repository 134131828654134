import Axios from "axios";

export const SelectAllReleveFRS = ( a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/ReleveFournisseur?idfrs&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "RELEVERFRS_GET", payload: response.data });
    });
  };
};
