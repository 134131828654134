import Axios from "axios";

export const BORDEREAUX_SELECT = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BordereauVersements?id=&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}` 
      ).then((response) => {
      return dispatch({ type: "BORDEREAUX_SELECT", payload: response.data });
    });
  };
};
