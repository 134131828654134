import { Component } from "react";
import { Pie } from "react-chartjs-2";
import * as React from "react";

import Axios from "axios";
import { connect } from "react-redux";

// const DATE_OPTIONS = {
//   month: "short",
//   day: "numeric",
// };

class CircleChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      sum: [],
      k: 0,
    };
  }

  componentDidMount() {
    // fetch(`/api/TotalClient`)
    //   .then((response) => response.json())
    //   .then((data) => this.setState({ sum: data, k: data.Column1 }));

    // this.state.sum.map((t) => this.setState({ k: t.Column1 }));

    Axios.get(
      `https://www.24-crm.com/webservice/api/GraphCperR?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(record.ville);

        runscore.push(record.num);
      });

      this.setState({
        Data: {
          labels: playername,

          datasets: [
            {
              label: "Clients / Région",

              data: runscore,

              backgroundColor: [
                "#20c997",

                "#6610f2",

                "#0000FF",

                "#007bff",

                "#ffc107",
              
              ],
            },
          ],
        },
      });
    });
  }
  render() {
    return (
      <div>
        <div>
          <Pie
            data={this.state.Data}
            options={{ maintainAspectRatio: false }}
            width={"300px"}
            height={"200px"}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
  };
}

export default connect(mapStateToProps, null)(CircleChart);
