import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Tab, Table, Tabs } from "react-bootstrap";
// import clsx from "clsx";
// import { lighten, makeStyles } from "@material-ui/core/styles";
// import { Row } from "reactstrap";
import { setAffectationRegFrs } from "../../redux/actions/SetAffectationRegFrs";
import { connect } from "react-redux";
import Axios from "axios";

import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const ModifierAffectationfrsModal = (props) => {
  const [key, setKey] = useState("Facture");
  //   const [orderBy, setOrderBy] = useState("");
  //   const [order, setOrder] = useState("asc");

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("codfrs");
  const [selected, setSelected] = React.useState([]);
  const [blselected, setBLselected] = React.useState([]);
  const [detailTab, setdetailTab] = React.useState([]);
  const [searchFac, setSearchFac] = React.useState([]);
  const [searchBL, setSearchBL] = React.useState([]);
  const [FirstDetailTab, setFirstDetailTab] = React.useState([]);
  const [IsSearchFac, setIsSearchFac] = React.useState(false);
  const [IsSearchBL, setIsSearchBL] = React.useState(false);
  const [IsSearchAV, setIsSearchAV] = React.useState(false);
  const [searchAV, setSearchAV] = React.useState([]);
  const [selectedAV, setAVSelected] = React.useState([]);
  const [soldfacbl, setsoldfacbl] = React.useState(0);
  const DATE_OPTIONS = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  useEffect(() => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/FOURNISSEURs?codfrss=${props.codefournisseur}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`
    ).then((res) => setsoldfacbl(res.data[0].soldfacbl));
    setdetailTab(
      props.detailAffectation.map((el) => {
        return {
          numfac: el.numfac,
          montant: el.montfac,
          datfac: el.datfac,

          typfac: el.typfac,

          monreg: el.montant,
          regant: Number(Math.abs(el.montfac - el.montant - el.reste)).toFixed(
            3
          ),
        };
      })
    );
    setFirstDetailTab(
      props.detailAffectation.map((el) => {
        return {
          numfac: el.numfac,
          montant: el.montfac,
          datfac: el.datfac,

          typfac: el.typfac,

          monreg: el.montant,
          regant: Number(Math.abs(el.montfac - el.montant - el.reste)).toFixed(
            3
          ),
        };
      })
    );
    setSelected(
      props.detailAffectation
        .map((el) => {
          return {
            numfac: el.numfac,
            montant: el.montfac,
            datfac: el.datfac,

            typfac: el.typfac,

            monreg: el.montant,
            regant: Number(
              Math.abs(el.montfac - el.montant - el.reste)
            ).toFixed(3),
          };
        })
        .filter((el) => el.typfac == "FF")
    );
    setBLselected(
      props.detailAffectation
        .map((el) => {
          return {
            numfac: el.numfac,
            montant: el.montfac,
            datfac: el.datfac,

            typfac: el.typfac,

            monreg: el.montant,
            regant: Number(
              Math.abs(el.montfac - el.montant - el.reste)
            ).toFixed(3),
          };
        })
        .filter((el) => el.typfac == "BF")
    );
    setAVSelected(
      props.detailAffectation
        .map((el) => {
          return {
            numfac: el.numfac,
            montant: el.montfac,
            datfac: el.datfac,

            typfac: el.typfac,

            monreg: el.montant,
            regant: Number(
              Math.abs(el.montfac - el.montant - el.reste)
            ).toFixed(3),
          };
        })
        .filter((el) => el.typfac == "AF")
    );
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const classes = {
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      for (let n = 0; n < props.listeFacture.length; n++) {
        setdetailTab(
          detailTab.push({
            numfac: props.listeFacture[n].numfac,
            montant: props.listeFacture[n].mntbon,
            datfac: props.listeFacture[n].datfac,
            typfac: props.listeFacture[n].typfac,
            monreg: Math.min(
              props.listeFacture[n].reste,
              props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
            ),
            regant: Number(
              props.listeFacture[n].mntbon - props.listeFacture[n].reste
            ).toFixed(3),
          })
        );

        //   newSelecteds.push({
        //     numfac: n.numfac,
        //     montant: n.mntbon,
        //     datfac: n.datfac,
        //     typfac: n.typfac,
        //     monreg: Math.min(
        //       n.reste,
        //       props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
        //     ),
        //     regant: Number(n.mntbon - n.reste).toFixed(3),
        //   });
        //   return newSelecteds;
        // }
      }
      setdetailTab(detailTab);
      const newSelecteds = props.listeFacture.map((n) => ({
        numfac: n.numfac,
        montant: n.mntbon,
        datfac: n.datfac,
        typfac: n.typfac,
        monreg: Math.min(
          n.reste,
          props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
        ),
        regant: Number(n.mntbon - n.reste).toFixed(3),
      }));
      setSelected(newSelecteds);

      return;
    }
    alert(`bl ${blselected.length == props.listebl.length} `);

    // if (blselected.length == props.listebl.length) {
    //   for (let n = 0; n < props.listebl.length; n++) {
    //     setdetailTab(
    //       detailTab.push({
    //         numfac: props.listebl[n].numfac,
    //         montant: props.listebl[n].mntbon,
    //         datfac: props.listebl[n].datfac,
    //         typfac: props.listebl[n].typfac,
    //         monreg: Math.min(
    //           props.listebl[n].reste,
    //           props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
    //         ),
    //         regant: Number(
    //           props.listebl[n].mntbon - props.listebl[n].reste
    //         ).toFixed(3),
    //       })
    //     );
    //   }
    //   setdetailTab(detailTab);
    // }
    setSelected([]);
    setdetailTab(blselected);

    // setdetailTab(
    //   detailTab.map((n) => ({
    //     numfac: n.numfac,
    //     montant: n.mntbon,
    //     datfac: n.datfac,
    //     typfac: n.typfac,
    //     monreg: Math.min(
    //       n.reste,
    //       props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
    //     ),
    //     regant: Number(n.mntbon - n.reste).toFixed(3),
    //   }))
    // );
  };
  const handleSelectBLAllClick = (event) => {
    if (event.target.checked) {
      for (let n = 0; n < props.listebl.length; n++) {
        setdetailTab(
          detailTab.push({
            numfac: props.listebl[n].numfac,
            montant: props.listebl[n].mntbon,
            datfac: props.listebl[n].datfac,
            typfac: props.listebl[n].typfac,
            monreg: Math.min(
              props.listebl[n].reste,
              props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
            ),
            regant: Number(
              props.listebl[n].mntbon - props.listebl[n].reste
            ).toFixed(3),
          })
        );
      }
      setdetailTab(detailTab);
      const newSelecteds = props.listebl.map((n) => ({
        numfac: n.numfac,
        montant: n.mntbon,
        datfac: n.datfac,
        typfac: n.typfac,
        monreg: Math.min(
          n.reste,
          props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
        ),
        regant: Number(n.mntbon - n.reste).toFixed(3),
      }));

      setBLselected(newSelecteds);

      return;
    }
    alert(`fac ${selected.length == props.listeFacture.length}`);

    // if (selected.length == props.listeFacture.length) {
    //   for (let n = 0; n < props.listeFacture.length; n++) {
    //     setdetailTab(
    //       detailTab.push({
    //         numfac: props.listeFacture[n].numfac,
    //         montant: props.listeFacture[n].mntbon,
    //         datfac: props.listeFacture[n].datfac,
    //         typfac: props.listeFacture[n].typfac,
    //         monreg: Math.min(
    //           props.listeFacture[n].reste,
    //           props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
    //         ),
    //         regant: Number(
    //           props.listeFacture[n].mntbon - props.listeFacture[n].reste
    //         ).toFixed(3),
    //       })
    //     );
    //   }
    //   setdetailTab(detailTab);
    // }
    //   setdetailTab(detailTab3);
    // }
    setBLselected([]);
    setdetailTab(selected);
  };
  const handleClick = (event, obj) => {
    // setSelected(
    //   selected.concat(detailTab.filter((el) => obj.numfac == el.numfac))
    // );

    const selectedIndex = selected.map((el) => el.numfac).indexOf(obj.numfac);
    //.concat(detailTab.filter((el) => obj.numfac == el.numfac))

    let newSelected = [];

    if (selectedIndex === -1) {
      if (obj.monreg == "0") {
        alert("montant reglement affecté");
        newSelected = selected;
      } else {
        newSelected = newSelected.concat(selected, obj);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    setdetailTab(newSelected.concat(blselected));
  };
  const handleBLClick = (event, obj) => {
    const selectedIndex = blselected
      .concat(detailTab.filter((el) => obj.numfac == el.numfac))
      .map((el) => el.numfac)
      .indexOf(obj.numfac);
    let newSelected = [];

    if (selectedIndex === -1) {
      if (obj.monreg == "0") {
        alert("montant reglement affecté");
      } else {
        newSelected = newSelected.concat(blselected, obj);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(blselected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(blselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        blselected.slice(0, selectedIndex),
        blselected.slice(selectedIndex + 1)
      );
    }

    setBLselected(newSelected);
    setdetailTab(newSelected.concat(selected));
  };
  const handleAVClick = (event, obj) => {
    const selectedIndex = selectedAV.map((el) => el.numfac).indexOf(obj.numfac);
    let newSelected = [];

    if (selectedIndex === -1) {
      if (obj.monreg == "0") {
        alert("montant reglement affecté");
        newSelected = selectedAV;
      } else {
        newSelected = newSelected.concat(selectedAV, obj);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAV.slice(1));
    } else if (selectedIndex === selectedAV.length - 1) {
      newSelected = newSelected.concat(selectedAV.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAV.slice(0, selectedIndex),
        selectedAV.slice(selectedIndex + 1)
      );
    }

    setAVSelected(newSelected);
    setdetailTab(newSelected.concat(selected).concat(blselected));
  };

  const isSelected = (obj) => {
    return selected.filter((el) => el.numfac == obj.numfac).length > 0;
  };

  const isblSelected = (obj) => {
    return blselected.filter((el) => el.numfac == obj.numfac).length > 0;
  };
  const isSelectedAV = (obj) =>
    selectedAV.filter((el) => el.numfac == obj.numfac).length > 0;

  const rechercheHandler = (a) => {
    if (a.length != 0) {
      fetch(
        `https://www.24-crm.com/webservice/api/FacFrs/${a}?codfrsss=${props.codefournisseur}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          setIsSearchFac(true);
          setSearchFac(data.filter((el) => el.typfac == "FF"));
        });
    } else {
      setIsSearchFac(false);
    }
  };
  const rechercheAvHandler = (a) => {
    if (a != "") {
      fetch(
        `https://www.24-crm.com/webservice/api/FacFrs/${a}?codfrsss=${props.codefournisseur}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          setIsSearchAV(true);
          setSearchAV(data.filter((el) => (el.typfac = "AF")));
        });
    } else {
      setIsSearchAV(false);
    }
  };
  const rechercheHandlerBL = (a) => {
    if (a != "") {
      fetch(
        `https://www.24-crm.com/webservice/api/FacFrs/${a}?codfrsss=${props.codefournisseur}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          setIsSearchBL(true);
          setSearchBL(data.filter((el) => (el.typfac = "BF")));
        });
    } else {
      setIsSearchBL(false);
    }
  };
  const enregistrer = () => {
    props.setAffectationRegfrs(
      detailTab,
      props.ConfigDossier.coddos,
      props.ConfigDossier.serveur,
      props.ConfigDossier.utilisateur,
      props.ConfigDossier.pwd_serveur,
      props.ConfigDossier.database
    );
    props.handleClose();
  };

  const { darck } = props;

  return (
    <>
      <Modal size="lg" show={props.show} onHide={props.handleClose} centered>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: `${darck ? "#fff" : "#172431"}`,
            color: `${darck ? "#00087E" : "#fff"}`,
            borderTop: `${darck ? "" : "2px solid #fff"}`,
            borderRight: `${darck ? "" : "2px solid #fff"}`,
            borderLeft: `${darck ? "" : "2px solid #fff"}`,
            borderBottom: `none`,
          }}
        >
          <Modal.Title>
            <b>Affectation</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: `${darck ? "#fff" : "#172431"}`,
            borderTop: `none`,
            borderRight: `${darck ? "" : "2px solid #fff"}`,
            borderLeft: `${darck ? "" : "2px solid #fff"}`,
            borderBottom: `${darck ? "" : "2px solid #fff"}`,
          }}
        >
          <Tabs
            style={{ color: "rgb(23, 162, 184)" }}
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="Facture" title="Facture">
              <TextField
                id="filled-full-width"
                placeholder="Recherche facture"
                fullWidth
                margin="dense"
                onChange={(e) => rechercheHandler(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                  color: !darck ? "#fff" : null,
                }}
              />
              <div className="mtabftfrs">
                <table>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={props.listeFacture.length}
                  />
                  <tbody>
                    {stableSort(
                      IsSearchFac ? searchFac : props.listeFacture,
                      getComparator(order, orderBy)
                    ).map((test, index) => {
                      const isItemSelected = isSelected({
                        numfac: test.numfac,
                        montant: test.mntbon,
                        datfac: test.datfac,
                        typfac: test.typfac,
                        reste: test.reste,
                        regant: Number(test.mntbon - test.reste).toFixed(3),
                        monreg: Math.min(
                          test.reste,
                          props.montReg -
                            detailTab.reduce((a, v) => a + v.monreg, 0)
                        ),
                      });
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(event, {
                              numfac: test.numfac,
                              montant: test.mntbon,
                              datfac: test.datfac,
                              typfac: test.typfac,
                              reste: test.reste,
                              monreg: Math.min(
                                test.reste,
                                props.montReg -
                                  detailTab.reduce((a, v) => a + v.monreg, 0)
                              ),
                              regant: Number(test.mntbon - test.reste).toFixed(
                                3
                              ),
                            })
                          }
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={{
                            numfac: test.numfac,
                            montant: test.mntbon,
                          }}
                          // selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.numfac}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {new Date(test.datfac).toLocaleDateString(
                              "fr",
                              DATE_OPTIONS
                            )}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.raisoc}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.codfrs}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.mntbon.toFixed(3)}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {Number(test.mntbon - test.reste).toFixed(3)}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.reste.toFixed(3)}
                          </td>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="BL" title="Bon de Livraison">
              <TextField
                id="filled-full-width"
                placeholder="Recherche BF"
                fullWidth
                margin="dense"
                onChange={(e) => rechercheHandlerBL(e.target.value)}
                InputLabelProps={{
                  color: !darck ? "#fff" : null,
                  shrink: true,
                }}
              />
              <div className="mtabftfrs">
                <table>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    numSelected={blselected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectBLAllClick}
                    rowCount={props.listebl.length}
                  />
                  <tbody>
                    {stableSort(
                      IsSearchBL ? searchBL : props.listebl,
                      getComparator(order, orderBy)
                    ).map((test, index) => {
                      const isItemblSelected = isblSelected({
                        numfac: test.numfac,
                        montant: test.mntbon,
                        datfac: test.datfac,
                        typfac: test.typfac,
                        regant: Number(test.mntbon - test.reste).toFixed(3),
                        reste: test.reste,
                        monreg: Math.min(
                          test.reste,
                          props.montReg -
                            detailTab.reduce((a, v) => a + v.monreg, 0)
                        ),
                      });
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleBLClick(event, {
                              numfac: test.numfac,
                              montant: test.mntbon,
                              datfac: test.datfac,
                              typfac: test.typfac,
                              reste: test.reste,
                              monreg: Math.min(
                                test.reste,
                                props.montReg -
                                  detailTab.reduce((a, v) => a + v.monreg, 0)
                              ),
                              regant: Number(test.mntbon - test.reste).toFixed(
                                3
                              ),
                            })
                          }
                          role="checkbox"
                          // aria-checked={isItemblSelected}
                          tabIndex={-1}
                          key={{
                            numfac: test.numfac,
                            montant: test.mntbon,
                          }}
                          // selected={isItemblSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ color: `${darck ? "" : "#fff"}` }}
                          >
                            <Checkbox
                              checked={isItemblSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.numfac}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {new Date(test.datfac).toLocaleDateString(
                              "fr",
                              DATE_OPTIONS
                            )}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.raisoc}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.codfrs}
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.mntbon.toFixed(3)}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {Number(test.mntbon - test.reste).toFixed(3)}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            {test.reste.toFixed(3)}
                          </td>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
            {/* <Tab eventKey="Avoir" title="Avoir">
              <TextField
                id="filled-full-width"
                placeholder="Recherche Avoir"
                fullWidth
                margin="dense"
                onChange={(e) => rechercheAvHandler(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="tabre">
                <Table>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    numSelected={selectedAV.length}
                    onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                    rowCount={props.listeAV.length}
                  />
                  <TableBody>
                    {stableSort(
                      IsSearchAV ? searchAV : props.listeAV,
                      getComparator(order, orderBy)
                    ).map((test, index) => {
                      const isItemAVSelected = isSelectedAV({
                        numfac: test.numfac,
                        montant: test.mntbon,
                        datfac: test.datfac,
                        typfac: test.typfac,
                        reste: test.reste,
                        regant: Number(test.mntbon - test.reste).toFixed(3),
                        monreg: Math.min(
                          test.reste,
                          props.montReg -
                            detailTab.reduce((a, v) => a + v.monreg, 0)
                        ),
                      });
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleAVClick(event, {
                              numfac: test.numfac,
                              montant: test.mntbon,
                              datfac: test.datfac,
                              typfac: test.typfac,
                              reste: test.reste,
                              monreg: Math.min(
                                test.reste,
                                props.montReg -
                                  detailTab.reduce((a, v) => a + v.monreg, 0)
                              ),
                              regant: Number(test.mntbon - test.reste).toFixed(
                                3
                              ),
                            })
                          }
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={{
                            numfac: test.numfac,
                            montant: test.mntbon,
                          }}
                          // selected={isItemSelected}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isItemAVSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell>{test.numfac}</TableCell>
                          <TableCell>{test.typfac}</TableCell>
                          <TableCell>
                            {new Date(test.datfac).toLocaleDateString(
                              "fr",
                              DATE_OPTIONS
                            )}
                          </TableCell>

                          <TableCell>{test.raisoc}</TableCell>

                          <TableCell>{test.codfrs}</TableCell>

                          <TableCell>{test.mntbon.toFixed(3)}</TableCell>
                          <TableCell>
                            {Number(test.mntbon - test.reste).toFixed(3)}
                          </TableCell>
                          <TableCell>{test.reste.toFixed(3)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Tab> */}
          </Tabs>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: `${darck ? "#fff" : "#172431"}`,
            border: `${darck ? "" : "2px solid #fff"}`,
          }}
        >
          <Col md={12}>
            <h5>Détails</h5>
            <div className="tabftre">
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Reg.Ant</th>
                    <th>Reglement</th>
                  </tr>
                </thead>
                <tbody style={{ color: `${darck ? "" : "#fff"}` }}>
                  {detailTab.map((el) => (
                    <tr>
                      <td>{el.numfac}</td>
                      <td>{el.typfac}</td>

                      <td>{new Date(el.datfac).toLocaleDateString()}</td>
                      <td>{el.montant.toFixed(3)}</td>
                      <td>{el.regant}</td>
                      <td>{el.monreg.toFixed(3)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
          <Col sm={{ span: 6, offset: 6 }} style={{ marginTop: "10%" }}>
            <Table>
              <TableBody>
                {/* <TableRow rowSpan={4}>
                      <TableCell>Montant Total</TableCell>
                      <TableCell>
                        {props.listeFacture
                          .reduce((c, v) => c + parseFloat(v.mntbon), 0)
                          .toFixed(3)}
                      </TableCell>
                    </TableRow> */}
                <TableRow rowSpan={4}>
                  <TableCell style={{ color: `${darck ? "" : "#fff"}` }}>
                    Montant Affecté
                  </TableCell>
                  <TableCell style={{ color: `${darck ? "" : "#fff"}` }}>
                    {detailTab
                      .reduce((c, v) => c + parseFloat(v.monreg), 0)
                      .toFixed(3)}
                  </TableCell>
                </TableRow>
                <TableRow rowSpan={4}>
                  <TableCell style={{ color: `${darck ? "" : "#fff"}` }}>
                    Montant Règlement
                  </TableCell>
                  <TableCell style={{ color: `${darck ? "" : "#fff"}` }}>
                    {props.montReg}
                  </TableCell>
                </TableRow>
                <TableRow rowSpan={4}>
                  <TableCell
                    style={{ color: `${darck ? "" : "#fff"}` }}
                    align="left"
                  >
                  Solde Global
                  </TableCell>
                  <TableCell style={{ color: `${darck ? "" : "#fff"}` }}>
                    {/* {(
                          parseFloat(
                            selected.reduce((c, v) => c + parseFloat(v.montant), 0)
                          ) - parseFloat(props.montReg)
                        ).toFixed(3)} */}
                    {soldfacbl.toFixed(3)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Col>

          <Col md={12}>
            <Button variant="primary" onClick={enregistrer}>
              Enregistrer
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAffectationRegfrs: (num, a, b, c, d, e) =>
      dispatch(setAffectationRegFrs(num, a, b, c, d, e)),
  };
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  { id: "numfac", numeric: true, disablePadding: true, label: "N°" },
  { id: "datfac", numeric: true, disablePadding: true, label: "Date" },
  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: "Raison Sociale",
  },
  {
    id: "codfrs",
    numeric: true,
    disablePadding: false,
    label: "Code Fournisseur",
  },

  { id: "mntbon", numeric: true, disablePadding: false, label: "Montant" },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Tot.Reg",
  },
  {
    id: "reste",
    numeric: true,
    disablePadding: false,
    label: "Reste",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierAffectationfrsModal));
