import { Component } from "react";
import { Bar } from "react-chartjs-2";
import * as React from "react";

import Axios from "axios";
import { connect } from "react-redux";

const DATE_OPTIONS = {
  month: "short",
  day: "numeric",
};

class ChargeGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      dataDarck: {}
    };
  }

  componentDidMount() {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BEREs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
        );

        runscore.push(record.sommemntbn);
      });

      this.setState({
        Data: {
          labels: playername,

          datasets: [
            {
              label: "Montant / jour",

              data: runscore,

              backgroundColor:"#6610f2",

                
           
            },
          ],
        },
      });

      this.setState({
        dataDarck: {
          labels: playername,

          datasets: [
            {
              label: "Montant / jour",

              data: runscore,

              backgroundColor: [
                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",

                "#fff",
              ],
            },
          ],
        },
      });
    });
  }
  render() {
    return (
      <div>
        {
          this.props.darck ? (
            <div>
            <Bar
              data={this.state.Data}
              options={{ maintainAspectRatio: false }}
              width={"300px"}
              height={"200px"}
            />
          </div>
          ) : (
            <div>
            <Bar
              data={this.state.dataDarck}
              options={{ maintainAspectRatio: false }}
              width={"300px"}
              height={"200px"}
            />
          </div>
          )
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, null)(ChargeGraph);
