import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import { Row, Col, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import {
  TextField,
  Button,
  Snackbar,
  IconButton,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { GetRECod } from "../../redux/actions/GetRECod";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { SelectNomenclatureAG } from "../../redux/actions/GetNomeAG";
import { SelectNomenclatureCS } from "../../redux/actions/GetNomeCS";
import { SelectNomenclatureSC } from "../../redux/actions/GetNomeSituation";
import { SelectNomenclatureCCB } from "../../redux/actions/GetNomeCCB";
import { SelectReglement } from "../../redux/actions/GetReg";
import LinkIcon from "@material-ui/icons/Link";
import AffectationModal from "./AffectationModal";

import AffectationRetenuALaSourceCli from "./AffectationRetenuALaSourceCli";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

// const styles = {
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     width: 300,
//     margin: 100,
//   },
//   //style for font size
//   resize: {
//     fontSize: 10,
//   },
// };

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddReModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: true,
      defaultdate: date,
      raisonsocial: "",
      codeclient: "",
      btnEnable: false,
      soldfacbl: 0,
      codmodreg: "",
      libmodreg: "",
      bqclient: "",
      montant: 0,
      codbqclient: "",
      libbqclient: "",
      codagence: "",
      libagence: "",
      codcaisse: "",
      libcaisse: "",
      codsituation: "",
      libsituation: "",
      codbqvers: "",
      libbqvers: "",
      codccb: "",
      libccb: "",
      chdec: "",
      snackbaropen: false,
      snackbarmsg: ",",
      isEspece: false,
      rechs: [],
      rechercheValue: "",
      rechercheclicked: false,
      showModal: false,
      listeFacture: [],
      numreg: "",
      listebl: [],
      listeAV: [],
      numnum: "",
      CodeCCb: "",
      CodeCCb:[],
    };
  }

  componentDidMount() {
    this.setState({
      rechercheValue: "",
      codeclient: "",
      raisonsocial: "",
    });
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rechs: data,
          rechercheclicked: true,
        });
      });

    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetRECod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclature(
      "MR",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureAG(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureSC(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCCB(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectReglement(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  enregistrer = (event) => {
    event.preventDefault();
    // if (
    //   this.state.montant <
    //   this.props.detailTabRegCli.reduce((a, v) => a + v.monreg, 0)
    // ) {
    //   alert("montant insuffisant!,veuillez rectifier les affectations");
    // } else {
    this.setState({
      numreg: event.target.codre.value,
    });
    // ${event.target.echeance.value}
    fetch(
      `https://www.24-crm.com/webservice/api/REGCLIs?numreg&datreg=${
        this.state.defaultdate
      }&codcli=${event.target.codcli.value}&raisoc=${
        event.target.raisonsocial.value
      }&modreg=${this.state.codmodreg}&numcais=${this.state.codcaisse}&matban=${
        this.state.codbqclient
      }&monreg=${event.target.montant.value}&reste=${event.target.montant.value}&VENDEUR=${
        this.props.Auth.username
      }&numchq=${event.target.numpiece.value}&titulaire=${
        event.target.titulaire.value
      }&datech=${this.state.defaultdate}&mntreg=${
        event.target.montant.value
      }&sens=${
        this.props.detailTabRegCli &&
        this.props.detailTabRegCli.filter((el) => el.typfac.includes("A"))
          .length === this.props.detailTabRegCli.length
          ? "d"
          : "c"
      }&impaye=0&verser=${this.state.codsituation}&lib_reg=${
        event.target.note.value
      }&ListPiece=${
        this.props.detailTabRegCli
          ? this.props.detailTabRegCli.map((el) => el.numfac)
          : ""
      }&BqEscompte=${this.state.codbqvers}&codccb=${this.state.ccb}&agence=${
        this.state.codagence
      }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
        this.props.ConfigDossier.serveur
      }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
        this.props.ConfigDossier.pwd_serveur
      }&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((result) => {
        console.log(
          "detailTabRegCli",
          this.props.detailTabRegCli && this.props.detailTabRegCli
        );
        
        this.setState({ numnum: result });
      
        this.props.detailTabRegCli &&
          this.props.detailTabRegCli[0] &&
          fetch(
            `https://www.24-crm.com/webservice/api/LIGREGCLIs?numreg=${result}&numfac=${
              this.props.detailTabRegCli && this.props.detailTabRegCli[0].numfac
            }&typfac=${
              this.props.detailTabRegCli && this.props.detailTabRegCli[0].typfac
            }&montant=${
              this.props.detailTabRegCli && this.props.detailTabRegCli[0].monreg
            }&numfbl=0&typfbl=0&coddos=${
              this.props.ConfigDossier.coddos
            }&serveur=${this.props.ConfigDossier.serveur}&user=${
              this.props.ConfigDossier.utilisateur
            }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
              this.props.ConfigDossier.database
            }`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.props.detailTabRegCli &&
              this.props.detailTabRegCli[1] &&
              fetch(
                `https://www.24-crm.com/webservice/api/LIGREGCLIs?numreg=${result}&numfac=${
                  this.props.detailTabRegCli &&
                  this.props.detailTabRegCli[1].numfac
                }&typfac=${
                  this.props.detailTabRegCli &&
                  this.props.detailTabRegCli[1].typfac
                }&montant=${
                  this.props.detailTabRegCli &&
                  this.props.detailTabRegCli[1].monreg
                }&numfbl=0&typfbl=0&coddos=${
                  this.props.ConfigDossier.coddos
                }&serveur=${this.props.ConfigDossier.serveur}&user=${
                  this.props.ConfigDossier.utilisateur
                }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
                  this.props.ConfigDossier.database
                }`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => {
                this.props.detailTabRegCli &&
                  this.props.detailTabRegCli[2] &&
                  fetch(
                    `https://www.24-crm.com/webservice/api/LIGREGCLIs?numreg=${result}&numfac=${
                      this.props.detailTabRegCli &&
                      this.props.detailTabRegCli[2].numfac
                    }&typfac=${
                      this.props.detailTabRegCli &&
                      this.props.detailTabRegCli[2].typfac
                    }&montant=${
                      this.props.detailTabRegCli &&
                      this.props.detailTabRegCli[2].monreg
                    }&numfbl=0&typfbl=0&coddos=${
                      this.props.ConfigDossier.coddos
                    }&serveur=${this.props.ConfigDossier.serveur}&user=${
                      this.props.ConfigDossier.utilisateur
                    }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
                      this.props.ConfigDossier.database
                    }`,
                    {
                      method: "POST",
                      header: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );
              });
          });
      })

      .then(
        (result) => {
          this.props.onHide();

          this.props.SelectReglement(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          //Recalculer solde client après reglement

          fetch(
            `https://www.24-crm.com/webservice/api/REGCLIs?codeclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then((res) => {
              //////////// switch update ////////////////

              fetch(
                `https://www.24-crm.com/webservice/api/Switch?code=REGC&valeur=${
                  parseInt(this.state.numreg) + 1
                }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                  this.props.ConfigDossier.serveur
                }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                  this.props.ConfigDossier.pwd_serveur
                }&database=${this.props.ConfigDossier.database}`,
                {
                  method: "PUT",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => res.json())
              .then(
                (result) => {
              //  window.location.reload();
                },);
              //   (error) => {
              //     this.setState({
              //       snackbaropen: true,
              //       snackbarmsg: "failed",
              //     });
              //   }
              // );
            });
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.GetRECod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          this.setState({
            soldfacbl: "0",
          });
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
  };
  clientCodehandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          codeclient: data[0].codcli,
          soldfacbl: data[0].soldfacbl,
        })
      );
  };

  clientRaisonHandlerChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    // fetch(`/api/CLIENTs?codclii=${event.target.value}`)
    //   .then((response) => response.json())
    //   .then((data) => this.setState({ rechs: data }));
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  rechercheHandler = (event) => {
    this.setState({
      rechercheValue: event.target.value,
      codeclient: "",
      raisonsocial: "",
    });
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        // if (data.length == 0) {
        //   this.setState({
        //     codeclient: "",
        //     raisonsocial: "",
        //   });
        // }

        if (data.length === 1) {
          this.setState({
            codeclient: data[0].codcli,
            raisonsocial: data[0].raisoc,
            soldfacbl: data[0].soldfacbl,
          });
        }
        this.setState({
          rechs: data,
          rechercheclicked: true,
        });
      });
  };
  handleClose = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  handleShow = () => {
    fetch(
      `https://www.24-crm.com/webservice/api/FACCLIs?codclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        fetch(
          `https://www.24-crm.com/webservice/api/BLBRs?codclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "GET",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())

          .then((res) => {
            this.setState({
              listebl: res,
            });
          })
          .then((res) =>
            fetch(
              `https://www.24-crm.com/webservice/api/FACCLIs?codcliiis=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "GET",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => response.json())

              .then((res) => {
                this.setState({
                  listeAV: res,
                });
              })
          );
        this.setState({
          listeFacture: data,
          showModal: true,
        });
      });
  };
  render() {
    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Règlement client</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        {/* {this.props.codres.codres.map((t) => ( */}
                        <TextField
                          id="standard-basic"
                          label="№ Règlement"
                          margin="dense"
                         
                          value={parseInt(
                           this.props.codres.codres[0] &&
                             this.props.codres.codres[0].valeur
                         )}  
                          fullWidth
                          name="codre"
                          variant="outlined"
                          // className="card add-input"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                        {/* ))} */}
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        margin="dense"
                        type="date"
                        fullWidth
                        variant="outlined"
                        defaultValue={this.state.defaultdate}
                        // className="card add-input"

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                  chdec: getOptionLabel.chdec,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <TextField
                        id="filled-full-width"
                        label="Recherche"
                        placeholder="Recherche Client"
                        fullWidth
                        // className="card"
                        margin="dense"
                        onChange={this.rechercheHandler}
                        variant="outlined"
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.rechs.filter((el) =>
                            el.codcli
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.codcli}
                          inputValue={this.state.codeclient}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ codeclient: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codeclient: getOptionLabel.codcli,
                                  soldfacbl: getOptionLabel.soldfacbl,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codeclient: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Code client"
                              margin="dense"
                              fullWidth
                              value={this.state.codeclient}
                              // onChange={this.clientCodehandlerChange}
                              name="codcli"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.rechs.filter((el) =>
                            el.raisoc
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.raisoc}
                          inputValue={this.state.raisonsocial}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ raisonsocial: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codeclient: getOptionLabel.codcli,
                                  soldfacbl: getOptionLabel.soldfacbl,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codeclient: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Raison Sociale"
                              margin="dense"
                              fullWidth
                              value={this.state.raisonsocial}
                              onChange={this.clientHandlerChange}
                              name="raisonsocial"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card style={{ marginTop: "2px" }}>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col sm={11}>
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "2px",
                          color: "#007bff",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {this.state.libmodreg}{" "}
                      </p>
                    </Col>
                    <Col sm={1}>
                      <Tooltip title="Affectation" aria-label="Affectation">
                        <LinkIcon
                          onClick={
                            this.state.codmodreg.toUpperCase().includes("RS")
                              ? this.state.codeclient
                                ? this.handleShow
                                : () =>
                                    alert("il faut préciser le code client ")
                              : this.state.codeclient
                              ? //&& this.state.montant
                                this.handleShow
                              : () =>
                                  alert(
                                    "il faut préciser le code client et le montant "
                                  )
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      {this.state.codmodreg.toUpperCase().includes("RS") ? (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          required
                          value={parseFloat(this.props.mntretenucli).toFixed(3)}
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          required
                          //defaultValue={parseFloat(0).toFixed(3)}
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisse: getOptionLabel.code,
                                  libcaisse: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codcaisse: "",
                                  libcaisse: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              // className="card add-input"
                              // required={
                              //   this.state.codmodreg
                              //     .toUpperCase()
                              //     .includes("RS")
                              //     ? false
                              //     : true
                              // }
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisse}</FormHelperText>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row style={{ marginBottom: "-25px" }}> */}
                  <Row>
                    {" "}
                    <Col
                      sm={12}
                      style={{
                        display:
                          !this.state.libmodreg ||
                          this.state.libmodreg.toUpperCase().includes("ESP") ||
                          this.state.codmodreg.toUpperCase().includes("RS")
                            ? "none"
                            : "block",
                      }}
                    >
                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="№ Pièce"
                              margin="dense"
                              fullWidth
                              name="numpiece"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              type="date"
                              label="Echéance"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              defaultValue={this.state.defauldate}
                              name="echeance"
                              // className="card add-input"
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Titulaire"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              name="titulaire"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqclient: getOptionLabel.code,
                                      libbqclient: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqclient: "",
                                      libbqclient: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Bq client"
                                  margin="dense"
                                  fullWidth
                                  name="bqclient"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqclient}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.ags.ags}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codagence: getOptionLabel.code,
                                      libagence: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codagence: "",
                                      libagence: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Agence"
                                  margin="dense"
                                  fullWidth
                                  name="agence"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libagence}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libagence}{" "}
                          </p>
                        </Col> */}

                      {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libcaisse}{" "}
                          </p>
                        </Col> */}

                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.scs.scs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codsituation: getOptionLabel.code,
                                      libsituation: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codsituation: "",
                                      libsituation: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Situation"
                                  margin="dense"
                                  fullWidth
                                  name="situation"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libsituation}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                        {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libsituation}{" "}
                          </p>
                        </Col> */}


<Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //options={this.props.bqs.bqs}
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                         if ( getOptionLabel !== null ) {
      fetch(
      `https://www.24-crm.com/webservice/api/BordereauVersements?CodeBqCcb=${getOptionLabel.code}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      )
        .then((res) => res.json())
        .then((data) => this.setState({ CodeCCb: data, clicked: true ,}));} 
         else
        {this.setState({ ccb:"",});} }}
              renderInput={(params) => (
              <TextField
                                  label="Bq vers"
                                  margin="dense"
                                  fullWidth              
                                  name="bqvers"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqvers}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
 {/* ////////////////////////////////////////////////  Declaration Code CCB       /////////////////////////////////////////////////////////////////////////// */}
                        <Col sm={4}>
                        <FormGroup>
                          <Autocomplete
                              id="outlined-select-currency"
                              includeInputInList
                              options={this.state.CodeCCb}
                              getOptionLabel={(option) => `${option.CodeCCb}`}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                    ccb: getOptionLabel.CodeCCb,
                                    })
                                  : this.setState({
                                    ccb: "",
                                    });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                label="CCB"
                                value={this.state.ccb}
                                name="codeccb"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                      </FormGroup>
                      </Col>     

                        {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libccb}{" "}
                          </p>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <TextField
                          id="outlined-multiline-static"
                          label="Note"
                          variant="outlined"
                          fullWidth
                          name="note"
                          rows={2}
                          multiline
                          // className="card add-input"

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <br />
              <Row>
                <Col sm={6}>
                  <h3 style={{ color: "rgb(23, 162, 184)" }}>
                    &nbsp; Solde:{"  "}
                    <span style={{ color: !darck ? "#fff" : "black" }}>
                      {Number(this.state.soldfacbl).toFixed(3)}
                    </span>
                  </h3>
                </Col>
                <Col sm={2}></Col>
                <Col sm={4}>
                  {this.state.codeclient &&
                  (this.state.montant || this.props.mntretenucli) ? (
                    <Button
                      variant="contained"
                      // color="secondary"

                      style={{
                        //marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      disabled
                      style={{
                        //marginTop: "20px",
                        width: "100%",
                        color: "white",
                      }}
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
        {this.state.codmodreg.toUpperCase().includes("RS") ? (
          <AffectationRetenuALaSourceCli
            chdec={this.state.chdec}
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codeclient={this.state.codeclient}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        ) : (
          <AffectationModal
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codeclient={this.state.codeclient}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetRECod: (a, b, c, d, e) => dispatch(GetRECod(a, b, c, d, e)),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("MR", a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectNomenclatureAG: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureAG(a, b, c, d, e)),
    SelectNomenclatureCS: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCS(a, b, c, d, e)),
    SelectNomenclatureSC: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureSC(a, b, c, d, e)),
    SelectNomenclatureCCB: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCCB(a, b, c, d, e)),
    SelectReglement: (a, b, c, d, e) =>
      dispatch(SelectReglement(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    codres: state.codres,
    nomenclatures: state.nomenclatures,
    bqs: state.bqs,
    ags: state.ags,
    css: state.css,
    scs: state.scs,
    ccbs: state.ccbs,
    Auth: state.Auth,
    detailTabRegCli: state.detailTabRegCli,
    mntretenucli: state.mntretenucli,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddReModal));
