import Axios from "axios";

export const SelectAvoirClientLig = (a, b, c, d, e, f) => {
  return (dispatch) => {
    Axios.get(
      // `https://www.24-crm.com/webservice/Api/LigFACCLIs?type=AC&numfac=${num}&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
   
      `https://www.24-crm.com/webservice/Api/LigFACCLIs?type=A&numfac=${a}&coddos=${b}&serveur=${c}&user=${d}&pwd=${e}&database=${f}`
    ).then((response) => {
      return dispatch({ type: "A_LIG_SELECT", payload: response.data });
    });
  };
};
