import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./bs.scss";
import { Redirect } from "react-router-dom";
import {
  Button,
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SelectBS } from "../../redux/actions/GetBS";
import GetBTByIdModal from "./GetBTByIdModal";
import AddBTModal from "./AddBTModal";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import translate from "../../i18n/translate";
import { Link } from "react-router-dom";
import "./Responsivebs.css";

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class BonDeTransfert extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      GetBTByIdModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      tabtab: [],

      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      showrechbydate: false,
      gilad: true,
      order: "asc",
      orderBy: "",
      valeurtim: 0,
    };
  }

  componentDidMount() {
    this.props.SelectBS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );

    this.props.valtimbres.valtimbres.map((t) =>
      this.setState({ valeurtim: t.valtimbre })
    );
    document.title = "Bon De Transfert";
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    this.state.gilad
      ? this.setState({ showrechbydate: false })
      : this.setState({ rechercheclicked: false });
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  rechercheHandler = (event) => {
    //Recherche des éléments de type= ‘type’ par codcli ou par numfac ou par raison sociale
    fetch(
      `https://www.24-crm.com/webservice/api/BSRS/${event.target.value}?type=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };

  firstrechdatHandler = (event) => {
    this.setState({ firstdate: event.target.value });
    //recherche par date début
    fetch(
      `https://www.24-crm.com/webservice/api/BSRS?datt=${event.target.value}&dattt=${this.state.seconddate}&typpe=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({ seconddate: event.target.value });
    //recherche par date fin
    fetch(
      `https://www.24-crm.com/webservice/api/BSRS?datt=${this.state.firstdate}&dattt=${event.target.value}&typpe=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  render() {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    let addModalClose1 = () => this.setState({ addModalShow: false });
    let getModalClose = () => this.setState({ GetBTByIdModalShow: false });

    const {
      bsid,
      datebs,
      client,
      raisonsociale,
      totalqteee,
      totalhtbrut,
      remiselignes,
      remiseglobale,
      totalhtnet,
      totaldc,
      totalcos,
      totalttc,
      totaltva,
      droitdetimbre,
      avanceimpot,
      rem,
      annuler,
      catfisc,
      sumqt,
      taurem,
      destination,
      valtimbree,
      //totalhtbr,
      vendeur,
      MG,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const { darck } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Stock"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>

          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            href="/bon-de-transfert"
            label="Bon De Transfert"
            icon={<AutorenewIcon fontSize="small" />}
          />
        </Breadcrumbs>
        {/* <div className="bc-icon">
          <i
            class="fa fa-reply"
            aria-hidden="true"
            style={{ fontSize: "25px" }}
          >
            {" "}
            Bon de transfert
          </i>
        </div> */}
        <br />

        <div>
          <Paper
            // elevation={0.5}
            elevation={2}
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              // height: "45px",
            }}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap" , marginTop:"5px"}}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col sm={10}>
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </>
                ) : (
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                )}
              </Col>
              {/* className="buttbs" */}
              <Col sm={2} style={{ display: "flex", justifyContent: "end" }} >
              {/* className="buttbs" */}
                <Button
                  color="primary"
                  onClick={() => this.setState({ addModalShow: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}/>
                  Bon De Transfert
                </Button>
                {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
              </Col>
            </Row>
          </Paper>
        </div>
        <br />

        {this.state.rechercheclicked ? (
          <div className="tabbs">
            <table striped>
              {/* <thead>
                <tr>
                  <th>№ BC</th>
                  <th>Date</th>
                  <th>Code client</th>
                  <th >Raison Sociale</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBSRS?type=BS&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );

                      this.setState({
                        GetBTByIdModalShow: true,
                        bsid: test.numfac,
                        datebs: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        destination: test.Destination,
                        MG: test.CodDep,
                      });
                    }}
                  >
                    <td style={{ color: "#8f2d56" }}>
                      <span>{test.numfac}</span>
                    </td>

                    <td>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td style={{ color: "#DF5F10" }}>
                      <span>{test.codcli}</span>
                    </td>
                    <td>
                      <span>{test.raisoc}</span>
                    </td>
                    {/* <td>
                      {test.annuler !== "0" ? (
                        <span
                          style={{
                            width: "50%",

                            backgroundColor: "#b2102f",
                            color: "white",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                          // variant="extended"
                          // size="small"
                          // color="secondary"
                          // style={{
                          //   width: "50%",
                          //   height: "80%",
                          // }}
                        >
                          Annulé
                        </span>
                      ) : (
                        <span
                          style={{
                            width: "50%",

                            backgroundColor: "#1769aa",
                            color: "white",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                          // variant="extended"
                          // size="small"
                          // color="primary"
                          // style={{
                          //   width: "50%",
                          //   height: "80%",
                          // }}
                        >
                          Actif
                        </span>
                      )}
                    </td> */}
                    <td style={{ color: "green" }}>
                      <span>{Number(parseFloat(test.mntbon)).toFixed(3)}</span>{" "}
                    </td>
                    <td> {test.vendeur} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : this.state.showrechbydate ? (
          <div className="tabbs">
            <table striped>
              {/* <thead>
                <tr>
                  <th>№ BC</th>
                  <th>Date</th>
                  <th>Code client</th>
                  <th >Raison Sociale</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechdats,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBSRS?type=BS&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );

                      this.setState({
                        GetBTByIdModalShow: true,
                        bsid: test.numfac,
                        datebs: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        destination: test.Destination,
                        MG: test.CodDep,
                      });
                    }}
                  >
                    <td>
                      <span>{test.numfac}</span>
                    </td>

                    <td>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span>{test.codcli}</span>
                    </td>
                    <td>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span
                        id="annulé"
                        style={{ paddingLeft: "10px", color: "#DF5F10" }}
                        >
                          Annulé
                        </span>
                      ) : (
                        <span
                        id="Actif"
                      
                        style={{ color: `${darck ? "" : "#fff"}`,paddingLeft: "10px", }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td>
                      <span>
                        {Number(
                          parseFloat(test.mntbon) +
                            parseFloat(this.state.valeurtim)
                        ).toFixed(3)}
                      </span>{" "}
                    </td>
                    <td> {test.vendeur} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="tabbs">
            <table striped>
              {/* <thead>
                <tr>
                  <th>№ BC</th>
                  <th>Date</th>
                  <th>Code client</th>
                  <th >Raison Sociale</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.props.bsss.bsss,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBSRS?type=BS&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );

                      this.setState({
                        GetBTByIdModalShow: true,
                        bsid: test.numfac,
                        datebs: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        destination: test.Destination,
                        MG: test.CodDep,
                      });
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codcli}</span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span id="annuler"
                        style={{ paddingLeft: "10px", color: "#DF5F10" }}
                      
                        >
                          Annulé
                        </span>
                      ) : (
                        <span id="Actif"
                        style={{ color: `${darck ? "" : "#fff"}` }}
                        
                        > Actif</span>
                      )}
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {Number(parseFloat(test.mntbon)).toFixed(3)}
                      </span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.vendeur}{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <AddBTModal
          show={this.state.addModalShow}
          onHide={addModalClose1}
          valtimbre={this.props.valtimbres.valtimbres.map((t) =>
            parseFloat(t.valtimbre)
          )}
        />

        <GetBTByIdModal
          show={this.state.GetBTByIdModalShow}
          onHide={getModalClose}
          bsid={bsid}
          datebs={datebs}
          client={client}
          raisonsociale={raisonsociale}
          totalhtbrut={totalhtbrut}
          remiselignes={remiselignes}
          remiseglobale={remiseglobale}
          totalhtnet={totalhtnet}
          totaldc={totaldc}
          totalcos={totalcos}
          totalttc={totalttc}
          totaltva={totaltva}
          vendeur={vendeur}
          droitdetimbre={droitdetimbre}
          avanceimpot={avanceimpot}
          destination={destination}
          rem={rem}
          tabtab={this.state.tabtab}
          annuler={annuler}
          sumqt={sumqt}
          taurem={taurem}
          catfisc={catfisc}
          MG={MG}
          valtimbre={this.props.valtimbres.valtimbres.reduce(
            (a, t) => a + parseFloat(t.valtimbre),
            0
          )}
          // valtimbree={valtimbree}

          // totalhtbr={totalhtbr}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectBS: (a, b, c, d, e) => dispatch(SelectBS(a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    bsss: state.bsss,
    valtimbres: state.valtimbres,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BonDeTransfert);
// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numfac",
    numeric: false,
    disablePadding: true,
    label: "№ BS",
  },

  {
    id: "datfac",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },

  { id: "codcli", numeric: false, disablePadding: false, label: "Code Client" },

  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: "Raison sociale",
  },
  {
    id: "annuler",
    numeric: true,
    disablePadding: false,
    label: "Etat",
  },
  { id: "mntbon", numeric: true, disablePadding: false, label: "Montant" },
  {
    id: "Vendeur",
    numeric: true,
    disablePadding: false,
    label: translate("Vendeur"),
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
