import React, { Component } from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { Breadcrumbs,Card, CardContent,Table,TableBody,TableCell,TableHead, TableRow, Typography,} from "@material-ui/core";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import UploadImage from "./UploadImage";
import { connect } from "react-redux";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { Button } from "react-bootstrap";
import ModifierFicheDossier from "./ModifierFicheDossier";

import { FormHelperText} from "@material-ui/core";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class FicheDossier extends Component {
 

constructor(props) {
  super(props);
  this.state = {
    showModal: false,
    mainState: "initial",
    selectedFile: null,
    imageUploaded: 0,
  };
}

handleUploadClick = (event) => {
  var file = event.target.files[0];
  const reader = new FileReader();

  reader.onloadend = function (e) {
    this.setState({
      selectedFile: [reader.result],
    });
  }.bind(this);

  this.setState({
    mainState: "uploaded",
    selectedFile: event.target.files[0],
    imageUploaded: 1,
  });
};
  render() {
    const { darck, classes  } = this.props;
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <StyledBreadcrumb
            component="a"
            href="/"
            label="Paramètres"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{ color: "rgb(63, 81, 181)" }}
            href="/fiche dossier"
            label="Fiche Dossier"
            icon={<PersonIcon fontSize="small" />}
          />
        </Breadcrumbs>
        <br />
        <Row className={`${darck ? "" : classes.root}`}>
          <Col sm={4}>
<Card style={{ textAlign: "center",height: "100%" ,backgroundColor: `${darck ? "#fff" : "#172431"}`, borderTop: `${darck ? "" : "2px solid #fff"}`,
borderRight: `${darck ? "" : "2px solid #fff"}`,
                          borderLeft: `${darck ? "" : "2px solid #fff"}`,
                          borderBottom: `${darck ? "" : "2px solid #fff"}`,
                          }}><CardContent>
      
  {/* <label style={{color: `${darck ? "#F00020" : "#F00020"}`, fontSize: "18px"}}>Entête de la Facture </label> */}
  <Typography style={{color: !darck ? "#fff" : null }} variant="h6" color="primary"> Nom de l'entreprise: {this.props.InfoDossier[0].lib}        </Typography>
  <Typography style={{color: !darck ? "#fff" : null }} variant="h6" color="primary"> Spécialité         : {this.props.InfoDossier[0].spe}        </Typography>  
  <Typography style={{color: !darck ? "#fff" : null }} variant="h6" color="primary"> Adresse            : {this.props.InfoDossier[0].adr},{this.props.InfoDossier[0].ville},{this.props.InfoDossier[0].pays}</Typography>

  <Typography style={{color: !darck ? "#fff" : null }}><PhoneIcon />{" "}{this.props.InfoDossier[0].tel1? this.props.InfoDossier[0].tel1: "--/--"}</Typography>
  <Typography style={{color: !darck ? "#fff" : null }}><PhoneIcon />{" "}{this.props.InfoDossier[0].tel2? this.props.InfoDossier[0].tel2: "--/--"}</Typography>
  <Typography style={{color: !darck ? "#fff" : null }}><MailIcon />{this.props.InfoDossier[0].email? this.props.InfoDossier[0].email: "--"}       </Typography>         
  
  <Typography style={{color: !darck ? "#fff" : null }} variant="h6" color="primary"> Logo de l'entreprise:  </Typography> 
   <img
                            src={`data:image/png;base64,${this.props.InfoDossier[0].Photo? this.props.InfoDossier[0].Photo: "--"} `}
                            alt="Pas-Photo"
                            style={{ width: '190px', height: '190px',marginBottom:"5px",marginTop:"5px" , borderRadius: "55px" }}
                            
                          />  

  </CardContent> 
  <Button color="primary" onClick={() => this.setState({ showModal: true })}>
    Modifier votre Fiche Dossier 
  </Button>
  </Card></Col>

    <Col sm={8}> <Card  style={{ textAlign: "center", height: "100%" ,  backgroundColor: `${darck ? "#fff" : "#172431"}`, borderTop: `${darck ? "" : "2px solid #fff"}`,borderRight: `${darck ? "" : "2px solid #fff"}`,
                          borderLeft: `${darck ? "" : "2px solid #fff"}`,
                          borderBottom: `${darck ? "" : "2px solid #fff"}`,}}>
<CardContent>
{/* <Row>
 <Col sm={8}>
  <Button color="primary" onClick={() => this.setState({ showModal: true })}>
    Modifier votre Fiche Dossier 
  </Button>
</Col> 
<Col sm={3}>
  <UploadImage />
</Col>
</Row> */}
<Table aria-label="simple table"> 
{/* <TableHead> 
  <TableRow>
   <TableCell
></TableCell> 
<TableCell align="right">

</TableCell>
 </TableRow> 
</TableHead> */}
 <TableBody> 
          
<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Matricule Fiscal:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].codtva? this.props.InfoDossier[0].codtva: "--"} </TableCell></TableRow>
  
<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Timbre Fiscale :</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].valtimbre? this.props.InfoDossier[0].valtimbre: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Categorie Fiscal:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}> 

{this.props.InfoDossier[0].catfisc === "0:Assujetti" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Assujetti{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "1:Non Assujetti" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Non Assujetti{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "2:Exonéré TVA/FODEC" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA/FODEC{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "3:Exonéré TVA" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "4:Suspenssion" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Suspenssion{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "5:Cession à Quai" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Cession à Quai{" "}
                              </FormHelperText>
                            ) : this.props.InfoDossier[0].catfisc === "6:Réduction TVA" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Réduction TVA{" "}
                              </FormHelperText>
                            ) : (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Cat Fiscale{" "}
                              </FormHelperText>
                            )} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Code Douane :</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].douane? this.props.InfoDossier[0].douane: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>CNSS :</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].cnss? this.props.InfoDossier[0].cnss: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Devise:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].Devise? this.props.InfoDossier[0].Devise: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Site Web:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].sitweb? this.props.InfoDossier[0].sitweb: "--"} </TableCell></TableRow>


<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Banque :</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].matban? this.props.InfoDossier[0].matban: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>Agence :</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].agence? this.props.InfoDossier[0].agence: "--"} </TableCell></TableRow>

<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>RIB 1:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].rib? this.props.InfoDossier[0].rib: "--"} </TableCell></TableRow>
 {/*   
<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>RIB 2:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].RIB2? this.props.InfoDossier[0].RIB2: "--"} </TableCell></TableRow>
    
<TableRow style={{color: !darck ? "#fff" : null }}> <TableCell style={{color: !darck ? "#fff" : null }}>RIB 3:</TableCell>
<TableCell style={{color: !darck ? "#fff" : null }}>{this.props.InfoDossier[0].RIB3? this.props.InfoDossier[0].RIB3: "--"} </TableCell></TableRow>  
*/} 

                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Col>
        </Row>
        <ModifierFicheDossier
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    InfoDossier: state.InfoDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(stylesFn)(FicheDossier));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
