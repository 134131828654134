import React, { Component } from "react";
import _ from "lodash"; 
import "./ficheCss.scss";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {
  getArticleList,
  getArticleHeader,
} from "../../redux/actions/ArticleActions";
import "../../App.css";
import Typography from "@material-ui/core/Typography";
import { SelectFiche} from "../../redux/actions/GetficheSTK";
import{SelectAllReleveFRS}from"../../redux/actions/GetRelevfrs";
import AddingArticleModal from "../gestion-des-articles/AddingArticleModal";
import GetArticleByIdModal from "../gestion-des-articles/GetArticleByIdModal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from 'moment';
import {
  TextField,
  Button,
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from "@material-ui/core";
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import "../gestion-des-articles/Responsiveart.css";
import DateRangeIcon from "@material-ui/icons/DateRange";
  import ReleveFRsDetaill from "./ReleveFRsDetaill";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
 
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};


var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);
var event = new Date();
event.setDate(1);
event.setMonth(0);

var date2 = event.toISOString().substr(0, 10);


class ReleveFournisseur extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      data: [],
      openReleveFournisseurModal: false,
      modal: false,
      addModalShow1: false,
      GetByIdModalShow: false,
      getArticleByIdModalShow: false,
      defaultdate: date,
      d1:date2,
      firstdate: date2,
      seconddate: date,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
      showrechbydate: false,
      showrechbydatecodeart: false,
      rechdats: [],
      rechdatscodeart: [],
   
      addModalShow: false,
      GetBSByIdModalShow: false,
      loggedIn,
   
      icon: false,
     
      tabtab: [],

      GMM: "",
      fichGMM:[],
      showrechbydatecodeartGMM: false,
      rechdatsGMM: [],
      rechdatscodeartGMM: [], 
      rechsGMM: [],
       rechercheclickedGMM: false,
      
      
      
      
      gilad: true,
     
      
    };
  }

  toggle = () => this.setState({ modal: !this.state.modal });
  handleOpen = () => {
    this.setState({ open: true });
    
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.props.getArticleHeader();
 
   
   
    this.props.SelectAllReleveFRS(
  
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
   
  
   
    document.title = "Fiche Article";
    // this.setState({ rechs: this.props.articles.articles });
 
    
  } 
 

  firstrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ firstdate: event.target.value });

    fetch(
       `https://www.24-crm.com/webservice/api/ReleveFournisseur?idfrs&DatDeb=${event.target.value}&DatFin=${this.state.seconddate}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
  
      )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ seconddate: event.target.value });

    fetch(
    `https://www.24-crm.com/webservice/api/ReleveFournisseur?idfrs&DatDeb=${this.state.firstdate}&DatFin=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
  
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
 

  rechercheHandler = (event) => {

 
    this.setState({rechs: []});

    fetch(
      `https://www.24-crm.com/webservice/api/ReleveFournisseur?codefournisseur=${event.target.value}&vendeur&grp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
               
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, 
        rechercheclicked: true,
     
       }));
     
   
  };


  // componentDidUpdate() {
  //   this.props.SelectFiche();
  // }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleCheckboxChangeA = (event) => {
    const isChecked = event.target.checked;
    const valeur = isChecked ? 1 : 0; // 1 si coché, 0 sinon
  
    // Mettez à jour l'état
    this.setState({ EscompterA: valeur });
   
    // Utilisez la valeur (0 ou 1) comme vous le souhaitez
    console.log("Valeur de la case à cocher : ", valeur);
  }
  remiseChange = (event) => {
    this.setState({
      stkfin1: event.target.value,
      totalstfin:
      this.state.QteEntr + this.state.QteRet - this.state.QteRet
          
 
    });
       console.log("totalstfin",this.props.totalstfin)
  };

  openReleveFournisseur = () => {
    this.setState({ openReleveFournisseurModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    if(this.state.GMM==false){
      this.state.gilad
    ? this.setState({ showrechbydatecodeart: false })
    ? this.setState({ rechercheclicked: false }) 
    :this.setState({showrechbydatecodeart:false}):this.setState({showrechbydatecodeart:false});
 
  }else{ 
     this.state.gilad
      ? this.setState({ showrechbydatecodeart: false })
      ? this.setState({ rechercheclickedGMM: false }) 
      :this.setState({showrechbydatecodeart:false}):this.setState({showrechbydatecodeart:false});
}
       
   
  
    
  };
 

  exportToExcel = () => {
    // Préparation des données

    if(this.state.rechercheclicked){

      const data = this.state.rechs.map(item => {
        const date = new Date(item.Date);
        let lib = item.datfac ? item.libelle : `${item.codfrs} ${item.raisoc}`;
        let formattedDate = item.Date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : 'Client : ';
        
        return [
          formattedDate,
          lib,
          item.Mode,
          item.CHQ,
          item.echeance,
          Number(item.debit).toFixed(3),
          Number(item.credit).toFixed(3),
          Number(item.Solde).toFixed(3),
        ];
      });
    
      // Calcul des totaux
      const totals = data.reduce(
        (acc, row) => {
          acc[6] += Number(row[6]); // Debit
          acc[7] += Number(row[7]); // Crédit
          acc[8] = Number(acc[6]-acc[7]); // Solde
          return acc;
        },
        ['', 'T o t a u x General','','','',  0, 0, 0]
      );
    
      // Ajout des totaux à la fin des données
      data.push(totals);
    
      // Création d'un nouveau classeur
      const classeur = XLSX.utils.book_new();
      const feuille = XLSX.utils.aoa_to_sheet([]);
    
      // Ajout du titre de tableau
      const title = `                                                                          Liste des Relevé clients de 
      (${moment(this.state.firstdate).format("DD/MM/YYYY")}) Au (${moment(this.state.seconddate).format("DD/MM/YYYY")})`;
      feuille['A1'] = { v: title, t: 's' };
      feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 9 } }];
    
      // Définition de l'entête du tableau
      const header = ['Date', 'Libelle', 'Mode', 'N°Pièce', 'Echéance', 'Debit', 'Crédit', 'Solde'];
      XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
      // Ajout des données de tableau à partir de la cinquième ligne
      XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
    
      // Définition des bordures
      const range = XLSX.utils.decode_range(feuille['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          if (!feuille[cellRef]) continue;
          feuille[cellRef].s = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
          };
        }
      }
    
      // Ajout de la feuille au classeur
      XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
    
      // Génération d'un fichier téléchargeable
      XLSX.writeFile(classeur, "releve Fournisseur.xlsx");
     }else if  (this.state.showrechbydate){
        const data = this.state.rechdats.map(item => {
          const date = new Date(item.datfac);
          let lib = item.datfac ? item.libelle : `${item.codfrs} ${item.raisoc}`;
          let formattedDate = item.datfac ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : 'Fournisseur : ';
          
          return [
            formattedDate,
            lib,
            item.Mode,
            item.CHQ,
            item.echeance,
            Number(item.debit).toFixed(3),
            Number(item.credit).toFixed(3),
            Number(item.Solde).toFixed(3),
          ];
        });
      
        // Calcul des totaux
        const totals = data.reduce(
          (acc, row) => {
            acc[6] += Number(row[6]); // Debit
            acc[7] += Number(row[7]); // Crédit
            acc[8] = Number(acc[6]-acc[7]); // Solde
            return acc;
          },
          ['', 'T o t a u x General',  '','','',  0, 0, 0]
        );
      
        // Ajout des totaux à la fin des données
        data.push(totals);
      
        // Création d'un nouveau classeur
        const classeur = XLSX.utils.book_new();
        const feuille = XLSX.utils.aoa_to_sheet([]);
      
        // Ajout du titre de tableau
        const title = `                                                                          Liste des Relevé Fournisseurs de 
        (${moment(this.state.firstdate).format("DD/MM/YYYY")}) Au (${moment(this.state.seconddate).format("DD/MM/YYYY")})`;
        feuille['A1'] = { v: title, t: 's' };
        feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 9 } }];
      
        // Définition de l'entête du tableau
        const header = ['Date', 'Libelle',  'Mode', 'N°Pièce', 'Echéance', 'Debit', 'Crédit', 'Solde'];
        XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
      
        // Ajout des données de tableau à partir de la cinquième ligne
        XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
      
        // Définition des bordures
        const range = XLSX.utils.decode_range(feuille['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!feuille[cellRef]) continue;
            feuille[cellRef].s = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
            };
          }
        }
      
        // Ajout de la feuille au classeur
        XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
      
        // Génération d'un fichier téléchargeable
        XLSX.writeFile(classeur, "releve Fournisseur.xlsx");
      }else{
        const data = this.props.AllReleverFRS.AllReleverFRS.map(item => {
          const date = new Date(item.datfac);
          let lib = item.datfac ? item.libelle : `${item.codfrs} ${item.raisoc}`;
          let formattedDate = item.datfac ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : 'Fournisseur : ';
          
          return [
            formattedDate,
            lib,
            item.Mode,
            item.CHQ,
            item.echeance,
            Number(item.debit).toFixed(3),
            Number(item.credit).toFixed(3),
            Number(item.Solde).toFixed(3),
          ];
        });
      
        // Calcul des totaux
        const totals = data.reduce(
          (acc, row) => {
            acc[5] += Number(row[5]); // Debit
            acc[6] += Number(row[6]); // Crédit
            acc[7] = Number(acc[5]-acc[6]); // Solde
            return acc;
          },
          ['', 'T o t a u x General','','','',0.0,0.0,0.0]
        );
      
        // Ajout des totaux à la fin des données
        data.push(totals);
      
        // Création d'un nouveau classeur
        const classeur = XLSX.utils.book_new();
        const feuille = XLSX.utils.aoa_to_sheet([]);
      
        // Ajout du titre de tableau
        const title = `                                       Liste des Relevé Fournisseurs de(${moment(this.state.firstdate).format("DD/MM/YYYY")}) Au (${moment(this.state.seconddate).format("DD/MM/YYYY")})`;
        feuille['A1'] = { v: title, t: 's' };
        feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 9 } }];
      
        // Définition de l'entête du tableau
        const header = ['Date', 'Libelle',  'Mode', 'N°Pièce', 'Echéance', 'Debit', 'Crédit', 'Solde'];
        XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
      
        // Ajout des données de tableau à partir de la cinquième ligne
        XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
      
        // Définition des bordures
        const range = XLSX.utils.decode_range(feuille['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!feuille[cellRef]) continue;
            feuille[cellRef].s = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
            };
          }
        }
      
        // Ajout de la feuille au classeur
        XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
      
        // Génération d'un fichier téléchargeable
        XLSX.writeFile(classeur, "releve Fournisseur.xlsx");
      }
  
  };
  



  totalGeneralsCreadits = () => {
    let totalcredit = 0;
 
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
       
        totalcredit+= parseFloat(Number(article.credit));
    })
  }else if(this.state.showrechbydate){
    this.state.rechdats.forEach((article) => {
     
      totalcredit+= parseFloat(Number(article.credit));
  })
}else{
    this.props.AllReleverFRS.AllReleverFRS.forEach((article) => {
        totalcredit += parseFloat(Number(article.credit));
      })
  }
      return totalcredit.toFixed(3); // arrondi à 4 chiffres après la virgule

  };

  totalGeneralsDebits = () => {
    let totalcredit = 0;
 
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
        totalcredit += parseFloat(Number(article.debit));
      })
      }else
      if(this.state.showrechbydate){
        this.state.rechdats.forEach((article) => {
          totalcredit += parseFloat(Number(article.debit));
        })
        }else{ 
    this.props.AllReleverFRS.AllReleverFRS.forEach((article) => {
        totalcredit += parseFloat(Number(article.debit));
      })
    }
      return totalcredit.toFixed(3); // arrondi à 4 chiffres après la virgule

  };


  render() {
   
   //  console.log("fichesGMM", this.state.AllReleverFRS)
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    const rowCount = stableSort(
      this.props.AllReleverFRS.AllReleverFRS,
      getComparator(this.state.order, this.state.orderBy)
    ).length;
  // console.log("raisoc 1", this.props.AllReleverFRS.AllReleverFRS.map((D,i) => (D.raisoc[0])));

  //   console.log(
  //     "raisoc 2",
  //     [...new Set(this.props.AllReleverFRS.AllReleverFRS.map((D) => D.raisoc))]
  //   );
    console.log(`Nombre de lignes affichées: ${rowCount}`);
    let ReleveFournisseurModalClose = () => this.setState({ openReleveFournisseurModal: false });
   //   let addModalClose1 = () => this.setState({ addModalShow1: false });
    let GetByIdModalClose = () => this.setState({ GetByIdModalShow: false });
    const {
      codfrs,
      raisoc,
      datfac,
      libelle,
      SIT,
      Mode,
      CHQ,
      debit,
      credit,
      Solde,
    } = this.state;
   const { darck } = this.props;


const groupedDatALL = _.groupBy(this.props.AllReleverFRS.AllReleverFRS, "raisoc");
const groupedDatarechs = _.groupBy(this.state.rechs, "raisoc");
const groupedDatarechdats = _.groupBy(this.state.rechdats, "raisoc");
// 


    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Stock"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>

          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/fiche-article"
            label="Relevé Fournisseurs"
            icon={<MenuBookIcon fontSize="small" />}
          />
                <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
            {/* <button onClick={this.exportToExcel}>Exporter vers Excel</button> */}
        </Breadcrumbs>
        <main className="gestion-des-articles">
        
          <br />
          <div>
            <Paper
              // elevation={0.5}
              elevation={2}
              style={{
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
            >
              <Row style={{display : "flex" , flexWrap: "nowrap" , marginTop:"2px"}}>
              <Col sm={10}>
               
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                /> 
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                   
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.d1}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  
                  </>
                ) : (
                 
                 <> 
          {this.state.GMM ? (<>  
                      <InputBase
                 margin="dense"
                 // fullWidth={true}
                 placeholder="Rechercher par code art "
                 defaultValue={this.state.codart2}
                 onChange={this.rechercheHandler}
                 value={this.state.codart2}
                 onClick={this.toggle}
                 style={{ color: `${darck ? "" : "#fff"}` }}
               />
                     <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                

                <InputBase
                   margin="dense"
                    //fullWidth={true}
                   placeholder="Rechercher par Mg "
                   onChange={this.rechercheHandlerGMM}
                   onClick={this.toggle}
                   style={{ color: `${darck ? "" : "#fff"}` }}
                 /> 
                </>):(<>  
                 <InputBase
                margin="dense"
                // fullWidth={true}
                placeholder="Rechercher "
                onChange={this.rechercheHandler}
                onClick={this.toggle}
                style={{ color: `${darck ? "" : "#fff"}` }}
              /> &nbsp;&nbsp; &nbsp;&nbsp;
              
                  </>)}
                </>
                )}
              </Col>
              
                {this.props.Auth.fct == "100" ? (
                  <Col sm={2} style={{ display: "flex", justifyContent: "end" }} className="buttart">
                  
                  </Col>
                ) : null}{" "}
              </Row>
            </Paper>
          
           
          </div>
          <br />

          {this.state.rechercheclicked ?(
                <div className="tabga">
             <table striped>
                  <EnhancedTableHead
                    classes={classes}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                    darck={darck}
                  />
                  <tbody style={{ maxHeight: "60vh" }}>
                  {stableSort(
                                   this.state.rechs,
                                   getComparator(this.state.order, this.state.orderBy)
                                 ).map((article) => (
                      <tr
                        key={article.codfrs}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          fetch(
                            `https://www.24-crm.com/webservice/api/ReleveFournisseur?codefournisseur=${article.codfrs}&vendeur&grp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
               
                          )
                            .then((response) => response.json())
                            .then((data) =>
                              this.setState({
                                tabtab: data,
                                sumqt:
                                  data &&
                                  data.reduce((a, v) => a + parseInt(v.quantite), 0),
                              })
                            );
                
                          this.setState({
                            GetByIdModalShow: true,
                            raisoc:article.raisoc,
                            codfrs: article.codfrs,
                            datfac: article.datfac,
                            libelle: article.libelle,
                          
                            Mode: article.Mode,
                            CHQ: article.CHQ,
                            debit: article.debit,
                            credit: article.credit,
                            Solde: article.Solde,
                        
                          });
                
                          article.codfrs === null
                            ? this.setState({ codfrs: "" })
                            : this.setState({ codfrs: article.codfrs });
                        }}
                      >
                        <td>
                         
                            {article.datfac == null ? (
                              <span  style={{   
                                color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                              fontWeight: "bold",
                              fontSize: "14px",}}
                              >Fournisseur :</span>
                            ) : (
                              <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                                {moment(article.datfac).format("DD/MM/YYYY")}
                              </span>
                            )}
                          
                        </td>
                        <td>
                
                          
                             {article.datfac == null ? (
                              <span style={{   
                                color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                              fontWeight: "bold",  textAlign: "left",
                              fontSize: "14px",}}>
                                {article.codfrs} {" "} {article.raisoc}
                                
                                 </span>
                            ) : (
                              <span
                              style={{
                                color: `${darck ? "#182532" : "#fff"}` 
                                // color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                                // fontWeight: "bold",
                                // fontSize: "14px",
                              }}
                            > {article.libelle}
                            </span>
                            )}
                           
                        </td>
                        {article.datfac == null ? ( <td>
                         
                        </td>):( <td
                          style={{
                            // color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                            // fontWeight: "bold",
                            // fontSize: "14px",
                          }}
                        >
                          <span>{article.SIT||"--"}</span>
                        </td>)}
                       
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                          <span>
                            {article.Mode == "" ? (
                              <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>--</span>
                            ) : (
                              <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                                {article.Mode}
                              </span>
                            )}
                          </span>
                        </td>)}
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td>
                       
                            {article.CHQ == "" ? (
                              <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                            ) : (
                              <span style={{ color: `${darck ? "" : "#fff"}` }}>
                                {article.CHQ}
                              </span>
                            )}
                      
                        </td>)}
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td style={{ color: `${darck ? "" : "#fff"}` }}>
                          {article.echeance == null ? (
                            <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                          ) : (
                            <span style={{ color: `${darck ? "" : "#fff"}` }}>
                              {moment(article.echeance).format("DD/MM/YYYY")}
                            </span>
                          )}
                        </td>)}
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                          {article.debit == "0" ? (
                            <span style={{ color: `${darck ? "green" : "#fff"}` }}>--</span>
                          ) : (
                            <span style={{ color: `${darck ? "green" : "#fff"}` }}>
                              {Number(article.debit).toFixed(3)}
                            </span>
                          )}
                        </td>)}
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td>
                          <span style={{ color: "#8f2d56" }}>
                            {Number(article.credit).toFixed(3)}
                          </span>
                        </td>)}
                        {article.datfac == null ? ( <td>
                         
                         </td>):(
                        <td>
                            {article.Solde < 0 ? (
                                        <span style={{ color: "#9B1C24" }}> {Number(article.Solde).toFixed(3)}</span>
                                      ) : (
                                        <span style={{ color: "#155757" }}> {Number(article.Solde).toFixed(3)}</span>
                                      )}
                        
                        </td>)}
                      </tr>
                    ))}
                  </tbody>
                </table>
                
                <Row style={{ marginTop: "10px" }}>
                                      <Col style={{ textAlign: "center" }} sm={4}>
                                        <Typography
                                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                        >
                                          Total Général débit:
                                        </Typography>
                                        <Typography
                                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                        >
                                             {this.totalGeneralsDebits()}
                                        </Typography>
                                      </Col>
                
                                      <Col style={{ textAlign: "center" }} sm={4}>
                                        <Typography
                                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                        >
                                          Total Général crédit:
                                        </Typography>
                                        <Typography
                                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                        >
                                         {this.totalGeneralsCreadits()}
                                        </Typography>
                                      </Col>
                
                                      <Col style={{ textAlign: "center" }} sm={4}>
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            color: `${darck ? "#020F75" : "#fff"}`,
                                          }}
                                        >
                                          Total Général solde:
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            color: `${darck ? "#020F75" : "#fff"}`,
                                          }}
                                        >
                                               {this.totalGeneralsDebits()-this.totalGeneralsCreadits()}
                                        </Typography>
                                      </Col>
                                    </Row>
        
                                                
                <br />
                 
                           
                
                                         </div>
          ):  this.state.showrechbydate ? (
            <div className="tabga">
              <table striped>
                 <EnhancedTableHead
                    classes={classes}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                   onRequestSort={this.handleRequestSort}
                    darck={darck}
                  /> 
                  <tbody style={{ maxHeight: "60vh" }}>
                  {stableSort(
                                   this.state.rechdats,
                                   getComparator(this.state.order, this.state.orderBy)
                                 ).map((article) => (
                      <tr
                                  key={article.codfrs}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    fetch(
                                      `https://www.24-crm.com/webservice/api/ReleveFournisseur?codefournisseur=${article.codfrs}&vendeur&grp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                         
                                    )
                                      .then((response) => response.json())
                                      .then((data) =>
                                        this.setState({
                                          tabtab: data,
                                          sumqt:
                                            data &&
                                            data.reduce((a, v) => a + parseInt(v.quantite), 0),
                                        })
                                      );
                          
                                    this.setState({
                                      GetByIdModalShow: true,
                                      raisoc:article.raisoc,
                                      codfrs: article.codfrs,
                                      datfac: article.datfac,
                                      libelle: article.libelle,
                                    
                                      Mode: article.Mode,
                                      CHQ: article.CHQ,
                                      debit: article.debit,
                                      credit: article.credit,
                                      Solde: article.Solde,
                                  
                                    });
                          
                                    article.codfrs === null
                                      ? this.setState({ codfrs: "" })
                                      : this.setState({ codfrs: article.codfrs });
                                  }}
                                >
                                  <td>
                                   
                                   {article.datfac == null ? (
                                     <span  style={{   
                                       color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                                     fontWeight: "bold",
                                     fontSize: "14px",}}
                                     >Fournisseur :</span>
                                   ) : (
                                     <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                                       {moment(article.datfac).format("DD/MM/YYYY")}
                                     </span>
                                   )}
                                 
                               </td>
                               <td>
                       
                                 
                                    {article.datfac == null ? (
                                     <span style={{   
                                       color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                                     fontWeight: "bold",  textAlign: "left",
                                     fontSize: "14px",}}>
                                       {article.codfrs} {" "} {article.raisoc}
                                       
                                        </span>
                                   ) : (
                                     <span
                                     style={{
                                       color: `${darck ? "#182532" : "#fff"}` 
                                       // color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                                       // fontWeight: "bold",
                                       // fontSize: "14px",
                                     }}
                                   > {article.libelle}
                                   </span>
                                   )}
                                  
                               </td>
                               
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                                 <span>
                                   {article.Mode == "" ? (
                                     <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>--</span>
                                   ) : (
                                     <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                                       {article.Mode}
                                     </span>
                                   )}
                                 </span>
                               </td>)}
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td>
                              
                                   {article.CHQ == "" ? (
                                     <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                                   ) : (
                                     <span style={{ color: `${darck ? "" : "#fff"}` }}>
                                       {article.CHQ}
                                     </span>
                                   )}
                             
                               </td>)}
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                 {article.echeance == null ? (
                                   <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                                 ) : (
                                   <span style={{ color: `${darck ? "" : "#fff"}` }}>
                                     {moment(article.echeance).format("DD/MM/YYYY")}
                                   </span>
                                 )}
                               </td>)}
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                                 {article.debit == "0" ? (
                                   <span style={{ color: `${darck ? "green" : "#fff"}` }}>--</span>
                                 ) : (
                                   <span style={{ color: `${darck ? "green" : "#fff"}` }}>
                                     {Number(article.debit).toFixed(3)}
                                   </span>
                                 )}
                               </td>)}
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td>
                                 <span style={{ color: "#8f2d56" }}>
                                   {Number(article.credit).toFixed(3)}
                                 </span>
                               </td>)}
                               {article.datfac == null ? ( <td>
                                
                                </td>):(
                               <td>
                                   {article.Solde < 0 ? (
                                               <span style={{ color: "#9B1C24" }}> {Number(article.Solde).toFixed(3)}</span>
                                             ) : (
                                               <span style={{ color: "#155757" }}> {Number(article.Solde).toFixed(3)}</span>
                                             )}
                               
                               </td>)}
                                </tr>
                    ))}
                  </tbody>
                </table>
                
            <Row style={{ marginTop: "10px" }}>
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                      Total Général débit:
                                    </Typography>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                         {this.totalGeneralsDebits()}
                                    </Typography>
                                  </Col>
            
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                      Total Général crédit:
                                    </Typography>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                     {this.totalGeneralsCreadits()}
                                    </Typography>
                                  </Col>
            
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        color: `${darck ? "#020F75" : "#fff"}`,
                                      }}
                                    >
                                      Total Général solde:
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        color: `${darck ? "#020F75" : "#fff"}`,
                                      }}
                                    >
                                           {this.totalGeneralsDebits()-this.totalGeneralsCreadits()}
                                    </Typography>
                                  </Col>
                                </Row>
    
                                            
            <br />
             
                       
            
                                     </div>
          ):(   
             <div className="tabga">
             <table striped>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
              {stableSort(
                               this.props.AllReleverFRS.AllReleverFRS,
                               getComparator(this.state.order, this.state.orderBy)
                             ).map((article) => (
                  <tr
                    key={article.codfrs}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      fetch(
                     `https://www.24-crm.com/webservice/api/ReleveFournisseur?codefournisseur=${article.codfrs}&vendeur&grp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
               
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce((a, v) => a + parseInt(v.quantite), 0),
                          })
                        );
            
                      this.setState({
                        GetByIdModalShow: true,
                        raisoc:article.raisoc,
                        codfrs: article.codfrs,
                        datfac: article.datfac,
                        libelle: article.libelle,
                       
                        Mode: article.Mode,
                        CHQ: article.CHQ,
                        debit: article.debit,
                        credit: article.credit,
                        Solde: article.Solde,
                    
                      });
            
                      article.codfrs === null
                        ? this.setState({ codfrs: "" })
                        : this.setState({ codfrs: article.codfrs });
                    }}
                  >
                    <td>
                     
                        {article.datfac == null ? (
                          <span  style={{   
                            color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                          fontWeight: "bold",
                          fontSize: "14px",}}
                          >Fournisseur :</span>
                        ) : (
                          <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                            {moment(article.datfac).format("DD/MM/YYYY")}
                          </span>
                        )}
                      
                    </td>
                    <td>
            
                      
                         {article.datfac == null ? (
                          <span style={{   
                            color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                          fontWeight: "bold",  textAlign: "left",
                          fontSize: "14px",}}>
                            {article.codfrs} {" "} {article.raisoc}
                            
                             </span>
                        ) : (
                          <span
                          style={{
                            color: `${darck ? "#182532" : "#fff"}` 
                            // color: `${darck ? "#8f2d56" : "#8f2d56"}`,
                            // fontWeight: "bold",
                            // fontSize: "14px",
                          }}
                        > {article.libelle}
                        </span>
                        )}
                       
                    </td>
                
                   
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      <span>
                        {article.Mode == "" ? (
                          <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>--</span>
                        ) : (
                          <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                            {article.Mode}
                          </span>
                        )}
                      </span>
                    </td>)}
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td>
                   
                        {article.CHQ == "" ? (
                          <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                        ) : (
                          <span style={{ color: `${darck ? "" : "#fff"}` }}>
                            {article.CHQ}
                          </span>
                        )}
                  
                    </td>)}
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td style={{ color: `${darck ? "" : "#fff"}` }}>
                      {article.echeance == null ? (
                        <span style={{ color: `${darck ? "" : "#fff"}` }}>--</span>
                      ) : (
                        <span style={{ color: `${darck ? "" : "#fff"}` }}>
                          {moment(article.echeance).format("DD/MM/YYYY")}
                        </span>
                      )}
                    </td>)}
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                      {article.debit == "0" ? (
                        <span style={{ color: `${darck ? "green" : "#fff"}` }}>--</span>
                      ) : (
                        <span style={{ color: `${darck ? "green" : "#fff"}` }}>
                          {Number(article.debit).toFixed(3)}
                        </span>
                      )}
                    </td>)}
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td>
                      <span style={{ color: "#8f2d56" }}>
                        {Number(article.credit).toFixed(3)}
                      </span>
                    </td>)}
                    {article.datfac == null ? ( <td>
                     
                     </td>):(
                    <td>
                        {article.Solde < 0 ? (
                                    <span style={{ color: "#9B1C24" }}> {Number(article.Solde).toFixed(3)}</span>
                                  ) : (
                                    <span style={{ color: "#155757" }}> {Number(article.Solde).toFixed(3)}</span>
                                  )}
                    
                    </td>)}
                  </tr>
                ))}
              </tbody>
            </table>
            
            <Row style={{ marginTop: "10px" }}>
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                      Total Général débit:
                                    </Typography>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                         {this.totalGeneralsDebits()}
                                    </Typography>
                                  </Col>
            
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                      Total Général crédit:
                                    </Typography>
                                    <Typography
                                      style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                                    >
                                     {this.totalGeneralsCreadits()}
                                    </Typography>
                                  </Col>
            
                                  <Col style={{ textAlign: "center" }} sm={4}>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        color: `${darck ? "#020F75" : "#fff"}`,
                                      }}
                                    >
                                      Total Général solde:
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        color: `${darck ? "#020F75" : "#fff"}`,
                                      }}
                                    >
                                           {this.totalGeneralsDebits()-this.totalGeneralsCreadits()}
                                    </Typography>
                                  </Col>
                                </Row>
                   
           
                                            
            <br />
             
                       
            
             </div>
             )}

        
  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "5px", right: "30px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
  <div style={{ display: "none" }}>
  <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
        <img
          src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
        alt="Pas-Photo"
          style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
        /> 
        
        
                    <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                      Société {this.props.InfoEntreprise[0].coddos}
                    </h1>
                    <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                      {this.props.InfoEntreprise[0].spe}
                      <br />
                      Adresse : {this.props.InfoEntreprise[0].adr} <br />
                      Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                      {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                      {this.props.InfoEntreprise[0].email} <br />
                    Matricule Fiscal : {this.props.InfoEntreprise[0].codtva} <br />
                      RIB: {this.props.InfoEntreprise[0].rib}{" "}
                      {this.props.InfoEntreprise[0].matban}{" "}
                      {this.props.InfoEntreprise[0].agence}{" "}
                      {this.props.InfoEntreprise[0].ville}
                      <br />
                      {translate("Etabli par")}: {this.props.Auth.username}
                    </p>
                    <h5
                      style={{
                        marginLeft: "450px",
                        flexDirection: "column",
                        marginTop: "25px",
                        marginBottom: "-35px",
                        fontWeight: "bolder",
                      }}
                    >
                    
                <>Relevé Fournisseurs </>
                  
                    </h5>
                    <br/>
                    <h5
                      style={{
                        marginLeft: "390px",
                        flexDirection: "column",
                        marginTop: "25px",
                        marginBottom: "-35px",
                        fontWeight: "bolder",
                      }}
                    >
                  
                 de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                        { moment(this.state.seconddate).format("DD/MM/YYYY")}
                   
                  
        
                    
                    </h5>

             <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6> 
            <h6
                      style={{
                        marginLeft: "35px",
                        flexDirection: "column",
                        marginTop: "25px",
                        marginBottom: "-35px",
                        fontWeight: "bolder",
                      }}
                    >
                   {this.state.rechercheclicked ?( <> Fournisseur: {[...new Set(this.state.rechs.map(i => i.raisoc))].join(', ')}</>):(<> </>)}
                    </h6>
                      </Col>
                  
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "10px",
                        width: "99%",
                        minHeight: "540px",
                        padding: "20px 25px",
                      }}
                    >  
            
            

            {this.state.rechercheclicked ?(   
              <>
              <table
        style={{
          textAlign: "center",
          borderStyle: "10px",
          width: "100%",
          marginTop: "25px",
        }}
      >
        {Object.keys(groupedDatarechs).map((raisocKey, index) => {
          // Initialize totals
          let totalDebit = 0;
          let totalCredit = 0;
          let totalSolde = 0;

          // Calculate totals for each client group
          groupedDatarechs[raisocKey].forEach((t) => {
            totalDebit += t.debit || 0;
            totalCredit += t.credit || 0;
            totalSolde = t.Solde || 0;
          });

          return (
            <React.Fragment key={raisocKey}>

            

              {/* Table header for each client group */}
              <thead
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "30px",
                }}
              > 
              <tr>
                <td colSpan="9" style={{ textAlign: "left", fontWeight: "bold", paddingTop: "20px" }}>
                  {/* Client:  */}
                  {/* {raisocKey} */}
                </td>
              </tr>
                <tr
                  style={{
                    borderBottom: "1px solid grey",
                    border: "1px solid grey",
                    height: "50px",
                  }}
                >
                  <th style={{ width: "5%"}}>{translate("date")}</th>
                  <th style={{ width: "25%", border: "1px solid gray" }}>{translate("Libellé")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("SIT")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("M.R")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("N° Pièce")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Echéance")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Debit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("crédit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Solde")}</th>
                </tr>
              </thead>

              {/* Render rows for each client group */}
              <tbody>
                {groupedDatarechs[raisocKey].map((t, i) => (
                  <tr key={i} style={{ textAlign: "center", height: "40px", border: "1px solid gray" }}>
                  {t.datfac == null ?(<td style={{ color: `${darck ? "#8f2d56" : "#fff"}`, width: "5%" ,fontWeight: "bold",fontSize: "16px"}}>
                      Fournisseur: 
                    </td>) :(<td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      { moment(t.datfac).format("DD/MM/YYYY")}
                    </td>) }
                  {t.datfac == null ? (  <td style={{ color: `${darck ? "#8f2d56" : "#fff"}`,   textAlign: "left", width: "25%" ,fontWeight: "bold",fontSize: "16px"}}>
                  { t.codfrs  + " " + t.raisoc}
                    </td>  )
                  :( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "25%", border: "1px solid gray" }}>
                  {t.libelle}
                    </td>)}  
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      {t.SIT||"--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.MR || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.CHQ || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.echeance == null ? "--" : moment(t.echeance).format("DD/MM/YYYY")}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "green" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.debit || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "#9B1C24" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.credit || "--"}
                    </td>)}
                    <td style={{ color: `${darck ? "darkslateblue" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.Solde < 0 ? (
                        <span style={{ color: "#9B1C24" }}>{(t.Solde).toFixed(3)}</span>
                      ) : (
                        <span style={{ color: "#155757" }}>{(t.Solde).toFixed(3)}</span>
                      )}
                    </td>
                  </tr>
                ))}

                {/* Totals row for each client group */}
                <tr style={{ borderBottom: "1px solid grey", height: "50px", border: "1px solid gray" }}>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "10%" }}>Totaux Du Fournisseur :  </th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalDebit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalCredit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalSolde).toFixed(3)}</th>
                </tr>
              </tbody>
            </React.Fragment>
          );
        })}
            </table> 
         
           </> ):this.state.showrechbydate ?(   
              <>
              <table
        style={{
          textAlign: "center",
          borderStyle: "10px",
          width: "100%",
          marginTop: "25px",
        }}
      >
        {Object.keys(groupedDatarechdats).map((raisocKey, index) => {
          // Initialize totals
          let totalDebit = 0;
          let totalCredit = 0;
          let totalSolde = 0;

          // Calculate totals for each client group
          groupedDatarechdats[raisocKey].forEach((t) => {
            totalDebit += t.debit || 0;
            totalCredit += t.credit || 0;
            totalSolde = t.Solde || 0;
          });

          return (
            <React.Fragment key={raisocKey}>

            

              {/* Table header for each client group */}
              <thead
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "30px",
                }}
              > 
              <tr>
                <td colSpan="9" style={{ textAlign: "left", fontWeight: "bold", paddingTop: "20px" }}>
                  {/* Client:  */}
                  {/* {raisocKey} */}
                </td>
              </tr>
                <tr
                  style={{
                    borderBottom: "1px solid grey",
                    border: "1px solid grey",
                    height: "50px",
                  }}
                >
                  <th style={{ width: "5%"}}>{translate("date")}</th>
                  <th style={{ width: "25%", border: "1px solid gray" }}>{translate("Libellé")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("SIT")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("M.R")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("N° Pièce")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Echéance")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Debit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("crédit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Solde")}</th>
                </tr>
              </thead>

              {/* Render rows for each client group */}
              <tbody>
                {groupedDatarechdats[raisocKey].map((t, i) => (
                  <tr key={i} style={{ textAlign: "center", height: "40px", border: "1px solid gray" }}>
                  {t.datfac == null ?(<td style={{ color: `${darck ? "#8f2d56" : "#fff"}`, width: "5%" ,fontWeight: "bold",fontSize: "16px"}}>
                      Fournisseur: 
                    </td>) :(<td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      { moment(t.datfac).format("DD/MM/YYYY")}
                    </td>) }
                  {t.datfac == null ? (  <td style={{ color: `${darck ? "#8f2d56" : "#fff"}`,   textAlign: "left", width: "25%" ,fontWeight: "bold",fontSize: "16px"}}>
                  { t.codfrs  + " " + t.raisoc}
                    </td>  )
                  :( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "25%", border: "1px solid gray" }}>
                  {t.libelle}
                    </td>)}  
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      {t.SIT||"--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.MR || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.CHQ || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.echeance == null ? "--" : moment(t.echeance).format("DD/MM/YYYY")}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "green" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.debit || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "#9B1C24" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.credit || "--"}
                    </td>)}
                    <td style={{ color: `${darck ? "darkslateblue" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.Solde < 0 ? (
                        <span style={{ color: "#9B1C24" }}>{(t.Solde).toFixed(3)}</span>
                      ) : (
                        <span style={{ color: "#155757" }}>{(t.Solde).toFixed(3)}</span>
                      )}
                    </td>
                  </tr>
                ))}

                {/* Totals row for each Fournisseur group */}
                <tr style={{ borderBottom: "1px solid grey", height: "50px", border: "1px solid gray" }}>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "10%" }}>Totaux Du Fournisseur :  </th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalDebit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalCredit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalSolde).toFixed(3)}</th>
                </tr>
              </tbody>
            </React.Fragment>
          );
        })}
            </table> 
         
           </> ):(  
            <>
              <table
        style={{
          textAlign: "center",
          borderStyle: "10px",
          width: "100%",
          marginTop: "25px",
        }}
      >
        {Object.keys(groupedDatALL).map((raisocKey, index) => {
          // Initialize totals
          let totalDebit = 0;
          let totalCredit = 0;
          let totalSolde = 0;

          // Calculate totals for each client group
          groupedDatALL[raisocKey].forEach((t) => {
            totalDebit += t.debit || 0;
            totalCredit += t.credit || 0;
            totalSolde = t.Solde || 0;
          });

          return (
            <React.Fragment key={raisocKey}>

            

              {/* Table header for each client group */}
              <thead
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "30px",
                }}
              > 
              <tr>
                <td colSpan="9" style={{ textAlign: "left", fontWeight: "bold", paddingTop: "20px" }}>
                  {/* Client:  */}
                  {/* {raisocKey} */}
                </td>
              </tr>
                <tr
                  style={{
                    borderBottom: "1px solid grey",
                    border: "1px solid grey",
                    height: "50px",
                  }}
                >
                  <th style={{ width: "5%"}}>{translate("datfac")}</th>
                  <th style={{ width: "25%", border: "1px solid gray" }}>{translate("Libellé")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("SIT")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("M.R")}</th>
                  <th style={{ width: "5%", border: "1px solid gray" }}>{translate("N° Pièce")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Echéance")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Debit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("crédit")}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Solde")}</th>
                </tr>
              </thead>

              {/* Render rows for each client group */}
              <tbody>
                {groupedDatALL[raisocKey].map((t, i) => (
                  <tr key={i} style={{ textAlign: "center", height: "40px", border: "1px solid gray" }}>
                  {t.datfac == null ?(<td style={{ color: `${darck ? "#8f2d56" : "#fff"}`, width: "5%" ,fontWeight: "bold",fontSize: "16px"}}>
                      Client: 
                    </td>) :(<td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      { moment(t.datfac).format("DD/MM/YYYY")}
                    </td>) }
                  {t.datfac == null ? (  <td style={{ color: `${darck ? "#8f2d56" : "#fff"}`,   textAlign: "left", width: "25%" ,fontWeight: "bold",fontSize: "16px"}}>
                  { t.codfrs  + " " + t.raisoc}
                    </td>  )
                  :( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "25%", border: "1px solid gray" }}>
                  {t.libelle}
                    </td>)}  
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%", border: "1px solid gray" }}>
                      {t.SIT||"--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                    </td>
                    ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.MR || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.CHQ || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "black" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.echeance == null ? "--" : moment(t.echeance).format("DD/MM/YYYY")}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "green" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.debit || "--"}
                    </td>)}
                    {t.datfac == null ? ( <td style={{ color: `${darck ? "black" : "#fff"}`, width: "5%"}}>
                      
                      </td>
                      ):(
                    <td style={{ color: `${darck ? "#9B1C24" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.credit || "--"}
                    </td>)}
                    <td style={{ color: `${darck ? "darkslateblue" : "#fff"}`, width: "3%", border: "1px solid gray" }}>
                      {t.Solde < 0 ? (
                        <span style={{ color: "#9B1C24" }}>{(t.Solde).toFixed(3)}</span>
                      ) : (
                        <span style={{ color: "#155757" }}>{(t.Solde).toFixed(3)}</span>
                      )}
                    </td>
                  </tr>
                ))}

                {/* Totals row for each client group */}
                <tr style={{ borderBottom: "1px solid grey", height: "50px", border: "1px solid gray" }}>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "0%" }}></th>
                  <th style={{ width: "10%" }}>Totaux Du Fournisseur :  </th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalDebit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalCredit).toFixed(3)}</th>
                  <th style={{ width: "10%", border: "1px solid gray" }}>{Number(totalSolde).toFixed(3)}</th>
                </tr>
              </tbody>
            </React.Fragment>
          );
        })}
            </table> 
         <Row style={{ marginTop: "10px" }}>
                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          Total Général débit:
                        </Typography>
                        <Typography
                          style={{ color: `${darck ? "black" : "#fff"}` }}
                        >
                             {this.totalGeneralsDebits()}
                        </Typography>
                      </Col>

                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          Total Général crédit:
                        </Typography>
                        <Typography
                          style={{ color: `${darck ? "black" : "#fff"}` }}
                        >
                         {this.totalGeneralsCreadits()}
                        </Typography>
                      </Col>

                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: `${darck ? "#020F75" : "#fff"}`,
                          }}
                        >
                          Solde  Général :
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: `${darck ? "#020F75" : "#fff"}`,
                          }}
                        > {this.totalGeneralsDebits()-this.totalGeneralsCreadits() < 0 ? (
                          <span style={{ color: "#9B1C24" }}>{(this.totalGeneralsDebits()-this.totalGeneralsCreadits()).toFixed(3)}</span>
                        ) : (
                          <span style={{ color: "#155757" }}>{(this.totalGeneralsDebits()-this.totalGeneralsCreadits()).toFixed(3)}</span>
                        )}
                                    {/* {this.totalGeneralsDebits()-this.totalGeneralsCreadits()} */}
                        </Typography>
                      </Col>
           </Row></>
           )}



        
       
      
              
               
           
           
               
              </div>
            
          
            
   </div>
   </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
                      
          
       
        
 
   
        </main>
    <ReleveFRsDetaill
            show={this.state.GetByIdModalShow}
            onHide={GetByIdModalClose}
            codfrs={codfrs}
            raisoc={raisoc}
            datfac={datfac}
            libelle={libelle}           
            Mode={Mode}
            CHQ={CHQ}
            debit={debit}
            credit={credit}
            Solde={Solde}
            tabtab={this.state.tabtab}
          />  
        {/* <ReleveFRSModal
              show={this.state.openReleveFRSModal}
              onHide={ReleveFRSModalClose}
              codeclient={this.props.codeclient}
              raisonsociale={this.props.raisoc}
              adresse={this.props.adresse}
              tel1={this.props.tel1}
            /> */}
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch) => ({
  getArticleList: (a, b, c, d, e) => {
    dispatch(getArticleList(a, b, c, d, e));
  },
  getArticleHeader: () => {
    dispatch(getArticleHeader());
  },


  // SelectFiche: (a, b, c, d, e) => dispatch(SelectFiche(a, b, c, d, e)),
   SelectAllReleveFRS: (a, b, c, d, e) => dispatch(SelectAllReleveFRS(a, b, c, d, e)),
});


const mapStateToProps = (state) => {
  return {
   
    Auth: state.Auth,

   // fiches: state.fiches,
    AllReleverFRS:state.AllReleverFRS,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
};
const ConnectedReleveFournisseur = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleveFournisseur);

export default ConnectedReleveFournisseur;

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "datefac",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
 
  {
    id: "Libelle",
    numeric: true,
    disablePadding: false,
    label: "Libellé",
  },


  
  {
    id: "MR",
    numeric: true,
    disablePadding: false,
    label: "Mode",
  },
  { id: "chqeff", numeric: true,
   disablePadding: false,
    label: "N° Pièce" },
  {
    id: "Echéance",
    numeric: true,
    disablePadding: false,
    label: "Echéance ",
  },
  {
    id: "Debit",
    numeric: true,
    disablePadding: false,
    label: "Debit",
  
  },
  {
    id: "crédit",
    numeric: true,
    disablePadding: false,
    label: "crédit",
  },
  {
    id: "Solde",
    numeric: true,
    disablePadding: false,
    label: "Solde",
  },
 
];


function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}




const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
