import React, { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TocIcon from "@material-ui/icons/Toc";
import DehazeIcon from "@material-ui/icons/Dehaze";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import translate from "../../i18n/translate";
import Switch from "@material-ui/core/Switch";
import { DarckModeAction } from "../../redux/actions/DarckModeAction";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

import "./style.css";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const NavbarComp = (props) => {
  const [isOpen, setOpen] = useState(true);
  const [time, setTime] = useState();
  const { darck } = props;
  const toggle = () => setOpen(!isOpen);

  setInterval(() => {
    const date = new Date().getDate(); //Current Date
    const month = new Date().getMonth() + 1; //Current Month
    const year = new Date().getFullYear(); //Current Year
    const hours = new String(new Date().getHours()); //Current Hours
    var zero1 = "";
    var zero2 = "";
    var zero3 = "";
    if (new String(hours).length === 1) {
      zero1 = "0";
    }
    const min = new String(new Date().getMinutes()); //Current Minutes
    if (new String(min).length === 1) {
      zero2 = "0";
    }
    const sec = new String(new Date().getSeconds()); //Current Seconds
    if (new String(sec).length === 1) {
      zero3 = "0";
    }

    const datenow =
      date + "/" + month + "/" + year + " " + zero1 + hours + ":" + zero2 + min;
    setTime(datenow);
  }, 1000);
  const username = localStorage.getItem("username");
  const theme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: "#0c0f4e",
          "&$checked": {
            color: "#fff",
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: "#0c0f4e",
          "$checked$checked + &": {
            opacity: 0.7,
            backgroundColor: "#fff",
          },
        },
      },
    },
  });
  
  return (
    <Navbar
      darck
      className="navbar shadow-sm p-2 rounded"
      style={{
        marginTop: "-10px",
        marginBottom: "20px",
        backgroundColor: `${darck ? "#fff" : "#172431"}`,
        border: `${darck ? "" : "2px solid #fff"}`,
      }}
      expand="md"
    >
      <TocIcon
        onClick={props.toggle}
        style={{ fontSize: "25px", color: `${darck ? "#0c0f4e" : "#fff"}` }}
      />
      {/* </Button> */}

      {darck ? (
        <Link id="logo">
          <img
            src="/images/24crmlogo2.png"
            alt="Logo"
            width="160"
            height="65"
          />
        </Link>
      ) : (
        <Link id="logo">
          <img
            src="/images/bigwhite24crm.png"
            //src="/images/24log2.png"
            alt="Logo"
            width="160"
            height="65"
          />
        </Link>
      )}

      <NavbarToggler onClick={toggle}>
        <DehazeIcon
          style={{ fontSize: "25px", color: `${darck ? "#0c0f4e" : "#fff"}` }}
        />
      </NavbarToggler>

      <Collapse isOpen={!isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <span style={{ marginTop: "6%" }}>
            <ThemeProvider theme={theme}>
              <Switch
                size="small"
                onChange={() => props.DarckModeAction(!darck)}
                color="warning"
                name="checkedB"
                inputProps={{ "aria-label": "warning checkbox" }}
                checked={!darck}
              />
            </ThemeProvider>
          </span>
          <span style={{ marginTop: "6%" }}>
            <AccountCircleIcon
              style={{ color: `${darck ? "#0c0f4e" : "#fff"}` }}
            />{" "}
            &nbsp;
            <span
              style={{
                fontWeight: "bold",
                color: `${darck ? "#0c0f4e" : "#fff"}`,
              }}
            >
              {props.Auth.username}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{new String(time)}
            </span>
          </span>

          <NavItem style={{ marginTop: "4%" }}>
            <NavLink tag={Link} to={"/logout"}>
              <i
                class="fas fa-sign-out-alt"
                style={{
                  color: `${darck ? "rgb(216, 95, 16)" : "rgb(216, 95, 16)"}`,
                  fontSize: "16px",
                }}
                //rgb(220, 0, 78)
              >
                {" "}
                {translate("Se Déconnecter")}
              </i>
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    DarckModeAction: (a) => dispatch(DarckModeAction(a)),
  };
}

function mapStateToProps(state) {
  return {
    blbrs: state.blbrs,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(NavbarComp));
