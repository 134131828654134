import React, { Component } from "react";
import { Modal, Card, Row, Col, Alert } from "react-bootstrap";
import "../styling/Styles.css";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import { Divider, Snackbar, IconButton } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MailModal from "./MailModal";
import ReleveFournisseurModal from "./ReleveFournisseurModal";
import ModifierFournisseurModal from "./ModifierFournisseurModal";
import { connect } from "react-redux";

const actions = [
  { icon: <EditIcon />, name: "Modifier" },
  { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  { icon: <MailOutlineIcon />, name: "Mail" },
  { icon: <ListAltIcon />, name: "Relevé" },
];

class GetFournisseurByID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      openReleveFournisseurModal: false,
      openMailModal: false,
      snackbaropen: false,
      snackbaropenn: false,
      snackbarmsg: "",
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  openReleveFournisseur = () => {
    this.setState({ openReleveFournisseurModal: true });
  };

  nomail = () => {
    this.setState({ snackbaropen: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosee = (event) => {
    this.setState({ snackbaropenn: false });
  };

  deletefournisseur(frid) {
    if (window.confirm("êtes-vous sûr de vouloir supprimer ce fournisseur?")) {
      fetch(
        `https://www.24-crm.com/webservice/api/FOURNISSEURs?&id=${frid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.props.onHide();

            this.setState({ snackbaropenn: true, snackbarmsg: result });
          },
          setTimeout((error) => {
            this.setState({ snackbaropenn: true, snackbarmsg: "failed" });
          }, 5000)
        )
        .then((res) => window.location.reload());

      //  this.props.SelectArticle();
    }
  }

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    let ReleveFournisseurModalClose = () =>
      this.setState({ openReleveFournisseurModal: false });
    let MailModalClose = () => this.setState({ openMailModal: false });
    const { darck } = this.props;
  
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropenn}
          autoHideDuration={3000}
          onClose={this.snackbarClosee}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosee}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert onClose={this.snackbarClose} variant={"danger"}>
            Email non trouvable pour ce client
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Détails Fournisseur</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <Card>
              <Card.Body
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                }}
              >
                <h3 style={{ color: `${darck ? "#172431" : "#fff"}` }}>{this.props.raisoc}</h3>
                <p style={{  fontSize: "larger" , color: `${darck ? "gray" : "#fff"}`, }}>
                  {this.props.codefournisseur}
                </p>
                <Divider></Divider>
                {this.props.tel1 === "" && !this.props.tel2 === "" ? (
                  <p>
                    <PhoneIcon /> <span style={{ color: `${darck ? "#172431" : "#fff"}` }}>{this.props.tel2}</span> 
                  </p>
                ) : !this.props.tel1 === "" && this.props.tel2 === "" ? (
                  <p>
                    <PhoneIcon /> <span style={{ color: `${darck ? "#172431" : "#fff"}` }}>{this.props.tel1}</span> 
                  </p>
                ) : this.props.tel1 === "" && this.props.tel2 === "" ? (
                  <p>
                    <PhoneIcon /> --
                  </p>
                ) : (
                  <p>
                    <PhoneIcon /><span style={{ color: `${darck ? "#172431" : "#fff"}` }}> {this.props.tel1} / {this.props.tel2} </span>
                  </p>
                )}
                {this.props.email === "" ? (
                  <p>
                    <EmailIcon /> --
                  </p>
                ) : (
                  <p>
                    <EmailIcon /><span style={{ color: `${darck ? "#172431" : "#fff"}` }}> {this.props.email} </span>
                  </p>
                )}

                {this.props.adresse === "" ? (
                  <p>
                    <HomeIcon /> --
                  </p>
                ) : (
                  <p>
                    <HomeIcon /><span style={{ color: `${darck ? "#172431" : "#fff"}` }}> {this.props.adresse} </span>
                  </p>
                )}

                {this.props.siteweb === "" ? null : (
                  <p>
                    <LanguageIcon /> <span style={{ color: `${darck ? "#172431" : "#fff"}` }}> {this.props.siteweb} </span>
                  </p>
                )}
              </Card.Body>
            </Card>

            <Row style={{ marginTop: "18px" }}>
              <Col sm={6}>
                {/* {this.props.timbre ? ( */}
                <Alert style={{ width: "100%" }} variant={"success"}>
                  Solde Factures: {Number(this.props.soldfac).toFixed(3)}
                </Alert>
                {/* ) : (
                  <Alert style={{ width: "100%" }} variant={"danger"}>
                    Sans Timbre
                  </Alert>
                )} */}
              </Col>

              <Col sm={6}>
                {/* {this.props.soustraitant ? ( */}
                <Alert style={{ width: "100%" }} variant={"danger"}>
                  Solde Global: {Number(this.props.soldfacbl).toFixed(3)}
                </Alert>
                {/* ) : (
                  <Alert style={{ width: "100%" }} variant={"danger"}>
                    Sans soustraitant
                  </Alert>
                )} */}
              </Col>
            </Row>

            <Card>
              <Card.Body
                              style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                borderTop: `${darck ? "" : "1px solid #fff"}`,
                                borderRight: `${darck ? "" : "1px solid #fff"}`,
                                borderLeft: `${darck ? "" : "1px solid #fff"}`,
                                borderBottom: `${darck ? "" : "1px solid #fff"}`,
                              }}
              >
                <Row style={{ marginLeft: "10px" }}>
                  <Col sm={2}>
                    <b style={{ color: `${darck ? "#172431" : "#fff"}` }}>Nom</b>
                  </Col>
                  <Col sm={3}>
                    {this.props.nom === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>{this.props.nom}</p>
                    )}
                  </Col>
                  <Col sm={4}>
                    <b style={{ color: `${darck ? "#172431" : "#fff"}` }}>Identifiant</b>
                  </Col>
                  <Col sm={3}>
                    {this.props.identifiant === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>{this.props.identifiant}</p>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginLeft: "10px" }}>
                  <Col sm={2}>
                    <b style={{ color: `${darck ? "#172431" : "#fff"}` }}>Ville</b>
                  </Col>
                  <Col sm={3}>
                    {this.props.ville === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>{this.props.ville}</p>
                    )}
                  </Col>
                  <Col sm={4}>
                    <b style={{ color: `${darck ? "#172431" : "#fff"}` }}>Code Postal</b>
                  </Col>
                  <Col sm={3}>
                    {this.props.codepostal === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>{this.props.codepostal}</p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                        this.props.email !== "" &&
                          action.name === "Mail" &&
                          this.openMail();
                        this.props.email === "" &&
                          action.name === "Mail" &&
                          this.nomail();
                        action.name === "Modifier" && this.openModifier();
                        action.name === "Supprimer" &&
                          this.deletefournisseur(this.props.codefournisseur) &&
                          this.props.onHide();
                        action.name === "Relevé" &&
                          this.openReleveFournisseur();
                      }}
                    />
                  ))}
                </SpeedDial>
              </Col>
            </Row>

            <ModifierFournisseurModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              // passager={this.props.passager}
              codefournisseur={this.props.codefournisseur}
              raisoc={this.props.raisoc}
              tel1={this.props.tel1}
              tel2={this.props.tel2}
              email={this.props.email}
              adresse={this.props.adresse}
              siteweb={this.props.siteweb}
              cin={this.props.cin}
              ville={this.props.ville}
              codepostal={this.props.codepostal}
              identifiant={this.props.identifiant}
              tauxfodec={this.props.tauxfodec}
              timbre={this.props.timbre}
              soustraitant={this.props.soustraitant}
              acontacter={this.props.acontacter}
              impot={this.props.impot}
              comptable={this.props.comptable}
              nom={this.props.nom}
            />

            <ReleveFournisseurModal
              show={this.state.openReleveFournisseurModal}
              onHide={ReleveFournisseurModalClose}
              codefournisseur={this.props.codefournisseur}
              raisonsociale={this.props.raisoc}
              tel1={this.props.tel1}
              adresse={this.props.adresse}
            />

            <MailModal
              show={this.state.openMailModal}
              onHide={MailModalClose}
              email={this.props.email}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}
export default connect(mapStateToProps, null)(GetFournisseurByID);
