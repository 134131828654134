import React, { Component } from "react";
import { Modal, Card, Row, Col } from "react-bootstrap";
import "../styling/Styles.css";
import "./ss.scss";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { SelectBCFrsLig } from "../../redux/actions/GetBCFrsLig";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ModifierBCModal from "./ModifierBCFrsModal";
import { Divider, Fab, Snackbar, IconButton } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import { SelectBCFrs } from "../../redux/actions/GetBCFrs";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import translate from "../../i18n/translate";
import "./Responsivebcfrs.css";

const roundTo = require("round-to");

const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

class GetBCFrsByIdModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      ligid: "",
      bcid: "",
      tab: [],
      newtab: [],

      codearticle: 0,
      des: "",
      qte: 0,
      unite: 0,
      puht: 0,
      faudec: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      totalht: 0,

      totalqte: 0,
      netapayer: 0,

      clientimp: [],
      snackbaropen: false,
      snackbarmsg: "",
    };
  }

  // componentDidMount() {
  //   this.props.SelectBCFrsLig(
  //     this.props.ConfigDossier.coddos,
  //     this.props.ConfigDossier.serveur,
  //     this.props.ConfigDossier.utilisateur,
  //     this.props.ConfigDossier.pwd_serveur,
  //     this.props.ConfigDossier.database
  //   );
  // }

  handleOpen = () => {
    this.setState({ open: true });
    fetch(
      `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    // this.setState({ openMailModal: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  openModifier = () => {
    if (this.props.annuler !== "0") {
      alert("Bon de commande annulé, il faut le réactiver");
    } else {
      this.setState({ openModifierModal: true, bcid: this.props.bcid });
      this.props.bcfligs.bcfligs.map((t) => {
        this.setState({ ligid: t.id });
      });
    }
  };


  activer = () => {
    // window.alert("annuler");
    this.props.annuler !== "0"
      ? fetch(
          `https://www.24-crm.com/webservice/api/BCDVFrs?idD=${this.props.bcid}&typfacC=CF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.onHide();
            this.props.SelectBCFrs(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then(() => window.location.reload())
      : window.alert("Bon de commande déja activé");
  };
  annuler = () => {
    this.props.annuler === "0"
      ? fetch(
          `https://www.24-crm.com/webservice/Api/BCDVFrs?idd=${this.props.bcid}&typfaccs=CF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.onHide();
            this.props.SelectBCFrs(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
          })
          .then(() => window.location.reload())
      : window.alert("Bon de Commande déja annulée");
  };


  
  imprimer = () => {
    window.alert("imprimer");
  };

  supprimer = () => {
    if (
      window.confirm("êtes-vous sûr de vouloir supprimer ce bon de commande?")
    ) {
      fetch(
        `https://www.24-crm.com/webservice/Api/LigBCDVFrs/${this.props.bcid}?typfacc=CF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      fetch(
        `https://www.24-crm.com/webservice/Api/BCDVFrs/${this.props.bcid}?typfacc=CF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectBCFrs(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          this.setState({ snackbaropen: true, snackbarmsg: result });
        })
        .then(() => window.location.reload());
    }
  };

  nonsupprimer = () => {
    window.alert(
      "Vous devez annuler ce bon de Bon de commande pour que vous puissiez le supprimer"
    );
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    const altText = this.props.ConfigDossier.coddos+".jpg";
    const { ligidd, bcidd, tabb } = this.state;
    const { darck } = this.props;
    const actions = [
      { icon: <EditIcon />, name: "Modifier" },
    //  { icon: <PrintIcon />, name: "Imprimer" },
      {
        icon:
          this.props.annuler === "0" ? (
            <CancelPresentationIcon />
          ) : (
        //     <SlideshowIcon />
        //   ),
        // name: this.props.annuler === "0" ? "Annuler" : "Activer",
        <DeleteOutlineIcon />
      ),
    name: this.props.annuler === "0" ? "Annuler" : "Supprimer",
      },
     // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
    ];

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            // closeButton
            // style={{ backgroundColor: "white", color: "#020F64" }}
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,}}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                Bon de commande N°{this.props.bcid} du{" "}
                {new Date(this.props.datebc).toLocaleDateString(DATE_OPTIONS)}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
                                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                  border: `${darck ? "" : "2px solid #fff"}`,
                                  borderBottom: `none`,
                                }}>
            <Card>
              <Card.Body style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                              }}>
                <Row>
                  {/* <Col style={{ textAlign: "center" }} sm={2}>
                    <Typography variant="h6" component="h3">
                       <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>№ BC</Label>
                    </Typography>
                    <Typography style={{ color: "grey", fontSize: "14px" }}>
                      {this.props.bcid}
                    </Typography>
                  </Col>
                  <Col style={{ textAlign: "center" }} sm={2}>
                    <Typography variant="h6" component="h3">
                       <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Date BC</Label>
                    </Typography>
                    <Typography style={{ color: "grey", fontSize: "14px" }}>
                      {new Date(this.props.datebc).toLocaleDateString(
                        DATE_OPTIONS
                      )}
                    </Typography>
                  </Col> */}
                  <Col style={{ textAlign: "center" }} sm={6}>
                    <Typography variant="h6" component="h3">
                       <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Fournisseur</Label>
                    </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {this.props.client}
                    </Typography>
                  </Col>
                  <Col style={{ textAlign: "center" }} sm={6}>
                    <Typography variant="h6" component="h3">
                       <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Raison Sociale</Label>
                    </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {this.props.raisonsociale}
                    </Typography>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}>
                                              {/* tabd28 */}
                <div className="tabdv2">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                        <th>Code</th>
                        <th style={{ width: "40%" }}>Désignation</th>
                        <th>Quantité</th>
                        <th>PUHT</th>
                        <th>Remise</th>
                        <th>TVA</th>
                        <th>TotalHT</th>
                        <th>PUTTCNET</th>
                      </tr>
                    </thead>
                    <tbody
                     
                     style={{
                      overflowY: "auto",
                      display: "block",
                      maxHeight: "10em",
                      textAlign: "center",
                      color: `${darck ? "" : "#fff"}`,
                    }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          <td>
                            <span>{t.codart}</span>
                          </td>
                          <td style={{ fontSize: "12px", width: "40%" }}>
                            {t.desart}
                          </td>
                          <td>
                            <span>{t.quantite}</span>
                          </td>

                          <td>
                            <span>{Number(t.priuni).toFixed(3)}</span>
                          </td>

                          <td>
                            <span>{Number(t.remise).toFixed(2)}</span>
                          </td>
                          <td>
                            <span>{Number(t.tautva).toFixed(2)}</span>
                          </td>

                          <td>
                            <span>{Number(t.montht).toFixed(3)}</span>
                          </td>
                          <td>
                            <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="bcfr1">
              <Card.Body style={{
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
                                }}>
                <Row style={{ marginBottom: "-10px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Brut
                    </p>
                    {/* {this.props.totalhtbrut === 0 ? (
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.props.totalhtbr).toFixed(3)}
                      </p>
                    ) : (
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.props.totalhtbrut).toFixed(3)}
                      </p>
                    )} */}

                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Article
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur de timbre
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Globale
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalttc).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}

            <Card style={{ marginTop: "10px" }} id="bcfr2">
              <Card.Body  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}>
                <Row className="rowbcf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HTBrut
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Article
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="bcf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbcf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>{" "}
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Globale
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="bcf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbcf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalttc).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="bcf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbcf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                   <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur Timbre
                    </p>
                  <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "10px",
                  }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name == "Modifier" && this.openModifier();
                        action.name == "Supprimer" &&
                          this.props.annuler === "1" &&
                          this.supprimer();
                        action.name == "Supprimer" &&
                          this.props.annuler === "0" &&
                          this.nonsupprimer();
                        action.name == "Annuler" && this.annuler();
                        action.name == "Activer" && this.activer();
                        action.name == "Imprimer" && this.imprimer();
                      }}
                    />
                  ))}
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              </Col>
            </Row>

            <ModifierBCModal
              valtimbre={this.props.valtimbree}
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              ligidd={this.state.ligid}
              bcid={this.props.bcid}
              tabb={this.props.tabtab}
              datebl={this.props.datebc}
              onHide01={this.props.onHide}
              taurem={this.props.taurem}
              catfisc={this.props.catfisc}
              client={this.props.client}
              raisonsociale={this.props.raisonsociale}
              droitdetimbre={this.props.droitdetimbre}
              avanceimpot={this.props.avanceimpot}
            />
          </Modal.Body> 
          {/* ///////////////////////////supprimer le bonde blanc//////////////////////////////////////////// */}

          {/* <Modal.Footer 
          ></Modal.Footer> */} 
         
        </Modal>
        {/* ///////////////////  etat print //////////////////////// */}

        <div style={{ display: "none" }}>
          <div ref={(el) => (this.componentRef = el)}
            style={{ margin: "40px  35px", height: "94%" }}>
            
              {/* //<img
  // src={altText}
  src={require("../assests/img/comaf.jpg")}
  style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} /> */}


 <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
          
            
            <h1 style={{ fontSize: "50px", fontWeight: "bolder" }}>Société {this.props.InfoEntreprise[0].lib}</h1>
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              {this.props.InfoEntreprise[0].spe}
              <br />
              Adresse : {this.props.InfoEntreprise[0].adr},{this.props.InfoEntreprise[0].ville},{this.props.InfoEntreprise[0].pays}<br />
              Tél 1 :{this.props.InfoEntreprise[0].tel1} <br/>
              Tél 2 :{this.props.InfoEntreprise[0].tel2} <br/>
              Email:{" "}{this.props.InfoEntreprise[0].email} <br/>
             Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br/>
             RIB: {this.props.InfoEntreprise[0].rib}{" "}
              {this.props.InfoEntreprise[0].matban}{" "}
              {this.props.InfoEntreprise[0].agence}{" "}
              {this.props.InfoEntreprise[0].ville} <br />
               {translate("Etabli par")}: {this.props.Auth.username}  </p>
        
         
         
         
         
         
         
         
         
            <h5 style={{
                marginLeft: "130px",
                flexDirection: "column",
                marginTop: "25px",
                marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >
              Bon de commande № {this.props.bcid}{" "}
            </h5>
            <Row>
              <Col>
                <h6 style={{ marginLeft: "225px", marginTop: "40px" }}>
                  Date:{" "}
                  {new Date(this.props.datebc).toLocaleDateString(DATE_OPTIONS)}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>Code Client : {this.props.client}</h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>{t.ville}</h5> : <h5>--</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6>Code TVA : --</h6>
                  ) : (
                    <h6>Code TVA : {t.codtva}</h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "5px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <tr style={{ borderBottom: "1px solid grey" }}>
                    <th>Code</th>
                    <th style={{ width: "37%" }}>Désignation</th>
                    <th>Quantité</th>
                    <th>PUHT</th>
                    <th>Remise</th>
                    <th>TVA</th>
                    <th>TotalHT</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        height: "60px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td>{t.quantite}</td>
                      <td>{Number(t.priuni).toFixed(3)}</td>

                      <td>{Number(t.remise).toFixed(2)}</td>
                      <td>{Number(t.tautva).toFixed(2)}</td>

                      <td>{Number(t.montht).toFixed(3)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <br /> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {/* <table
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    width: "450px",
                    borderWidth: "1px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    <tr>
                      <th>T.V.A</th>
                      <th>Assiette</th>
                      <th>Montant</th>
                    </tr>
                  </thead> */}

{/* ////////////////////       SI TVA = 0%      /////////////////// */}
                 {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 0 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))} */}

{/* ////////////////////       SI TVA = 7%      /////////////////// */}
                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 7 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}
                       */}
{/* ////////////////////       SI TVA = 13%      /////////////////// */}

                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 13 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))} */}
{/* ////////////////////       SI TVA = 19%      /////////////////// */}

                {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 19 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))}  */}
                      


                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 13 ? (
                        <tr style={{ height: "50px" }}>
                          <td>
                            {Number(t.tautva).toFixed(2)} */}
                            {/* {Number(
                          (Number(this.props.totaltva) /
                            Number(this.props.totalhtnet)) *
                            100
                        ).toFixed(2)}{" "}
                        % */}
                          {/* </td>
                          <td>
                            {Number(
                              this.props.tabtab.reduce((a, b) => a + b.montht)
                            ).toFixed(3)}
                          </td>
                          <td>
                            {(
                              (Number(
                                this.props.tabtab.reduce(
                                  (a, b) => a + b.montht,
                                  0
                                )
                              ) *
                                13) /
                              100
                            ).toFixed(3)}
                          </td>
                        </tr>
                      ) : null}
                    </tbody> */}
                  {/* ))} */}

                  
                {/* </table> */}


                <table
                          style={{
                            textAlign: "center",
                            borderStyle: "solid",
                            width: "450px",
                            borderWidth: "1px",
                          }}
                        >
                          <thead
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <tr>
                              <th>{translate("TVA")}</th>
                              <th>{translate("Assiette")}</th>
                              <th>{translate("Montant")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Initialize the totals */}
                            {(() => {
                              const tvaGroups = {};

                              // Grouping by TVA and summing the values
                              this.props.tabtab.forEach((t) => {
                                const tvaRate = Number(t.tautva).toFixed(2);

                                if (!tvaGroups[tvaRate]) {
                                  tvaGroups[tvaRate] = { assiette: 0, montant: 0 };
                                }

                                tvaGroups[tvaRate].assiette += Number(t.montht);
                                tvaGroups[tvaRate].montant += Number(t.ttclig - t.montht);
                              });

                              // Render the rows for each TVA group
                              return Object.keys(tvaGroups).map((tvaRate) => (
                                <tr key={tvaRate} style={{ height: "50px" }}>
                                  <td>{tvaRate}</td>
                                  <td>{tvaGroups[tvaRate].assiette.toFixed(3)}</td>
                                  <td>{tvaGroups[tvaRate].montant.toFixed(3)}</td>
                                </tr>
                              ));
                            })()}
                          </tbody>
                        </table>



              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b>Total quantité: </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Total.H.T Brut:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Remise Globale:
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp;Total.H.T Net:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Total TVA:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Net à Payer:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(parseFloat(this.props.totalttc)).toFixed(3)}{" "}
                  </td>
                </tr>
              </table>
            </div>
            {/* <br />
            <br /> */}

            {/* //////////////////////// footer ///////////////////////////// */}
            {Number(this.props.remiseglobale) != 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature Client:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature & cachet:
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "25px",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature Client:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature & cachet:
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectBCFrsLig: (a, b, c, d, e) => dispatch(SelectBCFrsLig(a, b, c, d, e)),
    SelectBCFrs: (a, b, c, d, e) => dispatch(SelectBCFrs(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    bcfligs: state.bcfligs,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetBCFrsByIdModal);
