import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "../styling/Styles.css";
// import { Input, Label, FormGroup, Col, Table } from "reactstrap";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import { SelectClient } from "../../redux/actions/GetClients";
import { connect } from "react-redux";
// import Center from "react-center";
import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";
// import AddClientPassagerModal from "./AddClientPassagerModal";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import LigModal from "./LigModal";
import { Divider, Chip, Snackbar, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Responsivedv.css";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import "./Styling.css";
import "./ss.scss";
import { SelectUser } from "../../redux/actions/DevisClient";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// const roundTo = require("round-to");

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class FieldArraysFormClass extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      defaultdate: date,
      showTimbre: 0,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totalputtcnet: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      gilad: true,
      cemail: "",
      openActionModal: false,
      snackbaropen: false,
      snackbarmsg: ",",
      MG: "",
      magasin: [],
      representant: [],
      rep: "",

      codart: "",
      quantite: 0,
      desart: "",
      priuse: 0,
      tautni: "",
      remiva: 0,
      fodart: false,
      avance: 0,
      AVANCE: 0,
      codtvaa: "",
      regimecli: "",
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],

      numnum: 0,
      numfacc: 0,
      nu: 0,
      arths: [],
      useligs: [],
      recrname: username,
      valtimbre: 0.0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      propsvaltimbre: 0,
      pmp: "",
      clicked: false,
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacDevis(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
      });

    this.setState({
      propsvaltimbre: this.props.valtimbres.valtimbres.map((t) =>
        parseFloat(t.valtimbre)
      ),
    });
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    avance,
    netnetapayer,
    codtvaa,
    pmp
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      TOTTVA: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      NETAPAYER: netapayer,
      btnEnabled: btnEnabled,
      avance: avance,
      AVANCE: avance,
      netnetapayer: netnetapayer,
      NETNETAPAYER: netnetapayer,
      codtvaa: codtvaa,
      pmp: pmp,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };


  // remiseglobalChange = (event) => {
  //   if(this.state.categoriefiscale=="0"||this.state.categoriefiscale=="6"){
  //   this.setState({
  //     remiseg: event.target.value,
  //     remiseglobal:
  //       ((this.state.totalhtbrut - this.state.sumremisearticle) *
  //         event.target.value) /
  //       100,
  //     totalhtnet:
  //       (this.state.totalhtbrut - this.state.sumremisearticle) *
  //       ((100 - event.target.value) / 100),
        
  //     TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
  //     NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
  //     AVANCE: this.state.avance * ((100 - event.target.value) / 100),
  //     NETNETAPAYER:
  //       ((100 - event.target.value) / 100) *
  //         (this.state.netapayer + this.state.avance) +
  //       Number(this.state.valtimbre),
  //   });
  // } else { this.setState({
  //   remiseg: event.target.value,
  //   remiseglobal:
  //     ((this.state.totalhtbrut - this.state.sumremisearticle) *
  //       event.target.value) /
  //     100,
  //   totalhtnet:
  //     (this.state.totalhtbrut - this.state.sumremisearticle) *
  //     ((100 - event.target.value) / 100),
  //   //TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
  //   TOTTVA: 0,
  //   NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
  //   AVANCE: this.state.avance * ((100 - event.target.value) / 100),
  //   NETNETAPAYER:
  //     ((100 - event.target.value) / 100) *
  //       (this.state.netapayer + this.state.avance) +
  //     Number(this.state.valtimbre),
  // });}
  // };
  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
        
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) *
          (this.state.netapayer + this.state.avance) +
        Number(this.state.valtimbre),
    });
  };

  catHandler = (event) => {
    if (event.target.value == "Assujetti") {
      this.setState({ categoriefiscale: 0 });
    } else if (event.target.value == "Non Assujetti") {
      this.setState({ categoriefiscale: 1 });
    } else if (event.target.value == "Exonéré TVA/FODEC") {
      this.setState({ categoriefiscale: 2 });
    } else if (event.target.value == "Exonéré TVA") {
      this.setState({ categoriefiscale: 3 });
    } else if (event.target.value == "Suspenssion") {
      this.setState({ categoriefiscale: 4 });
    } else if (event.target.value == "Cession à Quai") {
      this.setState({ categoriefiscale: 5 });
    } else {
      this.setState({ categoriefiscale: 6 });
    }
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };
  enregistrer = (event) => {
    event.preventDefault();

    //////////// post BCDV /////////////////////////////
    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?numfac&typfac=DV&taurem=${event.target.remise.value}&codcli=${event.target.codcli.value}&raisoc=${event.target.raissoc.value}&catfisc=${this.state.categoriefiscale}&datfac=${event.target.datfac.value}&timbre=${this.state.showTimbre}&tauxNASS=${this.state.regimecli}&smtva=${this.state.TOTTVA}&mntbon=${this.state.NETNETAPAYER}&vendeur=${this.props.Auth.username}&valtimbre=${this.state.valtimbre}&codrep=${this.state.rep}&smNASS=${this.state.AVANCE}&CodDep=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k, index) => {
          // for (var i = 0; i < this.state.tab.length; i++) {

          fetch(
            `https://www.24-crm.com/webservice/api/LigBCDV/{ID}?numfac=${this.state.numnum}&typfac=DV&numlig=${index}&codart=${k.codearticle}&quantite=${k.qte}&fodart=0&desart=${k.des}&datfac=${this.state.defaultdate}&priuni=${k.puht}&remise=${k.remisea}&unite=${k.unite}&CB=${k.codbar}&codtva=${k.codtvaa}&qtecom=${k.qte}&tautva=${k.tva}&formqt=0&pmp=${k.pmp}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                console.log(result);
              },

              (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: "failed" });
              }
            );
          // }
        });
      })

      .then(
        (result) => {
          this.props.onHide();

          this.props.SelectUser(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          // fetch(
          //   `https://www.24-crm.com/webservice/api/LigBCDV?FAC=${this.state.numnum}&typfacc=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          //   {
          //     method: "POST",
          //     header: {
          //       Accept: "application/json",
          //       "Content-Type": "application/json",
          //     },
          //   }
          // );
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.GetNumFacDevis(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )
      .then((res) => {
        //////////// switch update ////////////////

        fetch(
          `https://www.24-crm.com/webservice/api/Switch?code=DV2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
              window.location.reload();
            },
            (error) => {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Enregistrétest sous le N° " + this.state.numnum,
              });
            }
          );
      })
      .then(() => {
        // Réalisez le rechargement de la page ici
        // window.location.reload();
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "failed" });
      });
  };


  render() {
    let ligModalClose = () => this.setState({ ligModalShow: false });
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });
    const { dvnumfac, dvraisoc, rem, clientmail } = this.state;
    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={5000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={`${darck ? "" : classes.root}`}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter un Devis</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form autocomplete="off" onSubmit={this.enregistrer}>
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      {/* {this.props.numfac.numfac.map((num) => ( */}
                      <FormGroup style={{ overflow: "hidden" }}>
                        <TextField
                          id="standard-basic"
                          label="№ Devis"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          // value={num.valeur}
                          value={
                            this.props.numfac.numfac[0] &&
                            this.props.numfac.numfac[0].valeur
                          }
                          fullWidth
                          name="numfac"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                      {/* ))} */}
                    </Col>
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        type="date"
                        fullWidth
                        name="datfac"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    {this.state.GMM ? (
                      <Col sm={5}>
                        <FormGroup style={{ overflow: "hidden" }}>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}: ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                  })
                                : this.setState({
                                    MG: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup
                        style={{ marginTop: "25px", overflow: "hidden" }}
                      >
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              style={{ color: `${darck ? "grey" : "#fff"}` }}
                            >
                              Raison sociale
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "warning checkbox",
                                  }}
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid
                              item
                              style={{ color: `${darck ? "#3f51b5" : "#fff"}` }}
                            >
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup style={{ overflow: "hidden" }}>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    regimecli: getOptionLabel.regimecli,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                    showTimbre: 1,
                                  })
                                : this.setState({
                                    valtimbre: Number(0).toFixed(3),
                                    showTimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup style={{ overflow: "hidden" }}>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    regimecli: getOptionLabel.regimecli,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                    showTimbre: 1,
                                  })
                                : this.setState({
                                    valtimbre: Number(0).toFixed(3),
                                    showTimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager ? (
                        <Col sm={5}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial || ""}
                              fullWidth
                              name="raissoc"
                              onChange={this.raisocHandler}
                              required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup style={{ overflow: "hidden" }}>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row form>
                    {this.state.showButtonModalPassager ? (
                      <Col sm={3}>
                        <FormGroup style={{ overflow: "hidden" }}>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={this.state.cat}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel.label == "Assujetti"
                                ? this.setState({ categoriefiscale: 0 })
                                : getOptionLabel.label == "Non Assujetti"
                                ? this.setState({ categoriefiscale: 1 })
                                : getOptionLabel.label == "Exonéré TVA/FODEC"
                                ? this.setState({ categoriefiscale: 2 })
                                : getOptionLabel.label == "Exonéré TVA"
                                ? this.setState({ categoriefiscale: 3 })
                                : getOptionLabel.label == "Suspenssion"
                                ? this.setState({ categoriefiscale: 4 })
                                : getOptionLabel.label == "Cession à Quai"
                                ? this.setState({ categoriefiscale: 5 })
                                : this.setState({ categoriefiscale: 6 });
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Cat Fiscale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="catfisc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col sm={3}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fiscale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col style={{ padding: "0" }} sm={1} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              CatFiscale{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    )}

                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="remise"
                        value={this.state.remiseg}
                        onChange={this.remiseglobalChange}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    {/* <Col sm={5}>
                      <FormGroup style={{ overflow: "hidden" }}>
                        <Autocomplete
                          id="outlined-select-currency"
                          includeInputInList
                          options={this.state.representant}
                          getOptionLabel={(option) => `${option.nom}`}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  rep: getOptionLabel.codrep,
                                })
                              : this.setState({
                                  rep: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // label={translate("N° facture")}
                              label="Représentant"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onFocus={() => {
                                //liste des origines
                                fetch(
                                  `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                  {
                                    method: "GET",
                                    header: {
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                  .then((res) => res.json())
                                  .then((result) => {
                                    this.setState({
                                      representant: result,
                                    });
                                  });
                              }}
                              //variant="outlined"
                              fullWidth
                              name="representant"
                              // value={this.state.facOrBl}
                              // onChange={(event) =>
                              //   this.setState({ facOrBl: event.target.value })
                              // }
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col> */}
                    {/* <Col sm={2}>
                  <FormGroup style={{ marginTop: "40px" }}>
                    {this.state.showTimbre ? (
                      <p style={{ color: "grey" }}>Timbre: ✔</p>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col sm={2}>
                  <FormGroup style={{ marginTop: "40px" }}>
                    {this.state.showForfitaire === 1 ? (
                      <p style={{ color: "grey" }}>Forfitaire: ✔</p>
                    ) : null}
                  </FormGroup>
                </Col> */}
                  </Row>
                </Card.Body>
              </Card>
              <div></div>
              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12} style={{ flexWrap: "nowrap" }}>
                        <Row>
                          <Col sm={11} id="lidv">
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          {/* <Col sm={1} className="adddv">
                            {this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{
                                        backgroundColor: "rgb(2, 15, 100)",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col> */}
                           <Col sm={1} className="addbr">
                            {this.state.GMM ? (
                              this.state.btnEnable && this.state.MG ? (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={
                                        "Ajouterarticle"
                                      }
                                    >
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={
                                        "Ajouter article"
                                      }
                                    >
                                      <Fab
                                        disabled
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={
                                      "Ajouter article"
                                    }
                                  >
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={
                                      "Ajouter article"
                                   }
                                  >
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {/* <div className="lig-table"> */}
                      <div className="tabd28">
                        <table style={{ marginTop: "10px" }}>
                          <thead style={{ fontSize: "12px" }}>
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th>
                              <th>PU HT</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>PUTTCNet</th>
                              <th>TotalHT</th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: "center" }}>
                            {this.state.tab.map((t, i) => (
                              <tr key={i}>
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="dv1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row Row style={{ marginTop: "10px" }}>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                    
                      {this.state.remiseg === 0 ? (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                     ) : ( 
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(0).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="d">
                      <Divider
                        style={{
                          marginTop: "-8px",
                          backgroundColor: `${darck ? "gery" : "#fff"}`,
                        }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.NETAPAYER).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      {/* <p style={{ color: "darkslateblue", marginBottom: "-5px" }}>
                    Total Quantité
                  </p>
                  <p style={{ color:`${darck ?"black":"#fff"}`}}>{this.state.totalqte}</p> */}
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>

                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>{this.state.valtimbre}</p>
                    </Col>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.AVANCE).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}
              <Card style={{ marginTop: "10px" }} id="dv2">
                <Card.Body 
                style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                       style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total HTBrut
                      </p>
                      <p   style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color:`${darck ?"black":"#fff"}`}}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color:`${darck ?"black":"#fff"}`}}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="d">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color:`${darck ?"black":"#fff"}`}}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Total TVA///
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="d">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      {/* <p style={{ color: "darkslateblue", marginBottom: "-5px" }}>
                    Total Quantité
                  </p>
                  <p style={{ color:`${darck ?"black":"#fff"}`}}>{this.state.totalqte}</p> */}
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Valeur timbre
                      </p>

                      <p style={{ color:`${darck ?"black":"#fff"}`}}>{this.state.valtimbre}</p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                      style={{ color: `${darck ? "grey" : "#fff"}`,
                        marginBottom: "-5px" }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color:`${darck ?"black":"#fff"}`}}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p 
                        style={{ color:`${darck ?"black":"#fff"}`, fontWeight: "bold"}}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: "black", fontWeight: "bold" }}>
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={5}></Col>
                <Col sm={3}></Col>
                <Col sm={4}>
                {this.state.tab.length === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      type="submit"
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
        <LigModal
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          numfaccc={this.props.numfac.numfac.map((nu) => parseInt(nu.valeur))}
          dateee={this.state.defaultdate}
          rem={rem}
          valtimbre={this.state.valtimbre}
          avance={this.state.avance}
          regimecli={this.state.regimecli}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectUser: (a, b, c, d, e) => dispatch(SelectUser(a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    numfac: state.numfac,
    articles: state.articles,
    valtimbres: state.valtimbres,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
  darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(FieldArraysFormClass));
