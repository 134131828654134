// src/components/Loader.js
import React from 'react';
import ReactLoading from 'react-loading';
import Commet from 'react-loading';
import './Loader.css'; // Créez ce fichier pour ajouter des styles

const Loader = () => {
  return (
    <div className="loader">
      {/* <ReactLoading type="spin" color="#3498db" height={100} width={100} /> */}
      <Commet color="#3498db" size="medium" text="" textColor="" />
    </div>
  );
};

export default Loader;
