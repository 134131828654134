import Axios from "axios";

export const SelectNomenclatureCCB = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/NOME?cat=CCB&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({
        type: "NOMENCLATURECCB_SELECT",
        payload: response.data,
      });
    });
  };
};
