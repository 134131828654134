import React, { useState, useEffect } from "react";
import SubMenu from "./SubMenu";
import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  sidebarContent,
  sidebarCommerciale,
  sidebarContent2,
} from "../../constants/dataTables";
import {
  sidebarContentEng,
  sidebarCommercialeEng,
} from "../../constants/dataTablesEng";
import "./SideBar.scss";
import FullPageLoader from "../loadingComp/FullPageLoader";
import { LOCALES } from "../../i18n";
import "./Responsive.css";

const SideBar = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [ECO, setECO] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (width < 100) {
      props.toggle();
    }
  }, [width]);

  useEffect(() => {
    const fetchDossierData = async () => {
      try {
        const response = await fetch(
          `https://www.24-crm.com/webservice/api/Dossier?coddosPolysoft=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=polysoft`
        );
        const data = await response.json();
        setECO(data[0]?.ModCaiss);
        console.log("dossier gs", data[0]?.ModCaiss);
      } catch (error) {
        console.error("Error fetching dossier data:", error);
      }
    };

    fetchDossierData();
  }, [props.ConfigDossier]);

  const handleClick = () => {
    window.open("https://polysoftco.tn/");
  };

  const renderSidebarContent = () => {
    const { locale, Auth } = props;

    if (locale === LOCALES.FRENCH) {
      const content =
       ECO?(Auth.fct == "100" 
       ? sidebarContent2
       : sidebarCommerciale)
       :(Auth.fct == "100" 
       ? sidebarContent
       : sidebarCommerciale)
        

      return content.map((el, index) => (
        <SubMenu
          className="pointer-sidebar"
          key={index}
          title={el.title}
          icon={el.icon}
          items={el.submenu}
          toggle={props.toggle}
        />
      ));
    } else {
      const contentEng =
        Auth.fct == "100" ? sidebarContentEng : sidebarCommercialeEng;
      return contentEng.map((el, index) => (
        <SubMenu
          className="pointer-sidebar"
          key={index}
          title={el.title}
          icon={el.icon}
          items={el.submenu}
          toggle={props.toggle}
        />
      ));
    }
  };

  return (
    <div className={classNames("sidebar", { "is-open": props.isOpen })}>
      <section style={{ width: "100%", height: "100%" }}>
        <div className="sidebar-header">
          <span color="info" onClick={props.toggle} style={{ color: "#fff" }}>
            &times;
          </span>
        </div>

        <div >
          <Nav vertical className="list-unstyled pb-3">
            <NavItem
              style={{
                fontSize: "50px",
                paddingLeft: "27px",
                fontFamily: "Impact, Charcoal, sans-serif",
                color: "#fff",
              }}
            >
              <Link
                to={props.Auth.fct === "100" ? "/homepage" : "/dashboard"}
                onClick={props.toggle}
              >
                <i className="fas fa-chart-bar">&nbsp;&nbsp;Dashboard</i>
              </Link>
            </NavItem>

            <div
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                  marginLeft: "-10px",
                }}
              >
              {renderSidebarContent()}
            </div>
          </Nav>
        </div>

        <div
          style={{
            bottom: "0px",
            position: "absolute",
            width: "100%",
            borderTop: "1px solid white",
            paddingTop: "2%",
          }}
        >
          
          {props.Auth.fct == "100" && (
            <SubMenu
              title="Paramètres"
              toggle={props.toggle}
              icon={<i className="fas fa-cog"></i>}
              items={[
                { id: 0, title: "Fiche Dossier", icon: <i className="far fa-plus-square"></i> },
                { id: 1, title: "Nomenclature", icon: <i className="far fa-plus-square"></i> },
                { id: 2, title: "Ajout d'un Utilisateur", icon: <i className="far fa-plus-square"></i> },
                { id: 3, title: "Utilitaire", icon: <i className="far fa-plus-square"></i> },
              ]}
            />
          )}

          <a onClick={handleClick}>
            <NavItem className="text_menu" style={{ paddingLeft: "27px", cursor: "pointer" }}>
              <i className="fas fa-globe"></i>&nbsp;&nbsp;{" "}
              <span>Polysoft&CO.tn</span>
            </NavItem>
          </a>

          <Link to="/contact" onClick={props.toggle}>
            <NavItem className="text_menu" style={{ paddingLeft: "27px" }}>
              <i className="far fa-comment-alt"></i>&nbsp;&nbsp;
              <span>Contact</span>
            </NavItem>
          </Link>
        </div>

        <FullPageLoader />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  SideBarTitles: state.SideBarTitles,
  Auth: state.Auth,
  darck: state.darck.darck,
  dossier: state.dossier,
  ConfigDossier: state.ConfigDossier,
});

export default connect(mapStateToProps)(SideBar);

// import React, { useState, useEffect } from "react";
// import SubMenu from "./SubMenu";
// import { NavItem, Nav } from "reactstrap";
// import classNames from "classnames";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { sidebarContent, sidebarCommerciale } from "../../constants/dataTables";
// import {sidebarContentEng,sidebarCommercialeEng} from "../../constants/dataTablesEng";
// import "./SideBar.scss";
// import FullPageLoader from "../loadingComp/FullPageLoader";
// import { LOCALES } from "../../i18n";
// import "./Responsive.css";

// const SideBar = (props) => {
//   const [width, setWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     function handleResize() {
//       setWidth(window.innerWidth);
//     }
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, [width]);

//   useEffect(() => {
//     width < 600 && props.toggle();
//   }, [width]);

//   const tokenn = localStorage.getItem("token");

//   const [token, settoken] = useState(tokenn);

//   const handleClick = () => {
//     window.open("https://polysoftco.tn/");
//   };
//   const { darck } = props;

//   return (
//     //<>
//     <div className={classNames("sidebar", { "is-open": props.isOpen })}>
//       <section style={{ width: "100%", height: "100%" }}>
//         <div className="sidebar-header">
//           <span color="info" onClick={props.toggle} style={{ color: "#fff" }}>
//             &times;
//           </span>
//           &nbsp;&nbsp;&nbsp;
//           {/* <Link to={"/homepage"}>
//           <img src="/images/logo.png" alt="Logo" width="200" height="70" />
//         </Link> */}
//         </div>

//         {/* Ajouter les données à partir du datatables */}

//         <div className="side-menu">
//           <Nav vertical className="list-unstyled pb-3">
//             <div>
//               <NavItem
//                 style={{
//                   fontSize: "50px",
//                   paddingLeft: "27px",
//                   fontFamily: "Impact, Charcoal, sans-serif",
//                   color: "#fff",
//                 }}
//               >
//                 <Link
//                   to={props.Auth.fct == "100" ? "/homepage" : "/dashboard"}
//                   onClick={props.toggle}
//                 >
//                   <i class="fas fa-chart-bar">&nbsp;&nbsp;Dashboard</i>
//                 </Link>
//               </NavItem>

//               {/* <Link to={"/homepage"} className="title" onClick={props.toggle}>
//               <NavItem
//                 style={{
//                   fontSize: "16px",
//                   paddingLeft: "27px",
//                   fontFamily: "Helvetica",
//                 }}
//               >
//                 <i class="fas fa-chart-bar"> &nbsp;&nbsp;DashBoard</i>
//               </NavItem>
//             </Link> */}
//               {/* ) : null} */}
//               <div
//                 style={{
//                   marginTop: "20px",
//                   cursor: "pointer",
//                   marginLeft: "-10px",
//                 }}
//               >
//                 {props.locale === LOCALES.FRENCH
//                   ? (props.Auth.fct == "100"
//                       ? sidebarContent
//                       : sidebarCommerciale
//                     ).map((el, index) => (
//                       <SubMenu
//                         className="pointer-sidebar"
//                         key={index}
//                         title={el.title}
//                         icon={el.icon}
//                         items={el.submenu}
//                         toggle={props.toggle}
//                       />
//                     ))
//                   : (props.Auth.fct == "100"
//                       ? sidebarContentEng
//                       : sidebarCommercialeEng
//                     ).map((el, index) => (
//                       <SubMenu
//                         toggle={props.toggle}
//                         className="pointer-sidebar"
//                         key={index}
//                         title={el.title}
//                         icon={el.icon}
//                         items={el.submenu}
//                       />
//                     ))}
//               </div>
//             </div>
//           </Nav>
//         </div>
//         <div
//           style={{
//             bottom: "0px",
//             position: "absolute",
//             width: "100%",
//             borderTop: "1px solid white",
//             paddingTop: "2%",
//           }}
//         >
//           <div>
//             {props.Auth.fct == "100" ? (
//               <SubMenu
//                 title="Paramètres"
//                 toggle={props.toggle}
//                 icon={<i class="fas fa-cog"></i>}
//                 items={[
//                   {
//                     id: 0,
//                     title: "Fiche Dossier",
//                     icon: <i class="far fa-plus-square"></i>,
//                   },
//                   {
//                     id: 1,
//                     title: "Nomenclature",
//                     icon: <i class="far fa-plus-square"></i>,
//                   },
//                    {
//                      id: 2,
//                      title: "Ajout d'un Utilisateur",
//                      icon: <i class="far fa-plus-square"></i>,
//                    },
                   
//                    {
//                      id: 3,
//                      title: "Utilitaire",
//                      icon: <i class="far fa-plus-square"></i>,
//                    },
                  
//                 ]}
//               />
//             ) : null}
//           </div>

//           <a to="" onClick={handleClick}>
//             <NavItem
//               className="text_menu "
//               style={{ paddingLeft: "27px", cursor: "pointer" }}
//             >
//               <i class="fas fa-globe"></i>&nbsp;&nbsp;{" "}
//               <span>Polysoft&CO.tn</span>
//             </NavItem>
//           </a>
//           <Link to={"/contact"} onClick={props.toggle}>
//             <NavItem className="text_menu " style={{ paddingLeft: "27px" }}>
//               <i class="far fa-comment-alt"></i>&nbsp;&nbsp;
//               <span>Contact</span>
//             </NavItem>
//           </Link>
//         </div>
//         <FullPageLoader />
//       </section>
//     </div>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     SideBarTitles: state.SideBarTitles,
//     Auth: state.Auth,
//     darck: state.darck.darck,
//   };
// };
// export default connect(mapStateToProps)(SideBar);
