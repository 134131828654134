import { Component } from "react";
import { Pie } from "react-chartjs-2";
import * as React from "react";

import Axios from "axios";
import { connect } from "react-redux";

// const DATE_OPTIONS = {
//   month: "short",
//   day: "numeric",
// };

class TopArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    Axios.get(
      `https://www.24-crm.com/webservice/api/TopArticle?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(record.desart);

        runscore.push(record.num);
      });

      this.setState({
        Data: {
          labels: playername,

          datasets: [
            {
              label: "Les % des articles les plus vendus",

              data: runscore,

              backgroundColor: [
                // "#20c997",

                // "#6610f2",

                // "#0000FF",

                // "#007bff",

                // "#ffc107",
                '#007bff', '#ff7f0e', '#2ca02c', '#ffdd57', '#c0c0c0'
              ],
            },
          ],
        },
      });
    });
  }
  render() {
    return (
      <div>
        <div>
          <Pie
            data={this.state.Data}
            options={{ maintainAspectRatio: false }}
            width={"350px"}
            height={"250px"}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
  };
}
export default connect(mapStateToProps, null)(TopArticle);
