import React, { Component } from "react";
import { Collapse, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { NavLink as LinkNav } from "react-router-dom";
import { connect } from "react-redux";
import { DarckModeAction } from "../../redux/actions/DarckModeAction";
import "./Responsive.css";

class SubMenu extends Component {
  constructor(props) {
    super(props);
    const fct = localStorage.getItem("fct");
    const token = localStorage.getItem("token");

    this.state = { collapsed: true, fct: fct, token: token };
  }

  toggleNavbar = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };


  render() {
    const { title, items, darck } = this.props;
    return (
      <div className="text_menu">
        <div>
          {this.props.Auth.fct === "200" &&
          (title === "Suivi Banque" || title === "Parametres") ? null : (
            <NavItem
              onClick={this.toggleNavbar}
              className={classNames("menu-open-hover", {
                "menu-open": !this.state.collapsed,
              })}
            >
              <NavLink className="dropdown-toggle">
                {title === "Paramètres" ? (
                  <span>
                    <i class="fas fa-cog"></i>&nbsp;&nbsp;{title}
                  </span>
                ) : (
                  `${title}`
                )}
              </NavLink>
            </NavItem>
          )}

          <Collapse
            isOpen={!this.state.collapsed}
            navbar
            className={classNames("items-menu", {
              "mb-1": !this.state.collapsed,
            })}
          >
            {items &&
              items.map((item, index) => (
                <LinkNav
                  key={index}
                  to={
                    "/" + item.title &&
                    item.title.toLowerCase().split(" ").join("-")
                  }
                  // onClick={this.props.toggle}
                  // onClick={toggleNavbar}
                >
                  {this.props.Auth.fct === "200" &&
                  (item.title === "Bon d'entrée" ||
                    item.title === "Bon de sortie") ? null : (
                    <>
                      <NavItem style={{ marginLeft: "15px" }} className="pl-4">
                        {item.icon}&nbsp;&nbsp;{item.title}
                      </NavItem>

                      <NavItem
                        style={{ marginLeft: "15px" }}
                        className="pl-5"
                        onClick={this.props.toggle}
                      >
                        {item.icon}&nbsp;&nbsp;{item.title}
                      </NavItem>
                    </>
                  )}
                </LinkNav>
              ))}
          </Collapse>
        </div>
        <div>{darck}</div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    DarckModeAction: () => dispatch(DarckModeAction()),
  };
}
const mapStateToProps = (state) => {
  return {
    Auth: state.Auth,
    darck: state.darck.darck,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);

// const SubMenu = (props) => {
//   const [collapsed, setCollapsed] = useState(true);
//   const toggleNavbar = () => setCollapsed(!collapsed);
//   const { title, items, icon } = props;

//   return (
//     <div>
//       <NavItem
//         onClick={toggleNavbar}
//         className={classNames({ "menu-open": !collapsed })}
//       >
//         <NavLink className="dropdown-toggle">
//           {/* <FontAwesomeIcon icon={icon} className="mr-2" /> */}
//           {/* {icon} */}

//           {title}
//         </NavLink>
//       </NavItem>
//       <Collapse
//         isOpen={!collapsed}
//         navbar
//         className={classNames("items-menu", { "mb-1": !collapsed })}
//       >
//         {items &&
//           items.map((item, index) => (
//             <Link
//               key={index}
//               to={
//                 "/" + item.title &&
//                 item.title.toLowerCase().split(" ").join("-")
//               }
//               // onClick={toggleNavbar}
//             >
//               <NavItem style={{ marginLeft: "20px" }} className="pl-4">
//                 {item.icon}&nbsp;&nbsp;{item.title}
//               </NavItem>
//             </Link>
//           ))}
//       </Collapse>
//     </div>
//   );
// };

// export default SubMenu;
