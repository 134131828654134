import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import { Tabs, Tab } from "react-bootstrap";
import ClientCall from "./ClientCall";
import { Breadcrumbs } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import FournisseurCall from "./FournisseurCall";

class ClickToCall extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <StyledBreadcrumb
            component="a"
            href="/"
            label="Téléphonie"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{ color: "rgb(63, 81, 181)" }}
            href="/clicktocall"
            label="Click-To-Call"
            icon={<PhoneIcon fontSize="small" />}
          />
        </Breadcrumbs>
        { <div className="page-icon">
          <u>
            {" "}
            <i class="fas fa-phone-volume"> Click To Call</i>
          </u>
        </div> }
        <br />
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
        >
          <Tab eventKey="home" title="Client" style={{ padding: "5px" ,margin:"15px" }}>
            {/* <Row> */}
              <Col>
                {/* <Famille /> */}
                <ClientCall />
              </Col>
            {/* </Row> */}
          </Tab>
          {/* <Tab eventKey="profile" title="Fournisseur">
            <Row>
              <Col sm={3}></Col>
              <Col>
               
                <FournisseurCallCall />
                </h3> */}
                {/* <SousFamille /> */}
              {/* </Col>
              <Col sm={3}></Col>
            </Row>
          </Tab> */}
        </Tabs>
      </div>
    );
  }
}

export default ClickToCall;
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
