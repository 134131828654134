import { Component } from "react";
import { Line } from "react-chartjs-2";
import * as React from "react";
import Axios from "axios";
import { connect } from "react-redux";

const DATE_OPTIONS = {
  month: "short",
  day: "numeric",
};

class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datacli: {},
      datafrs: {},
    };
  }

  componentDidMount() {
    Axios.get(
      `https://www.24-crm.com/webservice/api/REGCLIs?typtyp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {
      const ipl = res.data;

      let playername = [];
      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datecli).toLocaleDateString("fr", DATE_OPTIONS)
        );
        runscore.push(record.sommonreg);
      });

      this.setState({
        datacli: {
          labels: playername,
          datasets: [
            {
              label: "Montant REGCLI / jour",
              data: runscore,
              backgroundColor: "transparent",
              borderColor: "rgba(255, 99, 132, 1)",
             borderWidth: 2,
             
            },
          ],
        },
      });
    });

    Axios.get(
      `https://www.24-crm.com/webservice/api/REGfrs?typtyp&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {
      const ipl = res.data;

      let playername = [];
      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datecli).toLocaleDateString("fr", DATE_OPTIONS)
        );
        runscore.push(record.sommonreg);
      });

      this.setState({
        datafrs: {
          labels: playername,
          datasets: [
            {
              label: "Montant REGFRS / jour",
              data: runscore,
              backgroundColor: "transparent",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 2,
             
            },
          ],
        },
      });
    });
  }

  render() {
    const combinedData = {
      labels: this.state.datacli.labels || this.state.datafrs.labels || [],
      datasets: [
        ...(this.state.datacli.datasets || []),
        ...(this.state.datafrs.datasets || []),
      ],
    };

    return (
      <div>
        <div>
           
          <Line
            data={combinedData}
            options={{ maintainAspectRatio: false }}
            width={"100px"}
            height={"300px"}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
  };
}

export default connect(mapStateToProps, null)(LineChart);

