import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";

import Popover from "@material-ui/core/Popover";
import { Label, Alert } from "reactstrap";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ReactToPrint from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TextField, Checkbox, Grid, Snackbar, IconButton, FormHelperText,} from "@material-ui/core";
import { connect } from "react-redux";
import { GetCodcli } from "../../redux/actions/GetCodcli";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Fab } from "@material-ui/core";
import ModifierBordereaux from"./ModifierBordereaux";
import "./re.scss";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
const actions = [
 { icon: <DeleteOutlineIcon />, name: "Supprimer" },
// { icon: <EditIcon />, name: "Modifier" },
 
];
const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
const DATE_OPTIONSS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});
class EditerBordereauBancaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soustraitant: 0,
      snackbarmsg: ",",
      NumBord:"",
      DatOp:"",
      Total:"",
      NbChq:"", 
      Rib:"",
      Ref:"",
      vers:"",
      CodBanque:"",
      codccb:"",
      TitreBord:"",
      Remettant:"",
      open: false,
       Details1:[],

      popoverOpen: false,
      anchorEl: null,
      snackbaropen: false,
      snackbarmsg: "",
      fournisseurimp: [],
      openModifierModal: false,




      
     };}
  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database,
  
    );


  }

  openModifier = () =>{ 
    if ((this.props.annuler === "0")) {
          this.setState({ openModifierModal: true, blid: this.props.blid });
          this.props.blligs.blligs.map((t) => {
            this.setState({ ligid: t.id });
          });
        } else {
          alert("Bon de livraison annulé, il faut le réactiver !");
        }
    };
   handleOpen = () => {
    this.setState({ open: true});
  };

    handleClose = () => {
      this.setState({ open: false });
    };
    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClosee = () => {
      this.setState({ anchorEl: null });
    };
  
    toggle = () => {
      this.setState({ popoverOpen: true });
    };
  
    toggleTable = () => {
      // Cette fonction bascule la valeur de showTable entre true et false
      this.setState((prevState) => ({
        showTable: !prevState.showTable,
      }));
    };


    snackbarClose = (event) => {
      this.setState({ snackbaropen: false });
    };
  
     // Fonction de suppression
  supprimerElement = () => {
    const {
      NumBord,
      ConfigDossier: { coddos, serveur, utilisateur, pwd_serveur, database },
    } = this.props;

    if (window.confirm(`Êtes-vous sûr de vouloir supprimer l'élément avec le numéro de bord ${NumBord}?`)) {
      // Construire l'URL pour la suppression
      const deleteUrl = `https://www.24-crm.com/webservice/api/BordereauVersements?NumBordDelete=${NumBord}&coddos=${coddos}&serveur=${serveur}&user=${utilisateur}&pwd=${pwd_serveur}&database=${database}`;
      // Envoyer une requête DELETE à l'API
      fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // Gérer la réponse de la suppression, par exemple, mettre à jour l'état ou afficher un message de confirmation
          console.log('Suppression réussie :', result);
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Suppression avec succès le N° " + NumBord,
          });
          // Mettre à jour l'état ou rafraîchir la liste d'éléments
        //  window.location.reload();
        })
        .catch((error) => {
          console.error('Erreur lors de la suppression :', error);
        });
    }
  };
    
  
  render() {

    let ModifierModalClose = () => this.setState({ openModifierModal: false });

const montantEnLettres = numberToFrenchWords(this.props.Total);
const result = convertToDinarsAndMillimes(this.props.Total);
    console.log("Banque",this.state.Details1.map((D,i) => (D.Banque)));
    const uniqueBanks = Array.from(new Set(this.state.Details1.map(D => D.Banque)));
console.log("Unique Bank Names:", uniqueBanks);
    const openn = Boolean(this.state.anchorEl);
    const { darck, classes, allCodeClient  } = this.props;
    const { statutsCodeClient } = this.state;
    return (
      <div className="container">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.snackbaropen}
        autoHideDuration={2000}
        onClose={this.snackbarClose}
        message={<span id="message-id"> {this.state.snackbarmsg} </span>}
        action={[
          <IconButton
            key="close"
            color="inherit"
            onClick={this.snackbarClose}  >x </IconButton>, ]} ></Snackbar>
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered >
        <Modal.Header closeButton  style={{  backgroundColor: `${darck ? "#fff" : "#172431"}`, color: `${darck ? "#020F64" : "#fff"}`,borderTop: `${darck ? "" : "2px solid #fff"}`,
            borderRight: `${darck ? "" : "2px solid #fff"}`,
            borderLeft: `${darck ? "" : "2px solid #fff"}`,
            borderBottom: `none`, }} >
          <Modal.Title id="contained-modal-title-vcenter">
            <b>Bordereau Bancaire</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: `${darck ? "#fff" : "#172431"}`,  border: `${darck ? "" : "2px solid #fff"}`, borderBottom: `none`,  }}  >
          <Card>
            <Card.Body style={{  backgroundColor: `${darck ? "#fff" : "#172431"}`, border: `${darck ? "" : "2px solid #fff"}`, }} >
              <Row>
                <Col style={{ textAlign: "center" }} sm={3}>
                  <Typography variant="h6" component="h2">
                    <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>№ Bordereau</Label>
                  </Typography>
                  <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "14px" }}>
                    {this.props.NumBord}
                  </Typography>
                </Col>
            
{/* /////////////////////////////////////////////  BLOC NUM 1   ///////////////////////////////////////////////////////////////////////////////////////// */}
            
                <Col style={{ textAlign: "center" }} sm={3}>
                  <Typography variant="h6" component="h2">
                    <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Date</Label>
                  </Typography>
                  <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "14px" }}>
                  {new Date(this.props.DatOp).toLocaleDateString("fr", DATE_OPTIONS )}
                  </Typography>
                </Col>

                <Col style={{ textAlign: "center" }} sm={3}>
                  <Typography variant="h6" component="h2">
                    <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Titre Bordereau</Label>
                  </Typography>
                  <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "14px" }}>
                    {this.props.TitreBord}
                  </Typography>
                </Col>
                
                <Col style={{ textAlign: "center" }} sm={3}>
                  <Typography variant="h6" component="h2">
                    <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Nombre des Chéques</Label>
                  </Typography>
                  <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "14px" }}>
                    {this.props.NbChq}
                  </Typography>
                </Col> </Row></Card.Body>  </Card> <br />

        
{/* /////////////////////////////////////////////  BLOC NUM 2   ///////////////////////////////////////////////////////////////////////////////////////// */}
<Row>
  <Col sm={12}>
    <Card>
      <Card.Body
        style={{
          backgroundColor: `${darck ? "#fff" : "#172431"}`,
          border: `${darck ? "" : "1px solid #fff"}`,
        }}
      >
        <Row style={{ marginBottom: "-12px" }}>
          <Col sm={3}>
            <div className="mb-3">
            <Typography variant="h6" component="h2">
            <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Remettant</Label>
                  </Typography>
              {this.props.Remettant === "" || this.props.Remettant === null ? (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>--</p>
              ) : (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>{this.props.Remettant}</p>
              )}
            </div>
          </Col>

          {/* <Col sm={2}>
            <div className="mb-3">
            <Typography variant="h6" component="h2">
            <Label style={{ color: `${darck ? "#darkslateblue" : "#fff"}` }}>Vers</Label>
                  </Typography>
              
              {this.props.vers === "" || this.props.vers === null ? (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>--</p>
              ) : (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>{this.props.vers}</p>
              )}
            </div>
          </Col> */}

          <Col sm={3}>
            <div className="mb-3">
            <Typography variant="h6" component="h2">
            <Label style={{ color: `${darck ? "#darkslateblue" : "#fff"}` }}>Banque</Label>
                  </Typography>
              {this.props.CodBanque === "" || this.props.CodBanque === null ? (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>--</p>
              ) : (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>{this.props.CodBanque}</p>
              )}
            </div>
          </Col>

          <Col sm={3}>
            <div className="mb-3">
            <Typography variant="h6" component="h2">
            <Label style={{ color: `${darck ? "#darkslateblue" : "#fff"}` }}>C.C.B</Label>
                  </Typography>
              {this.props.codccb === "" || this.props.codccb === null ? (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>--</p>
              ) : (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>{this.props.codccb}</p>
              )}
            </div>
          </Col>

          <Col sm={3}>
            <div className="mb-3">
              <Typography variant="h6" component="h2">
            <Label style={{ color: `${darck ? "#darkslateblue" : "#fff"}` }}>Rib</Label>
                  </Typography>
              {this.props.Rib === "" || this.props.Rib === null ? (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>--</p>
              ) : (
                <p style={{ color: `${darck ? "#black" : "#fff"}` }}>{this.props.Rib}</p>
              )}
            </div>
          </Col>
      

      
        
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>
<br />

{/* /////////////////////////////////////////////  BLOC 4 (TABLEAU)  ///////////////////////////////////////////////////////////////////////////////////////// */}
<Card>
  <Card.Body  style={{
          backgroundColor: `${darck ? "#fff" : "#172431"}`,
          border: `${darck ? "" : "1px solid #fff"}`,
        }}>
    {/* <Chip
      style={{
        fontSize: "16px",
        color: `${darck ? "" : "#fff"}`,
        border: `1px solid ${darck ? "" : "#fff"}`,
        cursor: "pointer",
        marginBottom: "10px", // Ajoutez cette ligne pour donner de l'espace en dessous du bouton
      }}
       icon={<VisibilityIcon />}
      color="primary"
      label="Liste des chéques" 
      variant="outlined"
      // onClick={() => {
      //  fetch(
      // `https://www.24-crm.com/webservice/api/BordereauVersements?numborrrdD=${this.props.NumBord}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      // { method: "GET",
      //       headers: { Accept: "application/json", "Content-Type": "application/json" },
      //     }
      //   )
      //   .then((response) => response.json())
      //   .then((data) => { this.setState({ Details: data }); });
      // }}
    ></Chip> */}
    <Row>
      {/* tabde "tabbl2" */}
      <div className="tabd28">
        <table>
          <thead style={{ background: "#B8D0E8", color: "white" }}>
            <tr>
              <th >N°</th> 
              <th >Banque Tirée</th>
              <th >Numéro du chéque</th>
              <th >Client</th>   
              <th >Titulaire</th>
              <th >Montant</th>  
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody style={{ maxHeight: "60vh" }}>
            {this.props.Details.map((D, i) => (
              <tr key={i}>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.numreg}</td>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.lib_banque}</td>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.numchq}</td>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.raisoc}</td>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.titulaire}</td>
                <td style={{ color: `${darck ? "" : "#fff"}` }}>{D.monreg}</td>
                {/* <td>{}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Row>
  </Card.Body>
</Card>
     
      <Card style={{ marginTop: "12px" }}> <Card.Body style={{ backgroundColor: `${darck ? "#fff" : "#172431"}`,border: `${darck ? "" : "2px solid #fff"}`,}} >
      <Row style={{ marginBottom: "-12px" }}><Col sm={3}>
           <div>
                      <Button variant="contained"
                        color="primary"
                        style={{width: "100%",height: "60px", }}
                        onClick={this.handleClick}>  Note </Button>
                      <Popover open={openn}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClosee}
                        anchorOrigin={{  vertical: "bottom",  horizontal: "center", }}
                        transformOrigin={{vertical: "top", horizontal: "center",  }} >
                        <Typography style={{ width: "500px",  height: "100px", padding: "10px",  background: "#eee",
                          }}
                          className={useStyles.typography}
                        >
                          {this.props.note === "" ||
                          this.props.note === null ? (
                            <p style={{ color: `${darck ? "#172431" : "#fff"}` }}> Note Vide ... </p>
                          ) : (
                            <p                               
                            style={{
                              color: `${darck ? "#172431" : "#fff"}`,
                              fontSize: "17px",
                            }}>
                              {this.props.note}
                            </p>
                          )}
                        </Typography>
                      </Popover>
                    </div>
                  </Col>
                  <Col sm={8}>
                    <Alert
                      color={"secondary"}
                      style={{
                        width: "100%",
                        height: "60px",
                      }}
                    >
                      <h4 style={{ color: "#17a2b8", textAlign: "center" }}>
                        Montant &nbsp;&nbsp;
                        <span style={{ color: `${darck ? "" : "#black"}` }}>
                          {Number(this.props.Total).toFixed(3)}
                        </span>
                      </h4>
                    </Alert>
                  </Col>
                  <Col sm={1}>
                    <div>
                      <SpeedDial style={{ position: "absolute",  bottom: "25px",right: "0px",  }}
                        ariaLabel="SpeedDial openIcon example"
                        hidden={this.state.hidden}
                        icon={<EditIcon openIcon={<EditIcon />} />}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        open={this.state.open}
                        FabProps={{ size: "small" }} >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                             onClick={() => {
                               this.handleClose();
                               action.name === "Supprimer" && this.supprimerElement();
                              //  action.name == "Modifier" &&
                              //  this.props.facture === "0" &&
                              //  this.openModifier();
                             }}
                          />
                        ))}
                        {!this.state.open ? (
                          <ReactToPrint
                            trigger={() => (
                              <Fab
                                size="small"
                                style={{
                                  backgroundColor: "white",
                                  display: "none",
                                }}
                                aria-label="add"
                              >
                                <PrintIcon />
                              </Fab>
                            )} 
                            content={() => this.componentRef}
                            />
                        ) : (
                          <ReactToPrint
                            trigger={() => (
                              <Fab
                                size="small"
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: "7px",
                                  color: "grey",
                                }}
                                aria-label="add"
                              >
                                <PrintIcon />
                              </Fab>
                            )} 
                            content={() => this.componentRef}
                            />
                        )}
                      </SpeedDial></div></Col>
                      </Row>
{/*                       
            <ModifierBordereaux
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              NumBord={this.state.NumBord}
              DatOp={this.state.DatOp}
              Total={this.state.Total}
              NbChq={this.state.NbChq}
              Rib={this.state.Rib}
              Ref={this.state.Ref}
              vers={this.state.vers}
              CodBanque={this.state.CodBanque}
              TitreBord={this.state.TitreBord}
              Remettant={this.state.Remettant}
              codccb={this.state.codccb}
              Details={this.state.Details}
            />
                       */}
                      
                      
                      </Card.Body>
                      </Card></Modal.Body></Modal>
{/* /////////////////////////////////////////////////////// ETAT D'impression   /////////////////////////////////////////////////////////////////// */}
        <div style={{ display: "none" }}>
          <div ref={(el) => (this.componentRef = el)} style={{ margin: "15px", height: "98%" }}  >
         

          <h3 style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "column",textAlign: "center",marginTop: "10px", }} >BORDEREAU {this.props.TitreBord}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; №:{this.props.Ref}</h3>
      <br/>
            {/* <div
              style={{ borderTopStyle: "solid", marginBottom: "20px" }}
            ></div> */}
            <Row>
              <Col></Col>
              <Col>
               </Col>  </Row>
            <div style={{display: "flex",flexDirection: "row",textAlign: "center", marginTop: "2px", marginLeft: "5px"}}> 
            <h5 style={{  marginLeft: "20px",fontWeight: "bolder",fontSize:"18px"}}> Bordereau N°: &nbsp;&nbsp;{this.props.NumBord}</h5>       
            <h5 style={{  marginLeft: "225px",fontWeight: "bolder", fontSize:"18px"}}>Date : &nbsp;&nbsp;{new Date(this.props.DatOp).toLocaleDateString("fr", DATE_OPTIONS )}</h5> 
            </div>

            <div style={{display: "flex",flexDirection: "row",textAlign: "center", marginTop: "2px", marginLeft: "5px"}}>
            <h5 style={{  marginLeft: "20px",fontWeight: "bolder",fontSize:"18px" }}> Banque: &nbsp;&nbsp;{uniqueBanks}</h5>
            <h5 style={{  marginLeft: "320px",fontWeight: "bolder",fontSize:"18px" }}>R.I.B Bénéficiaire : &nbsp;&nbsp;{this.props.Rib}</h5> 
            </div> 
             

            <div style={{display: "flex",flexDirection: "row",textAlign: "center", marginTop: "2px", marginLeft: "5px"}}>
            <h5 style={{  marginLeft: "20px",fontWeight: "bolder",fontSize:"18px" }}> Nom du Bénéficiaire : &nbsp;&nbsp;{this.props.InfoEntreprise[0].coddos}</h5>
            <h5 style={{  marginLeft: "175px",fontWeight: "bolder",fontSize:"18px" }}>Nom du Remettant    : &nbsp;&nbsp;{this.props.Remettant}</h5> 
            </div>  
        
        
           <div style={{ marginLeft: "5px", width: "99%",   minHeight: "50px",padding: "20px 25px", }} >
            {this.props.TitreBord==="REMISE CHEQUES"?(  
              
              
              <table style={{textAlign: "center", borderStyle: "5px", width: "100%"}} >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    
                  }}
                >
                  <tr style={{ border: "1px solid grey",}}>
                    <th style={{ width: "5%" ,   border: "1px solid gray"}}>N°</th>
                    <th style={{ width: "15%" ,   border: "1px solid gray"}}>Banque Tirée</th>
                    <th style={{ width: "10%" ,   border: "1px solid gray"}}>N°chéque</th>
                    <th style={{ width: "40%" ,   border: "1px solid gray"}}>Client</th>
                    <th style={{ width: "20%" ,   border: "1px solid gray"}}>Titulaire</th>
                    <th style={{ width: "10%" ,   border: "1px solid gray"}}>Montant</th>
                  </tr>
                </thead>

                <tbody style={{ textAlign: "center",border: "1px solid grey",}}>
              {this.props.Details.map((D, i) => (
              <tr 
               style={{ textAlign: "center",
              height: "40px",
               border: "1px solid gray",
            }}
              key={i}
              
              >
                    <td style={{ width: "5%" ,   border: "1px solid gray"}}> {i+1}</td>
                    <td style={{ width: "15%" ,   border: "1px solid gray"}}> {D.lib_banque} </td>
                    <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.numchq}</td>
                    <td style={{ width: "40%" ,   border: "1px solid gray"}}> {D.raisoc}</td>
                    <td style={{ width: "20%" ,   border: "1px solid gray"}}> {D.titulaire}</td>
                    <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.monreg}</td></tr>  ))} </tbody>    </table> 
                    ): this.props.TitreBord==="VIREMENT BANCAIRE"?  (
                         <table style={{textAlign: "center", borderStyle: "5px", width: "100%"}} >
                         <thead
                           style={{
                             textAlign: "center",
                             fontSize: "20px",
                             fontWeight: "bold",
                             paddingTop: "5px",
                             
                           }}
                         >
                           <tr style={{ border: "1px solid grey",}}>
                             <th style={{ width: "5%" ,   border: "1px solid gray"}}>N°</th>
                             <th style={{ width: "15%" ,   border: "1px solid gray"}}>Banque Tirée</th>
                             <th style={{ width: "10%" ,   border: "1px solid gray"}}>N°Effet</th>
                             <th style={{ width: "40%" ,   border: "1px solid gray"}}>Client</th>
                             <th style={{ width: "20%" ,   border: "1px solid gray"}}>Echéance</th>
                             <th style={{ width: "10%" ,   border: "1px solid gray"}}>Montant</th>
                           </tr>
                         </thead>
         
                         <tbody style={{ textAlign: "center",border: "1px solid grey",}}>
                       {this.props.Details.map((D, i) => (
                       <tr 
                        style={{ textAlign: "center",
                       height: "40px",
                        border: "1px solid gray",
                     }}
                       key={i}
                       
                       >
                             <td style={{ width: "5%" ,   border: "1px solid gray"}}> {i+1}</td>
                             <td style={{ width: "15%" ,   border: "1px solid gray"}}> {D.lib_banque} </td>
                             <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.numchq}</td>
                             <td style={{ width: "40%" ,   border: "1px solid gray"}}> {D.raisoc}</td>
                             <td style={{ width: "20%" ,   border: "1px solid gray"}}> { moment(D.datech).format("DD/MM/YYYY")} </td>
                             <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.monreg}</td></tr>  ))} </tbody>    </table> 
                    ):  this.props.TitreBord==="REMISE D'EFFETS A L'ENCAISSEMENT"?  (
                  <table style={{textAlign: "center", borderStyle: "5px", width: "100%"}} >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      
                    }}
                  >
                    <tr style={{ border: "1px solid grey",}}>
                      <th style={{ width: "5%" ,   border: "1px solid gray"}}>N°</th>
                      <th style={{ width: "15%" ,   border: "1px solid gray"}}>Banque Tirée</th>
                      <th style={{ width: "10%" ,   border: "1px solid gray"}}>N°Effet</th>
                      <th style={{ width: "40%" ,   border: "1px solid gray"}}>Client</th>
                      <th style={{ width: "20%" ,   border: "1px solid gray"}}>Echéance</th>
                      <th style={{ width: "10%" ,   border: "1px solid gray"}}>Montant</th>
                    </tr>
                  </thead>
  
                  <tbody style={{ textAlign: "center",border: "1px solid grey",}}>
                {this.props.Details.map((D, i) => (
                <tr 
                 style={{ textAlign: "center",
                height: "40px",
                 border: "1px solid gray",
              }}
                key={i}
                
                >
                      <td style={{ width: "5%" ,   border: "1px solid gray"}}> {i+1}</td>
                      <td style={{ width: "15%" ,   border: "1px solid gray"}}> {D.lib_banque} </td>
                      <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.numchq}</td>
                      <td style={{ width: "40%" ,   border: "1px solid gray"}}> {D.raisoc}</td>
                      <td style={{ width: "20%" ,   border: "1px solid gray"}}> {D.datech}</td>
                      <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.monreg}</td></tr>  ))} </tbody>    </table> 
             ):this.props.TitreBord==="REMISE D'EFFETS A L'ESCOMPTE"? (
               <table style={{textAlign: "center", borderStyle: "5px", width: "100%"}} >
               <thead
                 style={{
                   textAlign: "center",
                   fontSize: "20px",
                   fontWeight: "bold",
                   paddingTop: "5px",
                   
                 }}
               >
                 <tr style={{ border: "1px solid grey",}}>
                   <th style={{ width: "5%" ,   border: "1px solid gray"}}>N°</th>
                   <th style={{ width: "15%" ,   border: "1px solid gray"}}>Banque Tirée</th>
                   <th style={{ width: "10%" ,   border: "1px solid gray"}}>N°chéque</th>
                   <th style={{ width: "40%" ,   border: "1px solid gray"}}>Client</th>
                   <th style={{ width: "20%" ,   border: "1px solid gray"}}>Titulaire</th>
                   <th style={{ width: "10%" ,   border: "1px solid gray"}}>Montant</th>
                 </tr>
               </thead>

               <tbody style={{ textAlign: "center",border: "1px solid grey",}}>
             {this.props.Details.map((D, i) => (
             <tr 
              style={{ textAlign: "center",
             height: "40px",
              border: "1px solid gray",
           }}
             key={i}
             
             >
                   <td style={{ width: "5%" ,   border: "1px solid gray"}}> {i+1}</td>
                   <td style={{ width: "15%" ,   border: "1px solid gray"}}> {D.lib_banque} </td>
                   <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.numchq}</td>
                   <td style={{ width: "40%" ,   border: "1px solid gray"}}> {D.raisoc}</td>
                   <td style={{ width: "20%" ,   border: "1px solid gray"}}> {D.titulaire}</td>
                   <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.monreg}</td></tr>  ))} </tbody>    </table> 
          ):this.props.TitreBord==="VERSEMENT D'ESPACE"?(
            <table style={{textAlign: "center", borderStyle: "5px", width: "100%"}} >
            <thead
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                paddingTop: "5px",
                
              }}
            >
              <tr style={{ border: "1px solid grey",}}>
                <th style={{ width: "5%" ,   border: "1px solid gray"}}>N°</th>
                <th style={{ width: "15%" ,   border: "1px solid gray"}}>Date Reglement</th>
          
                <th style={{ width: "40%" ,   border: "1px solid gray"}}>Client</th>
     
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>Montant</th>
              </tr>
            </thead>

            <tbody style={{ textAlign: "center",border: "1px solid grey",}}>
          {this.props.Details.map((D, i) => (
          <tr 
           style={{ textAlign: "center",
          height: "40px",
           border: "1px solid gray",
        }}
          key={i}
          
          >
                <td style={{ width: "5%" ,   border: "1px solid gray"}}> {i+1}</td>
                 <td style={{ width: "15%" ,   border: "1px solid gray"}}> { moment(D.datreg).format("DD/MM/YYYY")}</td> 
        
                <td style={{ width: "40%" ,   border: "1px solid gray"}}> {D.raisoc}</td>

                <td style={{ width: "10%" ,   border: "1px solid gray"}}> {D.monreg}</td></tr>  ))} </tbody>    </table> 
       ):(<></>)}
           
                    
                    
                    
                    </div>
        <div>
          <h6 style={{  marginLeft: "920px",fontWeight: "bolder", }}>TOTAL : &nbsp;&nbsp;{this.props.Total}</h6> 
          <h6 style={{  marginLeft: "27px",fontWeight: "bolder", }}>Nombre De Chéque (s): &nbsp;&nbsp;{this.props.NbChq}</h6> 
          <h6 style={{ marginLeft: "27px", fontWeight: "bolder" }}>Montant en toutes lettres: &nbsp;&nbsp;{result}...... </h6>
          
           </div>   


    {/* //////////////////////// footer ///////////////////////////// */}
    <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "300px",
                  marginLeft: "25px",
                  borderWidth: "1px",
                  height: "70px",
                  textAlign: "left",
                }} >&nbsp;&nbsp;&nbsp;DATE: </div>

              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "300px",
                  borderWidth: "1px",
                  height: "70px",
                  textAlign: "left",
                }} > &nbsp;&nbsp;&nbsp;SIGNATURE BENEFICIAIRE/REMETTANT:  </div>

              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "300px",
                  marginRight: "10px",
                  borderWidth: "1px",
                  height: "70px",
                  textAlign: "left",
                }} >  &nbsp;&nbsp;&nbsp;VISA ET CACHET DE LA BANQUE: </div></div>

           <br></br> <div style={{ borderBottomStyle: "dashed" }}></div>
          </div>
              </div> </div> 













  );
}
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
  };
}
function mapStateToProps(state) {
  return {
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

function numberToFrenchWords(number) {
  const units = ['ZÉRO', 'UN', 'DEUX', 'TROIS', 'QUATRE', 'CINQ', 'SIX', 'SEPT', 'HUIT', 'NEUF'];
  const tens = ['', '', 'VINGT', 'TRENTE', 'QUARANTE', 'CINQUANTE', 'SOIXANTE', 'SOIXANTE-DIX', 'QUATRE-VINGT', 'QUATRE-VINGT-DIX'];
  const teens = ['DIX', 'ONZE', 'DOUZE', 'TREIZE', 'QUATORZE', 'QUINZE', 'SEIZE', 'DIX-SEPT', 'DIX-HUIT', 'DIX-NEUF'];

  if (number < 10) return units[number];
  if (number < 20) return teens[number - 10];
  if (number < 100) {
      if (number % 10 === 0) return tens[Math.floor(number / 10)];
      if (number < 70) return tens[Math.floor(number / 10)] + '-' + units[number % 10];
      if (number < 80) return 'SOIXANTE-' + teens[number % 10];
      return 'QUATRE-VINGT-' + units[number % 10];
  }
  if (number < 1000) {
      if (number % 100 === 0) return units[Math.floor(number / 100)] + ' CENT';
      return units[Math.floor(number / 100)] + ' CENT ' + numberToFrenchWords(number % 100);
  }
  if (number < 10000) {
      if (number % 1000 === 0) return units[Math.floor(number / 1000)] + ' MILLIMES';
      return units[Math.floor(number / 1000)] + ' MILLIMES ' + numberToFrenchWords(number % 1000);
  }
  return '';
}

function convertToDinarsAndMillimes(number) {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 1000);

  let dinars = numberToFrenchWords(integerPart) + ' DINARS';
  let millimes = '';

  // Convertir les millimes uniquement si la partie décimale n'est pas zéro
  if (decimalPart > 0) {
    millimes = ' ET ' + numberToFrenchWords(decimalPart) + ' MILIMES';
  }

  return dinars + millimes;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(EditerBordereauBancaire));
