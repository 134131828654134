import React, { Component } from "react";
import { Modal, Card, Alert } from "react-bootstrap";
import { Row, Col } from "reactstrap";

import { Divider, Button } from "@material-ui/core";
// import "../styling/Styles.css";
import { SelectClient } from "../../redux/actions/GetClients";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { SelectReleveClient } from "../../redux/actions/ActionGetReleverClient";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "./clientRelever.scss";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

import {Fab} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];
const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};



const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});


var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

const dateinitial = date
  .split("-")
  .map((val) => (val.length < 3 ? (val = "01") : val))
  .join("-");

var dayMinOne = new Date();
dayMinOne.setDate(dayMinOne.getDate() - 1);
var dateMinOne = dayMinOne.toISOString().substr(0, 10);

const dateinitialMinOne = date
  .split("-")
  .map((val, index) => {
    if (index === 2) {
      return (val = "31");
    } else if (index === 1) {
      return (val = "12");
    } else {
      return (val = val - 1);
    }
  })
  .join("-");

class ReleveClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabreleveclient: [],
      firstdatelayout: dateinitial,
      seconddatelayout: date,
      firstdate: new Date(dateinitial),
      seconddate: new Date(date),
      isSearchDate: false,
      defaultdate: date,
      dateJ1: dateinitialMinOne.slice(0, 10).split("-").reverse().join("-"),
    };
  }
  // ateinitial.slice(0, 10).split("-").reverse().join("-")
  // testhello = (event) => {
  //   this.setState({
  //     dateJ1: new Date(event.target.value),
  //   });
  // };

  componentDidMount() {
    this.props.SelectReleveClient(
      this.props.codeclient,
      this.props.Auth.username,
      this.props.Auth.fct,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  // HandlerJ1 = (event) => {
  //   this.setState({ dateJ1: this.state.dateJ1 });
  // };


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  firstrechdatHandler = (event) => {
    this.setState({
      firstdate: new Date(event.target.value),
      dateJ1: event.target.value.slice(0, 10).split("-").reverse().join("-"),
    });
    fetch(
      `https://www.24-crm.com/webservice/api/ReleveClient?codeclient=${this.props.codeclient}&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ tabreleveclient: data }));
  };

  secondrechdatHandler = (event) => {
    this.setState({ seconddate: new Date(event.target.value) });
    fetch(
      `https://www.24-crm.com/webservice/api/ReleveClient?codeclient=${this.props.codeclient}&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ tabreleveclient: data }));
  };    








  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  render() {
    const {
      firstdate,
      seconddate,
      isSearchDate,
      firstdatelayout,
      seconddatelayout,
      dateJ1,
    } = this.state;
    const { codeclient, GetReleveClient, raisonsociale,adresse,tel1 } = this.props;

    let totalGeneralCreadit =
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.map((item) => item.credit).reduce(
        (prev, curr) => prev + curr,
        0
      );

    let totalGeneralDebit = (
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.map((item) => item.debit)
    ).reduce((prev, curr) => prev + curr, 0);

    let totalGeneralSolde = totalGeneralDebit - totalGeneralCreadit;

    let totalePeriodeCredit =
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.filter((a) => {
        let resultmed = new Date(a.datfac);
        return resultmed >= firstdate && resultmed <= seconddate;
      })
        .map((item) => item.credit)
        .reduce((prev, curr) => prev + curr, 0);

    let totalePeriodeDebit =
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.filter((a) => {
        let resultmed = new Date(a.datfac);
        return resultmed >= firstdate && resultmed <= seconddate;
      })
        .map((item) => item.debit)
        .reduce((prev, curr) => prev + curr, 0);

    let totalePeriodeSolde = totalePeriodeDebit - totalePeriodeCredit;

    let totaleNoPeriodeDebit =
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed < firstdate;
      })
        .map((item) => item.debit)
        .reduce((prev, curr) => prev + curr, 0);

    let totaleNoPeriodeCredit =
      this.props.ReleverClient.ReleverClient &&
      this.props.ReleverClient.ReleverClient.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed < firstdate;
      })
        .map((item) => item.credit)
        .reduce((prev, curr) => prev + curr, 0);

    let totaleNoPeriodeSolde = totaleNoPeriodeDebit - totaleNoPeriodeCredit;

    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title>
              <b style={{ color: `${darck ? "#020F64" : "#fff"}` }}>
                Relevé Client 
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Row>
              {/* /////////////////////////////////////////////////////////////////////////////// */}
              <Col sm={12}>
                <Card>
                  <Row sm={12} style={{ flexWrap: "nowrap" }}>
                    <Col
                      sm={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          flexWrap: "wrap",
                        }}
                        variant="h6"
                        component="h3"
                      >
                        <Label
                          style={{
                            margin: "0",
                            minWidth: "70px",
                            color: "#020F64",
                          }}
                        >
                          <h4
                            style={{
                              margin: "0 0 0 2vw",
                              minWidth: "70px",
                              color: `${darck ? "#020F64" : "#fff"}`,
                            }}
                          >
                            Client :
                          </h4>
                        </Label>
                        <Label
                          style={{
                            margin: "2px 0 0 2vw",
                            minWidth: "70px",
                            color: `${darck ? "#020F64" : "#fff"}`,
                          }}
                        >
                          {codeclient}
                        </Label>
                        <Label
                          style={{
                            margin: "2px 0 0 2vw",
                            minWidth: "200px",
                            color: `${darck ? "#020F64" : "#fff"}`,
                          }}
                        >
                          {raisonsociale}
                        </Label>
                      </Typography>
                    </Col>
                    <Col
                      sm={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      }}
                    >
                      <DateRangeIcon
                        className="search-icon"
                        color={isSearchDate ? "primary" : "inherit"}
                        onClick={() =>
                          this.setState({ isSearchDate: !isSearchDate })
                        }
                      />
                    </Col>
                  </Row>
                </Card>
                <div style={{ height: "5px" }}></div>
                {/* /////////////////////////////////////////////////////////////////////////////// */}

                {isSearchDate ? (
                  <Card className="card0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <Row
                        className={`${darck ? "" : classes.root}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          md={12}
                        >
                          <Col sm={6}>
                            <Typography variant="h6" component="h3">
                              <Label
                                style={{
                                  margin: "0",
                                  minWidth: "150px",
                                  color: `${darck ? "#020F64" : "#fff"}`,
                                }}
                              >
                                Date de début :
                              </Label>
                            </Typography>
                          </Col>
                          <Col sm={6}>
                            <TextField
                              id="standard-basic"
                              label="Date"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              type="date"
                              fullWidth
                              name="datfac"
                              defaultValue={this.state.firstdatelayout}
                              onChange={this.firstrechdatHandler}
                              // onMouseLeave={this.testhello}
                              // disabled

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          md={12}
                        >
                          <Col  sm={6}>
                            <Typography variant="h6" component="h3">
                              <Label
                                style={{
                                  margin: "0",
                                  minWidth: "150px",
                                  color: `${darck ? "#020F64" : "#fff"}`,
                                }}
                              >
                                Date de fin :
                              </Label>
                            </Typography>
                          </Col>
                          <Col  sm={6}>
                            <TextField
                              id="standard-basic"
                              label="Date"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              type="date"
                              fullWidth
                              name="datfac"
                              defaultValue={this.state.seconddatelayout}
                              onChange={this.secondrechdatHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}

                {/* //////////////////////////////////////////////////////////////////////////////////////// */}
                <Card style={{ marginTop: "5px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    {/* <div className="ligbc-table"> */}
                    <div className="tabd29">
                      <table stripped>
                        <thead>
                          <tr style={{ textAlign: "center", fontSize: "12px" }}>
                            <th
                              style={{
                                minWidth: "90px",
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                paddingLeft: "1vw",
                              }}
                            >
                              Date
                            </th>
                            <th
                              // className="restd"
                              style={{
                                width: "30%",
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              libellé
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              SIT
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              M.R
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              CHQ/EFF
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Echéance
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Débit
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Crédit
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Solde
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            overflowY: "overlay",
                            display: "block",
                            maxHeight: "20em",
                            color: `${darck ? "" : "#fff"}`,
                          }}
                        >
                          {this.state.tabreleveclient &&
                          this.state.tabreleveclient.length !== 0 ? (
                            <>
                              {/* //////////////////////////////////////somme précedent////////////////////////////////////////////////////// */}
                            
                              {/* /////////////////////////////////////////////////////////////////////////////////// */}
                              {this.state.tabreleveclient &&
                                this.state.tabreleveclient
                                  .filter((a) => {
                                    let resultmed = new Date(a.datfac);
                                    return (
                                      resultmed >= firstdate &&
                                      resultmed <= seconddate
                                    );
                                  })
                                  .map((t, i) => (
                                    <tr key={i}>
                                      <td
                                        style={{
                                          minWidth: "90px",
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>
                                          {t.datfac.slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")}
                                        </span>
                                      </td>
                                      <td
                                        className="restd"
                                        style={{
                                          fontSize: "12px",
                                          width: "30%",
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.libelle}</span>
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.SIT}</span>
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.MR}</span>
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.CHQ}</span>
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>

                                          {t.echeance &&
                                            t.echeance
                                              .slice(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("-")}
                                        </span>
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>
                                          {t.debit !== 0
                                            ? t.debit.toFixed(3)
                                            : ""}
                                        </span>
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>
                                          {t.credit !== 0
                                            ? t.credit.toFixed(3)
                                            : ""}
                                        </span>
                                      </td>
                                      {/* <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {t.debit - t.credit < 0 ? (
                                          <span style={{ color: "#9B1C24" }}>
                                            {(t.debit - t.credit).toFixed(3)}
                                          </span>
                                        ) : (
                                          <span style={{ color: "#155757" }}>
                                            {(t.debit - t.credit).toFixed(3)}
                                          </span>
                                        )}
                                      </td> */}
                                        <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {/* {t.debit - t.credit < 0 ? ( */}
                                           {t.Solde < 0 ? (
                                        <span style={{ color: "#9B1C24" }}>
                                          {/* {t.debit - t.credit} */}
                                          {(t.Solde).toFixed(3)}
                                        </span>
                                      ) : (
                                        <span style={{ color: "#155757" }}>
                                          {/* {t.debit - t.credit} */}
                                          {(t.Solde).toFixed(3)}
                                        </span>
                                      )}
                                    </td>
                                      <td></td>
                                    </tr>
                                  ))}
                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Période</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeDebit !== 0
                                      ? totalePeriodeDebit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeCredit !== 0
                                      ? totalePeriodeCredit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeSolde !== 0
                                      ? totalePeriodeSolde.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Général</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralDebit !== 0
                                      ? totalGeneralDebit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralCreadit !== 0
                                      ? totalGeneralCreadit.toFixed(3)
                                      : totalGeneralCreadit}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralSolde !== 0
                                      ? totalGeneralSolde.toFixed(3)
                                      : totalGeneralSolde}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {/* //////////////////////////////////////somme précedent////////////////////////////////////////////////////// */}
                              <tr>
                                <td
                                  style={{
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span>{dateJ1}</span>
                                </td>
                                <td
                                  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "0.5vw",
                                    fontWeight: "bold",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}
                                >
                                  Solde_Précédent
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "20%" }}
                                ></td>
                                <td
                                  style={{
                                    // dispaly: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "start",
                                    marginLeft: "3vw",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {/* ///////////////////////////////////Solde Précédent//////////////////////////////// */}
                                    {totaleNoPeriodeDebit !== 0
                                      ? totaleNoPeriodeDebit.toFixed(3)
                                      : null}
                                  </span>
                                  {/* ///////////////////////////////////////////////////////////////////////////////// */}
                                </td>
                                <td
                                  style={
                                    {
                                      // dispaly: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "start",
                                    }
                                  }
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {/* ///////////////////////////////////Solde Précédent//////////////////////////////// */}
                                    {totaleNoPeriodeCredit !== 0
                                      ? totaleNoPeriodeCredit.toFixed(3)
                                      : null}
                                  </span>
                                  {/* ///////////////////////////////////////////////////////////////////////////////// */}
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totaleNoPeriodeSolde !== 0
                                      ? totaleNoPeriodeSolde.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td className="resajust"></td>
                              </tr>
                              {/* /////////////////////////////////////////////////////////////////////////////////// */}
                              {this.props.ReleverClient.ReleverClient &&
                                this.props.ReleverClient.ReleverClient.filter(
                                  (a) => {
                                    let resultmed = new Date(a.datfac);
                                    return (
                                      resultmed >= firstdate &&
                                      resultmed <= seconddate
                                    );
                                  }
                                ).map((t, i) => (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        minWidth: "90px",
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span>
                                        {t.datfac.slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                      </span>
                                    </td>
                                    <td
                                      className="restd"
                                      style={{
                                        fontSize: "12px",
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.libelle}</span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.SIT}</span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.MR}</span>
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.CHQ}</span>
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>
                                        {t.echeance &&
                                          t.echeance
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>
                                        {t.debit !== 0
                                          ? t.debit.toFixed(3)
                                          : ""}
                                      </span>
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>
                                        {t.credit !== 0
                                          ? t.credit.toFixed(3)
                                          : ""}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {/* {t.debit - t.credit < 0 ? ( */}
                                           {t.Solde < 0 ? (
                                        <span style={{ color: "#9B1C24" }}>
                                          {/* {t.debit - t.credit} */}
                                          {(t.Solde).toFixed(3)}
                                        </span>
                                      ) : (
                                        <span style={{ color: "#155757" }}>
                                          {/* {t.debit - t.credit} */}
                                          {(t.Solde).toFixed(3)}
                                        </span>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                ))}
                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Période</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeDebit !== 0
                                      ? totalePeriodeDebit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeCredit !== 0
                                      ? totalePeriodeCredit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeSolde !== 0
                                      ? totalePeriodeSolde.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Général</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralDebit !== 0
                                      ? totalGeneralDebit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralCreadit !== 0
                                      ? totalGeneralCreadit.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralSolde !== 0
                                      ? totalGeneralSolde.toFixed(3)
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
                {/* //////////////////////////////////////////////////////////////////////////////// */}

                <Card style={{ marginTop: "5px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                   

                    {/* ////////////////////////////////////////// */}

                    <Row style={{ marginBottom: "10px" }}>
                      <Col>
                        <Divider style={{ backgroundColor: "grey" }} />
                      </Col>
                    </Row>

                    {/* ////////////////////////////////////////// */}

                    <Row style={{ marginTop: "10px" }}>
                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          Total Général débit:
                        </Typography>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          {totalGeneralDebit.toFixed(3)}
                        </Typography>
                      </Col>

                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          Total Général crédit:
                        </Typography>
                        <Typography
                          style={{ color: `${darck ? "#020F75" : "#fff"}` }}
                        >
                          {totalGeneralCreadit.toFixed(3)}
                        </Typography>
                      </Col>

                      <Col style={{ textAlign: "center" }} sm={4}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: `${darck ? "#020F75" : "#fff"}`,
                          }}
                        >
                          solde Général :
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: `${darck ? "#020F75" : "#fff"}`,
                          }}
                        >
                          {totalGeneralSolde.toFixed(3)}
                        </Typography>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}

          
        {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "5px", right: "30px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Relevé client </>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
     
                  de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                        { moment(this.state.seconddate).format("DD/MM/YYYY")} 
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                Client  :  {this.props.codeclient} : {this.props.raisonsociale} / {this.props.adresse} / {this.props.tel1}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "5%" ,   border: "1px solid gray"}}>{translate("date")}</th>
                <th style={{ width: "25%" ,   border: "1px solid gray"}}>{translate("Libellé")}</th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("SIT")} </th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("M.R")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("CHQ/EFF")}</th>
                 <th style={{ width: "10%" ,   border: "1px solid gray" }}>{translate("Echéance")}</th>
                 <th style={{ width: "10%" ,   border: "1px solid gray" }}>{translate("Debit")}</th>
                 <th style={{ width: "10%" ,   border: "1px solid gray" }}>{translate("crédit")}</th>
                 <th style={{ width: "10%" ,   border: "1px solid gray" }}>{translate("Solde")}</th>
                </tr> 
                
                </thead>
            <tbody>
         



    {this.props.ReleverClient.ReleverClient &&
                                this.props.ReleverClient.ReleverClient.filter(
                                  (a) => {
                                    let resultmed = new Date(a.datfac);
                                    return (
                                      resultmed >= firstdate &&
                                      resultmed <= seconddate
                                    );
                                  }
                                ).map((t, i) => (

         
<tr
  key={i}
  style={{
    textAlign: "center",
    height: "40px",
    border: "1px solid gray",
 
  }}>
{/* ///////////////////////////////////////// date ///////////////////////*/}
{t.datfac==null? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "5%" ,   border: "1px solid gray"}}>
        { moment(t.datfac).format("DD/MM/YYYY")}
        
       
      </td>
    )}
{/* ///////////////////////////////////////////// libelle ///////////////////*/}
<td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "25%" ,   border: "1px solid gray"}} > 

{t.libelle}

</td>
{/* ////////////////////////////////////////////// sit ///////////////////////*/}
<td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "5%" ,   border: "1px solid gray"}} > 

{t.SIT}

</td>
{/* /////////////////////////////////////////// M.R //////////////////////*/}
{!t.MR ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.MR}
       
      </td>
    )}
{/* ///////////////////////////////////// Chq //////////////////////////////////*/}
{!t.CHQ ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.CHQ}
       
      </td>
    )}
{/* //////////////////////////////////////////// echeance ////////////////////////////*/}
{t.echeance==null? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        { moment(t.echeance).format("DD/MM/YYYY")}
        
       
      </td>
    )}
{/* //////////////////////////////////////////// debit ////////////////////////////*/}    
{!t.debit ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.debit}
       
      </td>
    )}     

{/* //////////////////////////////////////////// credit ////////////////////////////*/}              
{!t.credit ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
        --
    </td>
    ) : (
      <td  style={{ color: `${darck ? "#9B1C24" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.credit}
       
      </td>
    )}   
{/* //////////////////////////////////////////// solde ////////////////////////////*/}              

      {/* <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
      {t.debit - t.credit < 0 ? (
                          <span style={{ color: "#9B1C24" }}>
                            {(t.debit - t.credit).toFixed(3)}
                          </span>
                        ) : (
                          <span style={{ color: "#155757" }}>
                            {(t.debit - t.credit).toFixed(3)}
                          </span>
                        )}
    </td> */}

<td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
      {t.Solde < 0 ? (
                          <span style={{ color: "#9B1C24" }}>
                            {(t.Solde).toFixed(3)}
                          </span>
                        ) : (
                          <span style={{ color: "#155757" }}>
                            {(t.Solde).toFixed(3)}
                          </span>
                        )}
    </td>
 

  
    
 
   </tr>  
  
  ))} 
                                
             
                 </tbody>
                 <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >


                <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "25%" }}>Total Période</th>
                <th style={{ width: "5%"   }}> </th>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "5%"  }}></th>
                 <th style={{ width: "10%" }}></th>
                 <th style={{ width: "10%"  }}> {Number(totalePeriodeDebit).toFixed(3)}</th>
                 <th style={{ width: "10%"  }}> {Number(totalePeriodeCredit).toFixed(3)}</th>
                 <th style={{ width: "10%"  }}>{Number(totalePeriodeSolde).toFixed(3)}</th>
                </tr> 
                </thead>  
              <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >


                <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "25%" }}> Total Client</th>
                <th style={{ width: "5%"   }}> </th>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "5%"  }}></th>
                 <th style={{ width: "10%" }}></th>
                 <th style={{ width: "10%"  }}> {Number(totalGeneralDebit).toFixed(3)}</th>
                 <th style={{ width: "10%"  }}> {Number(totalGeneralCreadit).toFixed(3)}</th>
                 <th style={{ width: "10%"  }}>{Number(totalGeneralSolde).toFixed(3)}</th>
                </tr> 
                </thead>   
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
       
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    SelectReleveClient: (z, f, g, a, b, c, d, e) =>
      dispatch(SelectReleveClient(z, f, g, a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    ReleverClient: state.ReleverClient,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ReleveClientModal));
