import React, { Component } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  TextField,
  Snackbar,
  IconButton,
  Breadcrumbs,
  MenuItem,
  FormHelperText,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import FormControl from "@material-ui/core/FormControl";
import "./personnel.scss";
import { SelectVendeur } from "../../redux/actions/GetVendeur";
//import { SelectCodVendeur } from "../../redux/actions/GetCodVD";
import Axios from "axios";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
// import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Bar } from "react-chartjs-2";
import { FormGroup, Row, Col } from "reactstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import { SelectSECVendeur } from "../../redux/actions/GetSECVend";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
//import VisibilityOff from '@mui/icons-material/VisibilityOff';
//import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
const DATE_OPTIONS = {
  month: "short",
  day: "numeric",
};

var curr = new Date();
var dat = curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class Personnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat: "",
      show: false,
      snackbaropen: false,
      snackbarmsg: ",",
      usern: "",
      codvendeur: "",
      libvendeur: "",
      ModalShow: false,
      data: {},
      datadv: {},
      showing: false,
      databl: {},
      datafac: {},
      databe: {},
      dat: dat,
      dv: [],
      bc: [],
      ft: [],
      bl: [],
      pwd: "",
      groupe: "",
      errorMsg: false,
      Vendeur: "",
      Vendeur: [],
      libvendeur:[],

      groupe: "",
      adminsCount: 0,
      repsCount: 0,
      isPasswordShown: false

    };
   
  }
  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  componentDidMount() {
    this.props.SelectVendeur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
   {/* } this.props.SelectCodVendeur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
     this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
   );  */}
  //  this.props.SelectValTimbre(
  //   this.props.ConfigDossier.database,
  //   this.props.ConfigDossier.coddos,
  //   this.props.ConfigDossier.serveur,
  //   this.props.ConfigDossier.utilisateur,
  //   this.props.ConfigDossier.pwd_serveur
  // );
  this.props.SelectSECVendeur(
    this.props.ConfigDossier.database,
    this.props.ConfigDossier.coddos,
    this.props.ConfigDossier.serveur,
    this.props.ConfigDossier.utilisateur,
    this.props.ConfigDossier.pwd_serveur
  );
    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?dat=${date}&typppeeep=dv&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ dv: data }));

    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?dat=${date}&typppeeep=bc&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ bc: data }));

    fetch(
      `https://www.24-crm.com/webservice/api/BLBRs?dat=${date}&typppeeep=bl&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ bl: data }));

    fetch(
      `https://www.24-crm.com/webservice/api/FACCLIs?dat=${date}&typppeeep=ft&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ ft: data }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.snackbarmsg &&
      this.state.snackbarmsg !== "Utilisateur existant"
    ) {
    {/* } this.props.SelectCodVendeur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
       this.props.ConfigDossier.utilisateur,
       this.props.ConfigDossier.pwd_serveur,
       this.props.ConfigDossier.database
    ); */}
    }
  }
  vendeurHandler = (event) => {
    this.setState({ showing: true });
    const libvendeur = this.state.Vendeur;
    //////////bl //////////////

    Axios.get(
      `https://www.24-crm.com/webservice/api/BLBRs?typpppp=bl&vend=${this.state.libvendeur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
        );

        runscore.push(record.sommemntbn);
      });

      this.setState({
        databl: {
          labels: playername,

          datasets: [
            {
              label: "Montant BL effectué / jour",

              data: runscore,

              backgroundColor: [
                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",
              ],
            },
          ],
        },
      });
    });

    //////////facture //////////////

    Axios.get(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?typpppp=BC&vend=${this.state.libvendeur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
        );

        runscore.push(record.sommemntbn);
      });

      this.setState({
        datafac: {
          labels: playername,

          datasets: [
            {
              label: "Montant BC effectué / jour",

              data: runscore,

              backgroundColor: [
                "#28a745",

                "#28a745",

                "#28a745",

                "#28a745",

                "#28a745",

                "#28a745",

                "#28a745",
              ],
            },
          ],
        },
      });
    });

    //////////BE //////////////

    Axios.get(
      `https://www.24-crm.com/webservice/api/BEREs?typpppp=BE&vend=${this.state.libvendeur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
        );

        runscore.push(record.sommemntbn);
      });

      this.setState({
        databe: {
          labels: playername,

          datasets: [
            {
              label: "Montant BE effectué / jour",

              data: runscore,

              backgroundColor: [
                "blue",

                "blue",

                "blue",

                "blue",

                "blue",

                "blue",

                "blue",
              ],
            },
          ],
        },
      });
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
  
    // Récupérer les valeurs du formulaire
    const libvendeur = event.target.lib.value;
    const codvendeur = event.target.code.value;
    const motDePasse = event.target.pass.value;
  
    if (this.state.groupe) {
      // Appel API pour créer un vendeur
      fetch(
        `https://www.24-crm.com/webservice/api/Vendeur?code=${codvendeur}&nom=${libvendeur}&mp=${motDePasse}&grp=200&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`,
        {
          method: "POST",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              // Appel API réussi, vous pouvez effectuer d'autres actions si nécessaire
  
              // Appel API pour créer une entrée Nome
              fetch(
                `https://www.24-crm.com/webservice/api/Nome?cat=VD&code=${parseInt(
                  this.props.codvds.codvds.map((el) => el.Column1)[0]
                ) + 1}&lib=${libvendeur}&chdec=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((res) => res.json())
                .then(
                  (result) => {
                    // Appel API réussi, vous pouvez effectuer d'autres actions si nécessaire
  
                    // Appel API pour créer une entrée REPREST
                    fetch(
                      `https://www.24-crm.com/webservice/api/REPREST?codrep=${parseInt(
                        codvendeur
                      ) + 1}&nom=${libvendeur}&adr=''&ville=''&cp=''&pays=''&tel1=''&tel2=''&tel3=''&fax=''&email=''&sitweb=''&taux_com=0&ch_aff=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                      {
                        method: "POST",
                        header: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                      }
                    ).then((res) =>
                      this.setState({
                        usern: "",
                        libvendeur: "",
                        pwd: "",
                      })
                    );
                  },
                  (error) => {
                    this.setState({
                      snackbaropen: true,
                      snackbarmsg: "failed",
                    });
                  }
                );
            } else {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Utilisateur existant",
              });
            }
          },
          (error) => {
            this.setState({ snackbaropen: true, snackbarmsg: "failed" });
          }
        );
    } else {
      this.setState({ errorMsg: !this.state.groupe });
    }
  };
  

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  libChange = (event) => {
    this.setState({
      libvendeur: event.target.value,
      usern: event.target.value,
    });
    
  };

  pwChange = (event) => {
    this.setState({
      pwd: event.target.value,
      usern: event.target.value,
      [event.target.name]: event.target.value
    });

  };





  




handleRoleChange = (selectedRole) => {
  this.setState({
    groupe: selectedRole,
  });
};
handleButtonClick = () => {

  const { adminsCount, repsCount, groupe } = this.state;
  const randomCode = Math.floor(Math.random() * 1000) + 1;
  // Vous devez ajuster le service web en fonction de vos besoins
  if (window.confirm("voulez vous enregistrer l'utilisateur ?") == true) {
  fetch(
    `https://www.24-crm.com/webservice/api/Vendeur?code=${randomCode}&nom=${this.state.libvendeur}&mp=${this.state.pwd}&grp=${groupe}&statut=True&demostatut=False&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database='PSDOSSIER'`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())    
    
    .then((data) => {
      this.setState({
        tab: data,
        show: true,
        
      });
      
      
      this.setState({
        snackbaropen: true,
        snackbarmsg: "Utilisateur ajouté avec succés" ,
      });

      // Mettez à jour le nombre d'enregistrements en fonction du rôle
      if (groupe === 100) {
        this.setState((prevState) => ({ adminsCount: prevState.adminsCount + 1 }));
      } else if (groupe === 200) {
        this.setState((prevState) => ({ repsCount: prevState.repsCount + 1 }));
      }
    });
  }
  
};

ModifierButtonClick = () => {

  const { adminsCount, repsCount, groupe } = this.state;
  const randomCode = Math.floor(Math.random() * 1000) + 1;
  // Vous devez ajuster le service web en fonction de vos besoins
  if (window.confirm("voulez vous Modifier l'utilisateur ?") == true) {
  fetch(
    `https://www.24-crm.com/webservice/api/Vendeur?code=${randomCode}&nom=${this.state.libvendeur}&mp=${this.state.pwd}&grp=${groupe}&statut=True&demostatut=False&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database='PSDOSSIER'`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())    
    
    .then((data) => {
      this.setState({
        tab: data,
        show: true,
        
      });
      
      
      this.setState({
        snackbaropen: true,
        snackbarmsg: "Utilisateur modifié avec succés" ,
      });

      // Mettez à jour le nombre d'enregistrements en fonction du rôle
      if (groupe === 100) {
        this.setState((prevState) => ({ adminsCount: prevState.adminsCount + 1 }));
      } else if (groupe === 200) {
        this.setState((prevState) => ({ repsCount: prevState.repsCount + 1 }));
      }
    });
  }
  
};
isButtonDisabled = () => {
  const { adminsCount, repsCount, groupe } = this.state;
  // Désactivez le bouton après deux enregistrements d'admins ou trois enregistrements de représentants
  return (groupe === 100 && adminsCount >= 3) || (groupe === 200 && repsCount >= 3);
};
  


  render() {
    let ModalClose = () => this.setState({ ModalShow: false });
    const { isPasswordShown } = this.state;
    const { codvendeur, libvendeur } = this.state;
    const { darck, classes, alllistcode } = this.props;
  
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <StyledBreadcrumb
            component="a"
            href="/"
            label="Paramètres"
            icon={<SettingsIcon fontSize="small" />}
          />
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{ color: "rgb(63, 81, 181)" }}
            href="/ajout-d'un-utilisateur"
            label="Ajout D'un Utilisateur"
            icon={<ListAltIcon fontSize="small" />}
          />
        </Breadcrumbs>
        {/* <div className="page-icon">
          <u>
            {" "}
            <i class="fas fa-users" style={{ fontSize: "25px" }}>
              {" "}
              Vendeurs
            </i>
          </u>
        </div> */}
        <br />
    
          <Row>
            <Col sm={5}>
              <Card style={{ height: "100%" }}>
                <Card.Body style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <div style={{ margin: "20px" }}>
                    <p className="p111">Ajouter un nouveau compte vendeur pour 24-CRM</p>
                    <Form
                      autocomplete="off"
                      onSubmit={this.submitHandler}
                      validated
                    >
                      <Row form>
                        <Col sm={6}>
                          <Form.Group controlId="code">
                            {this.props.codvds.codvds.map((t) => (
                              <TextField
                                id="standard-basic"
                                label="Code"
                                margin="normal"
                                fullWidth
                                name="code"
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    codvendeur: e.target.value,
                                  })
                                }
                                value={parseInt(t.Column1) + 1}
                                disabled
                              />
                            ))}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row form style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <Form.Group controlId="lib">
                            <TextField
                              id="standard-basic"
                              label="Username"
                              margin="normal"
                              fullWidth
                              name="lib"
                              type="text"
                              required
                              onChange={this.libChange}
                              value={this.state.libvendeur}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={6}>
                          <Form.Group controlId="username">
                            <TextField
                              id="standard-basic"
                              label="Username"
                              margin="normal"
                              fullWidth
                              name="username"
                              type="text"
                              value={this.state.usern}
                              disabled
                            />
                          </Form.Group>
                        </Col> */}
                      </Row>

                      <Row form> 
                    
                        <Col sm={6}>
                          <Form.Group controlId="pass">
                
                            <TextField
                         
                              id="standard-basic"
                              label="Password"
                              margin="normal"
                              fullWidth
                              name="pass"
                              // type="password"
                              type={isPasswordShown ? "text" : "password"}
                              required
                              onChange={this.pwChange}
                              // onChange={(e) =>
                              //   this.setState({ pwd: e.target.value })
                              // }
                              value={this.state.pwd}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.togglePasswordVisiblity}
                                      edge="end"
                                    >
                                      {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                           
                            /> 
                    
                          </Form.Group>
                        </Col>
                       </Row>

                      <Row form>
                        <Col sm={6}>
                          <Form.Group controlId="fct">
                            <TextField
                              id="select"                            
                              select
                              label="Rôle"
                              fullWidth
                              required
                              onChange={(e) =>
                                this.setState({
                                  groupe: e.target.value,
                                })
                              }
                              value={this.state.groupe}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            >
                              <MenuItem value={100}>Admin</MenuItem>
                              <MenuItem value={200}>Représentant</MenuItem>
                            </TextField>
                            {this.state.errorMsg && (
                              <FormHelperText style={{ color: "red" }}>
                                champ obligatoire!
                              </FormHelperText>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />

                          <Row>
                    <Col sm={2}></Col>
                    <Col sm={8}>



                               

                               <Button
                                      color="primary"
                                      onClick={this.handleButtonClick}
                                      disabled={this.isButtonDisabled()}
                                    
                                    >
                                      Enregistrer
                                    </Button>
                                    &nbsp;&nbsp;
                                   <Button
                                    color="primary"
                                    onClick={this.ModifierButtonClick}
                                    disabled={this.isButtonDisabled()}
                                  
                                  >
                                    Modifier
                                  </Button>
                                     
                                 
                      


                                    

                    </Col>
                 
                    <Col sm={2}></Col>
                    </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={7}>
              <Card style={{ height: "100%", marginTop: "0px" }}>
                <Card.Body style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <div style={{ margin: "20px" }}>
                    <Row>
                  
                    <Col sm={4}>
                        <FormGroup style={{ marginTop: "-25px" }}>
                          <Autocomplete
                            id="include-input-in-list"
                          includeInputInList
                          options={this.state.Vendeur}
                          getOptionLabel={(option) => `${option.nom}`}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                libvendeur: getOptionLabel.nom,
                                codvendeur: getOptionLabel.code,
                                usern: getOptionLabel.nom,
                                pwd:getOptionLabel.mp,
                                showing: false,
                                })
                              : this.setState({
                                  rep: "",
                                  Vendeur: "",
                                  codvendeur: "",
                                  usern: "",
                                  pwd: "",
                                  showing: false,
                                });
                          }}
                            
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Vendeur"
                                margin="normal"
                                fullWidth
                                name="libvendeur"
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.com/webservice/api/Vendeur?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        Vendeur: result,
                                      });
                                    });
                                }}

                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                            </Col>  
                 
                      <Col sm={6}>
                        <Button
                          variant="outline-info"
                          onClick={this.vendeurHandler}
                        >
                          {" "}
                          Afficher les statistiques <VisibilityIcon />{" "}
                        </Button>
                      </Col>
                    </Row>

                    {this.state.showing ? (
                      <div>
                        <Row>
    {/* graphique  bon Lvr*/}
                          <Col sm={6}>
                            <div>
                              <Bar
                                data={this.state.databl}
                                options={{ maintainAspectRatio: false }}
                                width={"300px"}
                                height={"250px"}
                              />
                            </div>
                          </Col>
  {/* graphique  bon commande */}
                          <Col sm={6}>
                            <div>
                              <Bar
                                data={this.state.datafac}
                                options={{ maintainAspectRatio: false }}
                                width={"300px"}
                                height={"250px"}
                              />
                            </div>
                          </Col>
                        </Row>
            {/* graphique  bon entree */}
                        {/* <Row>
                          <Col sm={12}>
                            <div>
                              <Bar
                                data={this.state.databe}
                                options={{ maintainAspectRatio: false }}
                                width={"300px"}
                                height={"150px"}
                              />
                            </div>
                          </Col>
                        </Row> */}
                      </div>
                    ) : null}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br /> <br />
{/* 
          affichage de BUTTON devis et BC ,BL,FCT */}
           {/* <Row style={{ marginBottom: "10px" }}>
            <Col sm={12}>
              <h6 style={{ color: "rgb(2, 15, 100)", fontWeight: "bold" }}>
               Statistiques {date}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <h3>
                        {" "}
                        <i
                          className="fas fa-tasks"
                          style={{ color: "darkslateblue" }}
                        >
                          {" "}
                          Devis
                        </i>
                      </h3>
                    </Col>

                    <Col sm={4}>
                      {this.state.dv.map((t) => (
                        <h4
                          style={{
                            color: "black",
                          }}
                        >
                          {t.num}
                        </h4>
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <h3>
                        {" "}
                        <i
                          className="fas fa-clipboard-list"
                          style={{ color: "darkslateblue" }}
                        >
                          {" "}
                          BC
                        </i>
                      </h3>
                    </Col>

                    <Col sm={4}>
                      {this.state.bc.map((t) => (
                        <h4
                          style={{
                            color: "black",
                          }}
                        >
                          {t.num}
                        </h4>
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <h3>
                        {" "}
                        <i
                          className="fas fa-list-alt"
                          style={{ color: "darkslateblue" }}
                        >
                          {" "}
                          BL
                        </i>
                      </h3>
                    </Col>

                    <Col sm={4}>
                      {this.state.bl.map((t) => (
                        <h4
                          style={{
                            color: "black",
                          }}
                        >
                          {t.num}
                        </h4>
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
  <Col sm={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <h3>
                        {" "}
                        <i
                          className="fas fa-file-invoice-dollar"
                          style={{ color: "darkslateblue" }}
                        >
                          {" "}
                          Facture
                        </i>
                      </h3>
                    </Col>

                    <Col sm={4}>
                      {this.state.ft.map((t) => (
                        <h4
                          style={{
                            color: "black",
                          }}
                        >
                          {t.num}
                        </h4>
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
                        </Col> 
            
          </Row>  */}
        </div>
    
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
   
    SelectVendeur: (a, b, c, d, e)      =>     dispatch(SelectVendeur(a, b, c, d, e)),
   // SelectValTimbre: (a, b, c, d, e) =>    dispatch(SelectValTimbre(a, b, c, d, e)),
    SelectSECVendeur: (a, b, c, d, e) =>    dispatch(SelectSECVendeur(a, b, c, d, e)),
   //SelectCodVendeur: (a, b, c, d, e)   =>     dispatch(SelectCodVendeur(a, b, c, d, e)), 
  };
}

function mapStateToProps(state) {
  return {
    vendeurs: state.vendeurs,
    codvds: state.codvds,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Personnel);
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

