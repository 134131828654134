import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {

  Snackbar,
  IconButton,
 
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { GetCodcli } from "../../redux/actions/GetCodcli";
import { SelectClient } from "../../redux/actions/GetClients";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { withStyles } from "@material-ui/styles";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Help from "@material-ui/icons/Help";


const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class AddElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: 0,
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",
      codbqvers:"",
      libbqvers:"",
      
      BANK: [],
      CodeCCb: "",
      CodeCCb:[],

      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],

      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],

      categoriefiscale: "",
      attestation: "",
      dateetabli: "",
      datexp: "",
      statutsCodeClient: false,
      code: null,
      lib: null,
      chdec: null,
      compte: null,
      categorie: ""
    };
  }

  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  codeHandler = (event) => {
    this.setState({ code: event.target.value });
  };

  libHandler = (event) => {
    this.setState({ lib: event.target.value });
  };

  chdecHandler = (event) => {
    this.setState({ chdec: event.target.value });
  };
  chdecHandlerCCB = (event) => {
    this.setState({ chdec:this.props.codbqvers});
  };
  compteHandler = (event) => {
    this.setState({ compte: event.target.value });
  };
  
  categorieHandler = (event) => {
    this.setState({ categorie: event.target.value });
  };



  submitHandler = (event) => {
    event.preventDefault();
    if (window.confirm("Reconstitution du stock confirmée!") == true) {
      fetch(
       
       `https://www.24-crm.com/webservice/api/BEREs?typSTK&vend&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "POST",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      this.setState({
        snackbaropen: true,
        snackbarmsg: "Reconstitution du stock terminé avec succés!! "
      });
       window.location.reload();
    } else{
      window.location.reload();
    }
    //  else{
    //   fetch(
    //     `https://www.24-crm.com/webservice/api/BEREs?typMG&vend&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //     {
    //       method: "POST",
    //       header: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    
    //   ); 
    //   this.setState({
    //     snackbaropen: true,
    //     snackbarmsg: "Reconstitution du stock terminé avec succés MG!! "
    //   });
    // } 
    // if (window.confirm("Confirmer la recalcul de stcok virtuel!!") == true) {
    //   fetch(
    //   `https://www.24-crm.com/webservice/api/BEREs?typSOLD&vend&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //   {
    //     method: "POST",
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // this.setState({
    //   snackbaropen: true,
    //   snackbarmsg: "Reconstitution du stock terminé avec succés!!",
    // });
     
  // } 
       
  };

  render() {
    const { darck, classes, allCodeClient  } = this.props;
    const { statutsCodeClient } = this.state;

    return (
      
      <div className="container" >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
         //size="lg"
         // aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "0px"}}
       
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "0px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
            <Help style={{color:"rgb(2, 15, 100)" ,fontSize:"40px"}} />&nbsp;
            {/* &nbsp; */}
              <b>Confirmer la reconstitution des stocks? </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
              top:"-20px",
          bottom:"150%"
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.submitHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row form>
                <Col sm={12}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                     
                      }}
                    >
                      <h6 > <label style={{color:"red" }}> NB:</label> Il est recommandé que tous les utilisateurs quittent le logiciel...</h6>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button
                          type="submit"
                          color="primary"
                          style={{ width: "100%" }}
                          >
                   Confirmer
                  </Button>
               
                </Col>
              </Row>
            </form>
          </Modal.Body>
       
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
    dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
  };
}

function mapStateToProps(state) {
  return {
    bqs: state.bqs,
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddElement));

// import React, { Component } from "react";
// import { Modal, Card } from "react-bootstrap";
// import { Row, Col, FormGroup, Button } from "reactstrap";
// import {
//   TextField,
//   Checkbox,
//   Grid,
//   Snackbar,
//   IconButton,
//   FormHelperText,
// } from "@material-ui/core";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { connect } from "react-redux";
// import { GetCodcli } from "../../redux/actions/GetCodcli";
// import { SelectClient } from "../../redux/actions/GetClients";
// import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
// import { withStyles } from "@material-ui/styles";
// import { PieChart } from "@material-ui/icons";


// const stylesFn = () => ({
//   input: {
//     color: "#fff",
//   },
//   root: {
//     "& label.Mui-focused": {
//       color: "#fff",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "#fff",
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "#fff",
//       },
//       "&:hover fieldset": {
//         borderColor: "#fff",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "#fff",
//       },
//     },
//   },
// });

// class AddElement extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       gilad: 0,
//       timbre: 0,
//       soustraitant: 0,
//       snackbaropen: false,
//       snackbarmsg: ",",
//       codbqvers:"",
//       libbqvers:"",
      
//       BANK: [],
//       CodeCCb: "",
//       CodeCCb:[],

//       tablecat: [
//         { code: "0" },
//         { code: "1" },
//         { code: "2" },
//         { code: "3" },
//         { code: "4" },
//         { code: "5" },
//         { code: "6" },
//       ],

//       cat: [
//         { code: "0", label: "Assujetti" },
//         { code: "1", label: "Non Assujetti" },
//         { code: "2", label: "Exonéré TVA/FODEC" },
//         { code: "3", label: "Exonéré TVA" },
//         { code: "4", label: "Suspenssion" },
//         { code: "5", label: "Cession à Quai" },
//         { code: "6", label: "Réduction TVA" },
//       ],

//       categoriefiscale: "",
//       attestation: "",
//       dateetabli: "",
//       datexp: "",
//       statutsCodeClient: false,
//       code: null,
//       lib: null,
//       chdec: null,
//       compte: null,
//       categorie: ""
//     };
//   }

//   componentDidMount() {
//     this.props.GetCodcli(
//       this.props.ConfigDossier.coddos,
//       this.props.ConfigDossier.serveur,
//       this.props.ConfigDossier.utilisateur,
//       this.props.ConfigDossier.pwd_serveur,
//       this.props.ConfigDossier.database
//     );
//     this.props.SelectNomenclatureBQ(
//       this.props.ConfigDossier.coddos,
//       this.props.ConfigDossier.serveur,
//       this.props.ConfigDossier.utilisateur,
//       this.props.ConfigDossier.pwd_serveur,
//       this.props.ConfigDossier.database
//     );
//   }

//   snackbarClose = () => {
//     this.setState({ snackbaropen: false });
//   };
 
//   handleChange = (name) => (event) => {
//     this.setState({ [name]: event.target.checked });
//   };

//   handleClose  =  ()=> {
    
//     this.setState({ setShow:false });
//   };
//   catHandler = (event) => {
//     this.setState({ categoriefiscale: event.target.value });
//   };

//   codeHandler = (event) => {
//     this.setState({ code: event.target.value });
//   };

//   libHandler = (event) => {
//     this.setState({ lib: event.target.value });
//   };

//   chdecHandler = (event) => {
//     this.setState({ chdec: event.target.value });
//   };
//   chdecHandlerCCB = (event) => {
//     this.setState({ chdec:this.props.codbqvers});
//   };
//   compteHandler = (event) => {
//     this.setState({ compte: event.target.value });
//   };
  
//   categorieHandler = (event) => {
//     this.setState({ categorie: event.target.value });
//   };

//   submitHandler = (event) => {
//     event.preventDefault();
//     if (window.confirm("Confirmer la recalcul du pmp !") == true) {
//       fetch(
//        // `https://www.24-crm.com/webservice/api/BLBRs?ArtDeb=&ArtFin=&MajPmpMvts=1&AfficherLeResultat=&Ben=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
       
//        `https://www.24-crm.com/webservice/api/BEREs?typSTK&vend&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
//         {
//           method: "get",
//           header: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//         }
//       );
//     //   if (window.confirm("Confirmer la recalcul de stcok virtuel!!") == true) {
       
       
//     // }
//     }   
//   };

//   render() {
//     const { darck, classes, allCodeClient  } = this.props;
//     const { statutsCodeClient } = this.state;

//     return (
//       <div className="container">
//         <Snackbar
//           anchorOrigin={{ vertical: "top", horizontal: "right" }}
//           open={this.state.snackbaropen}
//           autoHideDuration={2000}
//           onClose={this.snackbarClose}
//           message={<span id="message-id"> {this.state.snackbarmsg} </span>}
//           action={[
//             <IconButton
//               key="close"
//               color="inherit"
//               onClick={this.snackbarClose}
//             >
//               x
//             </IconButton>,
//           ]}
//         ></Snackbar>

//         <Modal
 
//           onHide={this.props.onHide}
//           show={this.props.show}
//          size="lg"
//           // size="xl"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//           style={{ padding: "0px" }}
//         >
//           <Modal.Header
//             closeButton
//             style={{
//               backgroundColor: `${darck ? "#fff" : "#172431"}`,
//               color: `${darck ? "#020F64" : "#fff"}`,
//               borderTop: `${darck ? "" : "2px solid #fff"}`,
//               borderRight: `${darck ? "" : "2px solid #fff"}`,
//               borderLeft: `${darck ? "" : "2px solid #fff"}`,
//               borderBottom: `none`,
//             }}
//           >
//             <Modal.Title id="contained-modal-title-vcenter">
          
//               <b>Confirmer  la reconstitution des stock? </b>
//             </Modal.Title>
//           </Modal.Header>
//           <Modal.Body
//             style={{
//               backgroundColor: `${darck ? "#fff" : "#172431"}`,
//               borderTop: `none`,
//               borderRight: `${darck ? "" : "2px solid #fff"}`,
//               borderLeft: `${darck ? "" : "2px solid #fff"}`,
//               borderBottom: `${darck ? "" : "2px solid #fff"}`,
//             }}
//           >
//             <form
//               autocomplete="off"
//               onSubmit={this.submitHandler}
//               className={`${darck ? "" : classes.root}`}
//             >
//               <Row form>
//                 <Col sm={12}>
//                   <Card className="card0" style={{ height: "10%" }}>
//                     <Card.Body
//                       style={{
//                         backgroundColor: `${darck ? "#fff" : "#172431"}`,
//                         borderTop: `${darck ? "" : "2px solid #fff"}`,
//                         borderRight: `${darck ? "" : "2px solid #fff"}`,
//                         borderLeft: `${darck ? "" : "2px solid #fff"}`,
//                         borderBottom: `${darck ? "" : "2px solid #fff"}`,
//                         borderRadius: "5px",
//                       }}
//                     >
//                       <h5> NB:</h5>
                     
//                         <h6> Il est recommandé que tous les utilisateurs quittent le logiciel...</h6>

//                     </Card.Body>
//                   </Card>
//                 </Col>
//               </Row>
//               {/* <br /> */}
//               <Row>
//                 <Col sm={8}></Col>
//                 <Col sm={4}>
//                   <Button
//                           type="submit"
//                           // color="primary"
//                           variant="contained" color="success"
//                           style={{ width: "40%" }}
//                           > Confirmer
//                   </Button>
//                   {/* &nbsp;&nbsp;
       
                  
//                   <Button
//                     key="close"
//                     color="primary" 
//                     // variant="outlined"
//                     variant="secondary"
//                      // color="inherit"
//                      onClick={this.handleClose}
//                     >
//                     Non
//                   </Button> */}
                 
//                 </Col>
               
//               </Row>
//          </form>
//               {/*   <form
//               autocomplete="off"
//              // onSubmit={this.handleClose}
//               className={`${darck ? "" : classes.root}`}
//             >
//               <Row form>
                
             
              
//                   <Button
//                     key="close"
//                     color="primary" 
//                     // variant="outlined"
//                     variant="secondary"
//                      // color="inherit"
//                      onClick={this.onClose}
//                     >
//                     Non
//                   </Button>
                 
               
//               </Row>
//             </form> */}
//           </Modal.Body>
//         </Modal>
//       </div>
//     );
//   }
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
//     SelectNomenclatureBQ: (a, b, c, d, e) =>
//     dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
//     SelectClient: (a, b, c, d, e) => {
//       dispatch(SelectClient(a, b, c, d, e));
//     },
//   };
// }

// function mapStateToProps(state) {
//   return {
//     bqs: state.bqs,
//     codclis: state.codclis,
//     ConfigDossier: state.ConfigDossier,
//     darck: state.darck.darck,
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(stylesFn)(AddElement));
