import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Switch,
  Grid,
  Snackbar,
  IconButton,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import "../styling/Styles.css";
import { SelectClient } from "../../redux/actions/GetClients";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class ModifierClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: this.props.passager,
      timbre: this.props.timbre,
      soustraitant: this.props.soustraitant,
      snackbaropen: false,
      snackbarmsg: ",",

      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      categoriefiscale: "",
    };
  }

  componentDidMount() {
    this.setState({ categoriefiscale: this.props.categoriefiscale });
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  ATTHandler = (event) => {
    this.setState({ attestation: event.target.value });
  };
  
  submitHandler = (event) => {
    event.preventDefault();

    fetch(
    // `https://www.24-crm.com/webservice/api/CLIENTs?codcli=${event.target.codcli.value}&raisoc=${event.target.raisoc.value}&tel1=${event.target.tel1.value}&tel2=${event.target.tel2.value}&passager=${event.target.passager.value}&email=${event.target.emailcli.value}&adr=${event.target.adressecli.value}&sitweb=${event.target.sitewebcli.value}&cin=${event.target.cincli.value}&ville=${event.target.villecli.value}&cp=${event.target.cp.value}&codtva=${event.target.identcli.value}&compte=${event.target.fodeccli.value}&timbre=${event.target.timbre.value}&NAR=&RC=${event.target.contactcli.value}&CodDep=${event.target.impotcli.value}&RIB2=${event.target.comptable.value}&catfisc=${event.target.catfisc.value}&numexon=${event.target.attestation.value}&dfexon=${event.target.datexp.value}&dvexon=${event.target.dateetabli.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  // pour modification de catfiscal ex tva à asujjtti(19/02/2024)
    `https://www.24-crm.com/webservice/api/CLIENTs?codcli=${event.target.codcli.value}&raisoc=${event.target.raisoc.value}&tel1=${event.target.tel1.value}&tel2=${event.target.tel2.value}&passager=${event.target.passager.value}&email=${event.target.emailcli.value}&adr=${event.target.adressecli.value}&sitweb=${event.target.sitewebcli.value}&cin=${event.target.cincli.value}&ville=${event.target.villecli.value}&cp=${event.target.cp.value}&codtva=${event.target.identcli.value}&compte=${event.target.fodeccli.value}&timbre=${event.target.timbre.value}&NAR=&RC=${event.target.contactcli.value}&CodDep=${event.target.impotcli.value}&RIB2=${event.target.comptable.value}&catfisc=${event.target.catfisc.value}&numexon=${this.state.attestation}&dfexon=${this.props.datexp}&dvexon=${this.props.dateetabli}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,

      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();

          this.setState({ snackbaropen: true, snackbarmsg: result });
          this.props.SelectClient(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

        window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );

    //  this.props.onHide01();
  };

  render() {
    const { darck, classes } = this.props;
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={6000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier Client</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.submitHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row>
                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              style={{ margin: "0" }}
                              // className="card add-input"
                              id="standard-basic"
                              label="Code Client"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="codcli"
                              defaultValue={this.props.codeclient}
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <Grid item style={{ marginTop: "10px" }}>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  size="small"
                                  onChange={this.handleChange("gilad")}
                                  //onChange={() => props.DarckModeAction(!darck)}
                                  color="primary"
                                  name="passager"
                                  inputProps={{
                                    "aria-label": "warning checkbox",
                                  }}
                                  // checked={!darck}
                                  value={this.state.gilad}
                                />
                              </ThemeProvider>
                              <span style={{ color: !darck ? "#fff" : null }}>
                                Passager
                              </span>
                              {/* <Switch
                                name="passager"
                                color="primary"
                                checked={this.state.gilad}
                                onChange={this.handleChange("gilad")}
                                value={this.state.gilad}
                              /> */}
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Raison Sociale"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="raisoc"
                              defaultValue={this.props.raisoc}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Email"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="emailcli"
                              defaultValue={this.props.email}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Adresse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="adressecli"
                              defaultValue={this.props.adresse}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Ville"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="villecli"
                              defaultValue={this.props.ville}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code Postal"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cp"
                              defaultValue={this.props.codepostal}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Téléphone 1"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel1"
                              defaultValue={this.props.tel1}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Téléphone 2"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel2"
                              defaultValue={this.props.tel2}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Site Web"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="sitewebcli"
                              defaultValue={this.props.siteweb}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col style={{ margin: "0" }}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Identifiant unique"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="identcli"
                              defaultValue={this.props.identifiant}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col style={{ margin: "0" }}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Autre Impots"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="impotcli"
                              defaultValue={this.props.impot}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col style={{ margin: "0" }}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Taux Fodec"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="fodeccli"
                              defaultValue={this.props.tauxfodec}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col style={{ margin: "0" }}>
                          <FormGroup>
                            <TextField
                              style={{ overflow: "hidden" }}
                              // className="card add-input"
                              id="standard-basic"
                              label="Compte comptable"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="comptable"
                              defaultValue={this.props.comptable}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}
                      <Row form>
                        <Col style={{ margin: "0" }} sm={6}>
                          <FormGroup
                            style={{ overflow: "hidden" }}
                            controlId="catfisc"
                          >
                            <TextField
                              // className="card add-input"
                              id="outlined-select-currency"
                              select
                              label="Categorie Fisc"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="catfisc"
                              required
                              defaultValue={this.props.categoriefiscale}
                              onChange={this.catHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            >
                              {this.state.tablecat.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.code}
                                </MenuItem>
                              ))}
                            </TextField>
                            {this.state.categoriefiscale === "0" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Assujetti{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "1" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Non Assujetti{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "2" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA/FODEC{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "3" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "4" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Suspenssion{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "5" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Cession à Quai{" "}
                              </FormHelperText>
                            ) : this.state.categoriefiscale === "6" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Réduction TVA{" "}
                              </FormHelperText>
                            ) : (
                              <FormHelperText
                                style={{
                                  fontSize: "13px",
                                  marginTop: "20px",
                                  color: "#007bff",
                                }}
                              >
                                Cat Fiscale{" "}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                        {this.state.categoriefiscale == "1" ? (
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                // value={this.props.numexon}
                                // onChange={(e) =>
                                //   this.setState({ attestation: e.target.value })
                                // }
                                defaultValue={this.props.numexon}
                                onChange={this.ATTHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) :this.state.categoriefiscale == "2" ? (
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                // value={this.props.numexon}
                                // onChange={(e) =>
                                //   this.setState({ attestation: e.target.value })
                                // }
                                defaultValue={this.props.numexon}
                                onChange={this.ATTHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) :this.state.categoriefiscale == "3" ? (
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                // value={this.props.numexon}
                                // onChange={(e) =>
                                //   this.setState({ attestation: e.target.value })
                                // }
                                defaultValue={this.props.numexon}
                                onChange={this.ATTHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) :this.state.categoriefiscale == "4" ? (
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                // value={this.props.numexon}
                                // onChange={(e) =>
                                //   this.setState({ attestation: e.target.value })
                                // }
                                defaultValue={this.props.numexon}
                                onChange={this.ATTHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) :this.state.categoriefiscale == "5" ? (
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                // value={this.props.numexon}
                                // onChange={(e) =>
                                //   this.setState({ attestation: e.target.value })
                                // }
                                defaultValue={this.props.numexon}
                                onChange={this.ATTHandler}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : null}
                      </Row>
                      {this.state.categoriefiscale == "2" ? (
                        <Row form>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                value={new Date(this.props.dateetabli)
                                  .toISOString()
                                  .substr(0, 10)}
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({ dateetabli: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                         <TextField
                        name="dateetabli"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.dateetabli)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText  style={{
                                  
                                  color: !darck ? "#fff" : null,
                                }}
                              >Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                value={new Date(this.props.datexp)
                                  .toISOString()
                                  .substr(0, 10)}
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                               <TextField
                        name="datexp"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datexp)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText
                               style={{
                                  
                                color: !darck ? "#fff" : null,
                              }} >Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "3" ? (
                        <Row form>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                value={new Date(this.props.dateetabli)
                                  .toISOString()
                                  .substr(0, 10)}
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({ dateetabli: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                         <TextField
                        name="dateetabli"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.dateetabli)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText  style={{
                                  
                                  color: !darck ? "#fff" : null,
                                }}
                              >Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                value={new Date(this.props.datexp)
                                  .toISOString()
                                  .substr(0, 10)}
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                               <TextField
                        name="datexp"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datexp)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText
                               style={{
                                  
                                color: !darck ? "#fff" : null,
                              }} >Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "4" ? (
                        <Row form>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                value={new Date(this.props.dateetabli)
                                  .toISOString()
                                  .substr(0, 10)}
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({ dateetabli: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                         <TextField
                        name="dateetabli"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.dateetabli)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText  style={{
                                  
                                  color: !darck ? "#fff" : null,
                                }}
                              >Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                value={new Date(this.props.datexp)
                                  .toISOString()
                                  .substr(0, 10)}
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                               <TextField
                        name="datexp"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datexp)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText
                               style={{
                                  
                                color: !darck ? "#fff" : null,
                              }} >Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "5" ? (
                        <Row form>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                value={new Date(this.props.dateetabli)
                                  .toISOString()
                                  .substr(0, 10)}
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({ dateetabli: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                         <TextField
                        name="dateetabli"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.dateetabli)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText  style={{
                                  
                                  color: !darck ? "#fff" : null,
                                }}
                              >Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              {/* <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                value={new Date(this.props.datexp)
                                  .toISOString()
                                  .substr(0, 10)}
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              /> */}
                               <TextField
                        name="datexp"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datexp)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                              <FormHelperText
                               style={{
                                  
                                color: !darck ? "#fff" : null,
                              }} >Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="A Contacter"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="contactcli"
                              defaultValue={this.props.acontacter}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="CIN"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cincli"
                              defaultValue={this.props.cin}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <ThemeProvider theme={theme}>
                                <Switch
                                  name="timbre"
                                  color="primary"
                                  checked={this.state.timbre}
                                  onChange={this.handleChange("timbre")}
                                  value={this.state.timbre}
                                />

                                <span style={{ color: !darck ? "#fff" : null }}>
                                  Timbre Fiscale
                                </span>
                              </ThemeProvider>
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        {/* <Col>
                          <FormGroup>
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <Switch
                                name="soustraitant"
                                color="primary"
                                checked={this.state.soustraitant}
                                onChange={this.handleChange("soustraitant")}
                                value={this.state.soustraitant}
                              />
                              Sous Traitant
                            </Grid>
                          </FormGroup>
                        </Col> */}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button color="primary" style={{ width: "100%" }}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
  };
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierClientModal));
