import React, { Component ,  useState}  from "react";

import { Modal, Form } from "react-bootstrap";
import "../styling/Styles.css";
import {
  TextField,
  MenuItem,
  CardHeader,
  Checkbox,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { Input, Label, FormGroup, Col, Row } from "reactstrap";
import Center from "react-center";
import { Button } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { SelectSousFamille } from "../../redux/actions/GetSousFamille";
import { SelectNome } from "../../redux/actions/GetNome";
import { connect } from "react-redux";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import UploadImage from "./UploadImage";
import {SelectNomCategorie} from"../../redux/actions/GetNomCategorie";
import { SelectMemo } from "../../redux/actions/GetMemo";
const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");

class ModifierArticleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tt: "dqsd",
      purnhtnet: this.props.purevientnetht,
      remisefrs: this.props.remisefrs,
      purbrut: this.props.purevientbrut,
      purttc: this.props.purevientnetttc,
      tva: this.props.tvaarticle,
      marged: this.props.margeprrnet,
      pudhtt: this.props.puventeht,
      pudhttc: this.props.puventettc,
      codtva: this.props.codtva,
      photo:this.props.photo,
      tableunite: [
        { libele: "KG", name: 0 },
        { libele: "PI", name: 1 },
      ],
      tabletva: [
        { code: "0", label: "0" },
        { code: "1", label: "7" },
        { code: "2", label: "13" },
        { code: "3", label: "19" },
      ],
      van: [
        { code: "V", label: "Sur Vente" },
        { code: "A", label: "Sur Achat" },
        { code: "N", label: "Sans Fodec" },
      ],
      vann: [
        { code: "V", label: "Sur Vente" },
        { code: "A", label: "Sur Achat" },
        { code: "N", label: "Sans Fodec" },
      ],
      //  unite: "",
      codeabarre: "",
      designation: "",
      snackbaropen: false,
      snackbarmsg: ",",
      unite: "",
      famille: "",
      typfodec: "",
      fodec: 0,
      sousfam: "",
      rechs: [],
      typf: "",
      codfrs: "",
      ECO:"",
   
    }; 
      this.fileInputRef = React.createRef(this.props.photo);
      this.imageRef = React.createRef(this.props.photo);
     
  }
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imageRef.current.src = reader.result; // Update image src
  

      };
      reader.readAsDataURL(file);

    }
 
  };

  handleFileSelect = (base64String) => {
    this.setState({ photo: base64String });
  };

  componentDidMount() {
    this.props.SelectMemo(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNome(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectSousFamille(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclature(
      "UM",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    
    this.props.SelectNomCategorie(
      "CAT",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.setState({
      unite: this.props.unitearticles.replace(/ /g, ""),
      famille: this.props.famillearticle.replace(/ /g, ""),
      sousfam: this.props.sousfamillearticle.replace(/ /g, ""),
      codfrs: this.props.codfrs.replace(/ /g, ""),
    });

    if (this.props.typefodecarticle === "V")
      this.setState({ typf: "Sur Vente" });
    else if (this.props.typefodecarticle === "A")
      this.setState({ typf: "Sur Achat" });
    else this.setState({ typf: "Sans Fodec" });

    fetch(
      `https://www.24-crm.com/webservice/api/fournisseurs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          rechs: result,
        });
      });

      fetch(
        `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
      )
        .then((res) => res.json())
        .then((data) => {
          data.map((el) => this.setState({ ECO: el.ECO }));
          data.map((el) => console.log("dossier", el.ECO));
        });


      this.props.SelectFournisseur(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  codeabarrechange = (event) => {
    this.setState({
      codeabarre: event.target.value,
    });
  };
  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/FOURNISSEURs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsc: data, clicked: true }));
  };
  designationchange = (event) => {
    this.setState({
      designation: event.target.value,
    });
  };

  tvaHandler = (event) => {
    this.setState({
      tva: event.target.value,
      pudhttc: roundTo(
        this.state.pudhtt * (1 + Number(event.target.value) / 100),
        3
      ),
      purttc: roundTo(
        this.state.purnhtnet * (1 + Number(event.target.value) / 100),
        3
      ),
    });
    event.target.value == 0
      ? this.setState({ codtva: 0 })
      : event.target.value == 7
      ? this.setState({ codtva: 1 })
      : event.target.value == 13
      ? this.setState({ codtva: 2 })
      : this.setState({ codtva: 3 });
  };

  // submitHandler = (event) => {
  //   event.preventDefault();

  //   fetch(
  //     `https://www.24-crm.com/webservice/api/ARTICLEs?codart=${event.target.codart.value}
  //     &desart=${event.target.desart.value}
  //     &unite=${event.target.unite.value}
  //     &codtva=${this.state.codtva}
  //     &tautva=${this.state.tva}
  //     &codfrs=${this.state.codfrs}
  //     &famart=${event.target.famart.value}
  //     &codbar=${event.target.codbar.value}
  //     &typfodec=${this.state.typfodec}
  //     &typdc=${event.target.typdc.value}
  //     &sousfam=${event.target.sousfam.value}
  //     &TDC=${event.target.TDC.value}
  //     &reffrs=${event.target.reffrs.value}
  //     &PUDTTC=${event.target.PUDTTC.value}
  //     &PUDHT=${event.target.PUDHT.value}
  //     &PURBHT=${this.state.purbrut}
  //     &remisefrs=${event.target.remisefrs.value}
  //     &PURNHT=${event.target.PURNHT.value}
  //     &PURNTTC=${event.target.PURNTTC.value}&Photo=${this.state.photo}
  //     &margedet=${event.target.margedet.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //     {
  //       method: "PUT",
  //       header: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.props.onHide();
  //         this.props.SelectArticle(
  //           this.props.ConfigDossier.coddos,
  //           this.props.ConfigDossier.serveur,
  //           this.props.ConfigDossier.utilisateur,
  //           this.props.ConfigDossier.pwd_serveur,
  //           this.props.ConfigDossier.database
  //         );

  //         this.setState({ snackbaropen: true, snackbarmsg: result });

  //       //  window.location.reload();
  //       },
  //       (error) => {
  //         this.setState({ snackbaropen: true, snackbarmsg: "failed" });
  //       }
  //     );
  //   //  this.props.onHide01();
  // };

  submitHandler = (event) => {
    event.preventDefault();
  
    const requestBody = {
      CatArt: event.target.CatArt.value,
      codart: event.target.codart.value,
      desart: event.target.desart.value,
      unite: event.target.unite.value,
      codtva: this.state.codtva,
      tautva: this.state.tva,
      codfrs: this.state.codfrs,
      famart: event.target.famart.value,
      codbar: event.target.codbar.value,
      typfodec: this.state.typfodec,
      typdc: event.target.typdc.value,
      sousfam: event.target.sousfam.value,
      TDC: event.target.TDC.value,
      reffrs: event.target.reffrs.value,
      PUDTTC: event.target.PUDTTC.value,
      PUDHT: event.target.PUDHT.value,
      PURBHT: this.state.purbrut,
      remisefrs: event.target.remisefrs.value,
      PURNHT: event.target.PURNHT.value,
      PURNTTC: event.target.PURNTTC.value,
      margedet: event.target.margedet.value,
      Photo: this.state.photo,
   
      Ecomm: event.target.Ecomm.value? 1 : 0,
        };

        const  memolig= event.target.memolig.value;
  
    fetch(`https://www.24-crm.com/webservice/api/ARTICLEs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectArticle(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          fetch(
            `https://www.24-crm.com/webservice/api/Memos?nature='ART'&codlig=${requestBody.codart}&memolig=${memolig}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "PUT",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              // body: JSON.stringify(data1)
          
            }
          );
          this.props.SelectMemo(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );




          this.setState({ snackbaropen: true, snackbarmsg: result });
          // window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
    // this.props.onHide01();
  };
  
  brutHandler = (event) => {
    const purnh = roundTo(
      event.target.value * (1 - this.state.remisefrs / 100),
      3
    );
    const pu = roundTo(purnh * (1 + this.state.marged / 100), 3);

    this.setState({
      purbrut: event.target.value,
      purnhtnet: purnh,
      purttc: roundTo(purnh * (1 + this.state.tva / 100), 3),
      pudhtt: pu,
      pudhttc: roundTo(pu * (1 + this.state.tva / 100), 3),
    });
  };

  handleKeyDown = (event) => {
    if (this.state.typfodec === "A") {
      if (event.key === "Enter") {
        const purnh = roundTo(
          Number(event.target.value) *
            (1 + Number(this.state.fodec) / 100) *
            (1 - this.state.remisefrs / 100),
          3
        );
        const pu = roundTo(purnh * (1 + this.state.marged / 100), 3);
        this.setState({
          purbrut:
            Number(event.target.value) * (1 + Number(this.state.fodec) / 100),
          purnhtnet: purnh,
          purttc: roundTo(purnh * (1 + this.state.tva / 100), 3),
          pudhtt: pu,
          pudhttc: roundTo(pu * (1 + this.state.tva / 100), 3),
        });
      }
    }
  };

  remisefrsHandler = (event) => {
    const purnh = roundTo(
      this.state.purbrut * (1 - event.target.value / 100),
      3
    );

    this.setState({
      remisefrs: event.target.value,
      purnhtnet: purnh,
      purttc: roundTo(
        this.state.purbrut *
          (1 - event.target.value / 100) *
          (1 + this.state.tva / 100),
        3
      ),
    });
  };

  margedHandler = (event) => {
    const pudh = roundTo(
      this.state.purnhtnet * (1 + event.target.value / 100),
      3
    );

    this.setState({
      marged: event.target.value,
      pudhtt: pudh,
      pudhttc: roundTo(pudh * (1 + this.state.tva / 100), 3),
    });
  };

  ttcHandler = (event) => {
    const pud = roundTo(event.target.value / (1 + this.state.tva / 100), 3);

    this.setState({
      pudhttc: event.target.value,
      pudhtt: pud,
      marged: roundTo(100 * (pud / this.state.purnhtnet - 1), 3),
    });
  };

  htHandler = (event) => {
    this.setState({
      pudhtt: event.target.value,
      pudhttc: roundTo(event.target.value * (1 + this.state.tva / 100), 3),
      marged: roundTo(100 * (event.target.value / this.state.purnhtnet - 1), 3),
    });
  };

  render() {

    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={6000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          className={`${darck ? "" : classes.root}`}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#00087E" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier Article </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body 
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <div>
            <Col  style={{ margin: "0" }}>
          

              <Form
                autocomplete="off"
                onSubmit={this.submitHandler}
                onKeyPress={(event) => {
                  if (event.which === 13 /* Enter */) {
                    event.preventDefault();
                  }
                }}
              >
               <Card style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                                borderTop: `${darck ? "" : "2px solid #fff"}`,
                                borderRight: `${darck ? "" : "2px solid #fff"}`,
                                borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                borderRadius: "5px",
                                marginLeft:"-10px",
                                marginRight:"-10px",
                              padding:"11px"
                              }}> 
                <Row form style={{ margin: "0" }}>
                  <Col md={3} style={{marginLeft:"-5px"}}>
                    <Form.Group controlId="codart">
                      <Label className="labell" style={{ color: `${darck ? "#A9A9A9" : "#fff"}` }}>
                        Code article
                      </Label>
                      <Input
                        // className="card add-input"
                        type="text"
                        name="codart"
                        defaultValue={this.props.codearticle}
                        disabled
                        style= {{
                          color: !darck ? "#fff" : null,
                          backgroundColor: darck ? "#fff" : "#172431"
                        }}

                      ></Input>
                    </Form.Group>
                  </Col>

                  <Col md={3} style={{marginLeft:"5px"}}>
                    <Form.Group controlId="codbar">
                      <Label className="labell" style={{ color: `${darck ? "#A9A9A9" : "#fff"}` }}>
                        Code à barre
                      </Label>
                      <Input
                        // className="card add-input"
                        type="text"
                        name="codbar"
                        defaultValue={this.props.codeabarrearticle}
                        style= {{
                          color: !darck ? "#fff" : null,
                          backgroundColor: darck ? "#fff" : "#172431"
                        }}
                      ></Input>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="desart">
                      <Label className="labell" style={{ color: `${darck ? "#A9A9A9" : "#fff"}` }}>
                        Désignation
                      </Label>
                      <Input
                        // className="card add-input"
                        type="text"
                        name="desart"
                        defaultValue={this.props.designationarticle}
                        style= {{
                          color: !darck ? "#fff" : null,
                          backgroundColor: darck ? "#fff" : "#172431"
                        }}
                      ></Input>
                    </Form.Group>
                  </Col>
                </Row>
                <Row form>
                  <Col md={3}>
                    <Form.Group controlId="unite">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        select
                        label="Unité"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="unite"
                        required
                        defaultValue={this.state.unite}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      >
                        {this.props.nomenclatures.nomenclatures.map(
                          (t, index) => (
                            <MenuItem key={index} value={t.code}>
                              {t.code}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="famart">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        name="famart"
                        select
                        label="Famille Article"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        defaultValue={this.state.famille}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      >
                        <MenuItem value="" />
                        {this.props.nomes.nomes.map((t, index) => (
                          <MenuItem key={index} value={t.code}>
                            {t.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="sousfam">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        select
                        label="Sous Famille"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="sousfam"
                        defaultValue={this.state.sousfam}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      >
                        <MenuItem value="" />
                        {this.props.sousfamilles.sousfamilles.map(
                          (t, index) => (
                            <MenuItem key={index} value={t.code}>
                              {" "}
                              {t.code}{" "}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="reffrs">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        input
                        label="Réf Fournisseur"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="reffrs"
                        defaultValue={this.props.reffrsarticle}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      ></TextField>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Group controlId="tva">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        select
                        label="TVA"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="tva"
                        onChange={this.tvaHandler}
                        defaultValue={this.state.tva}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      >
                        {this.state.tabletva.map((option) => (
                          <MenuItem key={option.code} value={option.label}>
                            {option.label + "%"}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Form.Group>
                  </Col>
                 

                  <Col md={3}>
                    <Form.Group controlId="typfodec">
                      <Autocomplete
                        id="include-input-in-list"
                        includeInputInList
                        className="ajouter-client-input"
                        defaultValue={{ label: this.state.typf }}
                        options={this.state.van}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, getOptionLabel) => {
                          getOptionLabel
                            ? this.setState({
                                typfodec: getOptionLabel.code,
                              })
                            : this.setState({
                                typfodec: "",
                              });
                        }}
                        renderInput={(params) => (
                          <TextField
                            // className="card add-input"
                            // {...params}
                            label="Type Fodec"
                            margin="dense"
                            variant="outlined"
                            required
                            name="typfodec"
                            fullWidth

                            InputProps={{
                              ...params.InputProps,
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            inputProps={params.inputProps}
                            id={params.id}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3}>
                    <Form.Group controlId="TDC">
                      <TextField
                        // className="card add-input"
                        label="Taux D.C"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="TDC"
                        defaultValue={this.props.tauxdcarticle}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="typdc">
                      <TextField
                        // className="card add-input"
                        id="outlined-select-currency"
                        select
                        label="Type DC"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="typdc"
                        defaultValue={this.props.typedcarticle}

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      >
                        {this.state.vann.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Form.Group>
                  </Col>

                  {/* <Col sm={3}>
                    <Form.Group controlId="codfrs">
                      <TextField
                        className="card add-input"
                        id="outlined-select-currency"
                        select
                        label="Fournisseur"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="codfrs"
                        defaultValue={this.state.codfrs}
                      >
                        <MenuItem value="" />
                        {this.state.rechs.map((option) => (
                          <MenuItem key={option.codfrs} value={option.codfrs}>
                            {option.codfrs}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Form.Group>
                  </Col> */}

                  {/* <Col sm={3}>
                    <FormGroup>
                      <Autocomplete
                        id="include-input-in-list"
                        includeInputInList
                        className="ajouter-client-input"
                        options={this.state.rechs}
                        defaultValue={{ codfrs: this.state.codfrs }}
                        getOptionLabel={(option) => option.codfrs}
                        onChange={(event, getOptionLabel) => {
                          getOptionLabel
                            ? this.setState({
                                codfrs: getOptionLabel.codfrs,
                                fodec: Number(getOptionLabel.tauxfodec),
                              })
                            : this.setState({
                                codfrs: "",
                                fodec: 0,
                              });
                        }}
                        renderInput={(params) => (
                          <TextField
                            // className="card add-input"
                            // {...params}
                            label="Fournisseur"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onFocus={() => {
                              //liste  devis par code client
                              fetch(
                                `https://www.24-crm.com/webservice/api/fournisseurs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                              )
                                .then((res) => res.json())
                                .then((result) => {
                                  this.setState({
                                    rechs: result,
                                  });
                                });
                            }}
                            name="codcli"

                            InputProps={{
                              ...params.InputProps,
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            inputProps={params.inputProps}
                            id={params.id}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
              </Card>  
              
                {/* </CardContent>
                    </Card> */}
   <br/>


           

{/* //////////////////////////////////////////////////parrtie  image  descp //////////////////////////////////////////////// */}
{this.state.ECO ? (<>  <Row sm={12}>
                 <Col style={{ margin: "0" }}>
                   <Card>
                     <CardContent 
                                           style={{
                                             backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                             borderTop: `${darck ? "" : "0px solid #fff"}`,
                                             borderRight: `${darck ? "" : "2px solid #fff"}`,
                                             borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                             borderBottom: `${darck ? "" : "0px solid #fff"}`,
                                             borderRadius: "5px",
                                           }}
                     >
                     
                    

                       <Row>
                
{/* /////////////////////// ECOMM //////////////////////// */}
                 <Col sm={3}>
                         <FormGroup style={{ overflow: "hidden" }}>
                           <Grid
                             item
                             style={{
                               marginTop: "10px",
                               color: `${darck ? "" : "#fff"}`,
                             }}
                           >
                          
                             
                             <Checkbox
                               name="Ecomm"
                               color="primary"
                               checked={this.props.Ecomm?true:this.state.Ecomm}
                               onChange={this.handleChange("Ecomm")}
                                value={this.props.Ecomm}
                               // required
                              // checked={true}  // Toujours coché
                                // value={true}    // Toujours vrai
                               style={{ color: `${darck ? "" : "#fff"}` }}
                             />
                             ECOMM
                           </Grid>
                         </FormGroup>
                       </Col>
{/* /////////////////////// CCAT ART //////////////////////// */}
                         <Col sm={3}>
                         <Form.Group controlId="CatArt">
                     <TextField
                       // className="card add-input"
                       id="outlined-select-currency"
                       select
                       label="CatArt"
                       margin="dense"
                       variant="outlined"
                       fullWidth
                       name="CatArt"
                        required
                        defaultValue={this.props.CatArt}
                         InputProps={{
                         className: !darck ? this.props.classes.input : null,
                       }}
                       InputLabelProps={{
                         shrink: true,
                         style: {
                           color: !darck ? "#fff" : null,
                         },
                       }}
                     >
                       {this.props.nomCategories.nomCategories.map(
                         (t,op) => (
                           <MenuItem  key={t.code} value={t.lib}>
                             {t.lib}
                           </MenuItem>
                         )
                       )}
                     </TextField>
                   </Form.Group>
                         </Col>
                         
                         </Row>

                         <Row>
{/* ///////////////////////TDC//////////////////////// */}
                        <Col sm={12}>
                           <Form.Group controlId="memolig">
                        
                             <TextField
                               // className="card add-input"
                               id="outlined-basic"
                               label="description"
                               variant="outlined"
                               margin="dense"
                               fullWidth
                               name="memolig"
                               defaultValue={this.props.memolgg && this.props.memolgg.length > 0 ? this.props.memolgg[0].memolig : ""}
                         //  defaultValue={memolig}
                               multiline
                               rows={2}
                               InputProps={{
                                 className: !darck
                                   ? this.props.classes.input
                                   : null,
                               }}
                               InputLabelProps={{
                                 style: {
                                   color: !darck ? "#fff" : null,
                                 },
                               }}
                             />
                              
                           </Form.Group>
                         </Col>

                       </Row>
                   
                     </CardContent>
                   </Card>
                 </Col>
               </Row>

               <br /></>):null}
                <br />
                <Card style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`, marginBottom: "10px",
                                borderTop: `${darck ? "" : "2px solid #fff"}`,
                                borderRight: `${darck ? "" : "2px solid #fff"}`,
                                borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                borderRadius: "5px",
                                marginLeft:"-10px",
                                marginRight:"-10px",
                                padding:"11px"
                              }}>
            <Row style={{ marginBottom: "-5px", marginTop: "10px" }}>
                      <Col sm={3}>
                        <FormGroup>
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid style={{ color: `${darck ? "" : "#fff"}` }}> 
                                <b></b>
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid item style={{ color: "grey" }}>
                                Raison sociale
                              </Grid>
                              <Grid item>
                                <Switch
                                  checked={this.state.giladd}
                                  onChange={this.handleChange("giladd")}
                                  value="giladd"
                                  color="primary"
                                />
                              </Grid>
                              <Grid item style={{ color: "#3f51b5" }}>
                                Code 
                              </Grid>
                            </Grid>
                          </Typography>
                        </FormGroup>
                      </Col>

                      {this.state.giladd ? (
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.props.clients.clients}
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.fournisseurs.fournisseurs
                              }
                              getOptionLabel={(option) => option.codfrs}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                      codfrs: getOptionLabel.codfrs,
                                      btnEnabled: true,
                                      showTimbre: getOptionLabel.timbre,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codfrs: this.props.client,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Code Fournisseur"
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="codcli"
                                  // InputProps={{
                                  //   ...params.InputProps,
                                  //   className: !darck
                                  //     ? this.props.classes.input
                                  //     : null,
                                  // }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.props.clients.clients}
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.fournisseurs.fournisseurs
                              }
                              getOptionLabel={(option) => option.raisoc}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                     codfrs: getOptionLabel.codfrs,
                                      btnEnabled: true,
                                      showTimbre: getOptionLabel.timbre,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codfrs: this.props.client,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Raison sociale"
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined" //variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="raissoc"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    {/* </Row>
                    <Row style={{ marginBottom: "-20px" }}> */}
                      <Col sm={2}>
                        <FormGroup>
                          
                          <TextField
                            id="standard-basic"
                            label="Code Fournisseur"
                            className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codfrs}
                            fullWidth
                            name="codcli"
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={4}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled={
                              this.state.codfrs === "100" ? false : true
                            }
                            onChange={this.raisocHandler}
                          />
                        </FormGroup>
                      </Col>

                     

                    </Row>

            </Card>
                {/* ////////////////////////////////////////// partie des liste des prix//////////////////////////////////////////////////////// */}

                <Row sm={12} >
                  <Col style={{ margin: "0" }}>
                    <Card >
                      <CardHeader
                        avatar={<MonetizationOnIcon />}
                        title="Prix De Revient"
                        style={{
                          height: "20px" ,
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                          color: `${darck ? "#020F64" : "#fff"}`,
                          borderTop: `${darck ? "" : "2px solid #fff"}`,
                          borderRight: `${darck ? "" : "2px solid #fff"}`,
                          borderLeft: `${darck ? "" : "2px solid #fff"}`,
                          borderBottom: `none`,
                        }}
                      />
                      <CardContent
                                                                  style={{
                                                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                                                    borderTop: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderRight: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderRadius: "5px",
                                                                  }}
                      >
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId="PURBHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Brut HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURBHT"
                                value={this.state.purbrut}
                                onChange={this.brutHandler}
                                onKeyDown={this.handleKeyDown}
                                //  onChange={this.brutHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="remisefrs">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="Remise Frs (%)"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="remisefrs"
                                value={this.state.remisefrs}
                                // defaultValue={this.state.remisefrs}
                                onChange={this.remisefrsHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PURNHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Net HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURNHT"
                                value={this.state.purnhtnet}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PURNTTC">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Net TTC"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURNTTC"
                                value={this.state.purttc}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* ////////////////////////////////////////////////////////////////////// */}
                     
                      
                    
                      </CardContent>
                    </Card>
                  </Col>
                </Row>
                <Row sm={12} style={{ top: "0" }} >
                  <Col style={{ margin: "0" }}>
                    <Card >
                      <CardHeader
                        avatar={<MonetizationOnIcon />}
                        title="Prix De vente"
                        style={{
                          height: "20px" ,
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                          color: `${darck ? "#020F64" : "#fff"}`,
                          borderTop: `${darck ? "" : "2px solid #fff"}`,
                          borderRight: `${darck ? "" : "2px solid #fff"}`,
                          borderLeft: `${darck ? "" : "2px solid #fff"}`,
                          borderBottom: `none`,
                        }}
                      />
                      <CardContent
                                                                  style={{
                                                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                                                    borderTop: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderRight: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                                                    borderRadius: "5px",
                                                                  }}
                      >
                     
                        {/* ////////////////////////////////////////////////////////////////////// */}
                     
                      
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId="margedet">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="Marge/ P.R Net (%)"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="margedet"
                                value={this.state.marged}
                                onChange={this.margedHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={3}>
                            <Form.Group controlId="PUDHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Vente HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PUDHT"
                                value={this.state.pudhtt}
                                onChange={this.htHandler}
                                //  defaultValue={this.props.puventeht}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PUDTTC">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Vente TTC"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PUDTTC"
                                // defaultValue={this.state.pudhttc}
                                value={this.state.pudhttc}
                                onChange={this.ttcHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/* pour modification image  */}
                          <Col sm={2}>
                            <img
                              ref={this.imageRef}
                              src={`data:image/*;base64,${this.state.photo}`}
                              alt="Pas-Photo"
                              style={{ width: '80px', height: '80px',marginTop:"-75px" ,marginLeft:"65px"}}
                            />
                          
                              <UploadImage 
                              ref={this.fileInputRef}
                              onChange={this.handleFileChange}
                              onFileSelect={this.handleFileSelect}
                              darck={this.props.darck}
                            />
                           
                          </Col>
                          
                        </Row>
                      
                      
                      </CardContent>
                     
                    </Card>
                    
                  </Col>
                 
                </Row>
               
                <br />
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Row>
                  <Col sm={8}></Col>
                  <Col sm={4}>
                    {this.state.tt === "" ? (
                      <Center>
                        <Button
                          disabled
                          style={{ width: "100%", backgroundColor: "#020F64" }}
                          variant="outlined"
                          type="submit"
                        >
                          Enregistrer
                        </Button>
                      </Center>
                    ) : (
                      <Center>
                        <Button
                          style={{ width: "100%", backgroundColor: "#020F64" }}
                          type="submit"
                        >
                          Enregistrer
                        </Button>
                      </Center>
                    )}
                   
                  </Col>
                
                </Row>
               
                         
              </Form>
              
             </Col>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectNome: (a, b, c, d, e) => dispatch(SelectNome(a, b, c, d, e)),
    SelectSousFamille: (a, b, c, d, e) =>
      dispatch(SelectSousFamille(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("UM", a, b, c, d, e)),
      SelectFournisseur: (a, b, c, d, e) =>
      dispatch(SelectFournisseur(a, b, c, d, e)),

      SelectNomCategorie: (cat, a, b, c, d, e) =>dispatch(SelectNomCategorie("CAT", a, b, c, d, e)),
      
      SelectMemo: (a, b, c, d, e) => dispatch(SelectMemo(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    nomes: state.nomes,
    sousfamilles: state.sousfamilles,
    nomenclatures: state.nomenclatures,
    ConfigDossier: state.ConfigDossier,
   darck: state.darck.darck,
   fournisseurs: state.fournisseurs,
   nomCategories:state.nomCategories,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierArticleModal));