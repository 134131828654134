import Axios from "axios";

export const GetBordereaux = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?bv&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "BordCode", payload: response.data });
    });
  };
};

