import React, { Component } from "react";
import "./GestionDesArticles.scss";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {
  getArticleList,
  getArticleHeader,
} from "../../redux/actions/ArticleActions";
import "../../App.css";
import { SelectArticle } from "../../redux/actions/GetArticles";
import AddingArticleModal from "./AddingArticleModal";
import GetArticleByIdModal from "./GetArticleByIdModal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import moment from 'moment';
import {
  Button,
  Breadcrumbs,
  InputBase,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import "./Responsiveart.css";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import translate from "../../i18n/translate";
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class FicheArticle extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      data: [],
      modal: false,
      addModalShow1: false,
      getArticleByIdModalShow: false,
      defaultdate: date,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
    };
  }
  toggle = () => this.setState({ modal: !this.state.modal });

  componentDidMount() {
    this.props.getArticleHeader();
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    document.title = "Fiche Article";
    // this.setState({ rechs: this.props.articles.articles });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  deletearticle(articleid) {
    if (window.confirm("are you sure?")) {
      fetch(
        `https://www.24-crm.com/webservice/api/ARTICLEs?${articleid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    }
  }

  rechercheHandler = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };

  toggle = () => {
    this.setState({
      icon: !this.state.icon,
    });
  };

  // componentDidUpdate() {
  //   this.props.SelectArticle();
  // }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  
  
getTotalmontant = () => {
  let totalmontant = 0;
  if(this.state.rechercheclicked){
    this.state.rechs.forEach((article) => {
      totalmontant += parseFloat(Number(article.stkfin));
  })
}
else{
  this.props.articles.articles.forEach((article) => {
    totalmontant += parseFloat(Number(article.stkfin));
  })
};
  return totalmontant; //.toFixed(3) arrondi à 4 chiffres après la virgule
};
  exportToExcel = () => {
if(this.state.rechercheclicked){
     
    // Préparation des données
    const data =  this.state.rechs.map(item => {
      const date = new Date(item.datfac);
      const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
  
        return [
           item.codart,
           item.desart,
           item.stkfin,
           item.PUDHT,
           item.tautva,
           item.PUDTTC,
         
           

            // Ajoutez d'autres champs au besoin
        ];
    });

    // Création d'un nouveau classeur
    const classeur = XLSX.utils.book_new();
    const feuille = XLSX.utils.json_to_sheet(data);

    const title = '                                  Liste des Articles';
    feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
    feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 5 }  }]; // Fusionner les cellules pour le titre
    
    
    // Définition de l'entête du tableau (deuxième ligne)
    const header = ['Code article ', 'Désignation', 'Stock', 'P.U.V HT','T.V.A (%)','P.U.V TTC'];
    XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
    // Ajout des données de tableau à partir de la cinquième ligne
    XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "ART");
   
    // Génération d'un fichier téléchargeable
    XLSX.writeFile(classeur, "Article.xlsx");
  }
  else{
   
    // Préparation des données
    const data =  this.props.articles.articles.map(item => {
      const date = new Date(item.datfac);
      const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
  
        return [
           item.codart,
           item.desart,
           item.stkfin,
           item.PUDHT,
           item.tautva,
           item.PUDTTC,
         
           

            // Ajoutez d'autres champs au besoin
        ];
    });

    // Création d'un nouveau classeur
    const classeur = XLSX.utils.book_new();
    const feuille = XLSX.utils.json_to_sheet(data);

    const title = '                               Liste des Articles';
    feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
    feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 5 }  }]; // Fusionner les cellules pour le titre
    
    
    // Définition de l'entête du tableau (deuxième ligne)
    const header = ['Code article ', 'Désignation', 'Stock', 'P.U.V HT','T.V.A (%)','P.U.V TTC'];
    XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
    // Ajout des données de tableau à partir de la cinquième ligne
    XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "ART");
   
    // Génération d'un fichier téléchargeable
    XLSX.writeFile(classeur, "Article.xlsx");
  };
   
}
  render() {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    let addModalClose1 = () => this.setState({ addModalShow1: false });
    let getModalClose = () => this.setState({ getArticleByIdModalShow: false });
    const {
      designationarticle,
      codearticle,
      codeabarrearticle,
      famillearticle,
      reffrsarticle,
      remisearticle,
      stockarticle,
      pvttcarticle,
      typefodecarticle,
      typedcarticle,
      pudht,
      unitearticle,
      tvaarticle,
      sousfamillearticle,
      tauxdcarticle,
      purevientbrut,
      remisefrs,
      purevientnetht,
      purevientnetttc,
      margeprbrut,
      margeprrnet,
      puventeht,
      puventettc,
      codtva,
      codfrs,
      photo ,
      Ecomm,
      CatArt
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const { darck } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Stock"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>

          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/fiche-article"
            label="Fiche Article"
            icon={<MenuBookIcon fontSize="small" />}
          />

        <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
          
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
        </Breadcrumbs>
        <main className="gestion-des-articles">
          {/* <div className="page-icon">
            <i className="fas fa-cube" style={{ fontSize: "25px" }}>
              {" "}
              Fiche Article
            </i>
          </div> */}
          <br />
          <div>
            <Paper
              // elevation={0.5}
              elevation={2}
              style={{
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
            >
              <Row style={{display : "flex" , flexWrap: "nowrap" , marginTop:"2px"}}>
                {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}
                <Col sm={10}>
                  <SearchIcon
                    className="search-icon"
                    color={this.state.isSearchDate ? "inherit" : "primary"}
                    onClick={() => this.setState({ isSearchDate: false })}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                  &nbsp;&nbsp;
                  {/* <Col sm={10}>
                 
                  <DateRangeIcon
                    className="search-icon"
                    color={this.state.isSearchDate ? "primary" : "inherit"}
                    onClick={() => this.setState({ isSearchDate: true })}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.isSearchDate ? (
                    <>
                      <FormLabel>Date début</FormLabel>&nbsp;&nbsp;
                      <InputBase
                        type="date"
                        // fullWidth={true}
                        placeholder="date"
                        onClick={this.toggle}
                        onChange={this.firstrechdatHandler}
                        value={this.state.firstdate}
                        defaultValue={this.state.defaultdate}
                      />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormLabel>Date Fin</FormLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <InputBase
                        type="date"
                        // fullWidth={true}
                        placeholder="date"
                        defaultValue={this.state.defaultdate}
                        onChange={this.secondrechdatHandler}
                        value={this.state.seconddate}
                      />
                    </>
                  ) : ( */}
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                  {/* )} */}
                </Col>
                {this.props.Auth.fct == "100" ? (
                  <Col sm={2} style={{ display: "flex", justifyContent: "end" }} className="buttart">
                    <Button
                      color="primary"
                      onClick={() => this.setState({ addModalShow1: true })}
                      style={{
                        color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,left:"-7px",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,
                        }}
                      />
                      Fiche Article
                    </Button>
                  </Col>
                ) : null}{" "}
              </Row>
            </Paper>
            {/* <Row>
              <Col sm="9"> */}
            {/* Recherche */}
            {/* <ConnectedSearchBar /> */}
            {/* <div className="search-bar">
                  <TextField
                    placeholder="Recherche..."
                    id="input-with-icon-textfield"
                    className="input-search"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className="search-icon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Col>

              <Col sm="3"> */}
            {/* Add second part tests // Ligs */}
            {/* <div id="" style={{ textAlign: "center" }}>
                  <button
                    className="icon-btn add-btn"
                    onClick={() => this.setState({ addModalShow1: true })}
                  >
                    <div className="add-icon"></div>
                    <div className="btn-txt">Ajouter article</div>
                  </button>
                </div>
              </Col>
            </Row> */}
            <AddingArticleModal
              show={this.state.addModalShow1}
              onHide={addModalClose1}
            />
          </div>
          <br />

          {/* <div className="articles-table"> */}
          {this.state.rechercheclicked ? (
            <div className="tabga">
              <table striped>
                {/* <thead>
                  <tr style={{ textAlign: "center" }}> */}
                {/* {this.props.DataTables.tableHeader.map((el, index) => (
                      <th key={index} className={el.className}>
                        <center>{el.title}</center>
                      </th>
                    ))} */}
                {/* <th>Code</th>

                    <th >Désignation</th>
                    <th>Stock</th>

                    <th>P.U.V HT</th>
                    <th>T.V.A (%)</th>
                    <th>P.U.V TTC</th> 
                  </tr>
                </thead> */}
                <EnhancedTableHead
                  classes={classes}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                  darck={darck}
                />
                <tbody style={{ maxHeight: "60vh" }}>
                  {/* {this.props.articles.articles
                  .filter(
                    (article) =>
                      article.codart
                        .toString()
                        .includes(this.props.SearchingResult.searching) ||
                      article.desart
                        .toLowerCase()
                        .includes(this.props.SearchingResult.searching)
                  ) */}
                  {stableSort(
                    this.state.rechs,
                    getComparator(this.state.order, this.state.orderBy)
                  ).map((article) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={article.codart}
                      onClick={() => {
                        this.setState({
                          getArticleByIdModalShow: true,
                          designationarticle: article.desart,
                          codearticle: article.codart,
                          codeabarrearticle: article.codbar,
                          famillearticle: article.famart,
                          reffrsarticle: article.reffrs,
                          remisearticle: article.remise,
                          stockarticle: article.stkfin,
                          pvttcarticle: article.PUDTTC,
                          typefodecarticle: article.typfodec,
                          typedcarticle: article.typdc,
                          pudht: article.PUDHT,
                          unitearticle: article.unite,
                          codtva: article.codtva,
                          tvaarticle: article.tautva,
                          sousfamillearticle: article.sousfam,
                          tauxdcarticle: article.TDC,
                          purevientbrut: article.PURBHT,
                          remisefrs: article.remisefrs,
                          purevientnetht: article.PURNHT,
                          purevientnetttc: article.PURNTTC,
                          margeprrnet: article.margedet,
                          margeprbrut: article.remise,
                          puventeht: article.PUDHT,
                          puventettc: article.PUDTTC,
                          photo:article.photo,
                          Ecomm:article.Ecomm,
                          CatArt:article.CatArt
                        });
                        article.codfrs === null
                          ? this.setState({ codfrs: "" })
                          : this.setState({ codfrs: article.codfrs });
                      }}
                    >
                      <td>
                        <span style={{ color: "#DF5F10" }}>
                          {article.codart} 
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.desart}</span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.stkfin}</span>
                      </td>

                      <td>
                        <span style={{ color: "green" }}>
                          {Number(article.PUDHT).toFixed(3)}
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{Number(article.tautva).toFixed(2)}</span>
                      </td>
                      <td>
                        <span style={{ color: "#0D47A1" }}>
                          {Number(article.PUDTTC).toFixed(3)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
  <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  {/* <img
    // src={altText}
    src={require("../assests/img/comaf.jpg")}
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} />
  
   */}
   <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
                            
                          />  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche Article</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("code")}</th>
                <th style={{ width: "40%" ,   border: "1px solid gray"}}>{translate(" Designation")}</th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("Stock")} </th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("P.U.V H.T")}</th> 
                 <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("TVA(%)")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("P.U.V TTC")}</th>
              
               
                </tr> 
             
                </thead>
            <tbody>
   
               {this.state.rechs.map((t, i) => (

         
                <tr 
                  key={i}
                  style={{
                    //textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// code ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "0%" ,   border: "1px solid gray"}} > 
               
               {t.codart}
                
                </td>
 {/* ///////////////////////////////////////////// designation ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "40%" ,   border: "1px solid gray"}} > 
               
          {t.desart} 
                
   </td>
{/* ///////////////////////////////////////////////// stock ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.stkfin}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// PUVHT ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.PUDHT}</span>
                   
            </td>
 {/* //////////////////////////////////////////////// TVA ///////////*/}
  <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span>{t.tautva}</span>
     
</td>
      
{/* //////////////////////////////////////////////// PUVTTC ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span style={{ color: "#0D47A1" }}>
              {Number(t.PUDTTC).toFixed(3)}
            </span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{   color: !darck ? "#fff" : null,paddingLeft: "590px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
     
            </div>
          ) : (
            <div className="tabga">
              <table striped>
                {/* <thead>
                  <tr style={{ textAlign: "center" }}> */}
                {/* {this.props.DataTables.tableHeader.map((el, index) => (
                      <th key={index} className={el.className}>
                        <center>{el.title}</center>
                      </th>
                    ))} */}
                {/* <th>Code</th>

                    <th >Désignation</th>
                    <th>Stock</th>

                    <th>P.U.V HT</th>
                    <th>T.V.A (%)</th>
                    <th>P.U.V TTC</th>
                  </tr>
                </thead> */}
                <EnhancedTableHead
                  classes={classes}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                  darck={darck}
                />
                <tbody style={{ maxHeight: "60vh" }}>
                  {stableSort(
                    this.props.articles.articles,
                    getComparator(this.state.order, this.state.orderBy)
                  ).map((article) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={article.codart}
                      onClick={() => {
                        this.setState({
                          getArticleByIdModalShow: true,
                          designationarticle: article.desart,
                          codearticle: article.codart,
                          codeabarrearticle: article.codbar,
                          famillearticle: article.famart,
                          reffrsarticle: article.reffrs,
                          remisearticle: article.remise,
                          stockarticle: article.stkfin,
                          pvttcarticle: article.PUDTTC,
                          typefodecarticle: article.typfodec,
                          typedcarticle: article.typdc,
                          pudht: article.PUDHT,
                          unitearticle: article.unite,
                          codtva: article.codtva,
                          tvaarticle: article.tautva,
                          sousfamillearticle: article.sousfam,
                          tauxdcarticle: article.TDC,
                          purevientbrut: article.PURBHT,
                          remisefrs: article.remisefrs,
                          purevientnetht: article.PURNHT,
                          purevientnetttc: article.PURNTTC,
                          margeprrnet: article.margedet,
                          margeprbrut: article.remise,
                          puventeht: article.PUDHT,
                          puventettc: article.PUDTTC,
                          photo:article.photo,
                          Ecomm:article.Ecomm,
                          CatArt:article.CatArt
                        });
                        article.codfrs === null
                          ? this.setState({ codfrs: "" })
                          : this.setState({ codfrs: article.codfrs });
                      }}
                    >
                      <td>
                        <span style={{ color: "#DF5F10" }}>
                          {article.codart}   
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.desart}</span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.stkfin}</span>
                      </td>

                      <td>
                        <span style={{ color: "green" }}>
                          {Number(article.PUDHT).toFixed(3)}
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{Number(article.tautva).toFixed(2)}</span>
                      </td>
                      <td>
                        <span style={{ color: "#0D47A1" }}>
                          {Number(article.PUDTTC).toFixed(3)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
  <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche Article</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("code")}</th>
                <th style={{ width: "40%" ,   border: "1px solid gray"}}>{translate(" Designation")}</th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("Stock")} </th>
                <th style={{ width: "5%" ,   border: "1px solid gray" }}>{translate("P.U.V H.T")}</th> 
                 <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("TVA(%)")}</th>
                <th style={{ width: "5%"  ,   border: "1px solid gray"}}>{translate("P.U.V TTC")}</th>
              
               
                </tr> 
             
                </thead>
            <tbody>
   
               {this.props.articles.articles.map((t, i) => (

         
                <tr 
                  key={i}
                  style={{
                    //textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// code ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "0%" ,   border: "1px solid gray"}} > 
               
               {t.codart}
                
                </td>
 {/* ///////////////////////////////////////////// designation ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "40%" ,   border: "1px solid gray"}} > 
               
          {t.desart} 
                
   </td>
{/* ///////////////////////////////////////////////// stock ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.stkfin}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// PUVHT ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.PUDHT}</span>
                   
            </td>
 {/* //////////////////////////////////////////////// TVA ///////////*/}
  <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span>{t.tautva}</span>
     
</td>
      
{/* //////////////////////////////////////////////// PUVTTC ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span style={{ color: "#0D47A1" }}>
              {Number(t.PUDTTC).toFixed(3)}
            </span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{   color: !darck ? "#fff" : null,paddingLeft: "530px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// FIN print etat ////////////////////////////////////////////////////////////// */}
     

            </div>
          )}
 <br />
   
   <Paper
     style={{
       backgroundColor: `${darck ? "" : "#172431"}`,
       border: `${darck ? "" : "2px solid #fff"}`,
     }}
     // elevation={0.5}
     elevation={3}
   >
     <Row style={{ flexWrap: "nowrap" }}>
       {/* <InputGroupText>
       <SearchIcon className="search-icon" color="primary" />
     </InputGroupText> */}

       <Col sm={5}>
         <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
          

           {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           <label
            style={{ color: `${darck ? "#0D47A1" : "#fff"}`
            , fontSize: "12px" }}

           >T O T A U X : </label>
         </div>
       </Col> 
       {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
       <Col sm={1} style={{ display: "flex", justifyContent: "end" ,paddingRight: "90px"}}>
       <label>

    <span style={{  color: !darck ? "#fff" : null }}>
    {this.getTotalmontant()}
</span>
</label>   

         {/* <InputGroupAddon addonType="append">
       
     </InputGroupAddon> */}
       </Col>
     </Row>
   </Paper>
   {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
    </Row>  
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
     
          <GetArticleByIdModal
            show={this.state.getArticleByIdModalShow}
            onHide={getModalClose}
            designationarticle={designationarticle}
            codearticle={codearticle}
            codeabarrearticle={codeabarrearticle}
            famillearticle={famillearticle}
            reffrsarticle={reffrsarticle}
            remisearticle={remisearticle}
            stockarticle={stockarticle}
            pvttcarticle={pvttcarticle}
            typefodecarticle={typefodecarticle}
            typedcarticle={typedcarticle}
            pudht={pudht}
            unitearticle={unitearticle}
            tvaarticle={tvaarticle}
            sousfamillearticle={sousfamillearticle}
            tauxdcarticle={tauxdcarticle}
            purevientbrut={purevientbrut}
            remisefrs={remisefrs}
            purevientnetht={purevientnetht}
            purevientnetttc={purevientnetttc}
            margeprbrut={margeprbrut}
            margeprrnet={margeprrnet}
            puventeht={puventeht}
            puventettc={puventettc}
            codtva={codtva}
            codfrs={codfrs}
            photo={photo}
            Ecomm={Ecomm}
            CatArt={CatArt}
          />
        </main>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getArticleList: (a, b, c, d, e) => {
    dispatch(getArticleList(a, b, c, d, e));
  },
  getArticleHeader: () => {
    dispatch(getArticleHeader());
  },

  SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
});
const mapStateToProps = (state) => {
  return {
    SideBarTitles: state.SideBarTitles,

    SearchingResult: state.SearchingReducer,
    Auth: state.Auth,
    DataTables: state.DataTablesReducer,
    articles: state.articles,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
};
const ConnectedFicheArticle = connect(
  mapStateToProps,
  mapDispatchToProps
)(FicheArticle);

export default ConnectedFicheArticle;

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "codart",
    numeric: false,
    disablePadding: true,
    label: "Code",
  },

  {
    id: "desart",
    numeric: true,
    disablePadding: false,
    label: "Désignation",
  },

  { id: "stkfin", numeric: false, disablePadding: false, label: "Stock" },

  {
    id: "PUDHT",
    numeric: true,
    disablePadding: false,
    label: "P.U.V HT",
  },
  { id: "tautva", numeric: true, disablePadding: false, label: "T.V.A (%)" },
  {
    id: "PUDTTC",
    numeric: true,
    disablePadding: false,
    label: "P.U.V TTC",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
