import React, { Component } from "react";
import { Modal, Card, Row, Col } from "react-bootstrap";
import "../styling/Styles.css";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { SelectFactureLig } from "../../redux/actions/GetFactureLig";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
// import MailOutlineIcon from "@material-ui/icons/MailOutline";
 import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
 import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
 import ModifierFactureFRSModal from "./ModifierFactureFRSModal";

// import ProgressBar from "../commande-client-devis/ProgressBar";

import { Divider, Fab } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import "./Responsiveff.css";
import { SelectFacture } from "../../redux/actions/GetFacture";
import SlideshowIcon from "@material-ui/icons/Slideshow";


// const roundTo = require("round-to");

const actions = [
 // { icon: <PictureAsPdfIcon />, name: "PDF" },
//  { icon: <EditIcon />, name: "Modifier" },
   { icon: <CancelPresentationIcon />, name: "Annuler" },
   { icon: <DeleteOutlineIcon />, name: "Supprimer" },
];

const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

class GetFactureFrsById extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      ligid: "",
      blid: "",
      tab: [],
      newtab: [],

      codearticle: 0,
      des: "",
      qte: 0,
      unite: 0,
      puht: 0,
      faudec: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      totalht: 0,

      totalqte: 0,
      netapayer: 0,
      clientimp: [],
    };
  }

  componentDidMount() {
    this.props.SelectFactureLig(
      this.props.blid,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  handleOpen = () => {
    this.setState({ open: true });
    fetch(
      `https://www.24-crm.com/webservice/api/Clients?codeclient=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
  };
  handleOpen = () => {
    this.setState({ open: true });
      //avoir le client de code = codeclient
    fetch(
      `https://www.24-crm.com/webservice/api/Clients?codeclient=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
 //avoir Assiette/TVA/Montant
    fetch(

      //////06/02/2024 get le donnee valide 
      // `https://www.24-crm.com/webservice/Api/tva?numfacture=${this.props.blid}&type=FF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      `https://www.24-crm.com/webservice/api/TVA/?NumFac=${this.props.blid}&TypFac=FF&CodTva=${this.props.codtva}&TauxTva=${this.props.tva}&Assiette=${this.props.puht}&Montant=${this.props.mnttva}&MntFodec=${this.props.mntfod}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
     )
      .then((response) => response.json())
      .then((data) => this.setState({ Assiett: data }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    // this.setState({ openMailModal: true });
  };

  // openModifier = () => {
  //   if (this.props.annuler === "0") {
  //     this.setState({ openModifierModal: true, blid: this.props.blid });
  //   } else {
  //     alert("Bon de Sortie annulé, il faut le réactiver");
  //   }
  // };

  openModifier = () => {
    this.setState({ openModifierModal: true, blid: this.props.blid });
    this.props.fligs.fligs.map((t) => {
      this.setState({ ligid: t.id });
    });
  };
  // openModifier = () => {
  //   this.setState({ openModifierModal: true, blid: this.props.blid });
  //   this.props.fligs.fligs.map((t) => {
  //     this.setState({ ligid: t.id });
  //   });
  // };

  activer = () => {
    // window.alert("annuler");
    this.props.annuler !== "0"
      ? fetch(
          `https://www.24-crm.com/webservice/api/FacFrs?idD=${this.props.blid}&typfacC=FF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.onHide();
            this.props.SelectFacture(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then(() => window.location.reload())
      : window.alert("Facture déja activé");
  };

  annuler = () => {
    this.props.annuler === "0"
      ? fetch(
          `https://www.24-crm.com/webservice/Api/FacFrs?idd=${this.props.blid}&typfaccs=FF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.onHide();
            this.props.SelectFacture(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
          })
          .then(() => window.location.reload())
      : window.alert("Facture déja annulée");
  };
  imprimer = () => {
    window.alert("imprimer");
  };

  supprimer = () => {
    if (
      window.confirm("êtes-vous sûr de vouloir supprimer ce Facture?")
    ) {
      fetch(
        `https://www.24-crm.com/webservice/Api/FacFrs/${this.props.blid}?typfacc=FF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      fetch(
        `https://www.24-crm.com/webservice/Api/FacFrs/${this.props.blid}?typfacc=FF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectFacture(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          this.setState({ snackbaropen: true, snackbarmsg: result });
        })
        .then(() => window.location.reload());
    }
  };
  nonsupprimer = () => {
    window.alert(
      "Vous devez annuler ce Facture pour que vous puissiez le supprimer"
    );
  };
  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });

    const { ligidd, blidd, tabb } = this.state;

    const { darck } = this.props;
    const actions = [
    //  { icon: <EditIcon />, name: "Modifier" },
    //  { icon: <PrintIcon />, name: "Imprimer" },
      {
        icon:
          this.props.annuler === "0" ? (
            <CancelPresentationIcon />
          ) : (
        //     <SlideshowIcon />
        //   ),
        // name: this.props.annuler === "0" ? "Annuler" : "Activer",
        <DeleteOutlineIcon />
      ),
    name: this.props.annuler === "0" ? "Annuler" : "Supprimer",
      },
    //  { icon: <DeleteOutlineIcon />, name: "Supprimer" },
    ];


    return (
      <div className="container">
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{ 
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
             }}
            
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                Facture N° {this.props.blid} du{" "}
                {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}{" "}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `none`,
                      }}
          >
            <Card>
              <Card.Body
                              style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                              }}
              >
                {this.props.MG ? (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Fournisseur</Label>
                      </Typography>

                      <Typography style={{ color: `${darck ? "grey" : "#fff"}` ,  fontSize: "18px"  }}>
                        {this.props.client}{" "}
                        {
                          //fournisseur
                        }
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                          Raison Sociale
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "grey" : "#fff"}`, fontSize: "18px" }}>
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Magasin</Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}` , fontSize: "18px" }}>
                        {this.props.MG}
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={6}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>Fournisseur</Label>
                      </Typography>

                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                        {this.props.client}{" "}
                        {
                          //fournisseur
                        }
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={6}>
                      <Typography variant="h6" component="h2">
                        <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                          Raison Sociale
                        </Label>
                      </Typography>
                      <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body                 
                 style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}>
                <div className="tabbf2">
                  <table>
                    <thead style={{ textAlign: "center", fontSize: "12px" }}>
                      <tr>
                        <th>PU HT</th>
                        <th>Fodec</th>
                        <th>TVA</th>
                        <th>Montant TVA</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                      {this.props.tabtab.map((t) => (
                        <tr style={{ textAlign: "center" }}>
                          <td >
                            {" "}
                            <span> {Number(t.Assiette).toFixed(3)}</span>
                          </td>
                          <td>
                            {" "}
                            <span> {Number(t.MntFodec).toFixed(3)}</span>
                          </td>
                          <td>
                            {" "}
                            <span> {Number(t.TauxTva).toFixed(2)}</span>
                          </td>
                          <td>
                            {" "}
                            <span> {Number(t.Montant).toFixed(3)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="ff1">
              <Card.Body
                              style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                              }}
              >
                <Row style={{ marginBottom: "-10px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Brut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total Fodec
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}`}}>
                      {Number(this.props.totalfod).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        this.props.totalttc - this.props.valtimbre
                      ).toFixed(3)}
                    </p>{" "}
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur de timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.valtimbre).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.netapayer)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}

            <Card style={{ marginTop: "10px" }} id="ff2">
              <Card.Body style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}>
                <Row className="rowff">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Brut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total Fodec
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalfod).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="ff">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowff">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        this.props.totalttc - this.props.valtimbre
                      ).toFixed(3)}
                    </p>{" "}
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur Timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.valtimbre).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="ff">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowff">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.netapayer)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "10px",
                  }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                       // action.name == "Modifier" && this.openModifier();
                        action.name == "Supprimer" &&
                          this.props.annuler === "1" &&
                          this.supprimer();
                        action.name == "Supprimer" &&
                          this.props.annuler === "0" &&
                          this.nonsupprimer();
                        action.name == "Annuler" && this.annuler();
                        action.name == "Activer" && this.activer();
                       // action.name == "Imprimer" && this.imprimer();
                      }}
                    />
                  ))}
                  {/* {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )} */}
                </SpeedDial>
              </Col>
            </Row>
            <ModifierFactureFRSModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              ligidd={this.state.ligid}
              blidd={this.state.blid}
              tabb={this.state.newtab}
            />
           
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: "none",
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          ></Modal.Footer>
        </Modal>

        {/* /////////////////// print //////////////////////// */}

        {/* <div style={{ display: "none" }}>
          <div
            ref={(el) => (this.componentRef = el)}
            style={{ margin: "15px", height: "98%" }}
          >
            <h3>Société {this.props.InfoEntreprise[0].coddos}</h3>
            <p>
              {this.props.InfoEntreprise[0].spe}
              <br />
              Adresse : {this.props.InfoEntreprise[0].adr} <br />
              Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
              {this.props.InfoEntreprise[0].tel2} / Email:{" "}
              {this.props.InfoEntreprise[0].email} <br />
             Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
              RIB: {this.props.InfoEntreprise[0].rib}{" "}
              {this.props.InfoEntreprise[0].matban}
              {this.props.InfoEntreprise[0].agence}{" "}
              {this.props.InfoEntreprise[0].ville}
            </p>
            <h3
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              ---------- Facture № {this.props.numfacfrs}{" "}
              -------------------------------------
            </h3>
            <Row>
              <Col>
                <h4 style={{ marginLeft: "170px" }}>
                  Date:{" "}
                  {new Date(this.props.datebl).toLocaleDateString(
                    "fr",
                    DATE_OPTIONS
                  )}
                </h4>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>Code Fournisseur : {this.props.client}</h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>{t.ville}</h5> : <h5>--</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6>Code TVA : --</h6>
                  ) : (
                    <h6>Code TVA : {t.codtva}</h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginRight: "50px",
                marginTop: "10px",
                width: "99%",
                minHeight: "620px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "1px",
                  width: "100%",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <tr>
                    <th>Code</th>
                    <th style={{ width: "37%" }}>Désignation</th>
                    <th>Quantité</th>
                    <th>PUHT</th>
                    <th>Remise</th>
                    <th>TVA</th>
                    <th>TotalHT</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        height: "50px",
                      }}
                    >
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td>{t.quantite}</td>
                      <td>
                        <span>{t.unite}</span>
                      </td>
                      <td>{Number(t.priuni).toFixed(3)}</td>

                      <td>{Number(t.remise).toFixed(2)}</td>
                      <td>{Number(t.tautva).toFixed(2)}</td>

                      <td>{Number(t.montht).toFixed(3)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                <table
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    width: "450px",
                    borderWidth: "1px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    <tr>
                      <th>T.V.A</th>
                      <th>Assiette</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "50px" }}>
                      <td>
                        {Number(
                          (Number(this.props.totaltva) /
                            Number(this.props.totalhtnet)) *
                            100
                        ).toFixed(2)}{" "}
                        %
                      </td>
                      <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                      <td>{Number(this.props.totaltva).toFixed(3)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b>Total quantité: </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp;Total.H.T Net:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Total TVA:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Timbre fiscal:
                  </td>
                  <td>{this.props.droitdetimbre}</td>
                  <td>{Number(this.props.valtimbre).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Net à Payer:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(this.props.totalttc).toFixed(3)}{" "}
                    {Number(parseFloat(this.props.netapayer).toFixed(3))}
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <br />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "350px",
                  marginLeft: "5px",
                  borderWidth: "1px",
                  height: "100px",
                  borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;Notes:
              </div>

              <div
                style={{
                  borderStyle: "solid",
                  width: "350px",
                  borderWidth: "1px",
                  height: "100px",
                  borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;Signature Client:
              </div>

              <div
                style={{
                  borderStyle: "solid",
                  width: "340px",
                  marginRight: "10px",
                  borderWidth: "1px",
                  height: "100px",
                  borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;Signature & cachet:
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFactureLig: (num, a, b, c, d, e) =>
      dispatch(SelectFactureLig(num, a, b, c, d, e)),
      SelectFacture: (num, a, b, c, d, e) =>
      dispatch(SelectFacture(num, a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fligs: state.fligs,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetFactureFrsById);

