import Axios from "axios";

export const SelectRE = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BEREs?type=RE&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "RE_SELECT", payload: response.data });
    });
  };
};
