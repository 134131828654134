import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Alert } from "react-bootstrap";
import "../styling/Styles.css";
import "./facture.scss";

import { TextField, RadioGroup, FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { Button } from "reactstrap";

import Tooltip from "@material-ui/core/Tooltip";

// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// import AddClientPassagerModal from "../commande-client-devis/AddClientPassagerModal";
import { SelectFacCod } from "../../redux/actions/GetCodFac";
import Radio from "@material-ui/core/Radio";
import AddFactureAvecCumulQte from "./AddFactureAvecCumulQte";
import AddFactureSansCumulQte from "./AddFactureSansCumulQte";
import { withStyles } from "@material-ui/styles";
// const roundTo = require("round-to");

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddFactureCumulee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      btnEnable: false,
      btnEnabled: false,
      avance: 0,
      cemail: "",
      openActionModal: false,
      ChoixRadio: "",
      showModalRadio: false,
      valtimbre: 0,
      regimecli: 0,
      listebl: [],
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacDevis(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFacCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    avance,
    btnEnabled
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      avance: avance,
      btnEnabled: btnEnabled,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  ajouter = () => {
    this.setState({ showModalRadio: true });
  };
  AnnulerAjout = () => {
    this.props.onHide();
    this.setState({
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      ChoixRadio: "",
      remiseglobal: "",
      valtimbre: 0,
      regimecli: 0,
    });
  };
  render() {
    // const { dvnumfac, dvraisoc, rem, clientmail } = this.state;
    const { darck, classes } = this.props;

    let addModalClose1 = () => {
      this.setState({
        showModalRadio: false,
      });
    };
    // let ligModalClose = () => this.setState({ ligModalShow: false });

    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter une Facture Cumulée</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form autocomplete="off" className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    borderTop: `${darck ? "" : "2px solid #fff"}`,
                    borderRight: `${darck ? "" : "2px solid #fff"}`,
                    borderLeft: `${darck ? "" : "2px solid #fff"}`,
                    borderBottom: `${darck ? "" : "2px solid #fff"}`,
                    borderRadius: "5px",
                  }}
                >
                  <Row form>
                    {/* <Col sm={3}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Code Facture"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          name="codfacs"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col> */}

                    <Col sm={4}>
                      <FormGroup>
                        {this.state.showButtonModalPassager ? (
                          <Tooltip title="Ajouter les détails du client passager">
                            <Button
                              style={{
                                backgroundColor: "#3f51b5",
                                color: "white",
                                fontWeight: "bold",
                                width: "100%",
                                marginTop: "20px",
                                height: "40px",
                              }}
                              onClick={() =>
                                this.setState({
                                  addModalShow1: true,
                                  dvnumfac: this.props.numfac.numfac.map(
                                    (numf) => parseInt(numf.valeur, 10) + 1
                                  ),
                                  dvraisoc: this.state.raisonsocial,
                                })
                              }
                            >
                              Détails Client Passager
                            </Button>
                          </Tooltip>
                        ) : null}
                        {/* <AddClientPassagerModal
                          show={this.state.addModalShow1}
                          onHide={addModalClose1}
                          dvnumfac={dvnumfac}
                          dvraisoc={dvraisoc}
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row style={{ marginBottom: "-15px" }}>
                                        <Col sm={4}>
                                            <Label><b>Chercher client par:</b></Label>
                                        </Col>
                                    </Row> */}

                  <Row form>
                  <Col sm={4}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        type="date"
                        fullWidth
                        name="remise"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    {/* <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              Raison sociale 
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.gilad}
                                onChange={this.handleChange("gilad")}
                                value="gilad"
                                color="primary"
                              />
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col> */}
                    <Col sm={4}>
                      <FormGroup>
                        {this.state.gilad ? (
                          // <Autocomplete
                          //     id="include-input-in-list"
                          //     includeInputInList
                          //     className="ajouter-client-input"
                          //     options={this.props.clients.clients}
                          //     getOptionLabel={option => option.codcli}
                          //     onChange={(event, getOptionLabel) => {
                          //         getOptionLabel
                          //             ? this.setState({
                          //                 raisonsocial: getOptionLabel.raisoc,
                          //                 remiseg: getOptionLabel.remise,
                          //                 codeclient: getOptionLabel.codcli,
                          //                 categoriefiscale: getOptionLabel.catfisc,
                          //                 btnEnable: true,
                          //                 showTimbre: getOptionLabel.timbre,
                          //                 showForfitaire: getOptionLabel.regimecli,
                          //                 showButtonModalPassager:
                          //                     getOptionLabel.passager,
                          //                 cemail: getOptionLabel.email
                          //             })
                          //             : this.setState({
                          //                 raisonsocial: "",
                          //                 remiseg: 0,
                          //                 codeclient: "",
                          //                 categoriefiscale: "",
                          //                 btnEnable: false,
                          //                 showTimbre: false,
                          //                 showForfitaire: 0,
                          //                 showButtonModalPassager: false
                          //             });
                          //     }}
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.props.clients.clients}
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? fetch(
                                    `https://www.24-crm.com/webservice/api/BLBRs?codclii=${getOptionLabel.codcli}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((response) => response.json())

                                    .then((res) => {
                                      this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        remiseg: getOptionLabel.remise,
                                        codeclient: getOptionLabel.codcli,
                                        categoriefiscale:
                                          getOptionLabel.catfisc,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        regimecli: getOptionLabel.regimecli,
                                        cemail: getOptionLabel.email,
                                        listebl: res,
                                      });
                                    })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    regimecli: 0,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                    showTimbre: 1,
                                  })
                                : this.setState({
                                    valtimbre: Number(0).toFixed(3),
                                    showTimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        ) : (
                          // <Autocomplete
                          //     id="include-input-in-list"
                          //     includeInputInList
                          //     className="ajouter-client-input"
                          //     options={this.props.clients.clients}
                          //     getOptionLabel={option => option.raisoc}
                          //     onChange={(event, getOptionLabel) => {
                          //         getOptionLabel
                          //             ? this.setState({
                          //                 raisonsocial: getOptionLabel.raisoc,
                          //                 remiseg: getOptionLabel.remise,
                          //                 codeclient: getOptionLabel.codcli,
                          //                 categoriefiscale: getOptionLabel.catfisc,
                          //                 btnEnable: true,
                          //                 showTimbre: getOptionLabel.timbre,
                          //                 showForfitaire: getOptionLabel.regimecli,
                          //                 showButtonModalPassager:
                          //                     getOptionLabel.passager,
                          //                 cemail: getOptionLabel.email
                          //             })
                          //             : this.setState({
                          //                 raisonsocial: "",
                          //                 remiseg: 0,
                          //                 codeclient: "",
                          //                 categoriefiscale: "",
                          //                 btnEnable: false,
                          //                 showTimbre: false,
                          //                 showForfitaire: 0,
                          //                 showButtonModalPassager: false
                          //             });
                          //     }}
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.props.clients.clients}
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    regimecli: getOptionLabel.regimecli,
                                    cemail: getOptionLabel.email,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                    showTimbre: 1,
                                  })
                                : this.setState({
                                    valtimbre: Number(0).toFixed(3),
                                    showTimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      {this.state.gilad ? (
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codecli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col sm={4}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        type="date"
                        fullWidth
                        name="remise"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col> */}

                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              Raison sociale 
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.gilad}
                                onChange={this.handleChange("gilad")}
                                value="gilad"
                                color="primary"
                              />
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <TextField
                        id="standard-basic"
                        label="Cat Fiscale"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        fullWidth
                        name="categoriefiscale"
                        value={this.state.categoriefiscale}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    <Col sm={2} id="cat">
                      {this.state.categoriefiscale === "0" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Assujetti{" "}
                        </p>
                      ) : this.state.categoriefiscale === "1" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Non Assujetti{" "}
                        </p>
                      ) : this.state.categoriefiscale === "2" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Exonéré TVA/FODEC{" "}
                        </p>
                      ) : this.state.categoriefiscale === "3" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Exonéré TVA{" "}
                        </p>
                      ) : this.state.categoriefiscale === "4" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Suspenssion{" "}
                        </p>
                      ) : this.state.categoriefiscale === "5" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Cession à Quai{" "}
                        </p>
                      ) : this.state.categoriefiscale === "6" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Réduction TVA{" "}
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Cat Fiscale{" "}
                        </p>
                      )}
                    </Col>

                    <Col sm={4}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        fullWidth
                        name="remise"
                        value={this.state.remiseg}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "20px" }}>
                        {this.state.showTimbre ? (
                          <Alert
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                            variant={"success"}
                          >
                            <b style={{ marginTop: "-10px" }}></b>Timbre✔
                          </Alert>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "20px" }}>
                        {this.state.showForfitaire === 1 ? (
                          <Alert
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                            variant={"success"}
                          >
                            <b style={{ marginTop: "-10px" }}></b>Forfitaire
                          </Alert>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col sm={1}></Col>
                    <Col
                      sm={5}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        disableElevation
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <AddIcon />
                        Ajout Facture au comptant
                      </Button>
                    </Col>

                    <Col
                      sm={5}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        disableElevation
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <AddIcon />
                        Ajout Facture Cumulée
                      </Button>
                    </Col> */}
                    <Col
                      sm={8}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={this.state.ChoixRadio}
                        onChange={(e) => {
                          this.setState({
                            ChoixRadio: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="AvecCumulQte"
                          control={<Radio color="primary" />}
                          label="Plusieurs Bons /facture (Avec Cumul des Quantitées)"
                          style={{ color: !darck ? "#fff" : null }}
                        />
                        <FormControlLabel
                          value="SansCumulQte"
                          control={<Radio color="primary" />}
                          label="Plusieurs Bons /facture (Avec Détail des Bons)"
                          style={{ color: !darck ? "#fff" : null }}
                        />
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ offset: 8 }}>
                      {this.state.codeclient && this.state.ChoixRadio ? (
                        <Button color="primary" outlined onClick={this.ajouter}>
                          Ajouter
                        </Button>
                      ) : (
                        <Button outlined onClick={this.ajouter} disabled>
                          Ajouter
                        </Button>
                      )}

                      <Button
                        color="danger"
                        outlined
                        onClick={this.AnnulerAjout}
                        style={{ marginLeft: "2%" }}
                      >
                        Annuler
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}
              {/* 
              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header style={{ height: "60px" }}>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{ fontSize: "16px" }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1}>
                            {this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}

                            <LigFactureArticle
                              submitHandler={this.submitHandler}
                              show={this.state.ligModalShow}
                              onHide={ligModalClose}
                              rem={rem}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="tab28">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Article</th>
                              <th>Désignation</th>
                              <th>Quantité</th>
                              <th>Unité</th>
                              <th>PU HT</th>
                              <th>Fodec</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>TotalHT</th>
                              <th>PUNet</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td>{t.codearticle}</td>
                                <td style={{ fontSize: "12px" }}>{t.des}</td>
                                <td>{t.qte}</td>
                                <td>{t.unite}</td>
                                <td>{t.puht}</td>
                                <td>
                                  {t.faudec === "A" ? (
                                    <span>✔</span>
                                  ) : (
                                    <span>Ø</span>
                                  )}
                                </td>
                                <td>{t.remisea}</td>
                                <td>{t.tva}</td>
                                <td>{t.totalht}</td>
                                <td>{t.puttcnet}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}

              {/* <Table>
                                                <Thead style={{ textAlign: "center" }}>
                                                    <Tr>
                                                        <Th style={{ width: "10%" }}>Article</Th>
                                                        <Th style={{ width: "20%" }}>Dés</Th>
                                                        <Th style={{ width: "10%" }}>Quantité</Th>
                                                        <Th style={{ width: "10%" }}>Unité</Th>
                                                        <Th style={{ width: "10%" }}>PU HT</Th>
                                                        <Th style={{ width: "7%" }}>Fodec</Th>
                                                        <Th style={{ width: "5%" }}>Remise</Th>
                                                        <Th style={{ width: "5%" }}>TVA</Th>
                                                        <Th style={{ width: "10%" }}>Total HT</Th>
                                                        <Th style={{ width: "13%" }}>PUTTC Net</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody style={{ textAlign: "center" }}>
                                                    {this.state.tab.map(t => (
                                                        <Tr>
                                                            <Td>{t.codearticle}</Td>
                                                            <Td>{t.des}</Td>
                                                            <Td>{t.qte}</Td>
                                                            <Td>{t.unite}</Td>
                                                            <Td>{t.puht}</Td>
                                                            <Td>
                                                                {t.faudec === "A" ? (
                                                                    <span>✔</span>
                                                                ) : (
                                                                        <span>Ø</span>
                                                                    )}
                                                            </Td>
                                                            <Td>{t.remisea}</Td>
                                                            <Td>{t.tva}</Td>
                                                            <Td>{t.totalht}</Td>
                                                            <Td>{t.puttcnet}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table> */}
              {/* </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}

              {/* //////////////// Footer //////////////////// */}

              {/* <Card style={{ marginTop: "10px" }}>
                <Card.Body>
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Total HT Brut
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.totalhtbrut, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Remise Article
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.sumremisearticle, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Total TVA
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.totaltva, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Total Quantité
                      </p>
                      <p style={{ color: "black" }}>{this.state.totalqte}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Total HT Net
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.totalhtnet, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Remise Globale
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.remiseglobal, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Total TVA
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.totaltva, 3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ color: "grey", marginBottom: "-5px" }}>
                        Net à Payer
                      </p>
                      <p style={{ color: "black" }}>
                        {roundTo(this.state.netapayer, 3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card> */}
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
        {this.state.ChoixRadio === "AvecCumulQte" && (
          <AddFactureAvecCumulQte
            timbre={this.state.showTimbre}
            valtimbre={Number(this.state.valtimbre)}
            raisonsocial={this.state.raisonsocial}
            defaultdate={this.state.defaultdate}
            categoriefiscale={this.state.categoriefiscale}
            codeclient={this.state.codeclient}
            listebl={this.state.listebl}
            show={this.state.showModalRadio}
            onHide={addModalClose1}
            showmodal1={this.props.onHide}
          />
        )}
        {this.state.ChoixRadio === "SansCumulQte" && (
          <AddFactureSansCumulQte
            timbre={this.state.showTimbre}
            valtimbre={this.state.valtimbre}
            raisonsocial={this.state.raisonsocial}
            defaultdate={this.state.defaultdate}
            categoriefiscale={this.state.categoriefiscale}
            codeclient={this.state.codeclient}
            listebl={this.state.listebl}
            show={this.state.showModalRadio}
            onHide={addModalClose1}
            showmodal1={this.props.onHide}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectFacCod: (a, b, c, d, e) => dispatch(SelectFacCod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    numfac: state.numfac,
    articles: state.articles,
    codfacs: state.codfacs,
    ConfigDossier: state.ConfigDossier,
    valtimbres: state.valtimbres,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddFactureCumulee));
