import React from "react";
import {
  Card,
  CardContent,
  Fab,
  Grid,
  InputBase,
  CardActionArea,
  IconButton,
  Divider,
} from "@material-ui/core";
import {
  AddPhotoAlternate as AddPhotoAlternateIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  // Vos styles ici
});

class UploadImage extends React.Component {
  state = {
    mainState: "initial",
    imageUploaded: 0,
    selectedFile: null,
  };

  handleUploadClick = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        selectedFile: reader.result,
      });
    };

    reader.readAsDataURL(file);

    this.setState({
      mainState: "uploaded",
      imageUploaded: 1,
    });
  };

  handleSearchURL = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        selectedFile: reader.result,
      });
    };

    reader.readAsDataURL(file);

    this.setState({
      mainState: "uploaded",
      imageUploaded: 1,
    });
  };

  handleImageSearch = () => {
    // Logique de recherche ici
  };

  handleSeachClose = () => {
    this.setState({
      mainState: "initial",
    });
  };

  renderInitialState() {
    const { classes } = this.props;
    return (
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.handleUploadClick}
          />
          <label htmlFor="contained-button-file">
            <Fab component="span" className={classes.button}>
              <AddPhotoAlternateIcon />
            </Fab>
          </label>
        </Grid>
      </CardContent>
    );
  }

  renderSearchState() {
    const { classes } = this.props;
    return (
      <>
        <InputBase className={classes.searchInput} placeholder="Image URL" />
        <IconButton
          className={classes.button}
          aria-label="Search"
          onClick={this.handleImageSearch}
        >
          <SearchIcon />
        </IconButton>
        <Divider className={classes.searchDivider} />
        <IconButton
          color="primary"
          className={classes.secondaryButton}
          aria-label="Close"
          onClick={this.handleSeachClose}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  }

  renderUploadedState() {
    const { classes } = this.props;
    return (
      <>
        <CardActionArea onClick={this.imageResetHandler}>
          <img
            width="50%"
            height="80%"
            className={classes.media}
            src={this.state.selectedFile}
            alt="Uploaded"
          />
        </CardActionArea>
      </>
    );
  }

  imageResetHandler = () => {
    console.log("Click!");
    this.setState({
      mainState: "initial",
      selectedFile: null,
      imageUploaded: 0,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Card className={this.props.cardName} style={{ height: "80%" }}>
          {this.state.mainState === "initial" && this.renderInitialState()}
          {this.state.mainState === "search" && this.renderSearchState()}
          {this.state.mainState === "uploaded" && this.renderUploadedState()}
        </Card>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UploadImage);
