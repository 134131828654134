import Axios from "axios";

export const SelectBFCod = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/bCDVCLIs?typpe=BF&codep=&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "BF_COD", payload: response.data });
    });
  };
};
