import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Carousel, Alert } from "react-bootstrap";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import translate from "../i18n/translate";
import { LOCALES } from "../i18n";
import Switch from "@material-ui/core/Switch";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { DarckModeAction } from "../redux/actions/DarckModeAction";
import { Avatar, Box, Grid, TextField, Typography } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import imagebackground from "./WhiteB2.jpg";
import Darckimagebackground from "./darck.jpg";
import logo from "./logo24.png";
import logoDarck from "./white24crm.png";
import { connect } from "react-redux";
import { GetDBFolder } from "../redux/actions/GetDBFolders";
import "./login.css";
import { showLoader, hideLoader, AuthUser } from "../redux/actions/AuthAction";
import { setDataBase, setDossier } from "../redux/actions/SetDossier";
import InfoEntreprise from "../redux/actions/InfoEntreprise";
import Flag from "react-world-flags";
import { withStyles } from "@material-ui/styles";
import {Checkbox,IconButton,FormHelperText,} from "@material-ui/core";
import { Form } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";


 //fonction pour crypter et decrypter // 
/*function  securit  (str  )  {  
  var str1 ="";
   for (var i = 0; i < str.length; i++) {
     var code = str.charCodeAt(i)aze;
     str1=str1+ String.fromCharCode(code);
     }
     return str1;
   };
*/


const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});
class Login extends Component {
// Function pour eye de mot de passe 
state = {
  isPasswordShown: false,
  recaptchaToken: "", // Store the reCAPTCHA token
};

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
//


  constructor(props) {
    const token = localStorage.getItem("token");
    const fct = localStorage.getItem("fct");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    super(props);
    this.state = {
      username: "",
      password: "",
      loggedIn,
      code: "",
      coddos: "",
      snackbaropen: false,
      coddeps: [],
      fct: "",
      serveur: "",
      utilistateur: "",
      pwd_serveur: "",
      statut: "",
      captchaToken:null // Ajoutez cette ligne
    };
  }
  onCaptchaChange = (token) => {
    this.setState({ captchaToken: token });
  };

  handleChangeDarckMode = () => {
    this.setState({ darckMode: !this.state.darckMode });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  UserHandler = (event) => {
    this.setState({ username: event.target.value });
  };
  DossierHandler = (e) => {
    this.setState({
      coddos: e.target.value,
    });
  };
  componentDidMount() {
    this.props.GetDBFolder();
    this.snackbarClose();
  }

  submitForm = (event) => {
    event.preventDefault(event);
     // Vérifiez si le CAPTCHA est complété
  if (!this.state.captchaToken) {
    this.setState({
      snackbaropen: true,
      snackbarmsg: "Veuillez compléter le CAPTCHA"
    });
    return;
  }
  

  
  // Ajoutez le token CAPTCHA aux requêtes si nécessaire
  // fetch(`https://www.24-crm.com/webservice/api/Auth?coddos=${this.state.coddos}&captchaToken=${this.state.captchaToken}`, { ...
  
  // Votre logique de soumission existante
  fetch(`https://www.24-crm.com/webservice/api/Auth?coddos=${this.state.coddos}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((result) => {
      if (!result.includes(null)) {
        localStorage.setItem("serveur", result[0]);
        localStorage.setItem("utilisateur", result[1]);
        localStorage.setItem("pwd_serveur", result[2]);
        this.setState({
          serveur: result[0],
          utilistateur: result[1],
          pwd_serveur: result[2],
        });

        this.props.setDossier(
          this.state.coddos,
          result[0],
          result[1],
          result[2]
        );

        fetch(`https://www.24-crm.com/webservice/api/Dossier?coddos=${this.state.coddos}&serveur=${result[0]}&user=${result[1]}&pwd=${result[2]}&database=PSDOSSIER`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            this.props.InfoEntreprise(data);
            this.props.setDataBase(data[0].BDsql);
            localStorage.setItem("BDsql", data[0].BDsql);

            // Appel à l'API de sauvegarde après connexion réussie
            fetch(`https://www.24-crm.com/webservice/api/Auth?coddossback=${this.state.coddos}&serveur=${result[0]}&user=${result[1]}&pwd=${result[2]}&database=PSDOSSIER`)
              .then((backupRes) => backupRes.text())
              .then((backupMessage) => {
                console.log(backupMessage);
              })
              .catch((backupError) => {
                console.error("Erreur lors de la sauvegarde :", backupError);
              });
          });

        fetch(`https://www.24-crm.com/webservice/api/Vendeur?nomm=${this.state.username}&coddos=${this.state.coddos}&serveur=${result[0]}&user=${result[1]}&pwd=${result[2]}&database=PSDOSSIER`)
          .then((response) => response.json())
          .then((data) =>
            this.setState({
              coddeps: data,
              fct: data.map((t) => t.grp),
              code: data.map((t) => t.code)[0],
              statut: data.map((t) => t.statut)[0],
            })
          )
          .then((res) => {
            if (this.state.statut) {
              fetch(`https://www.24-crm.com/webservice/api/Auth?code=${this.state.code}&nom=${this.state.username}&mp=${this.state.password}&grp=${this.state.fct}&NBS=&CodeDep=&mps=&remise=&coddos=${this.state.coddos}&serveur=${result[0]}&user=${result[1]}&pwd=${result[2]}&database=PSDOSSIER`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .then(
                  (result) => {
                    this.props.hideloader();
                    if (result.Status == "Invalid")
                      this.setState({
                        snackbaropen: true,
                        snackbarmsg: translate("Invalide login ou mot de passe"),
                      });
                    else {
                      localStorage.setItem("token", `abcd${this.state.username}1234ghqsd`);
                      this.props.AuthUser(
                        this.state.username,
                        this.state.code,
                        this.state.fct,
                        `abcd${this.state.username}1234ghqsd`
                      );

                      this.setState({ loggedIn: true });
                    }
                  },
                  (error) => {
                    this.setState({
                      snackbaropen: true,
                      snackbarmsg: "failed",
                    });
                  }
                );
            } else {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Compte Désactivé!",
              });
            }
          });
      } else {
        this.setState({
          snackbaropen: true,
          snackbarmsg: translate("vérifier votre code Dossier"),
        });
      }
    });
};

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  render() {
// Déclaration pour eye de mot de passe !
    const { isPasswordShown } = this.state;

    const { darck, classes } = this.props;
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#0c0f4e",
            "&$checked": {
              color: "#fff",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#0c0f4e",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#fff",
            },
          },
        },
      },
    });

    if (this.state.loggedIn) {
      return (
        <Redirect
          to={this.props.Auth.fct == "100" ? "/homepage" : "/dashboard"}
        />
      );
    }
  
    return (
      <div
        style={{
          backgroundImage: `url(${
            darck ? imagebackground : Darckimagebackground
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert onClose={this.snackbarClose} variant={"danger"}>
            {this.state.snackbarmsg}
          </Alert>
        </Snackbar>
        <div className="log-in">
          <Grid container component="main">
            <Box
              component={Grid}
              item
              sm={6}
              md={7}
              display={{ xs: "none", sm: "block" }}
            >
              <Carousel>
                <Carousel.Item>
                  <div
                    style={{
                      marginTop: "20%",
                      textAlign: "center",
                      height: "500px",
                    }}
                  >
                    {darck ? (
                      <img className="logo" src={logo} />
                    ) : (
                      <img className="logo" src={logoDarck} />
                    )}
                    <br></br>
                    {/* <h1>{t("this_is_an_example")}</h1> */}

                    <pml
                      style={{
                        color: `${darck ? "#0c0f4e" : "#fff"}`,
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {translate("GESTION COMMERCIALE")}
                    </pml>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    style={{
                      marginTop: "20%",
                      textAlign: "center",
                      height: "500px",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "#0c0f4e" : "#fff"}`,
                        fontSize: "50px",
                      }}
                    >
                      {translate("Gérez vos affaires")}
                    </p>
                    <ul
                      style={{
                        color: `${darck ? "#0c0f4e" : "#fff"}`,
                        fontSize: "30px",
                        marginLeft: "50px",
                      }}
                    >
                      <li>
                        <pml style={{ color: "#ff4500" }}>✔</pml>
                        &nbsp;&nbsp;{translate("Vente")}
                      </li>
                      <li>
                        <pml style={{ color: "#ff4500" }}>✔</pml>
                        &nbsp;&nbsp;{translate("Achat")}
                      </li>
                      <li>
                        <pml style={{ color: "#ff4500" }}>✔</pml>
                        &nbsp;&nbsp;{translate("Factures")}
                      </li>
                      <li>
                        <pml style={{ color: "#ff4500" }}>✔</pml>
                        &nbsp;&nbsp;{translate("Statistiques")}
                      </li>
                      <li>
                        <pml style={{ color: "#ff4500" }}>✔</pml> &nbsp;&nbsp;
                        {translate("Analyses")}
                      </li>
                    </ul>
                  </div>
                  {/* </Carousel.Caption> */}
                </Carousel.Item>
              </Carousel>
              {/* </Grid> */}
            </Box>
            <Grid item xs={12} sm={6} md={5}>
              <div
                style={{
                  marginTop: "10%",
                  margin: "8%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "500px",
                  marginBottom: "-100px",
                }}
              >
                <div className="Lang-res">
                  <ThemeProvider theme={theme}>
                    <Switch
                      size="small"
                      onChange={() => this.props.DarckModeAction(!darck)}
                      color="warning"
                      name="checkedB"
                      inputProps={{ "aria-label": "warning checkbox" }}
                      checked={!darck}
                    />
                  </ThemeProvider>
                  <Flag
                    width="7%"
                    code={250}
                    onClick={() => {
                      this.props.lang(LOCALES.FRENCH);
                    }}
                    style={{ cursor: " pointer", marginRight: "20px" }}
                  />
                  <Flag
                    width="8%"
                    code={826}
                    onClick={() => {
                      this.props.lang(LOCALES.ENGLISH);
                    }}
                    style={{ cursor: " pointer" }}
                  />
                </div>
                <div
                  className="logo-res"
                  style={{
                    textAlign: "center",
                    marginBottom: "30px",
                  }}
                >
                  {darck ? (
                    <img
                      style={{
                        width: "65%",
                        height: "65%",
                        marginRight: "65px",
                      }}
                      className="logo"
                      src={logo}
                    />
                  ) : (
                    <img
                      style={{
                        width: "65%",
                        height: "65%",
                        marginRight: "65px",
                      }}
                      className="logo"
                      src={logoDarck}
                    />
                  )}

                  <br></br>

                  <pml
                    style={{
                      color: `${darck ? "#0c0f4e" : "#fff"}`,
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {translate("GESTION COMMERCIALE")}
                  </pml>
                </div>
                <div className="Lang">
                  <ThemeProvider theme={theme}>
                    <Switch
                      size="small"
                      onChange={() => this.props.DarckModeAction(!darck)}
                      color="warning"
                      name="checkedB"
                      inputProps={{ "aria-label": "warning checkbox" }}
                      checked={!darck}
                    />
                  </ThemeProvider>

                  {/* {darck ? <h2>white</h2> : <h2 style={{backgroundColor : "#fff"}}>darck</h2>} */}

                  <Flag
                    width="5%"
                    code={250}
                    onClick={() => this.props.lang(LOCALES.FRENCH)}
                    style={{ cursor: " pointer", marginRight: "15px" }}
                  />
                  <Flag
                    width="6%"
                    code={826}
                    onClick={() => this.props.lang(LOCALES.ENGLISH)}
                    style={{ cursor: " pointer" }}
                  />
                </div>          
                <Avatar
                  style={{
                    width: "35px",
                    height: "35px",
                    margin: "7px",
                    backgroundColor: "#ff4500",
                  }}
                >
                  <LockOpenIcon />
                </Avatar>

                <br />
                <Typography
                  variant="h5"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                >
                  {" "}
                  {translate("Se Connecter")}{" "}
                </Typography>
                <br />

                <form
                  autocomplete="off"
                  className={`${darck ? "" : classes.root}`}
                  onSubmit={this.submitForm}
                  style={{ width: "100%" }}
                >
                  <TextField
                    id="custom-css-standard-input"
                    label={translate("Code Dossier")}
                    variant="outlined"
                    name="coddos"
                    value={this.state.coddos}
                    onChange={this.DossierHandler}
                    required
                    fullWidth
                    InputProps={{
                      className: !darck ? this.props.classes.input : null,
                    }}
                    InputLabelProps={{
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                  />

                  <br />
                  <br />
                  <TextField
                    id="custom-css-standard-input"
                    label={translate("Nom d'utilisateur")}
                    variant="outlined"
                    name="username"
                    value={this.state.username}
                    onChange={this.UserHandler}
                    required
                    fullWidth
                    InputProps={{
                      className: !darck ? this.props.classes.input : null,
                    }}
                    InputLabelProps={{
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                  />

                  <br />
                  <br />
              
                  <TextField
                    id="custom-css-standard-input"
                    label={translate("Mot de Passe")}
                    type={isPasswordShown ? "text" : "password"}
                    variant="outlined"
                    name="password"
                    onChange={this.onChange}
                    required
                    fullWidth
                    InputProps={{
                      className: !darck ? this.props.classes.input : null,
                    }}
                    InputLabelProps={{
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
               /> 
 {/* Vos autres champs de formulaire */}
  
  
  <br />
                  <Form.Field>  
                  {translate("Afficher mot de passe")}<Checkbox
          onClick={this.togglePasswordVisiblity}
  value="checkedA"
  inputProps={{
    'aria-label': 'Checkbox A',
  }}
/></Form.Field> 
 <ReCAPTCHA
    sitekey="6LcmvuQpAAAAAJWUIet0bqsH40IEu7s4KzN_hGSq"
    onChange={this.onCaptchaChange}
  
  />
   
  
        {/*}    <i className="fa fa-eye password-icon"  /> */} 
                  <br />
                  {/* <br />
                  <br /> */}

                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "FF6600",
                      width: "100%",
                    }}
                  >
                    {translate("Se Connecter")}
                  </Button>
                </form>
              </div>
            </Grid>
            {/* <div
              className="carousel-resp"
              style={{
                marginTop: "60%",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  color: `${darck ? "#0c0f4e" : "#fff"}`,
                  fontSize: "40px",
                }}
              >
                {translate("Gérer vos affaires")}
              </p>
              <ul
                style={{
                  color: `${darck ? "#0c0f4e" : "#fff"}`,
                  fontSize: "20px",
                  marginLeft: "40px",
                }}
              >
                <li>
                  <pml style={{ color: "#ff4500" }}>✔</pml>
                  &nbsp;&nbsp;{translate("Vente")}
                </li>
                <li>
                  <pml style={{ color: "#ff4500" }}>✔</pml>
                  &nbsp;&nbsp;{translate("Achat")}
                </li>
                <li>
                  <pml style={{ color: "#ff4500" }}>✔</pml>
                  &nbsp;&nbsp;{translate("Factures")}
                </li>
                <li>
                  <pml style={{ color: "#ff4500" }}>✔</pml>
                  &nbsp;&nbsp;{translate("Statistiques")}
                </li>
                <li>
                  <pml style={{ color: "#ff4500" }}>✔</pml> &nbsp;&nbsp;Analyses
                </li>
              </ul>
            </div> */}
          </Grid>
        </div>
        <span
          style={{
            width: "100%",
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{ textAlign: "center", color: `${darck ? "" : "#fff"}` }}
          >
            {" "}
            {translate("CRM RÉALISÉ PAR")} {" "}
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            &nbsp;
            <span style={{ color: "rgb(25, 118, 210)" }}>POLYSOFT</span>
            <span style={{ color: "rgb(226, 127, 81)" }}>&amp;CO</span>
            &nbsp;
          </span>
          <span style={{ color: `${darck ? "" : "#fff"}` }}>
            | © 2024. {translate("TOUS DROITS RÉSERVÉS")}
          </span>
        </span>
      </div>
    );
  }
}
 
function mapDispatchToProps(dispatch) {
  return {
    GetDBFolder: () => dispatch(GetDBFolder()),
    hideloader: () => dispatch(hideLoader()),
    showloader: () => dispatch(showLoader()),
    AuthUser: (a, b, c, d) => dispatch(AuthUser(a, b, c, d)),
    setDossier: (a, b, c, d) => dispatch(setDossier(a, b, c, d)),
    setDataBase: (a) => dispatch(setDataBase(a)),
    InfoEntreprise: (a) => dispatch(InfoEntreprise(a)),
    DarckModeAction: (a) => dispatch(DarckModeAction(a)),
  };
}

function mapStateToProps(state) {
  return {
    dbs: state.dbs,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(Login));
