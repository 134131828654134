import React, { Component } from "react";
import { Modal, Card, Row, Col } from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ModifierREModal from "./ModifierREModal";
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import { SelectRE } from "../../redux/actions/GetRE";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import translate from "../../i18n/translate";
import { LOCALES } from "../../i18n";
import "./Responsivebrf.css";

const roundTo = require("round-to");

const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class GetREByIdModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      ligid: "",
      blbrid: "",
      tab: [],
      newtab: [],
      todaydate: date,

      codearticle: 0,
      des: "",
      qte: 0,
      unite: 0,
      puht: 0,
      faudec: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      totalht: 0,

      totalqte: 0,
      netapayer: 0,
      // tabtab: [],
      clientimp: [],
      snackbaropen: false,
      snackbarmsg: "",
      username: username,
      numav: "",
    };
  }

  //   componentDidMount() {
  //     this.props.SelectRELig(
  //       this.props.blbrid,
  //       this.props.ConfigDossier.coddos,
  //       this.props.ConfigDossier.serveur,
  //       this.props.ConfigDossier.utilisateur,
  //       this.props.ConfigDossier.pwd_serveur,
  //       this.props.ConfigDossier.database
  //     );
  //     this.props.SelectAvCod(
  //       this.props.ConfigDossier.coddos,
  //       this.props.ConfigDossier.serveur,
  //       this.props.ConfigDossier.utilisateur,
  //       this.props.ConfigDossier.pwd_serveur,
  //       this.props.ConfigDossier.database
  //     );
  //   }

  handleOpen = () => {
    this.setState({ open: true });
    fetch(
      `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    // this.setState({ openMailModal: true });
  };

  noupdate = () => {
    window.alert(
      "Impossible de modifier le Bon de Retour ... L'avoir est déja généré"
    );
  };

  openModifier = () => {
    if (this.props.annuler === "0") {
      this.setState({ openModifierModal: true });
    } else {
      alert("Bon de Retour annulé, il faut le réactiver");
    }
  };
  activer = () => {
    // update annuler=0
    this.props.annuler !== "0"
      ? fetch(
          `https://www.24-crm.com/webservice/api/BEREs?idD=${this.props.blid}&typfacC=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.props.onHide();
            this.props.SelectRE(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            //Exécuter la procédure UpdateStkBLBR
            fetch(
              `https://www.24-crm.com/webservice/api/LigBLBRs?FAC=${this.props.blid}&Typfacc=RE&CODDEPP=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  //  this.setState({ snackbaropen: true, snackbarmsg: result });
                  // window.location.reload();
                },
                (error) => {
                  this.setState({
                    snackbaropen: true,
                    snackbarmsg: "failed",
                  });
                }
              );
          
            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then(() => window.location.reload())
      : window.alert("Bon de Retour déja activé");
  };

  // annuler = () => {
  //   // update annuler=1
  //   this.props.annuler === "0"
  //     ? this.props.facture === "0"
  //       ? fetch(
  //           `http://www.24-crm.com/webservice/api/BEREs?idd=${this.props.blid}&typfaccs=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //           {
  //             method: "PUT",
  //             header: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         )
  //           .then((res) => res.json())
  //           .then((result) => {
  //             this.props.onHide();
  //             this.props.SelectRE(
  //               this.props.ConfigDossier.coddos,
  //               this.props.ConfigDossier.serveur,
  //               this.props.ConfigDossier.utilisateur,
  //               this.props.ConfigDossier.pwd_serveur,
  //               this.props.ConfigDossier.database
  //             );
  //             //Exécuter la procédure FinirAnnulBLBR
  //             fetch(
  //               `http://www.24-crm.com/webservice/api/BSRS?fac=${this.props.blid}&Typfacs=RE&NumComs=&Typcoms=&CODDEPs=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //               {
  //                 method: "POST",
  //                 header: {
  //                   Accept: "application/json",
  //                   "Content-Type": "application/json",
  //                 },
  //               }
  //             );

  //             console.log(result);

  //             this.setState({ snackbaropen: true, snackbarmsg: result });
  //           })
  //           .then(() => window.location.reload())
  //       : window.alert("Avoir généré, le bon de retour ne peut pas être annulé")
  //     : window.alert("Bon de retour déja annulé");
  // };

  annuler = () => {
    // // update annuler=1
    this.props.annuler === "0"
      ? fetch(
          `https://www.24-crm.com/webservice/api/BEREs?idd=${this.props.blid}&typfaccs=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.props.onHide();
            this.props.SelectRE(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            //annulation de BE et modif stock
            fetch(
              `https://www.24-crm.com/webservice/api/BSRS?fac=${this.props.blid}&Typfacs=RE&NumComs=&Typcoms=&CODDEPs=${this.props.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then((res) => {
            fetch(
              `https://www.24-crm.com/webservice/api/FOURNISSEURs?Codfrssss=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
          })
      : window.alert("Bon d'entrée déja annulé");
  };

  imprimer = () => {
    window.alert("imprimer");
  };

  nonsupprimer = () => {
    window.alert(
      "Vous devez annuler ce bon de retour  pour que vous puissiez le supprimer"
    );
  };

  //   Avoir = () => {
  //     //update facturer=iddd
  //     this.props.facture === "0"
  //       ? this.props.annuler === "0"
  //         ? fetch(
  //             `http://www.24-crm.com/webservice/api/BLBRs?iddd=${
  //               this.props.codav.codav.map((el) => el.valeur)[0]
  //             }&typfaccss=BR&numfacc=${this.props.blid}&coddos=${
  //               this.props.ConfigDossier.coddos
  //             }&serveur=${this.props.ConfigDossier.serveur}&user=${
  //               this.props.ConfigDossier.utilisateur
  //             }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
  //               this.props.ConfigDossier.database
  //             }`,
  //             {
  //               method: "PUT",
  //               header: {
  //                 Accept: "application/json",
  //                 "Content-Type": "application/json",
  //               },
  //             }
  //           )
  //             .then((res) => res.json())

  //             .then((result) => {
  //               console.log(result);
  //               this.props.onHide();
  //               this.props.SelectRE(
  //                 this.props.ConfigDossier.coddos,
  //                 this.props.ConfigDossier.serveur,
  //                 this.props.ConfigDossier.utilisateur,
  //                 this.props.ConfigDossier.pwd_serveur,
  //                 this.props.ConfigDossier.database
  //               );
  //               console.log(result);
  //               ////////////// 2nd part post data in ligfacli //////////

  //               this.props.tabtab.map((k, index) => {
  //                 for (var i = 0; i < this.props.tabtab.length; i++) {
  //                   fetch(
  //                     `http://www.24-crm.com/webservice/api/LigFACCLIs/{ID}?numfac=${this.props.codav.codav.map(
  //                       (t) => t.valeur
  //                     )}&typfac=AT&numlig=${k.numlig}&codart=${
  //                       k.codart
  //                     }&quantite=${k.quantite}&fodart=0&desart=${
  //                       k.desart
  //                     }&datfac=${this.state.todaydate}&priuni=${
  //                       k.priuni
  //                     }&remise=${k.remise}&unite${k.unite}&codtva=${
  //                       k.codtva
  //                     }&tautva=${k.tautva}&montht=${k.montht}&ttclig=${
  //                       k.ttclig
  //                     }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
  //                       this.props.ConfigDossier.serveur
  //                     }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
  //                       this.props.ConfigDossier.pwd_serveur
  //                     }&database=${this.props.ConfigDossier.database}`,
  //                     {
  //                       method: "POST",
  //                       header: {
  //                         Accept: "application/json",
  //                         "Content-Type": "application/json",
  //                       },
  //                     }
  //                   )
  //                     .then((res) => res.json())
  //                     .then(
  //                       (result) => {
  //                         this.setState({
  //                           snackbaropen: true,
  //                           snackbarmsg: result,
  //                         });

  //                         console.log("22", result);
  //                         // window.alert(result);
  //                       },
  //                       (error) => {
  //                         this.setState({
  //                           snackbaropen: true,
  //                           snackbarmsg: "failed",
  //                         });
  //                       }
  //                     );
  //                 }
  //               });

  //               // this.setState({
  //               //   numav: this.props.codav.codav.map((el) => el.valeur)[0],
  //               // });
  //               ////////////// 3rd part post data in factable //////////
  //               // this.props.codav.codav &&
  //               //   this.props.codav.codav.map((t) =>
  //               //     console.log("numfac", t.valeur)
  //               //   );
  //               // console.log("codcli", this.props.client);
  //               // console.log("raisoc", this.props.raisonsociale);
  //               // console.log("catfisc", this.props.catfisc);
  //               // console.log("date", this.state.todaydate);
  //               // console.log("timbre", this.props.droitdetimbre);
  //               // console.log("smhtb", this.props.totalhtbrut);
  //               // console.log("smremart", this.props.remiselignes);
  //               // console.log("semremglo", this.props.remiseglobale);
  //               // console.log("smhtn", this.props.totalhtnet);
  //               // console.log(
  //               //   "reste",
  //               //   parseFloat(this.props.totalttc) +
  //               //     parseFloat(this.props.valtimbre)
  //               // );
  //               // console.log("pj", this.props.blid);
  //               // console.log("mntbon", this.props.Auth.code);
  //               // this.props.codav.codav.map((t) =>
  //               //   console.log("numcom", t.valeur)
  //               // );
  //               // console.log("valtimbre", this.props.valtimbre);
  //               // console.log("typcom", this.props.typcom);
  //               // console.log("vendeur", this.props.Auth.username);

  //               fetch(
  //                 `http://www.24-crm.com/webservice/api/FACCLIs?numfac=${this.props.codav.codav.map(
  //                   (t) => t.valeur
  //                 )}&typfac=AT&codcli=${this.props.client}&raisoc=${
  //                   this.props.raisonsociale
  //                 }&catfisc=${this.props.catfisc}&datfac=${
  //                   this.state.todaydate
  //                 }&timbre=${this.props.droitdetimbre}&smhtb=${
  //                   this.props.totalhtbrut
  //                 }&smremart=${this.props.remiselignes}&smremglo=${
  //                   this.props.remiseglobale
  //                 }&smhtn=${this.props.totalhtnet}&reste=${
  //                   parseFloat(this.props.totalttc) +
  //                   parseFloat(this.props.valtimbre)
  //                 }&pj=${this.props.blid}&codrep=${this.props.Auth.code}&smtva=${
  //                   this.props.totaltva
  //                 }&mntbon=${
  //                   parseFloat(this.props.totalttc) +
  //                   parseFloat(this.props.valtimbre)
  //                 }&numcom=${this.props.codav.codav.map(
  //                   (t) => t.valeur
  //                 )}&valtimbre=${this.props.valtimbre}&typcom=AT&Vendeur=${
  //                   this.props.Auth.username
  //                 }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
  //                   this.props.ConfigDossier.serveur
  //                 }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
  //                   this.props.ConfigDossier.pwd_serveur
  //                 }&database=${this.props.ConfigDossier.database}`,
  //                 {
  //                   method: "POST",
  //                   header: {
  //                     Accept: "application/json",
  //                     "Content-Type": "application/json",
  //                   },
  //                 }
  //               )
  //                 .then((res) => res.json())
  //                 .then((res) => {
  //                   //////// update switch /////////////
  //                   fetch(
  //                     `http://www.24-crm.com/webservice/api/Switch?code=AC2&valeur=${this.props.codav.codav.map(
  //                       (t) => parseInt(t.valeur) + 1
  //                     )}&coddos=${this.props.ConfigDossier.coddos}&serveur=${
  //                       this.props.ConfigDossier.serveur
  //                     }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
  //                       this.props.ConfigDossier.pwd_serveur
  //                     }&database=${this.props.ConfigDossier.database}`,
  //                     {
  //                       method: "PUT",
  //                       header: {
  //                         Accept: "application/json",
  //                         "Content-Type": "application/json",
  //                       },
  //                     }
  //                   );
  //                 })
  //                 .then(() => window.location.reload())

  //                 .then(
  //                   (result) => {
  //                     //recalculer reste facture exécuter la procédure RecalculResteFacCli
  //                     fetch(
  //                       `http://www.24-crm.com/webservice/api/FACCLIs?numfacqq=${this.props.numcom}&resteqq=${this.props.totalttc}&typfacqq=${this.props.typcom}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //                       {
  //                         method: "POST",
  //                         header: {
  //                           Accept: "application/json",
  //                           "Content-Type": "application/json",
  //                         },
  //                       }
  //                     ).then((res) =>
  //                       //recalculer solde client Exécuter la procédure RecalculSoldeCliNew
  //                       fetch(
  //                         `http://www.24-crm.com/webservice/api/REGCLIs?codeclii=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //                         {
  //                           method: "POST",
  //                           header: {
  //                             Accept: "application/json",
  //                             "Content-Type": "application/json",
  //                           },
  //                         }
  //                       )
  //                     );

  //                     this.setState({ snackbaropen: true, snackbarmsg: result });

  //                     console.log(result);
  //                     //// window.alert(result);
  //                   },
  //                   (error) => {
  //                     this.setState({
  //                       snackbaropen: true,
  //                       snackbarmsg: "failed",
  //                     });
  //                   }
  //                 );

  //               this.setState({ snackbaropen: true, snackbarmsg: result });
  //             })
  //         : window.alert("Bon de retour annulé, il faut le réactivé")
  //       : window.alert("Avoir déjà généré ");
  //   };

  supprimer = () => {
    if (
      window.confirm("êtes-vous sûr de vouloir supprimer ce bon de retour ?")
    ) {
      fetch(
        `https://www.24-crm.com/webservice/api/BEREs/${this.props.blid}?typfacc=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      fetch(
        `https://www.24-crm.com/webservice/api/LigBEREs/${this.props.blid}?typfacc=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectRE(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          this.setState({ snackbaropen: true, snackbarmsg: result });
        })
        .then(() => window.location.reload());
    }
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });

    const { ligidd, bcidd, tabb } = this.state;
    const altText = this.props.ConfigDossier.coddos+".jpg";
    const { darck } = this.props;

    const actions =
      this.props.locale === LOCALES.FRENCH
        ? [
            { icon: <EditIcon />, name: "Modifier" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
              //     <SlideshowIcon />
              //   ),
              // name: this.props.annuler === "0" ? "Annuler" : "Activer",
              <DeleteOutlineIcon />
            ),
          name: this.props.annuler === "0" ? "Annuler" : "Supprimer",
            },
            //{ icon: <DeleteOutlineIcon />, name: "Supprimer" },
            // { icon: <FileCopyIcon />, name: "Avoir" },
          ]
        : [
            { icon: <EditIcon />, name: "Edit" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
              //     <SlideshowIcon />
              //   ),
              // name: this.props.annuler === "0" ? "Cancel" : "Activate",
              <DeleteOutlineIcon />
            ),
          name: this.props.annuler === "0" ? "Cancel" : "Remove",
            },
          //  { icon: <DeleteOutlineIcon />, name: "Remove" },
          ];

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                {" "}
                {translate("Bon de Retour N°")} 
                {this.props.blid} {translate("du")}{" "}
                {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}{" "}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                                style={{
                                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                  border: `${darck ? "" : "2px solid #fff"}`,
                                  borderBottom: `none`,
                                }}
          >
            <Card style={{ marginTop: "10px" }}>
              <Card.Body
                              style={{
                                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                border: `${darck ? "" : "2px solid #fff"}`,
                              }}
              >
   {this.props.MG ? (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Fournisseur")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("N° facture")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.typcom} {this.props.numcom}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Magasin
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.MG}
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Fournisseur")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("N° facture")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.typcom} {this.props.numcom}
                      </Typography>
                    </Col>
                  </Row>
                )}


                {/* <Row>
                 
                  <Col style={{ textAlign: "center" }} sm={4}>
                    <Typography variant="h6" component="h2">
                      <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                        {" "}
                        {translate("Clients")}{" "}
                      </Label>
                    </Typography>
                    <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {this.props.client}
                    </Typography>
                  </Col>

                  <Col style={{ textAlign: "center" }} sm={4}>
                    <Typography variant="h6" component="h2">
                      <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                        {" "}
                        {translate("Raison Social")}{" "}
                      </Label>
                    </Typography>
                    <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {this.props.raisonsociale}
                    </Typography>
                  </Col>
                  <Col style={{ textAlign: "center" }} sm={4}>
                    <Typography variant="h6" component="h2">
                      <Label style={{ color: `${darck ? "#020f64" : "#fff"}` }}>
                        {" "}
                        {translate("N° facture")}{" "}
                      </Label>
                    </Typography>
                    <Typography style={{ color: `${darck ? "#grey" : "#fff"}`, fontSize: "18px" }}>
                      {this.props.typcom} {this.props.numcom}
                    </Typography>
                  </Col>
                </Row>  */}
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}
              >
                <div className="tabbl2">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                        <th>Code</th>
                        <th style={{ width: "37%" }}>
                          {" "}
                          {translate("Désignation")}{" "}
                        </th>
                        <th> {translate("Quantité")} </th>
                        {/* <th>Unité</th> */}
                        <th> {translate("PUHT")} </th>
                        <th> {translate("Remise")} </th>
                        <th> {translate("TVA")} </th>
                        <th> {translate("PUTTCNet")} </th>
                        <th> {translate("TotalHT")} </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                        color: `${darck ? "" : "#fff"}`,
                      }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          <td>
                            <span>{t.codart}</span>
                          </td>
                          <td style={{ fontSize: "12px", width: "37%" }}>
                            {t.desart}
                          </td>
                          <td>
                            <span>{t.quantite}</span>
                          </td>
                          <td>
                            <span>{Number(t.priuni).toFixed(3)}</span>
                          </td>

                          <td>
                            <span>{Number(t.remise).toFixed(2)}</span>
                          </td>
                          <td>
                            <span>{Number(t.tautva).toFixed(2)}</span>
                          </td>

                          <td>
                            <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                          </td>
                          <td>
                            <span>{Number(t.montht).toFixed(3)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="brfr1">
              <Card.Body
              
              style={{
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
              >
                <Row style={{ marginBottom: "-10px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Brut
                    </p>

                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Article
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur de timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ olor: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Globale
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalttc).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    {/* <p style={{ color: "black" }}>{this.state.netapayer}</p> */}
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}

            <Card style={{ marginTop: "10px" }} id="brfr2">
              <Card.Body
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              border: `${darck ? "" : "2px solid #fff"}`,
                                            }}
              >
                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HTBrut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Article
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="brf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>{" "}
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Remise Globale
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="brf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>{" "}
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalttc).toFixed(3)}
                    </p>{" "}
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="brf">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbrf">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}>
                      Valeur Timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    {/* <p style={{ color: "black" }}>{this.state.netapayer}</p> */}
                    <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row style={{ marginTop: "20px" }}>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "10px",
                    
                  }}
                  FabProps={{ size: "small" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                >
                  {actions.map((action) =>
                    this.props.locale === LOCALES.FRENCH ? (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          this.handleClose();
                          action.name == "Imprimer" && this.imprimer();
                          action.name == "Modifier" &&
                            this.openModifier();

                          action.name == "Supprimer" &&
                            this.props.annuler === "1" &&
                            this.supprimer();
                          action.name == "Supprimer" &&
                            this.props.annuler === "0" &&
                            this.nonsupprimer();

                          action.name == "Annuler" && this.annuler();
                          action.name == "Activer" && this.activer();
                          action.name == "Avoir" && this.Avoir();
                        }}
                      />
                    ) : (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          this.handleClose();
                          action.name == "Imprimer" && this.imprimer();
                          action.name == "Edit" &&
                            this.openModifier();

                          action.name == "Remove" &&
                            this.props.annuler === "1" &&
                            this.supprimer();
                          action.name == "Remove" &&
                            this.props.annuler === "0" &&
                            this.nonsupprimer();

                          action.name == "Cancel" && this.annuler();
                          action.name == "Activate" && this.activer();
                          action.name == "Credit" && this.Avoir();
                        }}
                      />
                    )
                  )}
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              </Col>
            </Row>

            <ModifierREModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              ligidd={this.state.ligid}
              tabb={this.props.tabtab}
              blid={this.props.blid}
              datebl={this.props.datebl}
              onHide01={this.props.onHide}
              taurem={this.props.taurem}
              catfisc={this.props.catfisc}
              client={this.props.client}
              raisonsociale={this.props.raisonsociale}
              droitdetimbre={this.props.droitdetimbre}
              avanceimpot={this.props.avanceimpot}
              facture={this.props.facture}
            />
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>

      {/* ///////////////////  etat print //////////////////////// */}
      <div style={{ display: "none" }}>
          <div
            ref={(el) => (this.componentRef = el)}
            style={{ margin: "40px  35px", height: "94%" }}
          >
             {/* <img
  // src={altText}
  src={require("../assests/img/comaf.jpg")}
  style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} /> 
          */}

<img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
    <h1 style={{ fontSize: "50px", fontWeight: "bolder" }}> 
            {this.props.InfoEntreprise[0].coddos}
            </h1>
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              {this.props.InfoEntreprise[0].spe}
              <br />
              {translate("Adresse")} : {this.props.InfoEntreprise[0].adr} <br />
              {translate("Tél")}:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
              {this.props.InfoEntreprise[0].tel2} / Email:{" "}
              {this.props.InfoEntreprise[0].email} <br />
              {translate("Code TVA")}: {this.props.InfoEntreprise[0].codtva}{" "}
              <br />
              RIB: {this.props.InfoEntreprise[0].rib}{" "}
              {this.props.InfoEntreprise[0].matban}{" "}
              {this.props.InfoEntreprise[0].agence}{" "}
              {this.props.InfoEntreprise[0].ville}
              <br />
              {translate("Etabli par")}: {this.props.Auth.username}
            </p>
            <h5
              style={{
                marginLeft: "90px",
                flexDirection: "column",
                marginTop: "25px",
                marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >
              {translate("Bon De Retour")} № {this.props.blid} <br />
              {translate("sur Facture N°")}: {this.props.numcom}
            </h5>
            <Row>
              <Col>
                <h6 style={{ marginLeft: "165px", marginTop: "40px" }}>
                  Date:{" "}
                  {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>
                    {" "}
                    {translate("Code Fournisseur")} : {this.props.client}
                  </h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>{t.ville}</h5> : <h5>--</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6> {translate("Code TVA")} : --</h6>
                  ) : (
                    <h6>
                      {translate("Code TVA")} : {t.codtva}
                    </h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "5px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <tr style={{ borderBottom: "1px solid grey" }}>
                    <th>Code</th>
                    <th style={{ width: "37%" }}>
                      {" "}
                      {translate("Désignation")}{" "}
                    </th>
                    <th> {translate("Quantité")} </th>
                    <th> {translate("PUHT")} </th>
                    <th> {translate("Remise")} </th>
                    <th> {translate("TVA")} </th>
                    <th> {translate("TotalHT")} </th>
                  </tr>
                </thead>

                <tbody 
                       style={{color: `${darck ? "" : "#fff"}`}}
                >
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        height: "60px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td>{t.quantite}</td>
                      <td>{Number(t.priuni).toFixed(3)}</td>

                      <td>{Number(t.remise).toFixed(2)}</td>
                      <td>{Number(t.tautva).toFixed(2)}</td>

                      <td>{Number(t.montht).toFixed(3)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
             <table
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    width: "450px",
                    borderWidth: "1px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                <tr>
                      <th>T.V.A</th>
                      <th>Assiette</th>
                      <th>Montant</th></tr>  </thead> 
                  
                    
{/* ////////////////////       SI TVA = 0%      /////////////////// */}
 {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 0 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))} */}
{/* ////////////////////       SI TVA = 7%      /////////////////// */}
                   {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 7 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}  */}
                      
{/* ////////////////////       SI TVA = 13%      /////////////////// */}

                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 13 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}  */}
{/* ////////////////////       SI TVA = 19%      /////////////////// */}

               {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 19 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))}  */}
                         <tbody>
                            {/* Initialize the totals */}
                            {(() => {
                              const tvaGroups = {};

                              // Grouping by TVA and summing the values
                              this.props.tabtab.forEach((t) => {
                                const tvaRate = Number(t.tautva).toFixed(2);

                                if (!tvaGroups[tvaRate]) {
                                  tvaGroups[tvaRate] = { assiette: 0, montant: 0 };
                                }

                                tvaGroups[tvaRate].assiette += Number(t.montht);
                                tvaGroups[tvaRate].montant += Number(t.ttclig - t.montht);
                              });

                              // Render the rows for each TVA group
                              return Object.keys(tvaGroups).map((tvaRate) => (
                                <tr key={tvaRate} style={{ height: "50px" }}>
                                  <td>{tvaRate}</td>
                                  <td>{tvaGroups[tvaRate].assiette.toFixed(3)}</td>
                                  <td>{tvaGroups[tvaRate].montant.toFixed(3)}</td>
                                </tr>
                              ));
                            })()}
                          </tbody>
                </table> 

              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b> {translate("Total quantité")} : </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;{translate("Total HT Brut")}:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;{translate("Remise Globale")}:
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp; {translate("Total HT Net")}:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;{translate("Total TVA")}:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;{translate("Net à Payer")}:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(parseFloat(this.props.totalttc)).toFixed(3)}{" "}
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <br />

            {/* //////////////////////// footer ///////////////////////////// */}
            {Number(this.props.remiseglobale) != 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{translate("Signature Client")}:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{translate("Signature & cachet")}:
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "25px",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{translate("Signature Client")}:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{translate("Signature & cachet")}:
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // SelectRELig: (num, a, b, c, d, e) =>
    //   dispatch(SelectRELig(num, a, b, c, d, e)),
    SelectRE: (a, b, c, d, e) => dispatch(SelectRE(a, b, c, d, e)),
    // SelectAvCod: (a, b, c, d, e) => dispatch(SelectAVCod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    blbrligs: state.blbrligs,
    codav: state.codav,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetREByIdModal);
