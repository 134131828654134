import React, { Component } from "react";
import "./ficheCss.scss";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {
  getArticleList,
  getArticleHeader,
} from "../../redux/actions/ArticleActions";
import "../../App.css";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { SelectFiche} from "../../redux/actions/GetficheSTK";
import { SelectFicheGMM} from "../../redux/actions/GetficheSTKGMM";
import AddingArticleModal from "../gestion-des-articles/AddingArticleModal";
import GetArticleByIdModal from "../gestion-des-articles/GetArticleByIdModal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from 'moment';
import {
  TextField,
  Button,
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from "@material-ui/core";
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import "../gestion-des-articles/Responsiveart.css";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Fichestock from "./fichestock";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
 
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);
var event = new Date();
event.setDate(1);
event.setMonth(0);

var date2 = event.toISOString().substr(0, 10);


class FicheArticle extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      data: [],
 
      modal: false,
      addModalShow1: false,
      GetByIdModalShow: false,
      getArticleByIdModalShow: false,
      defaultdate: date,
      d1:date2,
      firstdate: date2,
      seconddate: date,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
      showrechbydate: false,
      showrechbydatecodeart: false,
      rechdats: [],
      rechdatscodeart: [],
   
      addModalShow: false,
      GetBSByIdModalShow: false,
      loggedIn,
   
      icon: false,
     
      tabtab: [],

      GMM: "",
      fichGMM:[],
      showrechbydatecodeartGMM: false,
      rechdatsGMM: [],
      rechdatscodeartGMM: [], 
      rechsGMM: [],
       rechercheclickedGMM: false,
      
      
      
      
      gilad: true,
     
      
    };
  }
  toggle = () => this.setState({ modal: !this.state.modal });
  handleOpen = () => {
    this.setState({ open: true });
    
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.props.getArticleHeader();
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFiche(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFicheGMM(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    
  
   
    document.title = "Fiche Article";
    // this.setState({ rechs: this.props.articles.articles });
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
      fetch(
        `https://www.24-crm.com/webservice/api/BEREs?coddossGMM=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          fichGMM: data,
       
        })
      
      );
  } 
 
  firstrechdatHandler = (event) => {
    if(this.state.GMM==false){
      this.setState({rechdats:[]})
      this.setState({rechdatscodeart: []});
      this.setState({ firstdate: event.target.value });
      //recherche par date début
      fetch(
        `https://www.24-crm.com/webservice/api/BEREs?dattstk=${event.target.value}&datttstk=${this.state.seconddate}&codeart=${this.state.codart1}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            rechdats: data,
            showrechbydate: true,
            showrechbydatecodeart: true,
            rechercheclicked: false,
          })
        );
     }else{ 
      this.setState({rechdatsGMM:[]})
      this.setState({rechdatscodeartGMM: []});
      this.setState({ firstdate: event.target.value });
      //recherche par date début
      fetch(
        `https://www.24-crm.com/webservice/api/BEREs?dattstkGMM=${event.target.value}&datttstkGMM=${this.state.seconddate}&codeartGMM=${this.state.codartGMM}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            rechdatsGMM: data,
            showrechbydateGMM: true,
            showrechbydatecodeartGMM: true,
            rechercheclickedGMM: false,
          })
        );
     }
   
  };

  secondrechdatHandler = (event) => {
    if(this.state.GMM==false){  
       this.setState({rechdats:[]})
      this.setState({rechdatscodeart: []});
     
      this.setState({ seconddate: event.target.value });
      //recherche par date fin
      fetch(
        `https://www.24-crm.com/webservice/api/BEREs?dattstk=${this.state.firstdate}&datttstk=${event.target.value}&codeart=${this.state.codart1}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            rechdats: data,
            showrechbydate: true,
            showrechbydatecodeart: true,
            rechercheclicked: false,
          })
        );
      }else{ 
        this.setState({rechdatsGMM:[]})
        this.setState({rechdatscodeartGMM: []});
       
        this.setState({ seconddate: event.target.value });
        //recherche par date fin
        fetch(
          `https://www.24-crm.com/webservice/api/BEREs?dattstkGMM=${this.state.firstdate}&datttstkGMM=${event.target.value}&codeartGMM=${this.state.codartGMM}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
        )
          .then((response) => response.json())
          .then((data) =>
            this.setState({
              rechdatsGMM: data,
              showrechbydateGMM: true,
              showrechbydatecodeartGMM: true,
              rechercheclickedGMM: false,
            })
          );
        
      }
  
  };
 
  Handlercodart = (event) => {
    if(this.state.GMM==false){
      this.setState({rechdats:[]})
    this.setState({rechdatscodeart: []});
    this.setState({codart1:event.target.value})
  
    //recherche par date fin
    fetch(
      `https://www.24-crm.com/webservice/api/BEREs?dattstk=${this.state.firstdate}&datttstk=${this.state.seconddate}&codeart=${event.target.value}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdatscodeart: data,
          showrechbydatecodeart: true,
          showrechbydate: false,
          rechercheclicked: false,
        })
       
      );
     }else{
      this.setState({rechdatsGMM:[]})
    this.setState({rechdatscodeartGMM: []});
    this.setState({codartGMM:event.target.value})
  
    //recherche par date fin
    fetch(
      `https://www.24-crm.com/webservice/api/BEREs?dattstkGMM=${this.state.firstdate}&datttstkGMM=${this.state.seconddate}&codeartGMM=${event.target.value}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdatscodeart: data,
          showrechbydatecodeartGMM: true,
          showrechbydateGMM: false,
          rechercheclickedGMM: false,
        })
       
      );
     }
    
    
  };
  rechercheHandler = (event) => {
    if(this.state.GMM==false){ 
 
    this.setState({rechs: []});
    // ///////////////////////////////////mono magasin///////////////////////////////
    fetch(
      `https://www.24-crm.com/webservice/api/BEREs?idddGMM=${event.target.value}&type&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, 
        rechercheclicked: true,
     
       }));
      }
       else{ 
          // ///////////////////////////////////multi magasin///////////////////////////////
        this.setState({rechsGMM: []});
       this.setState({rechs: []});
       this.setState({codart2:event.target.value})
       fetch(
         `https://www.24-crm.com/webservice/api/BEREs?iddd=${event.target.value}&coddep=${this.state.coddep}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
       )
         .then((response) => response.json())
         .then((data) => this.setState({ rechs: data, 
           rechercheclicked: true,
           rechercheclickedGMM:true,
          }));}
   
  };

  rechercheHandlerGMM = (event) => {
    this.setState({rechs: []});
    this.setState({rechsGMM: []});
    this.setState({ coddep: event.target.value });
    
    fetch(
      `https://www.24-crm.com/webservice/api/BEREs?iddd=${this.state.codart2}&coddep=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({
         rechsGMM: data,
         rechercheclickedGMM: true,
         rechercheclicked: true,
         }));
  };


  // componentDidUpdate() {
  //   this.props.SelectFiche();
  // }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleCheckboxChangeA = (event) => {
    const isChecked = event.target.checked;
    const valeur = isChecked ? 1 : 0; // 1 si coché, 0 sinon
  
    // Mettez à jour l'état
    this.setState({ EscompterA: valeur });
   
    // Utilisez la valeur (0 ou 1) comme vous le souhaitez
    console.log("Valeur de la case à cocher : ", valeur);
  }
  remiseChange = (event) => {
    this.setState({
      stkfin1: event.target.value,
      totalstfin:
      this.state.QteEntr + this.state.QteRet - this.state.QteRet
          
 
    });
       console.log("totalstfin",this.props.totalstfin)
  };


  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    if(this.state.GMM==false){
      this.state.gilad
    ? this.setState({ showrechbydatecodeart: false })
    ? this.setState({ rechercheclicked: false }) 
    :this.setState({showrechbydatecodeart:false}):this.setState({showrechbydatecodeart:false});
 
  }else{ 
     this.state.gilad
      ? this.setState({ showrechbydatecodeart: false })
      ? this.setState({ rechercheclickedGMM: false }) 
      :this.setState({showrechbydatecodeart:false}):this.setState({showrechbydatecodeart:false});
}
       
   
  
    
  };
 
  getTotalStkfin = () => {
    let totalStkfin = 0;
   if(this.state.GMM==false){
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
        totalStkfin +=  parseFloat(Number(article.QteEntr)+Number(article.QteRet)-Number(article.QteSor));

    })
  };
    return totalStkfin.toFixed(3); 
   }else{
    // ///////////////////////////////////multi magasin///////////////////////////////
    if(this.state.rechercheclicked && this.state.rechercheclickedGMM){
      this.state.rechsGMM.forEach((article) => {
        totalStkfin +=  parseFloat(Number(article.QteEntr)+Number(article.QteRet)-Number(article.QteSor));
    })
  };
    return totalStkfin.toFixed(3); // arrondi à 4 chiffres après la virgule
   }
  
  };

  getTotalValeur = () => {
    let TotalValeur = 0;
    if(this.state.GMM==false){ 
      if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
   TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor));
        })
  }
 
   else{
    this.props.fiches.fiches.forEach((article) => {
      TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor));

    })
  };
    return TotalValeur.toFixed(3); // arrondi à 4 chiffres après la virgule
    
  }else{
      // ///////////////////////////////////multi magasin///////////////////////////////
    if(this.state.rechercheclicked && this.state.rechercheclickedGMM){
      this.state.rechsGMM.forEach((article) => {
        TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor));
    

    })
  }

  else{
    this.state.fichGMM.forEach((article) => {
   TotalValeur += parseFloat(Number(article.ValEntr)+Number(article.valRet)-Number(article.ValSor));
  
    })
  };
    return TotalValeur.toFixed(3); // arrondi à 4 chiffres après la virgule
  }
   
  };

  getTotalQteEntr = () => {
    let totalQteEntr = 0;
   if(this.state.GMM==false){
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
        totalQteEntr += parseFloat(Number(article.QteEntr));
    })
  }
   else if(this.state.showrechbydate){
    this.state.rechdats.forEach((article) => {
      totalQteEntr += parseFloat(Number(article.QteEntr));
  })
}
  else{
    this.props.fiches.fiches.forEach((article) => {
      totalQteEntr += parseFloat(Number(article.QteEntr));
    })
  };
    return totalQteEntr.toFixed(3); // arrondi à 4 chiffres après la virgule
   }else{ 
      // ///////////////////////////////////multi magasin///////////////////////////////
    if(this.state.rechercheclicked && this.state.rechercheclickedGMM ){
      this.state.rechsGMM.forEach((article) => {
        totalQteEntr += parseFloat(Number(article.QteEntr));
    })
  }
 
  else{
    this.props.fiches.fiches.forEach((article) => {
      totalQteEntr += parseFloat(Number(article.QteEntr));
    })
  };
    return totalQteEntr.toFixed(3); // arrondi à 4 chiffres après la virgule
   }
  };
  getTotalQtesor = () => {
    let totalQtesor = 0;
    if(this.state.GMM==false){
      if(this.state.rechercheclicked){
        this.state.rechs.forEach((article) => {
          totalQtesor += parseFloat(Number(article.QteSor));
      })
    }
    else if(this.state.showrechbydate){
      this.state.rechdats.forEach((article) => {
        totalQtesor += parseFloat(Number(article.QteSor));
    })
  }
    else{
      this.props.fiches.fiches.forEach((article) => {
        totalQtesor += parseFloat(Number(article.QteSor));
      })
    };
      return totalQtesor.toFixed(3); // arrondi à 4 chiffres après la virgule
    }else{
        // ///////////////////////////////////multi magasin///////////////////////////////
    if(this.state.rechercheclicked && this.state.rechercheclickedGMM){
      this.state.rechsGMM.forEach((article) => {
        totalQtesor += parseFloat(Number(article.QteSor));
    })
  }
   
    else{
      this.props.fiches.fiches.forEach((article) => {
        totalQtesor += parseFloat(Number(article.QteSor));
      })
    };
      return totalQtesor.toFixed(3); // arrondi à 4 chiffres après la virgule
    }
  
  };

  
  getTotalQteret = () => {
    let totalQteret = 0;
    if(this.state.GMM==false){
      if(this.state.rechercheclicked){
        this.state.rechs.forEach((article) => {
          totalQteret += parseFloat(Number(article.QteRet));
      })
    } 
  else if(this.state.showrechbydate){
      this.state.rechdats.forEach((article) => {
        totalQteret += parseFloat(Number(article.QteRet));
    })
  }
    else{
      this.props.fiches.fiches.forEach((article) => {
        totalQteret += parseFloat(Number(article.QteRet));
      })
    };
      return totalQteret.toFixed(3); // arrondi à 4 chiffres après la virgule
    }
    
    else{
        // ///////////////////////////////////multi magasin///////////////////////////////
      if(this.state.rechercheclicked && this.state.rechercheclickedGMM){
      this.state.rechsGMM.forEach((article) => {
        totalQteret += parseFloat(Number(article.QteRet));
    })
 
  }
    else{
      this.props.fiches.fiches.forEach((article) => {
        totalQteret += parseFloat(Number(article.QteRet));
      })
    };
      return totalQteret.toFixed(3); // arrondi à 4 chiffres après la virgule

    }
 
  };



  exportToExcel = () => {
          // ///////////////////////////////////mono magasin///////////////////////////////////////////////
    if(this.state.GMM==false){

      if(this.state.rechercheclicked){

        // Préparation des données
        const data = this.state.rechs.map(item => {
          const date = new Date(item.datfac);
          //const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
          let Ccode;
          if (item.datfac==null) {
            if(item.libelle=="Totaux"){ 
              Ccode = '';
            }else{
               Ccode = item.codart;
            }
           
      } else{
        Ccode='';
      }
         
          let formattedDate;
          if (item.datfac==null) {
           
              formattedDate = '-';
        } else{
           formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
          return [
            formattedDate,
           Ccode,
            item.libelle,
            item.QteEntr,
            item.QteSor,
            item.QteRet,
            item.PrixEntr,
            item.QteEntr + item.QteRet - item.QteSor ,
            item.ValEntr + item.valRet - item.ValSor ,
            // item.StkFin,
           //  item.StkFinVal,
            // Ajoutez d'autres champs au besoin
          ];
        });
    
        // Calcul des totaux
        const totals = data.reduce(
          (acc, row) => {
            acc[3] += row[3]; // Qté Entrée
            acc[4] += row[4]; // Qté Sortie
            acc[5] += row[5]; // Qté Retour
            acc[7] += row[7]; // stkfin
            acc[8] += row[8]; // Valeur
            return acc;
          },
        
          ['', 'T o t a u x', '', 0, 0, 0, '', 0, 0]
        );
    
        // Ajout des totaux à la fin des données
        data.push(totals);
    
        // Création d'un nouveau classeur
        const classeur = XLSX.utils.book_new();
        const feuille = XLSX.utils.aoa_to_sheet([]);
    
        // Ajout du titre de tableau (première ligne)
        const title = '                                                           Liste des Fiches de stock'+
     
        ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
        feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
        feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 8 } }]; // Fusionner les cellules pour le titre
    
        // Définition de l'entête du tableau (deuxième ligne)
        const header = ['Date', 'Code article', 'Désignation', 'Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
        XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
        // Ajout des données de tableau à partir de la cinquième ligne
        XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
    
        // Définition des bordures
        const range = XLSX.utils.decode_range(feuille['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!feuille[cellRef]) continue;
            feuille[cellRef].s = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
            };
          }
        }
    
        // Ajout de la feuille au classeur
        XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
    
        // Génération d'un fichier téléchargeable
        XLSX.writeFile(classeur, "fiche.xlsx")
        }else if(this.state.showrechbydatecodeart && this.state.showrechbydate){

          // Préparation des données
          const data = this.state.rechdats.map(item => {
            const date = new Date(item.datfac);
            //const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
            let Ccode;
            if (item.datfac==null) {
              if(item.libelle=="Totaux"){ 
                Ccode = '';
              }else{
                 Ccode = item.codart;
              }
             
        } else{
          Ccode='';
        }
           
            let formattedDate;
            if (item.datfac==null) {
             
                formattedDate = '-';
          } else{
             formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          }
            return [
              formattedDate,
             Ccode,
              item.libelle,
              item.QteEntr,
              item.QteSor,
              item.QteRet,
              item.PrixEntr,
              item.QteEntr + item.QteRet - item.QteSor ,
              item.ValEntr + item.valRet - item.ValSor ,
              // item.StkFin,
              // item.StkFinVal,
              // Ajoutez d'autres champs au besoin
            ];
          });
      
          // Calcul des totaux
      
      
          // Création d'un nouveau classeur
          const classeur = XLSX.utils.book_new();
          const feuille = XLSX.utils.aoa_to_sheet([]);
      
          // Ajout du titre de tableau (première ligne)
          const title = '                                                           Liste des Fiches de stock'+
     
          ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
          feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
          feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 8 } }]; // Fusionner les cellules pour le titre
      
          // Définition de l'entête du tableau (deuxième ligne)
          const header = ['Date', 'Code article', 'Désignation', 'Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
          XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
      
          // Ajout des données de tableau à partir de la cinquième ligne
          XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
      
          // Définition des bordures
          const range = XLSX.utils.decode_range(feuille['!ref']);
          for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cellAddress = { c: C, r: R };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              if (!feuille[cellRef]) continue;
              feuille[cellRef].s = {
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
              };
            }
          }
      
          // Ajout de la feuille au classeur
          XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
      
          // Génération d'un fichier téléchargeable
          XLSX.writeFile(classeur, "fiche.xlsx")
          }
       
        else{
        // Préparation des données
        const data = this.props.fiches.fiches.map(item => {
          const date = new Date(item.datfac);
         // const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
          let Ccode;
          if (item.datfac==null) {
            if(item.libelle=="Totaux"){ 
              Ccode = '';
            }else{
               Ccode = item.codart;
            }
           
      } else{
        Ccode='';
      }
         
          let formattedDate;
          if (item.datfac==null) {
           
              formattedDate = '-';
        } else{
           formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
          return [
            formattedDate,
           Ccode,
            item.libelle,
            item.QteEntr,
            item.QteSor,
            item.QteRet,
            item.PrixEntr,
            item.QteEntr + item.QteRet - item.QteSor ,
            item.ValEntr + item.valRet - item.ValSor ,
            // item.StkFin,
            // item.StkFinVal,
            // Ajoutez d'autres champs au besoin
          ];
        });
    
        // Calcul des totaux
        const totals = data.reduce(
          (acc, row) => {
            // acc[3] += row[3]; // Qté Entrée
            // acc[4] += row[4]; // Qté Sortie
            // acc[5] += row[5]; // Qté Retour
            // acc[7] += row[7]; // stkfin
            acc[8] += row[8]; // Valeur
            return acc;
          },
        
          ['', '', 'T o t a u x', '', '', '', '', '', 0]
        );
    
        // Ajout des totaux à la fin des données
        data.push(totals);
    
        // Création d'un nouveau classeur
        const classeur = XLSX.utils.book_new();
        const feuille = XLSX.utils.aoa_to_sheet([]);
    
        // Ajout du titre de tableau (première ligne)
        const title = '                                                           Liste des Fiches de stock'+
     
        ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
        feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
        feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 8 } }]; // Fusionner les cellules pour le titre
    
        // Définition de l'entête du tableau (deuxième ligne)
        const header = ['Date', 'Code article', 'Désignation', 'Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
        XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
        // Ajout des données de tableau à partir de la cinquième ligne
        XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
    
        // Définition des bordures
        const range = XLSX.utils.decode_range(feuille['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!feuille[cellRef]) continue;
            feuille[cellRef].s = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
            };
          }
        }
    
        // Ajout de la feuille au classeur
        XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
    
        // Génération d'un fichier téléchargeable
        XLSX.writeFile(classeur, "fiche.xlsx")
      }



    }else{

      // ///////////////////////////////////multi magasin///////////////////////////////////////////////      
    if(this.state.rechercheclicked && this.state.rechercheclickedGMM){

      // Préparation des données
      const data = this.state.rechsGMM.map(item => {
        const date = new Date(item.datfac);
        //const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
        let Ccode;
          if (item.datfac==null) {
            if(item.libelle=="Totaux"){ 
              Ccode = '';
            }else{
               Ccode = item.codart;
            }
           
      } else{
        Ccode='';
      }
         
          let formattedDate;
          if (item.datfac==null) {
           
              formattedDate = '-';
        } else{
           formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
        return [
          formattedDate,
          Ccode,
          item.libelle,
          item.QteEntr,
          item.QteSor,
          item.QteRet,
          item.PrixEntr,
          item.QteEntr + item.QteRet - item.QteSor ,
          //item.ValEntr + item.valRet - item.ValSor ,
          // item.StkFin,
           item.StkFinVal,
          // Ajoutez d'autres champs au besoin
        ];
      });
  
      // Calcul des totaux
      const totals = data.reduce(
        (acc, row) => {
          acc[3] += row[3]; // Qté Entrée
          acc[4] += row[4]; // Qté Sortie
          acc[5] += row[5]; // Qté Retour
          acc[7] += row[7]; // stkfin
          acc[8] += row[8]; // Valeur
          return acc;
        },
      
        ['', 'T o t a u x', '', 0, 0, 0, '', 0, 0]
      );
  
      // Ajout des totaux à la fin des données
      data.push(totals);
  
      // Création d'un nouveau classeur
      const classeur = XLSX.utils.book_new();
      const feuille = XLSX.utils.aoa_to_sheet([]);
  // Récupération de item.lib pour le titre
const libelleItem = this.state.rechsGMM.length > 0 ? this.state.rechsGMM[0].lib : '';

      // Ajout du titre de tableau (première ligne)
 
   // Titre avec item.lib

const title = `                                         Liste des Fiches de stock - ( ${libelleItem} )`+
     
` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;   
     //  const title = 'Liste des Fiches de stock';
  

      feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
      feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 8 } }]; // Fusionner les cellules pour le titre
  
      // Définition de l'entête du tableau (deuxième ligne)
      const header = ['Date', 'Code article', 'Désignation', 'Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
      XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
  
      // Ajout des données de tableau à partir de la cinquième ligne
      XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
  
      // Définition des bordures
      const range = XLSX.utils.decode_range(feuille['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          if (!feuille[cellRef]) continue;
          feuille[cellRef].s = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
          };
        }
      }
  
      // Ajout de la feuille au classeur
      XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
  
      // Génération d'un fichier téléchargeable
      XLSX.writeFile(classeur, "fiche_MG.xlsx")
      }else  if(this.state.showrechbydatecodeartGMM && this.state.showrechbydateGMM){
    // ///////////////////////////////////multi magasin///////////////////////////////////////////////
        // Préparation des données
        const data = this.state.rechdatsGMM.map(item => {
          const date = new Date(item.datfac);
          //const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
          let Ccode;
          if (item.datfac==null) {
            if(item.libelle=="Totaux"){ 
              Ccode = '';
            }else{
               Ccode = item.codart;
            }
           
      } else{
        Ccode='';
      }
         
          let formattedDate;
          if (item.datfac==null) {
           
              formattedDate = '-';
        } else{
           formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
        return [
            formattedDate,
            Ccode,
            item.libelle,
            item.lib,
            item.QteEntr,
            item.QteSor,
            item.QteRet,
            item.PrixEntr,
            item.QteEntr + item.QteRet - item.QteSor ,
            //item.ValEntr + item.valRet - item.ValSor ,
            // item.StkFin,
             item.StkFinVal,
            // Ajoutez d'autres champs au besoin
          ];
        });
    
       
        // Création d'un nouveau classeur
        const classeur = XLSX.utils.book_new();
        const feuille = XLSX.utils.aoa_to_sheet([]);
    
        // Ajout du titre de tableau (première ligne)
        const title = '                                                           Liste des Fiches de stock'+
     
        ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
        feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
        feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 9 } }]; // Fusionner les cellules pour le titre
    
        // Définition de l'entête du tableau (deuxième ligne)
        const header = ['Date', 'Code article', 'Désignation', 'MG','Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
        XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
    
        // Ajout des données de tableau à partir de la cinquième ligne
        XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
    
        // Définition des bordures
        const range = XLSX.utils.decode_range(feuille['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!feuille[cellRef]) continue;
            feuille[cellRef].s = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
            };
          }
        }
    
        // Ajout de la feuille au classeur
        XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
    
        // Génération d'un fichier téléchargeable
        XLSX.writeFile(classeur, "fiche_date.xlsx")
        }
  
      else{
      // Préparation des données
      const data = this.state.fichGMM.map(item => {
        const date = new Date(item.datfac);
       // const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
        let Ccode;
        if (item.datfac==null) {
          if(item.libelle=="Totaux"){ 
            Ccode = '';
          }else{
             Ccode = item.codart;
          }
         
    } else{
      Ccode='';
    }
       
        let formattedDate;
        if (item.datfac==null) {
         
            formattedDate = '-';
      } else{
         formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
        return [
          formattedDate,
          Ccode,
          item.libelle,
          item.lib,
          item.QteEntr,
          item.QteSor,
          item.QteRet,
          item.PrixEntr,
          item.StkFin,
          item.StkFinVal,
          // Ajoutez d'autres champs au besoin
        ];
      });
  
      // Calcul des totaux
      const totals = data.reduce(
        (acc, row) => {
        //  acc[4] += row[4];
          // acc[5] += row[5]; // Qté Entrée
          // acc[6] += row[6]; // Qté Sortie
          // acc[7] += row[7]; // Qté Retour
          // acc[8] += row[8]; // stkfin
          acc[9] += row[9]; // Valeur
          return acc;
        },
      
        ['', '', 'T o t a u x','', '', '', '', '','', 0]
      );
  
      // Ajout des totaux à la fin des données
      data.push(totals);
  
      // Création d'un nouveau classeur
      const classeur = XLSX.utils.book_new();
      const feuille = XLSX.utils.aoa_to_sheet([]);
  
      // Ajout du titre de tableau (première ligne)
      const title = '                                                           Liste des Fiches de stock'+
     
        ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
        
        feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
      feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 2, c: 9 } }]; // Fusionner les cellules pour le titre
  
      // Définition de l'entête du tableau (deuxième ligne), 
      const header = ['Date', 'Code article', 'Désignation','Mg', 'Qté Entrée', 'Qté sortie', 'Qté Retour', 'Prix MVT', 'stkfin', 'Valeur'];
      XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });
  
      // Ajout des données de tableau à partir de la cinquième ligne
      XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
  
      // Définition des bordures
      const range = XLSX.utils.decode_range(feuille['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          if (!feuille[cellRef]) continue;
          feuille[cellRef].s = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
          };
        }
      }
  
      // Ajout de la feuille au classeur
      XLSX.utils.book_append_sheet(classeur, feuille, "fiche");
  
      // Génération d'un fichier téléchargeable
      XLSX.writeFile(classeur, "fiche_art.xlsx")
    }
    }

  }


 

  render() {
   
    // console.log("fichesGMM", this.state.fichGMM)
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
   
   //   let addModalClose1 = () => this.setState({ addModalShow1: false });
    let GetByIdModalClose = () => this.setState({ GetByIdModalShow: false });
    const {
      designationarticle,
      codearticle,
       datfac,
      qteentr,
        qtesor,
         qteret,
        valRet,
        ValEntr,
        ValSor,
        StkFin,
        StkFinVal,
        firstdate,
        seconddate,
       PrixEntr,
       
       libb,
       coddepp,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const { darck } = this.props;

 
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Stock"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>

          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/fiche-article"
            label="Fiche de stock"
            icon={<MenuBookIcon fontSize="small" />}
          />
                <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
            {/* <button onClick={this.exportToExcel}>Exporter vers Excel</button> */}
        </Breadcrumbs>
        <main className="gestion-des-articles">
        
          <br />
          <div>
            <Paper
              // elevation={0.5}
              elevation={2}
              style={{
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
            >
              <Row style={{display : "flex" , flexWrap: "nowrap" , marginTop:"2px"}}>
              <Col sm={10}>
               
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                /> 
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    defaultValue={this.state.codart1}
                    onChange={this.Handlercodart}
                   value={this.state.codart1}
                    onClick={this.toggle}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.d1}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  
                  </>
                ) : (
                 
                 <> 
          {this.state.GMM ? (<>  
                      <InputBase
                 margin="dense"
                 // fullWidth={true}
                 placeholder="Rechercher par code art "
                 defaultValue={this.state.codart2}
                 onChange={this.rechercheHandler}
                 value={this.state.codart2}
                 onClick={this.toggle}
                 style={{ color: `${darck ? "" : "#fff"}` }}
               />
                     <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                

                <InputBase
                   margin="dense"
                    //fullWidth={true}
                   placeholder="Rechercher par Mg "
                   onChange={this.rechercheHandlerGMM}
                   onClick={this.toggle}
                   style={{ color: `${darck ? "" : "#fff"}` }}
                 /> 
                </>):(<>  
                 <InputBase
                margin="dense"
                // fullWidth={true}
                placeholder="Rechercher "
                onChange={this.rechercheHandler}
                onClick={this.toggle}
                style={{ color: `${darck ? "" : "#fff"}` }}
              /> &nbsp;&nbsp; &nbsp;&nbsp;
              
                  </>)}
                </>
                )}
              </Col>
              
                {this.props.Auth.fct == "100" ? (
                  <Col sm={2} style={{ display: "flex", justifyContent: "end" }} className="buttart">
                  
                  </Col>
                ) : null}{" "}
              </Row>
            </Paper>
          
           
          </div>
          <br />

     {this.state.GMM?(<>
     
      {/* // ///////////////////////////////////multi magasin/////////////////////////////// */}
      {this.state.rechercheclicked && this.state.rechercheclickedGMM ?(
            <div className="tabga">
         <table striped>
              
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
               
                {stableSort(
                  this.state.rechsGMM,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((article) => (
                  <tr
                   
                    key={article.codart}
                    onClick={() => {
                      fetch(
                      //`https://www.24-crm.com/webservice/api/BEREs?iddd=${article.codart}&type&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                    `https://www.24-crm.com/webservice/api/BEREs?iddd=${article.codart}&coddep=${article.coddep}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
   
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );
                      this.setState({
                        GetByIdModalShow: true,
                        designationarticle: article.libelle,
                        codearticle: article.codart,
                        datfac:article.datfac,
                        qteentr: article.qteentr,
                        qtesor: article.qtesor,
                        qteret: article.qteret,
                        valRet: article.valRet,
                        ValEntr: article.ValEntr,
                        ValSor: article.ValSor,
                        StkFin:article.StkFin,
                        StkFinVal:article.StkFinVal,
                        PrixEntr:article.PrixEntr,
                  
                        coddepp: article.Coddep,
                        libb:article.lib
                      });
                      article.codart === null
                        ? this.setState({ codart: "" })
                        : this.setState({ codart: article.codart });
                    }}
                  >
                       {article.datfac == null? (<>
                      <td>
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                     
                        {article.datfac == null? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        { moment(article.datfac).format("DD/MM/YYYY")}
                      </span>
                    )}
                 
                      </span>
                    </td>

                    <td> 
                      
                      
                      <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                        {article.codart}   
                      </span>
                    </td>

                    <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                      <span>{article.libelle} ({article.coddep} : {article.lib} )</span>
                    </td>

                    <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      <span>
                    
                        {article.QteEntr == '0'? (
                     <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.QteEntr).toFixed(3)}
                      </span>
                    )}
                 
                        </span>

                    </td>
                     <td>
                      <span style={{ color: "red" }}>
                      {article.QteSor == '0'? (
                     <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        {Number(article.QteSor).toFixed(3)}
                      </span>
                    )}
                      
                      </span>
                    </td>

                      <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                      {article.QteRet == '0'? (
                     <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        <span></span>
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        {Number(article.QteRet).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {article.PrixEntr == '0'? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.PrixEntr).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                   <td>
                      <span style={{ color: "#8f2d56" }}>
                      {(Number(article.StkFin)).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#8f2d56" }}>
                      {(Number(article.StkFinVal)).toFixed(3)}
                      </span>
                    </td></>
                  ):(<>
                      <td>
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                        {article.datfac == null? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      --
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        { moment(article.datfac).format("DD/MM/YYYY")}
                      </span>
                    )}
                 
                      </span>
                    </td>

                    <td> 
                      
                     
                      <span style={{ color: `${darck ? "#182532" : "#fff"}`}}>
                          
                      </span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{article.libelle}</span>
                    </td>

                    <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      <span>
                      
                        {article.QteEntr == '0'? (
                     <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.QteEntr).toFixed(3)}
                      </span>
                    )}
                 
                        </span>

                    </td>
                     <td>
                      <span style={{ color: "red" }}>
                      {article.QteSor == '0'? (
                     <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        {Number(article.QteSor).toFixed(3)}
                      </span>
                    )}
                        
                      </span>
                    </td>

                      <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                      {article.QteRet == '0'? (
                     <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        {Number(article.QteRet).toFixed(3)}
                      </span>
                    )}
                     
                    </td>
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {article.PrixEntr == '0'? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.PrixEntr).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                   <td>
                      <span style={{ color: !darck ? "#fff" : null }}>
                      {(Number(article.StkFin)).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "black" }}>
                      {(Number(article.StkFinVal)).toFixed(3)}
                      </span>
                    </td>
                    
                   </>)}
                    

                  </tr>
                ))}
              </tbody>
            </table>
         
            
                                               
<br />
   
   <Paper
     style={{
       backgroundColor: `${darck ? "" : "#172431"}`,
       border: `${darck ? "" : "2px solid #fff"}`,
     }}
     // elevation={0.5}
     elevation={3}
   >
     <Row style={{ flexWrap: "nowrap" }}>
     

       <Col sm={4}>
         <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
          

           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <label
            style={{ color: `${darck ? "#0D47A1" : "#fff"}`
            , fontSize: "12px" }}

           >TOTAUX : </label>
         </div>
       </Col> 
       
       <Col sm={1} style={{ display: "flex",paddingLeft: "30px"}}>
       <label>
       
       <span style={{ color: "#0D47A1" }}>
         {this.getTotalQteEntr()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex",paddingLeft: "90px"}}>
     <label>
      
       <span style={{ color: "red" }}>
         {this.getTotalQtesor()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex",paddingLeft: "130px"}}>
     <label>
      
       <span style={{ color: "green" }}>
         {this.getTotalQteret()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex"}}></Col>
     <Col sm={1} style={{ display: "flex"}}></Col>

     <Col sm={1} style={{ display: "flex",paddingLeft: "30px"}}>
     <label>
       
        <span style={{ color: "#0D47A1" }}>
          {this.getTotalStkfin()}
        </span>
      </label>
      </Col>
      <Col sm={1} style={{ display: "flex",paddingLeft: "90px"}}>
       <label>
            
            <span style={{ color: "#0D47A1" }}>
              {this.getTotalValeur()}
            </span>
          </label>  

    
       </Col>
     </Row>
   </Paper>  
        </div>
          ) :this.state.showrechbydatecodeartGMM && this.state.showrechbydateGMM ? (
            <div className="tabga">
            <table striped>
               
               <EnhancedTableHead
                 classes={classes}
                 order={this.state.order}
                 orderBy={this.state.orderBy}
                 onRequestSort={this.handleRequestSort}
                 darck={darck}
               />
           <tbody>
           <tbody style={{ maxHeight: "60vh" }}>
                 {stableSort(
                   this.state.rechdatsGMM,
                   getComparator(this.state.order, this.state.orderBy)
                 ).map((article) => (
                  <tr
                
                  key={article.codart}
                  onClick={() => {
                    fetch(
                  
                       `https://www.24-crm.com/webservice/api/BEREs?dattstkGMM=${this.state.firstdate}&datttstkGMM=${this.state.seconddate}&codeartGMM=${article.codart}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`

                    )
                      .then((response) => response.json())
                      .then((data) =>
                        this.setState({
                          tabtab: data,
                          sumqt:
                            data &&
                            data.reduce(
                              (a, v) => a + parseInt(v.quantite),
                              0
                            ),
                        })
                      );
                    this.setState({
                      GetByIdModalShow: true,
                      designationarticle: article.libelle,
                      codearticle: article.codart,
                      datfac:article.datfac,
                      qteentr: article.qteentr,
                      qtesor: article.qtesor,
                      qteret: article.qteret,
                      valRet: article.valRet,
                      ValEntr: article.ValEntr,
                      ValSor: article.ValSor,
                      StkFin:article.StkFin,
                      StkFinVal:article.StkFinVal,
                      PrixEntr:article.PrixEntr, 
                      coddepp: article.Coddep,
                      libb:article.lib
                    });
                  
                  }}
                >
                   {article.datfac == null? (<>
                        <td>
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                          {article.datfac == null? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          { moment(article.datfac).format("DD/MM/YYYY")}
                        </span>
                      )}
                   
                        </span>
                      </td>

                      <td> 
                        
                        
                        <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                          {article.codart}   
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                        <span>{article.libelle}</span>
                      </td>

                      <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        <span>
                      
                          {article.QteEntr == '0'? (
                       <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.QteEntr).toFixed(3)}
                        </span>
                      )}
                   
                          </span>

                      </td>
                       <td>
                        <span style={{ color: "red" }}>
                        {article.QteSor == '0'? (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                          {Number(article.QteSor).toFixed(3)}
                        </span>
                      )}
                        
                        </span>
                      </td>

                        <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                        {article.QteRet == '0'? (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                          {Number(article.QteRet).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                        <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {article.PrixEntr == '0'? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.PrixEntr).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                     <td>
                        <span style={{ color: "#8f2d56" }}>
                        {(Number(article.StkFin)).toFixed(3)}
                        </span>
                      </td>
                      <td>
                        <span style={{ color: "#8f2d56" }}>
                        {(Number(article.StkFinVal)).toFixed(3)}
                        </span>
                      </td></>
                    ):(<>
                        <td>
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                          {article.datfac == null? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        --
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          { moment(article.datfac).format("DD/MM/YYYY")}
                        </span>
                      )}
                   
                        </span>
                      </td>

                      <td> 
                        
                       
                        <span style={{ color: `${darck ? "#DF5F10" : "#fff"}`}}>
                        {article.lib}
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.libelle}</span>
                      </td>

                      <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        <span>
                        
                          {article.QteEntr == '0'? (
                       <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.QteEntr).toFixed(3)}
                        </span>
                      )}
                   
                          </span>

                      </td>
                       <td>
                        <span style={{ color: "red" }}>
                        {article.QteSor == '0'? (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                          {Number(article.QteSor).toFixed(3)}
                        </span>
                      )}
                          
                        </span>
                      </td>

                        <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                        {article.QteRet == '0'? (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                          {Number(article.QteRet).toFixed(3)}
                        </span>
                      )}
                       
                      </td>
                        <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {article.PrixEntr == '0'? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.PrixEntr).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                     <td>
                        <span style={{ color: !darck ? "#fff" : null }}>
                        {(Number(article.StkFin)).toFixed(3)}
                        </span>
                      </td>
                      <td>
                        <span style={{ color: "black" }}>
                        {(Number(article.StkFinVal)).toFixed(3)}
                        </span>
                      </td></>)}
                  
                 
                </tr>
                 ))}
               </tbody>
           </tbody>
             </table>

              {/*  ///////////////////////////////////////////////////////debut print etat ///////////////////////////////////// */}
           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                       
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
               
                   </Col>
            </Row>  
  <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche de stock </>
          
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "380px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
            
                  de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                   { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
         <>
          <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                   
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Mg")}</th>
                
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Valeur")}</th>
               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechdatsGMM.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                    

                    
                      {t.datfac == null? (<>
                   {t.libelle=="Totaux"?(
                    <>
    <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
             
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
           
              <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}` ,width: "0%" ,   border: "1px solid gray"}} >
            
           {t.lib}</td> 
           
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               {/* |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)} */}
               </>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)} */}
              </>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)} */}
              </>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
          
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
            </> 

                   </>
                  ):(<>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
               
              
                
               </td>
          {/* rowspan="3"    colspan="2"*/}
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.codart}:{t.libelle}</td>
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}></td>
           <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
              {/* ////////////////// entrée ///////////*/}
            
           </td>

            {/* ////////////////// sortie ///////////*/}
           <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
            
            
             </td>
              {/* ////////////////// retour ///////////*/}
           <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
            
             </td>
           
           <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
             <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
               </>)} 
                 
             
            
           
              </>):(
                 <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.datfac == null? (
                 <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                  --
                  </span>
                ) :t.libelle == "Totaux"? (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                 
                   </span>
                 ) : (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                    { moment(t.datfac).format("DD/MM/YYYY")}
                  </span>
                )}
                
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
           
              <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}` ,width: "0%" ,   border: "1px solid gray"}} >
            
           {t.lib}</td> 
           
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               {/* |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)} */}
               </>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)} */}
              </>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)} */}
              </>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
          
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
            </> 
           )}     
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

    
                
               
         </>
               
           
           
               
              </div>
              <br />
          
            
            </div>
          </div>
   {/*  /////////////////////////////////////////////////////// finprint etat ///////////////////////////////////// */}
      </div>
          ):(
            <div className="tabga">
            
                <table striped >
               
               <EnhancedTableHead
                 classes={classes}
                 order={this.state.order}
                 orderBy={this.state.orderBy}
                 onRequestSort={this.handleRequestSort}
                 darck={darck}
               /> 
               <tbody style={{ maxHeight: "60vh" }}>
                 {stableSort(
                     //  this.props.fiches.fiches,
                   this.state.fichGMM,
                   getComparator(this.state.order, this.state.orderBy)
                 ).map((article) => (
                  
                   <tr
                     style={{ cursor: "pointer" }}
                     key={article.codart}
                     onClick={() => {
                       fetch(
                         `https://www.24-crm.com/webservice/api/BEREs?idApGMM=${article.codart}&type&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                        
                       )
                         .then((response) => response.json())
                         .then((data) =>
                           this.setState({
                             tabtab: data,
                             sumqt:
                               data &&
                               data.reduce(
                                 (a, v) => a + parseInt(v.quantite),
                                 0
                               ),
                           })
                         );
                       this.setState({
                         GetByIdModalShow: true,
                         designationarticle: article.libelle,
                         codearticle: article.codart,
                         datfac:article.datfac,
                         qteentr: article.qteentr,
                         qtesor: article.qtesor,
                         qteret: article.qteret,
                         valRet: article.valRet,
                         ValEntr: article.ValEntr,
                         ValSor: article.ValSor,
                         StkFin:article.StkFin,
                         StkFinVal:article.StkFinVal,
                         PrixEntr:article.PrixEntr,
                    
                         coddepp: article.coddep,
                         libb:article.lib
                       });
                       article.codart === null
                         ? this.setState({ codart: "" })
                         : this.setState({ codart: article.codart });
                     }}
                   >
                     {article.datfac == null? (<>
                       <td>
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                         {article.datfac == null? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         { moment(article.datfac).format("DD/MM/YYYY")}
                       </span>
                     )}
                  
                       </span>
                     </td>

                     <td> 
                       
                       
                       <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                         {article.codart}   
                       </span>
                     </td>

                     <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                       <span>{article.libelle}</span>
                     </td>

                     <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       <span>
                       
                         {article.QteEntr == '0'? (
                      <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.QteEntr).toFixed(3)}
                       </span>
                     )}
                  
                         </span>

                     </td>
                      <td>
                       <span style={{ color: "red" }}>
                       {article.QteSor == '0'? (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                         {Number(article.QteSor).toFixed(3)}
                       </span>
                     )}
                       
                       </span>
                     </td>

                       <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                       {article.QteRet == '0'? (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                         {Number(article.QteRet).toFixed(3)}
                       </span>
                     )}
                      
                     </td>
                       <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       {article.PrixEntr == '0'? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.PrixEntr).toFixed(3)}
                       </span>
                     )}
                      
                     </td>
                    <td>
                       <span style={{ color: "#8f2d56" }}>
                       {(Number(article.StkFin)).toFixed(3)}
                       </span>
                     </td>
                     <td>
                       <span style={{ color: "#8f2d56" }}>
                       {(Number(article.StkFinVal)).toFixed(3)}
                       </span>
                     </td></>
                   ):(<>
                       <td>
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                         {article.datfac == null? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       --
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         { moment(article.datfac).format("DD/MM/YYYY")}
                       </span>
                     )}
                  
                       </span>
                     </td>

                     <td> 
                       
                    
                       <span style={{ color: `${darck ? "#DF5F10" : "#fff"}`}}>
                          {article.lib} 
                       </span>
                     </td>

                     <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       <span>{article.libelle}</span>
                     </td>

                     <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       <span>
                        
                         {article.QteEntr == '0'? (
                      <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#0D47A1" : "#fff"}`}}>
                         {Number(article.QteEntr).toFixed(3)}
                       </span>
                     )}
                  
                         </span>

                     </td>
                      <td>
                       <span style={{ color: "red" }}>
                       {article.QteSor == '0'? (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                         {Number(article.QteSor).toFixed(3)}
                       </span>
                     )}
                        
                       </span>
                     </td>

                       <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                       {article.QteRet == '0'? (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                         {Number(article.QteRet).toFixed(3)}
                       </span>
                     )}
                     
                     </td>
                       <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       {article.PrixEntr == '0'? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.PrixEntr).toFixed(3)}
                       </span>
                     )}
                     
                     </td>
                    <td>
                       <span style={{ color: !darck ? "#fff" : null }}>
                       {(Number(article.StkFin)).toFixed(3)}
                       </span>
                     </td>
                     <td>
                       <span style={{ color: "#0D47A1" }}>
                       {(Number(article.StkFinVal)).toFixed(3)}
                       </span>
                     </td></>)}
                     
                    
                   </tr>
                 ))}
               </tbody>
             </table>
    
                                
<br />
  
  <Paper
    style={{
      backgroundColor: `${darck ? "" : "#172431"}`,
      border: `${darck ? "" : "2px solid #fff"}`,
    }}
    // elevation={0.5}
    elevation={3}
  >
    <Row style={{ flexWrap: "nowrap" }}>
     
      <Col sm={9}>
        <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
         

        
          <label
           style={{ color: `${darck ? "#0D47A1" : "#fff"}`
           , fontSize: "12px" }}

          >TOTAUX :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
        </div>
      </Col> 
         <Col sm={2} style={{ display: "flex",justifyContent: "end" ,marginLeft:"60px"}}>
      <label>
          
           <span style={{ color: "#0D47A1" }}>
        {this.getTotalValeur()}
           </span>
         </label>  

   
      </Col>
    </Row>
  </Paper>   
           
 {/*  ///////////////////////////////////////////////////////debut print etat ///////////////////////////////////// */}
           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                       
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
               
                   </Col>
            </Row>  
  <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche de stock </>
          
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "380px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
           
                  de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                   { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
         <>
          <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                     // fontSize: "14px",
                     // fontWeight: "bold",
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Mg")}</th>
                
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Valeur")}</th>
               
                </tr> 
                
                </thead>
            <tbody>
          
               {this.state.fichGMM.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                    

                    
                      {t.datfac == null? (<>
                   {t.libelle=="Totaux"?(<>
                   </>):(<>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
               
              
                
               </td>
          {/* rowspan="3"    colspan="2"*/}
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.codart}:{t.libelle}</td>
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}></td>
           <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
              {/* ////////////////// entrée ///////////*/}
            
           </td>

            {/* ////////////////// sortie ///////////*/}
           <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
            
            
             </td>
              {/* ////////////////// retour ///////////*/}
           <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
            
             </td>
           
           <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
             <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
               </>)} 
                 
             
            
           
              </>):( <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.datfac == null? (
                 <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                  --
                  </span>
                ) :t.libelle == "Totaux"? (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                 
                   </span>
                 ) : (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                    { moment(t.datfac).format("DD/MM/YYYY")}
                  </span>
                )}
                
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
           
              <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}` ,width: "0%" ,   border: "1px solid gray"}} >
            
           {t.lib}</td> 
           
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               {/* |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)} */}
               </>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)} */}
              </>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)} */}
              </>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
          
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
            </>  )}     
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

          <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {/* {this.getTotalQteEntr()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {/* {this.getTotalQtesor()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {/* {this.getTotalQteret()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
      
           {/* {this.getTotalStkfin()}| */}
           {this.getTotalValeur()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 
        
                
               
         </>
               
           
           
               
              </div>
              <br />
          
            
            </div>
          </div>
   {/*  /////////////////////////////////////////////////////// finprint etat ///////////////////////////////////// */}
                         </div>

                         
                         
          )}  
            
     
     {/*////////////////////////////////////////// Mono magasin //////////////////////////////////////////*/}
     </>):(<>
       
      {this.state.rechercheclicked ?(
            <div className="tabga">
         <table striped>
              
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
               
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((article) => (
                  <tr
                   
                    key={article.codart}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/BEREs?idddGMM=${article.codart}&type&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                       
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );
                      this.setState({
                        GetByIdModalShow: true,
                        designationarticle: article.libelle,
                        codearticle: article.codart,
                        datfac:article.datfac,
                        qteentr: article.qteentr,
                        qtesor: article.qtesor,
                        qteret: article.qteret,
                        valRet: article.valRet,
                        ValEntr: article.ValEntr,
                        ValSor: article.ValSor,
                        StkFin:article.StkFin,
                        StkFinVal:article.StkFinVal,
                        PrixEntr:article.PrixEntr,
                      
             
                      });
                      article.codart === null
                        ? this.setState({ codart: "" })
                        : this.setState({ codart: article.codart });
                    }}
                  >
                       {article.datfac == null? (<>
                      <td>
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                     
                        {article.datfac == null? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        { moment(article.datfac).format("DD/MM/YYYY")}
                      </span>
                    )}
                 
                      </span>
                    </td>

                    <td> 
                      
                      
                      <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                        {article.codart}   
                      </span>
                    </td>

                    <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                      <span>{article.libelle}</span>
                    </td>

                    <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      <span>
                    
                        {article.QteEntr == '0'? (
                     <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.QteEntr).toFixed(3)}
                      </span>
                    )}
                 
                        </span>

                    </td>
                     <td>
                      <span style={{ color: "red" }}>
                      {article.QteSor == '0'? (
                     <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        {Number(article.QteSor).toFixed(3)}
                      </span>
                    )}
                      
                      </span>
                    </td>

                      <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                      {article.QteRet == '0'? (
                     <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        {Number(article.QteRet).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {article.PrixEntr == '0'? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.PrixEntr).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                   <td>
                      <span style={{ color: "#8f2d56" }}>
                      {(Number(article.StkFin)).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#8f2d56" }}>
                      {(Number(article.StkFinVal)).toFixed(3)}
                      </span>
                    </td></>
                  ):(<>
                      <td>
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      
                        {article.datfac == null? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      --
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        { moment(article.datfac).format("DD/MM/YYYY")}
                      </span>
                    )}
                 
                      </span>
                    </td>

                    <td> 
                      
                     
                      <span style={{ color: `${darck ? "#182532" : "#fff"}`}}>
                          
                      </span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{article.libelle}</span>
                    </td>

                    <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      <span>
                      
                        {article.QteEntr == '0'? (
                     <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.QteEntr).toFixed(3)}
                      </span>
                    )}
                 
                        </span>

                    </td>
                     <td>
                      <span style={{ color: "red" }}>
                      {article.QteSor == '0'? (
                     <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        {Number(article.QteSor).toFixed(3)}
                      </span>
                    )}
                        
                      </span>
                    </td>

                      <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                      {article.QteRet == '0'? (
                     <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        {Number(article.QteRet).toFixed(3)}
                      </span>
                    )}
                     
                    </td>
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {article.PrixEntr == '0'? (
                     <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                      -
                      </span>
                    ) : (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        {Number(article.PrixEntr).toFixed(3)}
                      </span>
                    )}
                  
                    </td>
                   <td>
                      <span style={{color: !darck ? "#fff" : null }}>
                      {(Number(article.StkFin)).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: !darck ? "#0D47A1" : "#0D47A1"   }}>
                      {(Number(article.StkFinVal)).toFixed(3)}
                      </span>
                    </td>
                   </>)}
                    

                  </tr>
                ))}
              </tbody>
            </table>
         
            
                                               
<br />
   
   <Paper
     style={{
       backgroundColor: `${darck ? "" : "#172431"}`,
       border: `${darck ? "" : "2px solid #fff"}`,
     }}
     // elevation={0.5}
     elevation={3}
   >
     <Row style={{ flexWrap: "nowrap" }}>
     

       <Col sm={4}>
         <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
          

           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <label
            style={{ color: `${darck ? "#0D47A1" : "#fff"}`
            , fontSize: "12px" }}

           >TOTAUX : </label>
         </div>
       </Col> 
       
       <Col sm={1} style={{ display: "flex",paddingLeft: "30px"}}>
       <label>
       
       <span style={{ color: "#0D47A1" }}>
         {this.getTotalQteEntr()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex",paddingLeft: "90px"}}>
     <label>
      
       <span style={{ color: "red" }}>
         {this.getTotalQtesor()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex",paddingLeft: "130px"}}>
     <label>
      
       <span style={{ color: "green" }}>
         {this.getTotalQteret()}
       </span>
     </label>
     </Col>
     <Col sm={1} style={{ display: "flex"}}></Col>
     <Col sm={1} style={{ display: "flex"}}></Col>

     <Col sm={1} style={{ display: "flex",paddingLeft: "30px"}}>
     <label>
       
        <span style={{ color: "#0D47A1" }}>
          {this.getTotalStkfin()}
        </span>
      </label>
      </Col>
      <Col sm={1} style={{ display: "flex",paddingLeft: "90px"}}>
       <label>
            
            <span style={{ color: "#0D47A1" }}>
              {this.getTotalValeur()}
            </span>
          </label>  

    
       </Col>
     </Row>
   </Paper>  
        </div>
          ) :this.state.showrechbydatecodeart && this.state.showrechbydate ? (
            <div className="tabga">
            <table striped>
               
               <EnhancedTableHead
                 classes={classes}
                 order={this.state.order}
                 orderBy={this.state.orderBy}
                 onRequestSort={this.handleRequestSort}
                 darck={darck}
               />
           <tbody>
           <tbody style={{ maxHeight: "60vh" }}>
                 {stableSort(
                   this.state.rechdats,
                   getComparator(this.state.order, this.state.orderBy)
                 ).map((article) => (
                  <tr
                
                  key={article.codart}
                  onClick={() => {
                    fetch(
                  
                       `https://www.24-crm.com/webservice/api/BEREs?dattstk=${this.state.firstdate}&datttstk=${this.state.seconddate}&codeart=${article.codart}&coddosstk=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`

                    )
                      .then((response) => response.json())
                      .then((data) =>
                        this.setState({
                          tabtab: data,
                          sumqt:
                            data &&
                            data.reduce(
                              (a, v) => a + parseInt(v.quantite),
                              0
                            ),
                        })
                      );
                    this.setState({
                      GetByIdModalShow: true,
                      designationarticle: article.libelle,
                      codearticle: article.codart,
                      datfac:article.datfac,
                      qteentr: article.qteentr,
                      qtesor: article.qtesor,
                      qteret: article.qteret,
                      valRet: article.valRet,
                      ValEntr: article.ValEntr,
                      ValSor: article.ValSor,
                      StkFin:article.StkFin,
                      StkFinVal:article.StkFinVal,
                      PrixEntr:article.PrixEntr, 
                 
                    });
                  
                  }}
                >
                   {article.datfac == null? (<>
                        <td>
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                          {article.datfac == null? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          { moment(article.datfac).format("DD/MM/YYYY")}
                        </span>
                      )}
                   
                        </span>
                      </td>

                      <td> 
                        
                        
                        <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                          {article.codart}   
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                        <span>{article.libelle}</span>
                      </td>

                      <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        <span>
                      
                          {article.QteEntr == '0'? (
                       <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.QteEntr).toFixed(3)}
                        </span>
                      )}
                   
                          </span>

                      </td>
                       <td>
                        <span style={{ color: "red" }}>
                        {article.QteSor == '0'? (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                          {Number(article.QteSor).toFixed(3)}
                        </span>
                      )}
                        
                        </span>
                      </td>

                        <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                        {article.QteRet == '0'? (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                          {Number(article.QteRet).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                        <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {article.PrixEntr == '0'? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.PrixEntr).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                     <td>
                        <span style={{ color: "#8f2d56" }}>
                        {(Number(article.StkFin)).toFixed(3)}
                        </span>
                      </td>
                      <td>
                        <span style={{ color: "#8f2d56" }}>
                        {(Number(article.StkFinVal)).toFixed(3)}
                        </span>
                      </td></>
                    ):(<>
                        <td>
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        
                          {article.datfac == null? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        --
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          { moment(article.datfac).format("DD/MM/YYYY")}
                        </span>
                      )}
                   
                        </span>
                      </td>

                      <td> 
                        
                       
                        <span style={{ color: `${darck ? "#182532" : "#fff"}`}}>
                            
                        </span>
                      </td>

                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{article.libelle}</span>
                      </td>

                      <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        <span>
                        
                          {article.QteEntr == '0'? (
                       <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.QteEntr).toFixed(3)}
                        </span>
                      )}
                   
                          </span>

                      </td>
                       <td>
                        <span style={{ color: "red" }}>
                        {article.QteSor == '0'? (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                          {Number(article.QteSor).toFixed(3)}
                        </span>
                      )}
                          
                        </span>
                      </td>

                        <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                        {article.QteRet == '0'? (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                          {Number(article.QteRet).toFixed(3)}
                        </span>
                      )}
                       
                      </td>
                        <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {article.PrixEntr == '0'? (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                        -
                        </span>
                      ) : (
                        <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                          {Number(article.PrixEntr).toFixed(3)}
                        </span>
                      )}
                    
                      </td>
                     <td>
                        <span style={{ color: !darck ? "#fff" : null }}>
                        {(Number(article.StkFin)).toFixed(3)}
                        </span>
                      </td>
                      <td>
                        <span style={{ color: !darck ? "#0D47A1" :null  }}>
                        {(Number(article.StkFinVal)).toFixed(3)}
                        </span>
                      </td></>)}
                  
                 
                </tr>
                 ))}
               </tbody>
           </tbody>

           
 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche de stock </>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                   { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Valeur")}</th>
               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechdats.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                    

                    
                      {t.datfac == null? (<>
                   {t.libelle=="Totaux"?(
                    
                    <>
                    {/* ////////////////// date ///////////*/}
                   <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
                     </td>
               {/* ////////////////// libelle ///////////*/}
                   <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}} >
                   {t.libelle}
                    </td> 
                                      
                    
                     {/* ////////////////// entrée ///////////*/}
                   <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
                     
                     
                    {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} 
                    
                      
                   </td>
                 
                    {/* ////////////////// sortie ///////////*/}
                   <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
                    
                       {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
                     
                    
                    
                     </td>
                      {/* ////////////////// retour ///////////*/}
                   <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
                    
                       {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
                      </td>
                     {/* ////////////////// stfin///////////*/}
                   <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
                  {/* ////////////////// valeur ///////////*/}
                   <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
                   </> 
                   ):(
                    <>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
               
              
                
               </td>
          {/* rowspan="3"    colspan="2"*/}
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.codart}:{t.libelle}</td>
         
           <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
              {/* ////////////////// entrée ///////////*/}
            
           </td>

            {/* ////////////////// sortie ///////////*/}
           <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
            
            
             </td>
              {/* ////////////////// retour ///////////*/}
           <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
            
             </td>
           
           <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
             <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
               </>)} 
                 
             
            
           
              </>):( 
                <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.datfac == null? (
                 <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                  --
                  </span>
                ) :t.libelle == "Totaux"? (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                 
                   </span>
                 ) : (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                    { moment(t.datfac).format("DD/MM/YYYY")}
                  </span>
                )}
                
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
           
             
           
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               {/* |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)} */}
               </>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)} */}
              </>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)} */}
              </>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
          
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
            </> 
           )}     
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
             </table>

             
      </div>

      
          ):(
            <div className="tabga">
            
                <table striped >
               
               <EnhancedTableHead
                 classes={classes}
                 order={this.state.order}
                 orderBy={this.state.orderBy}
                 onRequestSort={this.handleRequestSort}
                 darck={darck}
               /> 
               <tbody style={{ maxHeight: "60vh" }}>
                 {stableSort(
                   this.props.fiches.fiches,
                   getComparator(this.state.order, this.state.orderBy)
                 ).map((article) => (
                  
                   <tr
                     style={{ cursor: "pointer" }}
                     key={article.codart}
                     onClick={() => {
                       fetch(
                         `https://www.24-crm.com/webservice/api/BEREs?idAp=${article.codart}&type&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                        
                       )
                         .then((response) => response.json())
                         .then((data) =>
                           this.setState({
                             tabtab: data,
                             sumqt:
                               data &&
                               data.reduce(
                                 (a, v) => a + parseInt(v.quantite),
                                 0
                               ),
                           })
                         );
                       this.setState({
                         GetByIdModalShow: true,
                         designationarticle: article.libelle,
                         codearticle: article.codart,
                         datfac:article.datfac,
                         qteentr: article.qteentr,
                         qtesor: article.qtesor,
                         qteret: article.qteret,
                         valRet: article.valRet,
                         ValEntr: article.ValEntr,
                         ValSor: article.ValSor,
                         StkFin:article.StkFin,
                         StkFinVal:article.StkFinVal,
                         PrixEntr:article.PrixEntr,
                   
                       });
                       article.codart === null
                         ? this.setState({ codart: "" })
                         : this.setState({ codart: article.codart });
                     }}
                   >
                     {article.datfac == null? (<>
                       <td>
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                         {article.datfac == null? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         { moment(article.datfac).format("DD/MM/YYYY")}
                       </span>
                     )}
                  
                       </span>
                     </td>

                     <td> 
                       
                       
                       <span style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px",}}>
                         {article.codart}   
                       </span>
                     </td>

                     <td style={{ color: `${darck ? "#8f2d56" : "#8f2d56"}`,fontWeight: "bold",fontSize: "14px", }}>
                       <span>{article.libelle}</span>
                     </td>

                     <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       <span>
                       
                         {article.QteEntr == '0'? (
                      <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.QteEntr).toFixed(3)}
                       </span>
                     )}
                  
                         </span>

                     </td>
                      <td>
                       <span style={{ color: "red" }}>
                       {article.QteSor == '0'? (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                         {Number(article.QteSor).toFixed(3)}
                       </span>
                     )}
                       
                       </span>
                     </td>

                       <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                       {article.QteRet == '0'? (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                         {Number(article.QteRet).toFixed(3)}
                       </span>
                     )}
                      
                     </td>
                       <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       {article.PrixEntr == '0'? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.PrixEntr).toFixed(3)}
                       </span>
                     )}
                      
                     </td>
                    <td>
                       <span style={{ color: "#8f2d56" }}>
                       {(Number(article.StkFin)).toFixed(3)}
                       </span>
                     </td>
                     <td>
                       <span style={{ color: "#8f2d56" }}>
                       {(Number(article.StkFinVal)).toFixed(3)}
                       </span>
                     </td></>
                   ):(<>
                       <td>
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       
                         {article.datfac == null? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       --
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         { moment(article.datfac).format("DD/MM/YYYY")}
                       </span>
                     )}
                  
                       </span>
                     </td>

                     <td> 
                       
                    
                       <span style={{ color: `${darck ? "#182532" : "#fff"}`}}>
                           
                       </span>
                     </td>

                     <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       <span>{article.libelle}</span>
                     </td>

                     <td style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       <span>
                        
                         {article.QteEntr == '0'? (
                      <span style={{ color: `${darck ? "#0D47A1" : "#fff"}` }}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#0D47A1" : "#fff"}`}}>
                         {Number(article.QteEntr).toFixed(3)}
                       </span>
                     )}
                  
                         </span>

                     </td>
                      <td>
                       <span style={{ color: "red" }}>
                       {article.QteSor == '0'? (
                      <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "red" : "#fff"}`}}>
                         {Number(article.QteSor).toFixed(3)}
                       </span>
                     )}
                        
                       </span>
                     </td>

                       <td style={{ color: `${darck ? "green" : "#fff"}` }}>
                       {article.QteRet == '0'? (
                      <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "green" : "#fff"}`}}>
                         {Number(article.QteRet).toFixed(3)}
                       </span>
                     )}
                     
                     </td>
                       <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                       {article.PrixEntr == '0'? (
                      <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                       -
                       </span>
                     ) : (
                       <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                         {Number(article.PrixEntr).toFixed(3)}
                       </span>
                     )}
                     
                     </td>
                    <td>
                       <span style={{  color: !darck ? "#fff" : null}}>
                       {(Number(article.StkFin)).toFixed(3)}
                       </span>
                     </td>
                     <td>
                       <span style={{ color: !darck ? "#0D47A1" : "#0D47A1"  }}>
                       {(Number(article.StkFinVal)).toFixed(3)}
                       </span>
                     </td></>)}
                     
                    
                   </tr>
                 ))}
               </tbody>
             </table>
    
                                
<br />
  
  <Paper
    style={{
      backgroundColor: `${darck ? "" : "#172431"}`,
      border: `${darck ? "" : "2px solid #fff"}`,
    }}
    // elevation={0.5}
    elevation={3}
  >
    <Row style={{ flexWrap: "nowrap" }}>
     
      <Col sm={9}>
        <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
         

        
          <label
           style={{ color: `${darck ? "#0D47A1" : "#fff"}`
           , fontSize: "12px" }}

          >TOTAUX : </label>
        </div>
      </Col> 
    
     <Col sm={2} style={{ display: "flex",justifyContent: "end" ,marginLeft:"80px"}}>
      <label>
          
           <span style={{ color: "#0D47A1" }}>
             {this.getTotalValeur()}
           </span>
         </label>  

   
      </Col>
    </Row>
  </Paper>   
           

 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche de stock </>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                   { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                     // fontSize: "14px",
                     // fontWeight: "bold",
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Date")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé/Lot")}</th>
                {/* <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("Mg")}</th> */}
                
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Entrées")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Sorties")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Retours")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Stock Final")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Valeur")}</th>
               
                </tr> 
                {/* <tr style={{ borderBottom: "1px solid grey" ,height: "50px", }}>
                <th style={{ width: "0%"  }}>{translate(" ")}</th>
                <th style={{ width: "10%"  }}>{translate(" ")}</th>
                <th style={{ width: "0%"}}>{translate(" ")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Pr.Rev")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("CMP")} |{translate("Valeur")}</th>
                <th style={{ width: "3%"  ,   border: "1px solid gray"}}>{translate("Qte")} |{translate("Valeur")}</th>
             
               
                </tr>  */}
                </thead>
            <tbody>
          
               {this.props.fiches.fiches.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "40px",
                    border: "1px solid gray",
                 
                  }}>
                    

                    
                      {t.datfac == null? (<>
                   {t.libelle=="Totaux"?(<>
                   </>):(<>  <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" }} > 
               
              
                
               </td>
          {/* rowspan="3"    colspan="2"*/}
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}>{t.libelle}</td>
           <td style={{ width: "10%" ,color:"#8f2d56",fontWeight: "bold",fontSize: "20px",}}></td>
           <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "5%" ,  }}> 
              {/* ////////////////// entrée ///////////*/}
            
           </td>

            {/* ////////////////// sortie ///////////*/}
           <td style={{ color: `${darck ? "red" : "#fff"}`,width: "5%"  ,   }}  >
            
            
             </td>
              {/* ////////////////// retour ///////////*/}
           <td style={{ color: `${darck ? "green" : "#fff"}`,width: "5%" ,   }} >
            
             </td>
           
           <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
             <td style={{ color: `${darck ? "black" : "#fff"}` ,   }}>
      
             </td>
             
               </>)} 
                 
             
            
           
              </>):( <>
             {/* ////////////////// date ///////////*/}
            <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.datfac == null? (
                 <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                  --
                  </span>
                ) :t.libelle == "Totaux"? (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                 
                   </span>
                 ) : (
                  <span style={{  color: `${darck ? "#182532" : "#fff"}`}}>
                    { moment(t.datfac).format("DD/MM/YYYY")}
                  </span>
                )}
                
                </td>
        {/* ////////////////// libelle ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} >
            {t.libelle}
             </td> 
                {/* ////////////////// magasin ///////////*/}
           
           
             
           
           
             
              {/* ////////////////// entrée ///////////*/}
            <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
              
               {t.libelle=="Totaux"?(<>
                {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
              
               </>):
               (<> {t.QteEntr==0?(<>-</>):(<>{Number(t.QteEntr).toFixed(3)}</>)} &nbsp;
               {/* |&nbsp;
               {t.PrixEntr==0?(<>-</>):(<>{Number(t.PrixEntr).toFixed(3)}</>)}&nbsp;
               |&nbsp;{t.ValEntr==0?(<>-</>):(<>{Number(t.ValEntr).toFixed(3)}</>)} */}
               </>)}
            </td>
          
             {/* ////////////////// sortie ///////////*/}
            <td style={{ color: `${darck ? "red" : "#fff"}`,width: "3%"  ,   border: "1px solid gray"}}  >
              {t.libelle=="Totaux"?(<>
                {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              
              </>):(<> {t.QteSor==0?(<>-</>):(<> {Number(t.QteSor).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp; {t.PrixSor==0?(<>-</>):(<> {Number(t.PrixSor).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.ValSor==0?(<>-</>):(<>{Number(t.ValSor).toFixed(3)}</>)} */}
              </>)}
             
              </td>
               {/* ////////////////// retour ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}`,width: "3%" ,   border: "1px solid gray" }} >
              {t.libelle=="Totaux"?(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
           
              </>):(<>
                {t.QteRet==0?(<>-</>):(<>  {Number(t.QteRet).toFixed(3)}</>)}&nbsp;
              {/* |&nbsp;{t.PrixRet==0?(<>-</>):(<>  {Number(t.PrixRet).toFixed(3)}</>)}&nbsp;
              |&nbsp;{t.valRet==0?(<>-</>):(<>{Number(t.valRet).toFixed(3)}</>)} */}
              </>)}
          
              </td>
              {/* ////////////////// stfin/valeur ///////////*/}
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFin).toFixed(3)} </td>
          
            <td style={{ color: `${darck ? "black" : "#fff"}` ,   border: "1px solid gray" }}>{Number(t.StkFinVal).toFixed(3)} </td>
            </>  )}     
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

          <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {/* {this.getTotalQteEntr()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {/* {this.getTotalQtesor()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {/* {this.getTotalQteret()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
      
           {/* {this.getTotalStkfin()}| */}
           {this.getTotalValeur()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 
        
      
              
               
           
           
               
              </div>
              <br />
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
  
                         </div>
                         
          )}  
            </>)}
        
 
   
        </main>
        <Fichestock
            show={this.state.GetByIdModalShow}
            onHide={GetByIdModalClose}
            codearticle={codearticle}
            designationarticle={designationarticle}        
             datfac={datfac}
            qteentr={qteentr}
              qtesor={qtesor}
               qteret={qteret}
              valRet={valRet}
              ValEntr={ValEntr}
              ValSor={ValSor}
              StkFin={StkFin}
              StkFinVal={StkFinVal}
              firstdate={firstdate}
              seconddate={seconddate}
              tabtab={this.state.tabtab}
              PrixEntr={PrixEntr}
        
              lib={libb}
              coddep={coddepp}
              GMM={this.state.GMM}
              rechdatsGMM={this.state.rechdatsGMM} 
              rechsGMM={this.state.rechsGMM} 
          />  
      
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch) => ({
  getArticleList: (a, b, c, d, e) => {
    dispatch(getArticleList(a, b, c, d, e));
  },
  getArticleHeader: () => {
    dispatch(getArticleHeader());
  },
  SelectArticle : (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),

  SelectFiche: (a, b, c, d, e) => dispatch(SelectFiche(a, b, c, d, e)),
  SelectFicheGMM: (a, b, c, d, e) => dispatch(SelectFicheGMM(a, b, c, d, e)),
});
const mapStateToProps = (state) => {
  return {
    // SideBarTitles: state.SideBarTitles,

    // SearchingResult: state.SearchingReducer,
    Auth: state.Auth,
   // DataTables: state.DataTablesReducer,
    //articles: state.articles,
    fiches: state.fiches,
    fichesGMM: state.fichesGMM,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
};
const ConnectedFicheArticle = connect(
  mapStateToProps,
  mapDispatchToProps
)(FicheArticle);

export default ConnectedFicheArticle;

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "datefac",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "codart",
    numeric: false,
    disablePadding: false,
    label: "Code",
  },

  {
    id: "desart",
    numeric: true,
    disablePadding: false,
    label: "Désignation",
  },

  { id: "qteentr", numeric: false, disablePadding: false, label: "Qté Entrée" },
  
  {
    id: "PUDHT",
    numeric: true,
    disablePadding: false,
    label: "Qté sortie",
  },
  { id: "tautva", numeric: true,
   disablePadding: false,
    label: "Qté Retour" },
  {
    id: "PUDTTC",
    numeric: true,
    disablePadding: false,
    label: "Prix MVT",
  },
  {
    id: "stkfin",
    numeric: true,
    disablePadding: false,
    label: " Stk Fin",
  
  },
  {
    id: "Valeur",
    numeric: true,
    disablePadding: false,
    label: "Valeur",
  },
  
 
];


function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}




const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
