import { Component } from "react";
import { Bar } from "react-chartjs-2";
import * as React from "react";

import Axios from "axios";
import { connect } from "react-redux";

// import translate from "../../i18n/translate";

const DATE_OPTIONS = {
  month: "short",
  day: "numeric",
};
// var curr = new Date();
// var dat = curr.setDate(curr.getDate());
// var date = curr.toISOString().substr(0, 10);
class ChartBL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      databl: {},
      datafac: {},
      databe: {},
    };
  }

  componentDidMount() {
    // Axios.get(`/api/FACCLIs?typppeeee=FT`).then(
    //   (res) => {
    //     console.log(res);

    //     const ipl = res.data;

    //     let playername = [];

    //     let runscore = [];

    //     ipl.forEach((record) => {
    //       playername.push(
    //         new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
    //       );

    //       runscore.push(record.sommemntbn);
    //     });

    //     this.setState({
    //       Data: {
    //         labels: playername,

    //         datasets: [
    //           {
    //             label: "Montant Facture / jour",

    //             data: runscore,

    //             backgroundColor: [
    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",

    //               "rgb(220, 0, 78)",
    //             ],
    //             // borderColor: "rgba(0,0,0,1)",
    //             // borderWidth: 2,
    //           },
    //         ],
    //       },
    //     });
    //   }
    // );
    Axios.get(
      `https://www.24-crm.com/webservice/api/BLBRs?typpppp=bl&vend=${this.props.Auth.username}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(
          new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
        );

        runscore.push(record.sommemntbn);
      });

      this.setState({
        databl: {
          labels: playername,

          datasets: [
            {
              // label: translate("Montant BL effectué / jour"),
              label: "Montant BL effectué / jour",

              data: runscore,

              backgroundColor: [
                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",

                "#6610f2",
              ],
            },
          ],
        },
      });
    });

    //////////facture //////////////

    // Axios.get(
    //   `/api/FACCLIs?typpppp=FT&vend=${this.props.Auth.username}`
    // ).then((res) => {
    //   console.log(res);

    //   const ipl = res.data;

    //   let playername = [];

    //   let runscore = [];

    //   ipl.forEach((record) => {
    //     playername.push(
    //       new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
    //     );

    //     runscore.push(record.sommemntbn);
    //   });

    //   this.setState({
    //     datafac: {
    //       labels: playername,

    //       datasets: [
    //         {
    //           label: "Montant Facture effectué / jour",

    //           data: runscore,

    //           backgroundColor: [
    //             "#28a745",

    //             "#28a745",

    //             "#28a745",

    //             "#28a745",

    //             "#28a745",

    //             "#28a745",

    //             "#28a745",
    //           ],
    //         },
    //       ],
    //     },
    //   });
    // });

    // //////////BE //////////////

    // Axios.get(
    //   `/api/BEREs?typpppp=BE&vend=${this.props.Auth.username}`
    // ).then((res) => {
    //   console.log(res);

    //   const ipl = res.data;

    //   let playername = [];

    //   let runscore = [];

    //   ipl.forEach((record) => {
    //     playername.push(
    //       new Date(record.datfac).toLocaleDateString("fr", DATE_OPTIONS)
    //     );

    //     runscore.push(record.sommemntbn);
    //   });

    //   this.setState({
    //     databe: {
    //       labels: playername,

    //       datasets: [
    //         {
    //           label: "Montant BE effectué / jour",

    //           data: runscore,

    //           backgroundColor: [
    //             "blue",

    //             "blue",

    //             "blue",

    //             "blue",

    //             "blue",

    //             "blue",

    //             "blue",
    //           ],
    //         },
    //       ],
    //     },
    //   });
    // });
  }
  render() {
    return (
      <div>
        <div>
          <Bar
            data={this.state.databl}
            options={{ maintainAspectRatio: false }}
            width={"300px"}
            height={"200px"}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
  };
}

export default connect(mapStateToProps, null)(ChartBL);
