import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import "./bs.scss";
import { FormGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab, IconButton } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { SelectBS } from "../../redux/actions/GetBS";
import { SelectClient } from "../../redux/actions/GetClients";

import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class ModifierBS extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      qtte: 0,
      btnEnabled: false,
      gilad: true,
      snackbarmsg: "",
      enrsnackbaropen: false,
      fsnackbaropen: false,
      pmp: "",
      openEditModal: false,
      idel: 0,
      reload: false,
      codtva: 0,
      giladd: true,
      codbar: "",

      rechs: [],
      rechsc: [],

      changeButton: false,

      remiseg: 0,
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",

      datebcc: "",

      username: username,
      clicked: false,
      clickeda: false,

      stkfin: 0,
      yesno: "",
    };
  }

  componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.sameTable();
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );

    this.setState({
      remiseg: this.props.taurem,
      raisonsocial: this.props.raisonsociale,
      codeclient: this.props.client,
      categoriefiscale: this.props.catfisc,
      datebcc: new Date(this.props.datebs)
        .toLocaleDateString()
        .substring(0, 10)
        .split("/")
        .reverse()
        .join("-"),
    });
  }

  datHandler = (event) => {
    this.setState({ datebcc: event.target.value });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  remiseglobalChange = (event) => {
    this.setState({ remiseg: event.target.value });
  };

  remiseHandler = (event) => {
    this.setState({
      remisea: event.target.value,
      totalht: Number(
        this.state.qte * this.state.puht * ((100 - event.target.value) / 100)
      ).toFixed(3),
      puttcnet: (
        this.state.puht *
        (1 + this.state.tva / 100) *
        ((100 - event.target.value) / 100)
      ).toFixed(3),
    });
  };

  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100),
      puttcnet: Number(
        event.target.value *
          (1 + this.state.tva / 100) *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        ((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100)
      ).toFixed(3),
      totalht: (
        this.state.qte *
        ((event.target.value /
          ((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)) *
          ((100 - this.state.remisea) / 100))
      ).toFixed(3),
    });
  };

  articleHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clickeda: true }));
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsc: data, clicked: true }));
  };

  sameTable = () => {
    fetch(
      `https://www.24-crm.com/webservice/Api/LigBSRS?type=BS&numfac=${this.props.bsid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          tab: data,
          //    sumqt: data && data.reduce((a, v) => a + parseInt(v.quantite), 0),
        })
      );
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  enrsnackbarClose = (event) => {
    this.setState({ enrsnackbaropen: false });
  };

  fsnackbarClose = (event) => {
    this.setState({ fsnackbaropen: false });
  };

  /////// modiifier les modifications /////////////
  modifiermodification = (event) => {
    // event.preventDefault();

    const newtab = this.state.tab.concat({
      codart: this.state.codearticle,
      desart: this.state.des,
      quantite: this.state.qte,
      unite: this.state.unite,
      priuni: this.state.puht,
      remise: this.state.remisea,
      tautva: this.state.tva,
      montht: this.state.totalht,
      puttcnet: this.state.puttcnet,
      pmp: this.state.pmp,
      codtva: this.state.codtva,
      faudec: this.state.faudec,
    });
    const SumQte =
      newtab && newtab.reduce((a, v) => a + parseInt(v.quantite), 0);
    const SumRemiseArticle =
      newtab &&
      newtab.reduce(
        (a, v) => a + (parseInt(v.quantite) * v.montht * v.remise) / 100,
        0
      );
    const SumHtBrut =
      newtab && newtab.reduce((a, v) => a + parseInt(v.quantite) * v.montht, 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          parseInt(v.quantite) *
            v.montht *
            ((100 - v.remise) / 100) *
            (v.tautva / 100),
        0
      );
    const SumHtNet =
      newtab &&
      newtab.reduce((a, v) => a + v.montht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      newtab &&
      newtab.reduce((a, v) => a + v.montht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          (v.montht * ((100 - this.props.rem) / 100) +
            v.quantite *
              v.priuni *
              ((100 - v.remise) / 100) *
              (v.tautva / 100)),
        0
      );

    this.setState({
      tab: newtab,

      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      changeButton: false,
    });

    this.setState({
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      faudec: "N",
      tva: 0,
      puttcnet: 0,
      stkfin: "",
      pmp: "",
      codtva: 0,
    });
  };

  /////// submit pour le bouton ajouter /////////////

  submitHandler = (event) => {
    event.preventDefault();
    if (
      this.state.tab.filter((el) => el.codart == this.state.codearticle)
        .length > 0
    ) {
      alert("article existant, veuillez modifier la quantité ");
    } else {
      const newtab = this.state.tab.concat({
        codart: this.state.codearticle,
        desart: this.state.des,
        quantite: this.state.qte,
        unite: this.state.unite,
        priuni: this.state.puht,
        remise: event.target.remisea.value,
        tautva: this.state.tva,
        montht: this.state.totalht,
        puttcnet: this.state.puttcnet,
        pmp: this.state.pmp,
        codtva: this.state.codtva,
        faudec: this.state.faudec,
      });
      const SumQte =
        newtab && newtab.reduce((a, v) => a + parseInt(v.quantite), 0);
      const SumRemiseArticle =
        newtab &&
        newtab.reduce(
          (a, v) => a + (parseInt(v.quantite) * v.priuni * v.remise) / 100,
          0
        );
      const SumHtBrut =
        newtab &&
        newtab.reduce((a, v) => a + parseInt(v.quantite) * v.priuni, 0);
      const SumTva =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            parseInt(v.quantite) *
              v.priuni *
              ((100 - v.remise) / 100) *
              (v.tautva / 100),
          0
        );
      const SumHtNet =
        newtab &&
        newtab.reduce(
          (a, v) => a + v.montht * ((100 - this.props.rem) / 100),
          0
        );
      const SumRemiseGlobale =
        newtab &&
        newtab.reduce((a, v) => a + v.montht * (this.props.rem / 100), 0);
      const SumNetAPayer =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            (v.montht * ((100 - this.props.rem) / 100) +
              v.quantite *
                v.priuni *
                ((100 - v.remise) / 100) *
                (v.tautva / 100)),
          0
        );

      this.setState({
        tab: newtab,

        totalqte: SumQte,
        sumremisearticle: SumRemiseArticle,
        totalhtbrut: SumHtBrut,
        totaltva: SumTva,
        totalhtnet: SumHtNet,
        remiseglobal: SumRemiseGlobale,
        netapayer: SumNetAPayer,
        snackbaropen: true,
        btnEnabled: true,
      });

      this.setState({
        codearticle: "",
        qte: "",
        totalht: 0,
        des: "",
        unite: "",
        puht: "",
        remisea: 0,
        tva: 0,
        puttcnet: 0,
        faudec: "N",
        pmp: "",
        stkfin: "",
        codtva: 0,
      });
    }
  };

  onCellChange = (event) => {
    this.setState({ codearticle: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbarfail: true,
      btnEnabled: true,
    });
  };

  ///////////// delete for modification without snackbaropende ///////////
  deleteRowMod = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      btnEnabled: true,
    });
  };

  qteHandler = (event) => {
    this.setState({
      qte: event.target.value,
      puttcnet: Number(this.state.puht * (1 + this.state.tva / 100)).toFixed(3),
      totalht: Number(
        event.target.value *
          this.state.puht *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });

    this.state.stkfin >= parseInt(event.target.value)
      ? this.setState({ yesno: "yes" })
      : this.setState({ yesno: "no" });
  };

  enregistrer = () => {
    ///////////////// Update blbrs ////////////
    fetch(
      `https://www.24-crm.com/webservice/api/BSRS?numfac=${this.props.bsid}&typfac=BS&datfac=${this.state.datebcc}&codcli=${this.state.codeclient}&raisoc=${this.state.raisonsocial}&taurem=${this.state.remiseg}&catfisc=${this.state.categoriefiscale}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    ////////// first part delete ///////////////////
    fetch(
      `https://www.24-crm.com/webservice/api/LigBSRS/${this.props.bsid}?typfacc=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // this.setState({ enrsnackbaropen: true, snackbarmsg: result });

        ///////////// second part add new table to database //////////////

        this.state.tab.map((k, index) => {
          // for (var i = 0; i < this.state.tab.length; i++) {
          fetch(
            `https://www.24-crm.com/webservice/api/LigBSRS/{ID}?numfac=${
              this.props.bsid
            }&typfac=BS&numlig=${index + 10}&codart=${k.codart}&quantite=${
              k.quantite
            }&fodart=${k.faudec}&desart=${k.desart}&datfac=${
              this.props.datebs
            }&priuni=${k.priuni}&remise=${k.remise}&unite${k.unite}&codtva=${
              k.codtva
            }&tautva=${k.tautva}&montht=${k.montht}&pmp=${
              k.pmp
            }&formqt=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${
              this.props.ConfigDossier.serveur
            }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
              this.props.ConfigDossier.pwd_serveur
            }&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                //this.setState({ enrsnackbaropen: true, snackbarmsg: result });

                fetch(
                  `https://www.24-crm.com/webservice/api/LigBSRS?FAC=${this.props.bsid}&typfacc=BS&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                ).then((res) => {
                  window.location.reload();
                });
              },
              (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: "failed" });

                // window.alert(result);
              },
              (error) => {
                this.setState({
                  enrsnackbaropen: true,
                  snackbarmsg: "failed",
                });
              }
            );
        });

        //// third part calcul with vue/////////////
        // fetch(
        //   `/api/LigBLBRs?FAC=${this.props.bsid}&typfacc=BL`,
        //   {
        //     method: "POST",
        //     header: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //     },
        //   }
        // )
        //   .then((res) => res.json())
        //   .then(
        //     (result) => {
        //       console.log(result);
        //     },
        //     (error) => {
        //       this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        //     }
        //   );

        ///////////// 4th part tva total calcul ///////////////
        // fetch(
        //   `/api/LigBLBRs?FAC=${this.props.bsid}&Typfacc=bl&CODDEPP=`,
        //   {
        //     method: "POST",
        //     header: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //     },
        //   }
        // )
        //   .then((res) => res.json())
        //   .then(
        //     (result) => {
        //       console.log(result);
        //       window.location.reload();
        //     },
        //     (error) => {
        //       this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        //     }
        //   );
      });
  };

  render() {
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            Article ajouté
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            Article supprimé
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.enrsnackbaropen}
          autoHideDuration={2000}
          onClose={this.enrsnackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.enrsnackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={`${darck ? "" : classes.root}`}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#00087E" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier Bon de sortie № {this.props.bsid}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Row>
              <Col style={{ margin: "0px" }}>
                <Card style={{ marginBottom: "10px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <Row style={{ marginBottom: "-20px", marginTop: "-20px" }}>
                      <Col sm={8}>
                        <FormGroup>
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid style={{ color: `${darck ? "" : "#fff"}` }}>
                                <b>Chercher client par :</b>
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid
                                item
                                style={{ color: `${darck ? "grey" : "#fff"}` }}
                              >
                                Raison sociale
                              </Grid>
                              <Grid item>
                                <ThemeProvider theme={theme}>
                                  <Switch
                                    checked={this.state.giladd}
                                    onChange={this.handleChange("giladd")}
                                    value="giladd"
                                    color="primary"
                                  />
                                </ThemeProvider>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  color: `${darck ? "#3f51b5" : "#fff"}`,
                                }}
                              >
                                Code client
                              </Grid>
                            </Grid>
                          </Typography>
                        </FormGroup>
                      </Col>

                      {this.state.giladd ? (
                        <Col sm={4} style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.clients.clients
                              }
                              getOptionLabel={(option) => option.codcli}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                      codeclient: getOptionLabel.codcli,
                                      categoriefiscale: getOptionLabel.catfisc,
                                      btnEnable: true,
                                      showTimbre: getOptionLabel.timbre,
                                      showForfitaire: getOptionLabel.regimecli,
                                      showButtonModalPassager:
                                        getOptionLabel.passager,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codeclient: this.props.client,
                                      categoriefiscale: this.props.catfisc,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                      showButtonModalPassager: false,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Code client"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="codcli"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={4} style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.clients.clients
                              }
                              getOptionLabel={(option) => option.raisoc}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                      codeclient: getOptionLabel.codcli,
                                      categoriefiscale: getOptionLabel.catfisc,
                                      btnEnable: true,
                                      showTimbre: getOptionLabel.timbre,
                                      showForfitaire: getOptionLabel.regimecli,
                                      showButtonModalPassager:
                                        getOptionLabel.passager,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codeclient: this.props.client,
                                      categoriefiscale: this.props.catfisc,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                      showButtonModalPassager: false,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Raison sociale"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="raissoc"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>

                    <Row style={{ marginBottom: "-20px" }}>
                      <Col sm={2}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled={
                              this.state.codeclient === "100" ? false : true
                            }
                            onChange={this.raisocHandler}
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={2}>
                        <TextField
                          id="standard-basic"
                          label="Remise Globale %"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined" //variant="outlined"
                          fullWidth
                          name="remise"
                          onChange={this.remiseglobalChange}
                          value={this.state.remiseg}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>

                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          name="datfac"
                          onChange={this.datHandler}
                          value={this.state.datebcc}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}

                          // defaultValue={new Date(this.props.datebl)
                          //   .toISOString()
                          //   .substr(0, 10)}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <form
                      autocomplete="off"
                      onSubmit={this.submitHandler}
                      className={`${darck ? "" : classes.root}`}
                    >
                      <Row form>
                        <b style={{ color: `${darck ? "" : "#fff"}` }}>
                          Chercher article par :
                        </b>
                        <b style={{ marginTop: "10px", marginLeft: "10px" }}>
                          {this.state.faudec === "A" ? (
                            <p style={{ color: `${darck ? "grey" : "#fff"}` }}>
                              Fodec: ✔
                            </p>
                          ) : null}
                        </b>
                      </Row>

                      <Row from>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      puht: getOptionLabel.PUDHT,
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtva: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "N",
                                      codbar: "",
                                      codtva: "",
                                      qte: "",
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Code article"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.desart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      puht: getOptionLabel.PUDHT,
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtva: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "N",
                                      codbar: "",
                                      qte: "",
                                      codtva: "",
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Désignation article"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codbar}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unite: getOptionLabel.unite,
                                      puht: getOptionLabel.PUDHT,
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtva: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unite: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "N",
                                      codbar: "",
                                      qte: "",
                                      codtva: "",
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.com/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Code à Barre"
                                  // className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Code article"
                              value={this.state.codearticle}
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Désignation"
                              value={this.state.des}
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={1}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Qte"
                              type="number"
                              value={this.state.qte}
                              onChange={this.qteHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              value={`Stock: ${this.state.stkfin}`}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Unité"
                              value={this.state.unite}
                              fullWidth
                              disabled
                              size="small"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU HT"
                              value={this.state.puht}
                              fullWidth
                              name="puht"
                              onChange={this.puhtHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Remise %"
                              value={this.state.remisea}
                              fullWidth
                              name="remisea"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onChange={this.remiseHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="TVA"
                              value={this.state.tva}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU TTC Net"
                              value={this.state.puttcnet}
                              fullWidth
                              onChange={this.puttcHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Total HT"
                              value={Number(this.state.totalht).toFixed(3)}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.changeButton ? (
                        <Center>
                          <Button
                            disabled={
                              this.state.yesno === "no" ||
                              this.state.qte === "" ||
                              this.state.stkfin <= 0 ||
                              parseInt(this.state.qte) <= 0
                                ? true
                                : false
                            }
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.modifiermodification();
                            }}
                          >
                            Enregistrer les modifications
                          </Button>
                        </Center>
                      ) : this.state.des === "" ||
                        this.state.yesno === "no" ||
                        this.state.qte === "" ||
                        this.state.stkfin <= 0 ||
                        parseInt(this.state.qte) <= 0 ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      )}
                    </form>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <div className="tabbs2">
                      <table style={{ marginTop: "10px" }}>
                        <thead
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          <tr>
                            <th>Code</th>
                            <th style={{ width: "37%" }}>Désignation</th>
                            <th>Quantité</th>
                            <th>PU HT</th>
                            <th>Remise</th>
                            <th>TVA</th>
                            <th>TotalHT</th>
                            <th>PUNet</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            overflowY: "auto",
                            display: "block",
                            maxHeight: "10em",
                          }}
                        >
                          {this.state.tab.map((t, i) => (
                            <tr key={i}>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{t.codart}</span>
                              </td>
                              <td
                                style={{
                                  fontSize: "12px",
                                  width: "37%",
                                  color: `${darck ? "" : "#fff"}`,
                                }}
                              >
                                {t.desart}
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{t.quantite}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.priuni).toFixed(3)}</span>
                              </td>

                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.remise).toFixed(2)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.tautva).toFixed(2)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.montht).toFixed(3)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>
                                  {Number(
                                    parseFloat(t.priuni) *
                                      (1 - parseFloat(t.remise) / 100)
                                  ).toFixed(3)}
                                </span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <Tooltip title="Editer cet article">
                                  <Fab size="small">
                                    <EditIcon
                                      style={{}}
                                      onClick={() => {
                                        fetch(
                                          `https://www.24-crm.com/webservice/api/ARTICLEs?codartt=${t.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                        )
                                          .then((response) => response.json())
                                          .then((data) =>
                                            this.setState({
                                              stkfin: data.map((t) => t.stkfin),
                                            })
                                          );
                                        this.setState({
                                          codearticle: t.codart,
                                          des: t.desart,
                                          unite: t.unite,
                                          puht: t.priuni,
                                          remisea: t.remise,
                                          tva: t.tautva,
                                          faudec: t.faudec,
                                          qte: t.quantite,
                                          totalht: t.montht,
                                          puttcnet: t.puttcnet,
                                          pmp: t.pmp,
                                          codtva: t.codtva,
                                          changeButton: true,
                                        });
                                        this.deleteRowMod(i);
                                      }}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <Tooltip title="Supprimer cet article">
                                  <Fab size="small">
                                    <DeleteIcon
                                      style={{}}
                                      onClick={() => this.deleteRow(i)}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {!this.state.btnEnabled ? (
              <Button disabled variant="contained" style={{ width: "30%" }}>
                Enregistrer
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(0, 8, 126)",
                  color: "white",
                  width: "30%",
                }}
                onClick={() => {
                  // this.props.submitHandler(this.state.tab,
                  //     this.state.totalqte,
                  //     this.state.sumremisearticle,
                  //     this.state.totalhtbrut,
                  //     this.state.totaltva,
                  //     this.state.totalhtnet,
                  //     this.state.remiseglobal,
                  //     this.state.netapayer,
                  //     this.state.btnEnabled,
                  // )
                  this.enregistrer();
                  this.props.onHide();
                  this.props.onHide01();
                  window.alert("Modification enregistrés");

                  this.props.SelectBS(
                    this.props.ConfigDossier.coddos,
                    this.props.ConfigDossier.serveur,
                    this.props.ConfigDossier.utilisateur,
                    this.props.ConfigDossier.pwd_serveur,
                    this.props.ConfigDossier.database
                  );
                }}
              >
                Enregistrer
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectBS: (a, b, c, d, e) => dispatch(SelectBS(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    clients: state.clients,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierBS));
