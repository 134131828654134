import Axios from "axios";

export const SelectUser = (a,b,c,d,e,f,g) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?type=DV&vendeur=${a}&grp=${b}&coddos=${c}&serveur=${d}&user=${e}&pwd=${f}&database=${g}`
    ).then((response) => {
      return dispatch({ type: "USER_SELECTED", payload: response.data });
    });
  };
};
