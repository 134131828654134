import React, { Component } from "react";
import { Modal, Card, Alert } from "react-bootstrap";
import { Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { Divider, Button } from "@material-ui/core";
// import "../styling/Styles.css";
import { SelectClient } from "../../redux/actions/GetClients";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import { SelectReleveFrc } from "../../redux/actions/ActionGetReleverFrc";
import { SelectAffchStock } from "../../redux/actions/ActionGetaffchstock";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "../clients-fournisseurs/clientRelever.scss";
import { useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { SelectFiche} from "../../redux/actions/GetficheSTK";
const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);
const dateinitial = date
  .split("-")
  .map((val, index) => (val.length < 3 ? (val = "01") : val))
  .join("-");

var dayMinOne = new Date();
dayMinOne.setDate(dayMinOne.getDate() - 1);
var dateMinOne = dayMinOne.toISOString().substr(0, 10);

const dateinitialMinOne = date
  .split("-")
  .map((val, index) => {
    if (index === 2) {
      return (val = "31");
    } else if (index === 1) {
      return (val = "12");
    } else {
      return (val = val - 1);
    }
  })
  .join("-");

class Affchfichestock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabreleveclient: [],
      firstdatelayout: dateinitial,
      seconddatelayout: date,
      firstdate: new Date(dateinitial),
      seconddate: new Date(date),
      isSearchDate: false,
      dateJ1: dateinitialMinOne.slice(0, 10).split("-").reverse().join("-"),
    };
  }
  // .slice(0, 10).split("-").reverse().join("-")
  componentDidMount() {
   
    this.props.SelectAffchStock(
      this.props.codearticle,
      this.props.Auth.username,
      this.props.Auth.fct,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFiche(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  // HandlerJ1 = (event) => {
  //   this.setState({ dateJ1: this.state.dateJ1 });
  // };

  firstrechdatHandler = (event) => {
    this.setState({
      firstdate: new Date(event.target.value),
      dateJ1: event.target.value.slice(0, 10).split("-").reverse().join("-"),
    });
    fetch(
  `https://www.24-crm.com/webservice/api/BEREs?coddoss=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
  
)
      .then((response) => response.json())
      .then((data) => this.setState({ tabreleveclient: data }));
   
  };

  secondrechdatHandler = (event) => {
    this.setState({ seconddate: new Date(event.target.value) });
    fetch(
     
  `https://www.24-crm.com/webservice/api/BEREs?coddoss=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
 
  )
      .then((response) => response.json())
      .then((data) => this.setState({ tabreleveclient: data }));
     
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };


  render() {
    const {
      firstdate,
      seconddate,
      isSearchDate,
      firstdatelayout,
      seconddatelayout,
      dateJ1,
      libelle,
    } = this.state;
    const {codearticle} = this.props;
    // console.log("ReleverFrc", this.props.ReleverFrc);
    // console.log("Affchstock", this.props.fichesstock);
    // const fichesstock = this.props.fichesstock;
    // console.log("fichesstock", fichesstock);
  
  
    let totalGeneralCreadit =
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.map((item) => item.credit).reduce(
        (prev, curr) => prev + curr,
        0
      );

    let totalGeneralDebit = (
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.map((item) => item.debit)
    );
    //.reduce((prev, curr) => prev + curr, 0);

    let totalGeneralSolde = totalGeneralDebit - totalGeneralCreadit;

    let totalePeriodeCredit =
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed >= firstdate && resultmed <= seconddate;
      })
        .map((item) => item.credit)
        .reduce((prev, curr) => prev + curr, 0);

    let totalePeriodeDebit =
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed >= firstdate && resultmed <= seconddate;
      })
        .map((item) => item.debit)
        .reduce((prev, curr) => prev + curr, 0);

    let totalePeriodeSolde = totalePeriodeDebit - totalePeriodeCredit;

    let totaleNoPeriodeDebit =
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed < firstdate;
      })
        .map((item) => item.debit)
        .reduce((prev, curr) => prev + curr, 0);

    let totaleNoPeriodeCredit =
      this.props.fichesstock.fichesstock &&
      this.props.fichesstock.fichesstock.filter((a) => {
        let resultmed = new Date(a.Date);
        return resultmed < firstdate;
      })
        .map((item) => item.credit)
        .reduce((prev, curr) => prev + curr, 0);

    let totaleNoPeriodeSolde = totaleNoPeriodeDebit - totaleNoPeriodeCredit;

    const { darck, classes } = this.props;

  
    return (   <div className="container">
       {/* {this.state.tabreleveclient.map((item)=>{
        return(
          <h1>   {item.libelle} </h1>
  
        );
       })

       } */}
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title>
              <b style={{ color: `${darck ? "#020F64" : "#fff"}` }}>
                Fiche de stock
              </b>
              
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Row>
              {/* /////////////////////////////////////////////////////////////////////////////// */}
              <Col sm={12}>
              <Card>
                  <Row sm={12} style={{ flexWrap: "nowrap" }}>
                    <Col
                      sm={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      }}
                    >
                      {/* <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          flexWrap: "wrap",
                        }}
                        variant="h6"
                        component="h3"
                      >
                        <Label
                          style={{
                            margin: "0",
                            minWidth: "70px",
                            color: "#020F64",
                          }}
                        >
                          <h4
                            style={{
                              margin: "0 0 0 2vw",
                              minWidth: "70px",
                              color: `${darck ? "#020F64" : "#fff"}`,
                            }}
                          >
                            article :
                          </h4>
                        </Label>
                        <Label
                          style={{
                            margin: "2px 0 0 2vw",
                            minWidth: "70px",
                            color: `${darck ? "#020F64" : "#fff"}`,
                          }}
                        >
                          {codart}
                        </Label>
                        <Label
                          style={{
                            margin: "2px 0 0 2vw",
                            minWidth: "300px",
                            color: `${darck ? "#020F64" : "#fff"}`,
                          }}
                        >
                          {raisonsociale}
                        </Label>
                      </Typography> */}
                    </Col>
                    <Col
                      sm={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      }}
                    >
                      <DateRangeIcon
                        className="search-icon"
                        color={isSearchDate ? "primary" : "inherit"}
                        onClick={() =>
                          this.setState({ isSearchDate: !isSearchDate })
                        }
                      />
                    </Col>
                  </Row>
                </Card>
                <div style={{ height: "5px" }}></div>
                {/* /////////////////////////////////////////////////////////////////////////////// */}
              
                {isSearchDate ? (
                  <Card className="card0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <Row
                        className={`${darck ? "" : classes.root}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          md={12}
                        >
                          <Col style={{ padding: "0" }} sm={6}>
                            <Typography variant="h6" component="h3">
                              <Label
                                style={{
                                  margin: "0",
                                  minWidth: "150px",
                                  color: `${darck ? "#020F64" : "#fff"}`,
                                }}
                              >
                                Date de début :
                              </Label>
                            </Typography>
                          </Col>
                          <Col style={{ padding: "0" }} sm={6}>
                            <TextField
                              id="standard-basic"
                              label="Date"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              type="date"
                              fullWidth
                              name="datfac"
                              defaultValue={this.state.firstdatelayout}
                              onChange={this.firstrechdatHandler}
                              // onMouseLeave={this.HandlerJ1}
                              // disabled

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          md={12}
                        >
                          <Col style={{ padding: "0" }} sm={6}>
                            <Typography variant="h6" component="h3">
                              <Label style={{ margin: "0", minWidth: "150px" }}>
                                Date de fin :
                              </Label>
                            </Typography>
                          </Col>
                          <Col style={{ padding: "0" }} sm={6}>
                            <TextField
                              id="standard-basic"
                              label="Date"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              type="date"
                              fullWidth
                              name="datfac"
                              defaultValue={this.state.seconddatelayout}
                              onChange={this.secondrechdatHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}

                {/* //////////////////////////////////////////////////////////////////////////////////////// */}
                <Card style={{ marginTop: "0px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    {/* <div className="ligbc-table"> */}
                    <div className="tabd29">
                      <table stripped>
                        <thead>
                          <tr style={{ textAlign: "center", fontSize: "12px" }}>
                            <th
                              style={{
                                minWidth: "90px",
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                paddingLeft: "1vw",
                              }}
                            >
                              Date
                            </th>
                            <th
                              // className="restd"
                              style={{
                                width: "30%",
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              libellé
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Qté Entrée
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Qté sortie
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Qté Retour
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                             Prix MVT
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Stock Fin
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              Valeur
                            </th>
                            <th
                              style={{
                                dispaly: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              L
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            overflowY: "overlay",
                            display: "block",
                            maxHeight: "20em",
                            color: `${darck ? "" : "#fff"}`,
                          }}
                        >
                          {this.state.tabreleveclient &&
                          this.state.tabreleveclient.length !== 0 ? (
                            <>
                              {/* //////////////////////////////////////somme précedent////////////////////////////////////////////////////// */}
                              <tr>
                                <td
                                  style={{
                                    // minWidth: "100px",
                                    // maxWidth: "100px",
                                    // fontWeight: "bold",
                                    // dispaly: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "start",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    color: "#020F64",
                                    fontWeight: "bold",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}
                                >
                                  <span>{dateJ1}</span>
                                </td>
                                <td
                                  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "0",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}
                                >
                                  {/* Solde_Précédent */}
                                 {codearticle}
                                </td>
                                <td> {this.state.libelle}</td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{
                                    width: "20%",
                                    color: `${darck ? "" : "#fff"}`,
                                  }}
                                ></td>
                                <td
                                  style={{
                                    // dispaly: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                    marginLeft: "2vw",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totaleNoPeriodeDebit !== 0
                                      ? totaleNoPeriodeDebit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totaleNoPeriodeCredit !== 0
                                      ? totaleNoPeriodeCredit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#020F64",
                                    }}
                                  >
                                    {totaleNoPeriodeSolde !== 0
                                      ? totaleNoPeriodeSolde
                                      : null}
                                  </span>
                                </td>
                                <td className="resajust"></td>
                              </tr>
                              {/* /////////////////////////////////////////////////////////////////////////////////// */}
                              {this.state.tabreleveclient &&
                                this.state.tabreleveclient
                                  .filter((a) => {
                                    let resultmed = new Date(a.Date);
                                    return (
                                      resultmed >= firstdate &&
                                      resultmed <= seconddate
                                    );
                                  })
                                  .map((t, i) => (
                                    <tr key={i}>
                                      <td
                                        style={{
                                          minWidth: "90px",
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>
                                          {t.datfac.slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")}
                                        </span>
                                      </td>
                                      <td
                                        className="restd"
                                        style={{
                                          fontSize: "12px",
                                          width: "30%",
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.libelle}</span>
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.QteEntr}</span>
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.QteSor}</span>
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>{t.QteRet}</span>
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* <span>
                                          {t.QTER}
                                        </span> */}
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* <span>
                                          {t.QTER}
                                        </span> */}
                                      </td>

                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* <span>
                                          {t.credit !== 0
                                            ? t.credit
                                            : ""}
                                        </span> */}
                                      </td>
                                      <td
                                        style={{
                                          dispaly: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* {t.debit - t.credit < 0 ? (
                                          <span style={{ color: "#9B1C24" }}>
                                            {(t.debit - t.credit)}
                                          </span>
                                        ) : (
                                          <span style={{ color: "#155757" }}>
                                            {(t.debit - t.credit)}
                                          </span>
                                        )} */}
                                      </td>
                                      <td></td>
                                    </tr>
                                  ))}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeDebit !== 0
                                      ? totalePeriodeDebit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeCredit !== 0
                                      ? totalePeriodeCredit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeSolde !== 0
                                      ? totalePeriodeSolde
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralDebit !== 0
                                      ? totalGeneralDebit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralCreadit !== 0
                                      ? totalGeneralCreadit
                                      : totalGeneralCreadit}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralSolde !== 0
                                      ? totalGeneralSolde
                                      : totalGeneralSolde}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {/* //////////////////////////////////////somme précedent////////////////////////////////////////////////////// */}
                              <tr>
                                <td
                                  style={{
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span>{dateJ1}</span>
                                </td>
                                <td
                                  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "0.5vw",
                                    fontWeight: "bold",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}
                                >
                                  {/* Solde_Précédent */}
                                  {codearticle}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "20%" }}
                                ></td>
                                <td
                                  style={{
                                    // dispaly: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "start",
                                    marginLeft: "3vw",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {/* ///////////////////////////////////Solde Précédent//////////////////////////////// */}
                                    {totaleNoPeriodeDebit !== 0
                                      ? totaleNoPeriodeDebit
                                      : null}
                                  </span>
                                  {/* ///////////////////////////////////////////////////////////////////////////////// */}
                                </td>
                                <td
                                  style={
                                    {
                                      // dispaly: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "start",
                                    }
                                  }
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {/* ///////////////////////////////////Solde Précédent//////////////////////////////// */}
                                    {totaleNoPeriodeCredit !== 0
                                      ? totaleNoPeriodeCredit
                                      : null}
                                  </span>
                                  {/* ///////////////////////////////////////////////////////////////////////////////// */}
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totaleNoPeriodeSolde !== 0
                                      ? totaleNoPeriodeSolde
                                      : null}
                                  </span>
                                </td>
                                <td className="resajust"></td>
                              </tr>
                              {/* /////////////////////////////////////////////////////////////////////////////////// */}
                              {this.props.fichesstock.fichesstock &&
                                this.props.fichesstock.fichesstock
                                // .filter((a) => {
                                //   let resultmed = new Date(a.Date);
                                //   return (
                                //     resultmed >= firstdate &&
                                //     resultmed <= seconddate
                                //   );
                                // })
                                .map((t, i) => (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        minWidth: "90px",
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span>
                                        {t.datfac.slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                      </span>
                                    </td>
                                    <td
                                      className="restd"
                                      style={{
                                        fontSize: "12px",
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.libelle}</span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.QteEntr}</span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.QteSor}</span>
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>{t.QteRet}</span>
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <span>
                                        {t.PrixEntr &&
                                          t.PrixEntr
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {/* <span>
                                        {t.debit !== 0
                                          ? t.debit
                                          : ""}
                                      </span> */}
                                    </td>

                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {/* <span>
                                        {t.credit !== 0
                                          ? t.credit
                                          : ""}
                                      </span> */}
                                    </td>
                                    <td
                                      style={{
                                        dispaly: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {/* {t.debit - t.credit < 0 ? (
                                        <span style={{ color: "#9B1C24" }}>
                                          {t.debit - t.credit}
                                        </span>
                                      ) : (
                                        <span style={{ color: "#155757" }}>
                                         {t.debit - t.credit} 
                                        </span>
                                      )} */}
                                    </td>
                                    <td></td>
                                  </tr>
                                ))}



                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Période</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeDebit !== 0
                                      ? totalePeriodeDebit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeCredit !== 0
                                      ? totalePeriodeCredit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalePeriodeSolde !== 0
                                      ? totalePeriodeSolde
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td  className="SoldePrecedent"
                                  style={{
                                    fontSize: "12px",
                                    // width: "30%",
                                    maxWidth: "200px",
                                    fontWeight: "bold",
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingLeft: "10px",
                                    color: `${darck ? "#020F64" : "#fff"}`,
                                  }}> Total_Général</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="rescol"
                                  style={{ width: "30%" }}
                                ></td>

                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralDebit !== 0
                                      ? totalGeneralDebit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralCreadit !== 0
                                      ? totalGeneralCreadit
                                      : null}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    dispaly: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `${darck ? "#020F64" : "#fff"}`,
                                    }}
                                  >
                                    {totalGeneralSolde !== 0
                                      ? totalGeneralSolde
                                      : null}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
                {/* //////////////////////////////////////////////////////////////////////////////// */}

              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
   
    SelectAffchStock: (z, f, g, a, b, c, d, e) =>
      dispatch(SelectAffchStock( z,f, g, a, b, c, d, e)),
    
  SelectFiche: (a, b, c, d, e) => dispatch(SelectFiche(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    ReleverFrc: state.ReleverFrc,
    darck: state.darck.darck,
    fichesstock: state.fichesstock,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(Affchfichestock));
