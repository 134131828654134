import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Wrapper from "./components/wrapper/Wrapper";
import Login from "./Login/Login";
import Logout from "./components/content/Logout";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import { I18nProvider, LOCALES } from "./i18n";
import Loader from "./components/Loader"; // Importez le composant Loader

export default () => {
  window.onbeforeunload = function (e) {
    window.localStorage.unloadTime = JSON.stringify(new Date());
  };

  window.onload = function () {
    let loadTime = new Date();
    let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
    
    let refreshTime = loadTime.getTime() - unloadTime.getTime();

    if (refreshTime > 10000) {
      window.localStorage.removeItem("token");
      window.location.reload();
    }
  };

  const [locale, setLocale] = useState(LOCALES.FRENCH);
  const [loading, setLoading] = useState(true);

  const changeLang = (lang) => {
    setLocale(lang);
  };

  // Simuler un délai de chargement
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Changez le délai selon vos besoins
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <I18nProvider locale={locale}>
      <div>
        <Router>
          <Switch>
            <Route exact path="/" render={() => <Login lang={changeLang} />} />
            <Route exact path="/logout" component={Logout} />
            <Route render={() => <Wrapper locale={locale} />} />
          </Switch>
        </Router>
      </div>
    </I18nProvider>
  );
};




// import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
// import { BrowserRouter as Router } from "react-router-dom";
// import { Switch, Route } from "react-router-dom";
// import Wrapper from "./components/wrapper/Wrapper";
// import Login from "./Login/Login";
// import Logout from "./components/content/Logout";
// import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
// import { I18nProvider, LOCALES } from "./i18n";

// import ReactLoading from "react-loading";


// export default () => {
//   window.onbeforeunload = function (e) {
//     window.localStorage.unloadTime = JSON.stringify(new Date());
//   };

//   window.onload = function () {
//     let loadTime = new Date();
//     let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
    
//     let refreshTime = loadTime.getTime() - unloadTime.getTime();

//     if (refreshTime > 10000) {
//       window.localStorage.removeItem("token");
//       window.location.reload();
//     }
//   };

//   const [locale, setLocale] = useState(LOCALES.FRENCH);

//   const changeLang = (lang) => {
//     setLocale(lang);
//   };
//   return (
//     <I18nProvider locale={locale}>
//       <div>
//         <Router>
//           <Switch>
//             <Route exact path="/" render={() => <Login lang={changeLang} />} />
//             <Route exact path="/logout" component={Logout} />
//             <Route render={() => <Wrapper locale={locale} />} />
//           </Switch>
//         </Router>
//       </div>
//     </I18nProvider>
//   );
// };

// // export default () => {
// //   window.onbeforeunload = function (e) {
// //     window.localStorage.unloadTime = JSON.stringify(new Date());
// //   };

// //   window.onload = function () {
// //     let loadTime = new Date();
// //     let unloadTime = window.localStorage.unloadTime
// //       ? new Date(JSON.parse(window.localStorage.unloadTime))
// //       : loadTime; // Utilise la date actuelle si unloadTime n'est pas défini

// //     let refreshTime = loadTime.getTime() - unloadTime.getTime();

// //     if (refreshTime > 10000) {
// //       window.localStorage.removeItem("token");
// //       window.location.reload();
// //     }
// //   };

// //   const [locale, setLocale] = useState(LOCALES.FRENCH);

// //   const changeLang = (lang) => {
// //     setLocale(lang);
// //   };

// //   return (
// //     <I18nProvider locale={locale}>
// //       <div>
// //         <Router>
// //           <Switch>
// //             <Route exact path="/" render={() => <Login lang={changeLang} />} />
// //             <Route exact path="/logout" component={Logout} />
// //             <Route render={() => <Wrapper locale={locale} />} />
// //           </Switch>
// //         </Router>
// //       </div>
// //     </I18nProvider>
// //   );
// // };
