import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    "GESTION COMMERCIALE": "BUSINESS MANAGEMENT",
   //"REPRESENTANT COMMERCIAL": "BUSINESS REPRESENTATIVE",
    "Gérez vos affaires": "Manage your business",
    Vente: "Sales",
    Achat: "Purchase",
    Factures: "Invoices",
    Statistiques: "Statistics",
    Analyses: "Analyzes",
    "Se Connecter": "Log In",
    "Code Dossier": "Folder Code",
    "Nom d'utilisateur": "Username",
    "Afficher mot de passe":"show password",
    "CRM RÉALISÉ PAR":"CRM CREATE BY",
    "TOUS DROITS RÉSERVÉS":"ALL RIGHTS RESERVED",
    "Mot de Passe": "Password",
    "Total Charge": "Total load",
    "Total Charge des Fournisseurs par jour": "Total Supplier Charges per day",
    "Chiffre d'affaire Global": "Global Turnover",
    BL: "DN",
    Facture: "Invoice",
    "Total Montant des Factures par jour": "Total Amount of Invoices per day",
    "Total Montant des BL par jour": "Total Amount of DF per day",
    "Raison Social": "Company Name",
    Montant: "Amount",
    Clients: "Customers",
    Fournisseurs: "Suppliers",
    "Top 5 Fournisseurs": "Top 5 Suppliers",
    "% Des clients par région": "% Of customers by region",
    "% Des articles les plus vendus": "% Of the best-selling products",
    Articles: "Products",
    Année: "Year",
    "Veuillez Patientez...": "Please Wait...",
    "Se Déconnecter": "Log Out",
    "Non Autorisé!": "Unauthorized!",
    "Route de Tunis km 0.5 imm LOULOU": "Tunis road Km 0.5 LOULOU building",
    "3000 Sfax Tunisie": "3000 Sfax Tunisia",
    "Date début": "Start date",
    Rechercher: "Search",
    "Date Fin": "End date",
    "Invalide login ou mot de passe": "Invalid username or password!",
    "vérifier votre code Dossier": "Check your Folder code",
    "№ Avoir": "№ Credit",
    "Pièce Jointe": "N°Attachment",
    "Code client": "Customer code",
    "Montant BC effectué / jour": "Order Note Amount / day",
    "Montant BL effectué / jour": "DN amount made / day",
    "Bon de Livraison": "Delivery Note",
    "Bon de Commande": "Order Form",
    "êtes-vous sûr de vouloir supprimer cet avoir?":
      "Are you sure you want to delete this credit note?",
    Supprimer: "Remove",
    "Avoir N°": "Credit N°",
    "Date Avoir": "Credit Date",
    Désignation: "Designation",
    Quantité: "Quantity",
    PUHT: "UPBVAT",
    Remise: "Discount",
    TVA: "VAT",
    TotalHT: "TotalBVAT",
    TotalTTC: "TotalAVAT",
    "Total HT Brut": "Gross Total BVAT",
    "Remise Article": "Product Discount",
    "Total TVA": "Total VAT",
    "Valeur de timbre": "Stamp value",
    "Total HT Net": "Net Total BVAT",
    "Remise Globale": "Global Discount",
    "Net à Payer": "Net Payable",
    "sur Facture N°": "on invoice N°",
    "Code TVA": "VAT Code",
    Assiette: "Plate",
    "Total quantité": "Total Quantity",
    "Timbre fiscal": "Tax stamp",
    "Signature Client": "Customer signature",
    "Signature & cachet": "Signature & stamp",
    "Ajouter Bon de livraison": "Add delivery Note",
    "Cat Fiscale": "Tax Category",
    Assujetti: "Subject",
    "Non Assujetti": "Not Subject",
    "Exonéré TVA/FODEC": "VAT / FODEC Exempt",
    "Exonéré TVA": "VAT Exempt",
    Suspenssion: "Suspension",
    "Cession à Quai": "Transfer to Quay",
    "Réduction TVA": "VAT Reduction",
    "Remise Globale %": "Global Discount %",
    "Ajouter, Editer et supprimer article": "Add, Edit and delete product",
    PUTTCNet: "UPAUVTNet",
    "Enregistrer tous": "Save all",
    "Liste des Articles": "List of Products",
    Annulé: "Canceled",
    Facturé: "Invoiced",
    "Non Facturé": "Not Invoiced",
    "№ BL": "№ DN",
    Etat: "State",
    Enregistrer: "Save",
    Modifier: "Edit",
    Annuler: "Cancel",
    Activer: "Activate",
    Facturer: "Charge",
    "Bon de Livraison N°": "Delivery Note N°",
    Société: "Company",
    Adresse: "Address",
    Tél: "Phone",
    "Article ajouté": "Product added",
    "Article supprimé": "Product Removed",
    "Chercher article": "Search Product",
    "Code article": "Product Code",
    "Désignation article": "Product Designation",
    "Stock insuffisant!": "Insufficient stock!",
    Unité: "Unit",
    "Remise %": "Discount %",
    "Supprimer cet article": "Delete this product",
    "Modifier Bon de livraison №": "Edit Delivery Note №",
    "Chercher client par": "Search customer by",
    "Enregistrer les modifications": "Save Changes",
    Ajouter: "Add",
    PUNet: "UPNet",
    "Editer cet article": "Edit this Product",
    "№ BE": "№ EN",
    "Code Fournisseur": "Supplier code",
    "Numéro de facture": "Bill number",
    PJ: "Attachment",
    "Bon De Livraison Fournisseur": "Supplier Delivery Note",
    "N°Pièce Frs": "N°Supplier Part",
    "N° BE associé": "N° Associated EN",
    "Date BL": "DN Date",
    "Ajouter Bon de Retour": "Add Return note",
    "№ BLBR": "№ DNRN",
    "N° facture": "N° Invoice",
    "Total TTC": "Total IT",
    "Bon De Retour": "Return Note",
    "№ BR": "№ RN",
    "Edit ligne article": "Edit product line",
    Avoir: "Credit",
    "Bon de Retour N°": "Return Note N°",
    "Date BR": "RN Date",
    "Modifier Bon de Retour": "Return Note modifier",
    Vendeur: "Seller",
    "Etabli par": "Established by",
    Utilisateur: " User",
    du: "of",
    "Code à Barre": "Barcode",
  },
};
