import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Checkbox,
  Grid,
  Snackbar,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { GetCodcli } from "../../../redux/actions/GetCodcli";
import { SelectClient } from "../../../redux/actions/GetClients";
import { withStyles } from "@material-ui/styles";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { parseTwoDigitYear } from "moment/moment";

import { SelectNomenclature } from "../../../redux/actions/GetNomenclature";
import { SelectNomenclatureBQ } from "../../../redux/actions/GetNomeBQ";
import { SelectNomenclatureCS } from "../../../redux/actions/GetNomeCS";
import { SelectNomedepense } from "../../../redux/actions/Getdepense";
import{SelectNomeEmply}from "../../../redux/actions/GetEmpl";
import{GetCodeCaisse}from"../../../redux/actions/GetCodCais";
import {SelectCaisse} from "../../../redux/actions/GetCaisse";
import {SelectVend} from "../../../redux/actions/GetVend";
const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddMVTCAISModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: '',
      passager: 0,
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",
      defaultdate: date,
      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],

      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      numnum: 0,
      categoriefiscale: "",
      attestation: "",
      dateetabli: "",
      datexp: "",
      statutsCodeClient: false,
      codcaisse: "",
    };
  }

  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectVend(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclature(
      "MR",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomeEmply(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetCodeCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomedepense(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  

  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

 savecaisse = (event) => {
    event.preventDefault();
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
   // const sens = this.state.sens === 'recette' ? 'd' : 'c'; // 'd' for recette, 'c' for depense
if( this.state.sens==='c'){ 
 
const requestBody1 = {
  numopr: this.props.codCais.codCais[0].valeur,
  datopr: this.state.defaultdate,
  libelle: this.state.libelle,
  modreg: this.state.codmodreg,
  numcais: this.state.codcaisse,
  numchq: this.state.numchq,
  matban: this.state.codbqclient,
  datech: this.state.defaultdate,
  montant: this.state.montant,
  sens:'c',
  depense:this.state.coddepense,
  heur: currentTime,
  VENDEUR: this.props.Auth.username,
  numoprdebit: this.props.codCais.codCais[0].valeur+1,
  emp: this.state.libEmplss,

};
const requestBody2 = {
  numopr: this.props.codCais.codCais[0].valeur+1,
  datopr: this.state.defaultdate,
  libelle: this.state.libelle,
  modreg: this.state.codmodreg,
  numcais: this.state.codcaisseDest,
  numchq: this.state.numchq,
  matban: this.state.codbqclient,
  datech: this.state.defaultdate,
  montant: this.state.montant,
  sens:'d',
  depense:this.state.coddepense,
  heur: currentTime,
  VENDEUR: this.props.Auth.username,
  numoprdebit: '',
  emp: this.state.libEmplss,

};

// Fetch request to the API
fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
  method: "POST",
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  },
  body: JSON.stringify(requestBody1), // Convert the object to JSON
})

.then((res) => {
  if (!res.ok) {
      throw new Error("Network response was not ok");
  }
  return res.json();
})
fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
  method: "POST",
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  },
  body: JSON.stringify(requestBody2), // Convert the object to JSON
})

.then((res) => {
  if (!res.ok) {
      throw new Error("Network response was not ok");
  }
  return res.json();
})
.then((result) => {
  this.props.onHide();
  this.props.SelectCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
  );

  this.setState({ snackbaropen: true, snackbarmsg: result });
  this.props.GetCodeCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
  );

  // Reload the page to refresh the data
  window.location.reload();
})
.catch((error) => {
  console.error("Error:", error);
  this.setState({ snackbaropen: true, snackbarmsg: "Failed to submit data" });
});  

}else {
const requestBody = {
  numopr: this.props.codCais.codCais[0].valeur,
  datopr: this.state.defaultdate,
  libelle: this.state.libelle,
  modreg: this.state.codmodreg,
  numcais: this.state.codcaisse,
  numchq: this.state.numchq,
  matban: this.state.codbqclient,
  datech: this.state.defaultdate,
  montant: this.state.montant,
  sens:'d',
  depense:this.state.coddepense,
  heur: currentTime,
  VENDEUR: this.props.Auth.username,
  numoprdebit: '',
  emp: this.state.libEmplss,

};

// Fetch request to the API
fetch(`https://www.24-crm.com/webservice/api/Caisse?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
  method: "POST",
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  },
  body: JSON.stringify(requestBody), // Convert the object to JSON
})
.then((res) => {
  if (!res.ok) {
      throw new Error("Network response was not ok");
  }
  return res.json();
})
.then((result) => {
  this.props.onHide();
  this.props.SelectCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
  );

  this.setState({ snackbaropen: true, snackbarmsg: result });
  this.props.GetCodeCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
  );

  // Reload the page to refresh the data
  window.location.reload();
})
.catch((error) => {
  console.error("Error:", error);
  this.setState({ snackbaropen: true, snackbarmsg: "Failed to submit data" });
});  
 }  
};


handleInputChange = (event) => {
  const { value } = event.target;
  // Set sens based on the selected radio button
  this.setState({ sens: value === 'recette' ? 'd' : 'c' });
};



  render() {
    const { darck, classes, allCodeClient } = this.props;
    const { statutsCodeClient } = this.state;
console.log("codcaisse",this.props.codCais.codCais)
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
             
            >
              x
             
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "0px" }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Mouvement de caisse</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.savecaisse}
              className={`${darck ? "" : classes.root}`}
            >
              <Row form>
                <Col sm={12}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                     
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            {/* {this.props.codclis.codclis.map((cod) => ( */}
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="N°Mvt caisse"
                              margin="dense"
                              variant="outlined"
                              value={
                                this.props.codCais.codCais[0] &&
                                this.props.codCais.codCais[0].valeur
                              }
                              disabled
                              fullWidth
                              name="codcli"
                              required
                              // onChange={(event) =>
                              //   this.setState({
                              //     CodeClient: event.target.value,
                              //   })
                              // }
                              error={statutsCodeClient}
                              helperText={
                                statutsCodeClient
                                  ? "Code Client existe déjà"
                                  : ""
                              }
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                            {/* ))} */}
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        margin="dense"
                        type="date"
                        fullWidth
                        variant="outlined"
                        defaultValue={this.state.defaultdate}
                        // className="card add-input"

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                      </Row>

                      {/* /////////////////////////Raison Sociale///////////////////////// */}

                      <Row form>
                        {/* <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }} controlId="Libelle">
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Libelle"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="Libelle"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col> */}
  
   <Col sm={12}>
    <FormGroup style={{ overflow: "hidden" }} controlId="Libelle">
        <TextField
            id="standard-basic"
            label="Libellé"
            margin="dense"
            variant="outlined"
            fullWidth
            name="Libelle"
            value={this.state.libelle} // Bind value to state
            onChange={(e) => this.setState({ libelle: e.target.value })} // Update state on change
            InputProps={{
                className: !darck ? this.props.classes.input : null,
            }}
            InputLabelProps={{
                style: {
                    color: !darck ? "#fff" : null,
                },
            }}
        />
    </FormGroup>
</Col>

                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                      <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                  chdec: getOptionLabel.chdec,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisse: getOptionLabel.code,
                                  libcaisse: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codcaisse: "",
                                  libcaisse: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisse}</FormHelperText>
                      </FormGroup>
                    </Col>
                      </Row>

                    </Card.Body>
                  </Card>
                </Col>
        {/* /////////////////////////detaille1///////////////////////// */}
  <Col sm={6}>
                  
                  <Card className="card0" style={{ height: "100%",marginTop:"15px" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >

       <Row>
       <Col sm={8}>
                    
                    <TextField
                      id="standard-basic"
                      label="Montant"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      name="montant"
                      required
                      //defaultValue={parseFloat(0).toFixed(3)}
                      // className="card add-input"
                      onChange={(e) => {
                        this.setState({
                          montant: e.target.value,
                        });
                      }}
                      InputProps={{
                        className: !darck ? this.props.classes.input : null,
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                    />
                
   </Col>



<Col sm={4}>
    <FormGroup>
        <div>
            <label>
                <input
                    type="radio"
                    name="type" // Ensure both radio buttons share the same name
                    value="recette"
                    checked={this.state.sens === 'd'} // Check if sens is 'd' for Recette
                    onChange={this.handleInputChange} // Handle change
                />{' '}
                Recette
            </label>
            <br />
            <label>
                <input
                    type="radio"
                    name="type" // Ensure both radio buttons share the same name
                    value="depense"
                    checked={this.state.sens === 'c'} // Check if sens is 'c' for Dépense
                    onChange={this.handleInputChange} // Handle change
                />{' '}
                Dépense
            </label>
        </div>
    </FormGroup>
</Col>





     <Col sm={12}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.depenses.depenses}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  coddepense: getOptionLabel.code,
                                  libdepense: getOptionLabel.lib,
                                })
                              : this.setState({
                                coddepense: "",
                                  libdepense: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Dépense"
                              margin="dense"
                              fullWidth
                              name="depense"
                              variant="outlined"
                             
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libdepense}</FormHelperText>
                      </FormGroup>
                    </Col>
                 
            </Row>
            {this.state.sens==='c'?( <Row>
             <Col sm={12}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisseDest: getOptionLabel.code,
                                  libcaisseDest: getOptionLabel.lib,
                                })
                              : this.setState({
                                codcaisseDest: "",
                                  libcaisseDest: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse Destination"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisseDest}</FormHelperText>
                      </FormGroup>
                    </Col>         
                   
</Row> ):null}
             
                   
                    </Card.Body>
                  </Card>
                </Col>
        {/* /////////////////////////detaille2///////////////////////// */}
                <Col sm={6}>
                  
                  <Card className="card0" style={{ height: "100%" ,marginTop:"15px" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >

       <Row>
       <Col sm={12}>
                    
                    <TextField
                      id="standard-basic"
                      label="N°chéque"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      name="numchq"
                     // required
                      //defaultValue={parseFloat(0).toFixed(3)}
                      // className="card add-input"
                      onChange={(e) => {
                        this.setState({
                          numchq: e.target.value,
                        });
                      }}
                      InputProps={{
                        className: !darck ? this.props.classes.input : null,
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                    />
                
   </Col>
   <Col sm={12}>
    <br/>
   </Col>
   <Col sm={12}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              type="date"
                              label="Echéance"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              defaultValue={this.state.defauldate}
                              name="echeance"
                              // className="card add-input"
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
  <Col sm={12}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqclient: getOptionLabel.code,
                                      libbqclient: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqclient: "",
                                      libbqclient: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Banque"
                                  margin="dense"
                                  fullWidth
                                  name="bqclient"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqclient}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
                 
            </Row>
 <Row>
             <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.Emplss.Emplss}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codEmplss: getOptionLabel.code,
                                  libEmplss: getOptionLabel.lib,
                                })
                              : this.setState({
                                codcaisseDest: "",
                                  libcaisseDest: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Employé"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              value={this.state.Emp === this.props.codEmplss ? this.props.codEmplss : this.state.codEmplss}
                              // className="card add-input"
                              // required={
                              //   this.state.codmodreg
                              //     .toUpperCase()
                              //     .includes("RS")
                              //     ? false
                              //     : true
                              // }
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libEmplss}</FormHelperText>
                      </FormGroup>
                    </Col>         
      <Col sm={8}>
                    
                    <TextField
                      id="standard-basic"
                      label=""
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      name="montant"
                     // required
                     value={this.state.Emp === this.props.libEmplss ? this.props.libEmplss : this.state.libEmplss}
                           
                      // className="card add-input"
                      onChange={(e) => {
                        this.setState({
                          montant: e.target.value,
                        });
                      }}
                      InputProps={{
                        className: !darck ? this.props.classes.input : null,
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                    />
                
   </Col>
</Row>              
                   
                    </Card.Body>
                  </Card>
                </Col>
             
             
              </Row>





              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                <Button type="submit" color="primary" style={{ width: "100%" }}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
    SelectCaisse: (a, b, c, d, e) => {
      dispatch(SelectCaisse(a, b, c, d, e));
    },
    SelectNomenclature: (cat, a, b, c, d, e) =>
    dispatch(SelectNomenclature("MR", a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
    dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectNomenclatureCS: (a, b, c, d, e) =>
    dispatch(SelectNomenclatureCS(a, b, c, d, e)),
    SelectNomedepense: (a, b, c, d, e) =>
    dispatch(SelectNomedepense(a, b, c, d, e)),
    SelectNomeEmply: (a, b, c, d, e) =>
    dispatch(SelectNomeEmply(a, b, c, d, e)),
    
    GetCodeCaisse: (a, b, c, d, e) =>
    dispatch(GetCodeCaisse(a, b, c, d, e)),

    SelectVend : (a, b, c, d, e) => {
      dispatch(SelectVend (a, b, c, d, e));
    }
  };
}


function mapStateToProps(state) {
  return {
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    nomenclatures: state.nomenclatures,
    bqs: state.bqs,
    css: state.css,
    depenses:state.depenses,
    Emplss:state.Emplss,
    codCais:state.codCais,
    Auth: state.Auth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddMVTCAISModal));
