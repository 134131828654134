import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import "../styling/Styles.css";
import "./ss.scss";
import "./Responsivebc.css";

import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";

import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import LigBCArticle from "./LigBCArticle";
import { GetNumFacBC } from "../../redux/actions/Getcodbc";
import { SelectBC } from "../../redux/actions/GetBC";

import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});


class AddBCModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      test: "25-04-2022",
      gilad: true,
      passager: false,
      defaultdate: new Date().toISOString().slice(0, 10),
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,

      snackbaropen: false,
      avance: 0,
      AVANCE: 0,
      regimecli: "",
      snackbarmsg: ",",
      GMM: "",
      magasin: [],
      MG: "",
      GMM: "",
      rechs: [],
      DEP: "",
      username: username,

      libellecatfisc: "",
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      typfac: "",
      numfac: "",
      typfacTab: [],
      numdevTab: [],
      facOrBl: "",
      numnum: 0,
      pmp: "",
      rep: "",
      representant: [],
      //valtimbre: 0,
      clicked: false,
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacDevis(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacBC(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
      });
    this.setState({ tab: [] });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.facOrBl != this.state.facOrBl) {
      fetch(
        `https://www.24-crm.com/webservice/api/LigBCDV?type=DV&numfac=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            tab: data.map((el) => {
              return {
                codearticle: el.codart,
                codtvaa: el.codtva,
                qte: el.quantite,
                des: el.desart,
                puht: el.priuni,
                remisea: el.remise,
                tva: el.tautva,
                puttcnet: el.ttclig,
                totalht: el.montht,
                unite: el.unite,
                faudec: el.fodart,
                pmp: el.pmp,
              };
            }),
          });
        })

        .then((res) => {
          const SumQte =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte), 0);
          const SumRemiseArticle =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
              0
            );
          const SumHtBrut =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
          const SumTva =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseInt(v.qte) *
                  parseFloat(v.puht) *
                  ((100 - parseFloat(v.remisea)) / 100) *
                  ((100 - parseFloat(this.state.remiseg)) / 100) *
                  (parseFloat(v.tva) / 100),
              0
            );
          const SumHtNet =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseFloat(v.totalht) *
                  ((100 - parseFloat(this.state.remiseg)) / 100),
              0
            );
          const SumRemiseGlobale =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + v.totalht * (this.state.remiseg / 100),
              0
            );
          const SumNetAPayer =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                (v.totalht * ((100 - this.state.remiseg) / 100) +
                  v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
              0
            );
          const Avance = (SumNetAPayer * this.state.regimecli) / 100;
          const SumNetNetAPayer = SumNetAPayer + Avance;

          this.setState({
            totalqte: SumQte,
            sumremisearticle: SumRemiseArticle,
            totalhtbrut: SumHtBrut,
            totaltva: SumTva,
            totalhtnet: SumHtNet,
            remiseglobal: SumRemiseGlobale,
            netapayer: SumNetAPayer,
            btnEnabled: true,
            avance: Avance,
            netnetapayer: SumNetNetAPayer,
          });
        });
    }
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    avance,
    netnetapayer,
    codtvaa,
    pmp
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      avance: avance,
      netnetapayer: netnetapayer,
      codtvaa: codtvaa,
      pmp: pmp,
    });
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  raisocHandler = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?typfac=DV&codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          numdevTab: result,
        });
      });

    this.setState({ raisonsocial: event.target.value });
  };

  catHandler = (event) => {
    if (event.target.value == "Assujetti") {
      this.setState({ categoriefiscale: 0 });
    } else if (event.target.value == "Non Assujetti") {
      this.setState({ categoriefiscale: 1 });
    } else if (event.target.value == "Exonéré TVA/FODEC") {
      this.setState({ categoriefiscale: 2 });
    } else if (event.target.value == "Exonéré TVA") {
      this.setState({ categoriefiscale: 3 });
    } else if (event.target.value == "Suspenssion") {
      this.setState({ categoriefiscale: 4 });
    } else if (event.target.value == "Cession à Quai") {
      this.setState({ categoriefiscale: 5 });
    } else {
      this.setState({ categoriefiscale: 6 });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) *
          (this.state.netapayer + this.state.avance) +
        Number(this.state.valtimbre),
    });
  };

  enregistrer = (event) => {
    event.preventDefault();
    //////////// post BCDV /////////////////////////////
   fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?numfac&typfac=BC&taurem=${event.target.remise.value}&codcli=${event.target.codcli.value}&raisoc=${event.target.raissoc.value}&catfisc=${this.state.categoriefiscale}&datfac=${this.state.defaultdate}&timbre=${this.state.showTimbre}&tauxNASS=${this.state.regimecli}&vendeur=${this.props.Auth.username}&valtimbre=${this.state.valtimbre}&codrep=${this.state.rep}&pj=${this.state.numfac}&smNASS=&${this.state.avance}&CodDep=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      //////////////////////////////////////////////////////////////////////////// post ligarticle ////////////////////////////////
      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k, index) => {
          fetch(
            `https://www.24-crm.com/webservice/api/LigBCDV/{ID}?numfac=${this.state.numnum}&typfac=BC&numlig=${index}&codart=${k.codearticle}&quantite=${k.qte}&fodart=0&desart=${k.des}&datfac=${this.state.defaultdate}&priuni=${k.puht}&remise=${k.remisea}&unite=${k.unite}&codtva=${k.codtvaa}&tautva=${k.tva}&qtecom=${k.qte}&CB=${k.codbar}&formqt=0&pmp=${k.pmp}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                console.log(result);
              },
              (error) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "failed",
                });
              }
            );
        });
      })

      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectBC(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          //Exécuter la procédure SommeTTCBCDV
          fetch(
            `https://www.24-crm.com/webservice/api/LigBCDV?FAC=${this.state.numnum}&typfacc=BC&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N°" + this.state.numnum,
          });
          this.props.GetNumFacBC(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )

      .then((res) => {
        //////////// switch update ////////////////

        // setTimeout(() => {   
          fetch(
          `https://www.24-crm.com/webservice/api/Switch?code=BC2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              // window.location.reload();
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          );
        // },3000)
      });
  };
  render() {
    const { rem } = this.state;
    const { darck, classes, alllistcode } = this.props;

    let addModalClose1 = () => this.setState({ addModalShow1: false });
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter un Bon de commande </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      <FormGroup>
                        {
                          this.props.numfacbcs.numfacbcs && (
                            // this.props.numfacbcs.numfacbcs.map((t) => (

                            <>
                              <TextField
                                // className="card add-input"
                                //BC
                                id="standard-basic"
                                label="№ BC"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="codbc"
                                value={
                                  this.props.numfacbcs.numfacbcs[0] &&
                                  this.props.numfacbcs.numfacbcs[0].valeur
                                }
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </>
                          )
                          // ))
                        }
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      <TextField
                        id="standard-basic"
                        label=""
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        type="date"
                        fullWidth
                        name="datfac"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {this.state.GMM ? (
                      <>
                        {/* <Col sm={2}>
                          <FormGroup>
                            <Autocomplete
                              id="outlined-select-currency"
                              includeInputInList
                              options={this.state.representant}
                              getOptionLabel={(option) => `${option.nom}`}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      rep: getOptionLabel.codrep,
                                    })
                                  : this.setState({
                                      rep: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    onFocus={() => {
                                      //liste des origines
                                      fetch(
                                        `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                        {
                                          method: "GET",
                                          header: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                          },
                                        }
                                      )
                                        .then((res) => res.json())
                                        .then((result) => {
                                          this.setState({
                                            representant: result,
                                          });
                                        });
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    name="representant"
                                    label="Représentant"
                                    disabled={params.disabled}
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    margin="dense"
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                </>
                              )}
                            />
                          </FormGroup>
                        </Col> */}
                        <Col sm={5}>
                          <FormGroup>
                            <Autocomplete
                              id="list-fac-bl"
                              includeInputInList
                              value={{
                                code: this.state.MG,
                                lib: this.state.DEP,
                              }}
                              className="ajouter-client-input"
                              options={this.state.magasin}
                              getOptionLabel={(option) =>
                                ` ${option.code}  ${option.lib}`
                              }
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      MG: getOptionLabel.code,
                                      DEP: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      MG: "",
                                      DEP: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // label={translate("N° facture")}

                                  onFocus={() => {
                                    //liste  bon de commande par code client
                                    fetch(
                                      `https://www.24-crm.com/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                      {
                                        method: "GET",
                                        header: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    )
                                      .then((res) => res.json())
                                      .then((result) => {
                                        this.setState({
                                          magasin: result,
                                        });
                                      });
                                  }}
                                  label="Magasin"
                                  // className="card add-input"
                                  fullWidth
                                  name="devis"
                                  required
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  disabled={params.disabled}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  margin="dense"
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}




                                  options={
                                    this.state.clicked
                                      ? this.state.rechs
                                      : this.props.clients.clients
                                  }
                                  getOptionLabel={(option) => option.raisoc}
                                  onChange={(event, getOptionLabel) => {
                                    getOptionLabel
                                      ? this.setState({
                                          raisonsocial: getOptionLabel.raisoc,
                                          remiseg: getOptionLabel.remise,
                                          codeclient: getOptionLabel.codcli,
                                          categoriefiscale: getOptionLabel.catfisc,
                                          btnEnable: true,
                                          showTimbre: getOptionLabel.timbre,
                                          regimecli: getOptionLabel.regimecli,
                                          showButtonModalPassager:
                                            getOptionLabel.passager,
                                          cemail: getOptionLabel.email,
                                          valtimbre:                                      
                                            0,
                                        })
                                      : this.setState({
                                          raisonsocial: "",
                                          remiseg: 0,
                                          codeclient: "",
                                          categoriefiscale: "",
                                          btnEnable: false,
                                          showTimbre: false,
                                          regimecli: 0,
                                          showButtonModalPassager: false,
                                          valtimbre: 0,
                                        });
                                  }}




                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    ) : null
                    // (
                    //   <>
                    //     <Col sm={5}>
                    //       {this.props.Auth.fct[0] === "100" ? (
                    //         <FormGroup>
                    //           <Autocomplete
                    //             id="outlined-select-currency"
                    //             includeInputInList
                    //             options={this.state.representant}
                    //             getOptionLabel={(option) => `${option.codrep}`}
                    //             onChange={(event, getOptionLabel) => {
                    //               getOptionLabel
                    //                 ? this.setState({
                    //                     rep: getOptionLabel.codrep,
                    //                   })
                    //                 : this.setState({
                    //                     rep: "",
                    //                   });
                    //             }}
                    //             // props.Auth.username

                    //             renderInput={(params) => (
                    //               <TextField
                    //                 // label={translate("N° facture")}
                    //                 // className="card add-input"
                    //                 variant="outlined"
                    //                 fullWidth
                    //                 name="representant"
                    //                 label="Représentant"
                    //                 InputProps={{
                    //                   ...params.InputProps,
                    //                   className: !darck
                    //                     ? this.props.classes.input
                    //                     : null,
                    //                 }}
                    //                 disabled={params.disabled}
                    //                 inputProps={params.inputProps}
                    //                 id={params.id}
                    //                 margin="dense"
                    //                 InputLabelProps={{
                    //                   style: {
                    //                     color: !darck ? "#fff" : null,
                    //                   },
                    //                 }}
                    //                 // value={
                    //                 //   this.props.Auth && this.props.Auth.username
                    //                 // }
                    //                 onFocus={() => {
                    //                   //liste des origines
                    //                   fetch(
                    //                     `https://www.24-crm.com/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                    //                     {
                    //                       method: "GET",
                    //                       header: {
                    //                         Accept: "application/json",
                    //                         "Content-Type": "application/json",
                    //                       },
                    //                     }
                    //                   )
                    //                     .then((res) => res.json())
                    //                     .then((result) => {
                    //                       this.setState({
                    //                         representant: result,
                    //                       });
                    //                     });
                    //                 }}
                    //               />
                    //             )}
                    //           />
                    //         </FormGroup>
                    //       ) : (
                    //         <FormGroup>
                    //           <TextField
                    //             // label={translate("N° facture")}
                    //             // className="card add-input"
                    //             variant="outlined"
                    //             fullWidth
                    //             name="representant"
                    //             label="Représentant"
                    //             margin="dense"
                    //             disabled
                    //             InputLabelProps={{
                    //               style: {
                    //                 color: !darck ? "#fff" : null,
                    //               },
                    //             }}
                    //             value={
                    //               this.props.Auth && this.props.Auth.username
                    //             }
                    //           />
                    //         </FormGroup>
                    //       )}
                    //     </Col>
                    //   </>
                    // )
                    }
                  </Row>

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              style={{
                                color: `${darck ? "grey" : "#fff"}`,
                              }}
                            >
                              Raison social
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid
                              item
                              style={{ color: `${darck ? "#3f51b5" : "#fff"}` }}
                            >
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    regimecli: getOptionLabel.regimecli,

                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="codcli"
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                onChange={this.clientHandlerChange}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    regimecli: getOptionLabel.regimecli,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      //getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Raison social"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                id="standard-basic"
                                // className="card add-input"

                                // InputProps={{
                                //   className: !darck
                                //     ? this.props.classes.input
                                //     : null,
                                // }}
                                // InputLabelProps={{
                                //   style: {
                                //     color: !darck ? "#fff" : null,
                                //   },
                                // }}

                                // InputProps={{
                                //   className: !darck
                                //     ? this.props.classes.input
                                //     : null,
                                // }}
                                // InputLabelProps={{
                                //   style: {
                                //     color: !darck ? "#fff" : null,
                                //   },
                                // }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager &&
                      !this.state.numfac ? (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="raissoc"
                              value={this.state.raisonsocial || ""}
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : this.state.showButtonModalPassager &&
                        this.state.numfac ? (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              disabled
                              fullWidth
                              name="raissoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              // disabled={
                              //   this.state.codeclient === "100" ? false : true
                              // }
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row form>
                    {this.state.showButtonModalPassager &&
                    !this.state.numfac ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={this.state.cat}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel.label == "Assujetti"
                                ? this.setState({ categoriefiscale: 0 })
                                : getOptionLabel.label == "Non Assujetti"
                                ? this.setState({ categoriefiscale: 1 })
                                : getOptionLabel.label == "Exonéré TVA/FODEC"
                                ? this.setState({ categoriefiscale: 2 })
                                : getOptionLabel.label == "Exonéré TVA"
                                ? this.setState({ categoriefiscale: 3 })
                                : getOptionLabel.label == "Suspenssion"
                                ? this.setState({ categoriefiscale: 4 })
                                : getOptionLabel.label == "Cession à Quai"
                                ? this.setState({ categoriefiscale: 5 })
                                : this.setState({ categoriefiscale: 6 });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cat Fiscale"
                                className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="catfisc"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : this.state.showButtonModalPassager &&
                      this.state.numfac ? (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fis"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            //  onChange={this.catHandler}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fis"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            //  onChange={this.catHandler}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    )}
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="remise"
                        onChange={this.remiseglobalChange}
                        value={this.state.remiseg}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {this.state.codeclient ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.numdevTab}
                            getOptionLabel={(option) =>
                              `${option.typfac} : ${option.numfac}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    facOrBl: getOptionLabel,
                                    numfac: getOptionLabel.numfac,
                                    typfac: getOptionLabel.typfac,
                                    raisonsocial: getOptionLabel.raisoc,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    MG: getOptionLabel.CodDep,
                                  })
                                : this.setState({
                                    numfac: "",
                                    typfac: "",
                                    facOrBl: "",
                                    raisonsocial: "",
                                    categoriefiscale: "",
                                    MG: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // label={translate("N° facture")}
                                label="N° Devis"
                                // className="card add-input"
                                onFocus={() => {
                                  //liste  devis par code client
                                  fetch(
                                    `https://www.24-crm.com/webservice/Api/BCDVCLIs?typfac=DV&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        numdevTab: result,
                                        typfacTab: result.map(
                                          (el) => el.typfac
                                        ),
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }

                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11} id="libc">
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addbc">
                            <>
                              {this.state.GMM ? (
                                this.state.btnEnable ? (
                                  <label>
                                    <h5>
                                      <Tooltip title="Ajouter article">
                                        <Fab
                                          style={{
                                            backgroundColor: "#3f51b5",
                                            color: "white",
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          aria-label="add"
                                          onClick={() =>
                                            this.setState({
                                              ligModalShow: true,
                                              rem: this.state.remiseg,
                                            })
                                          }
                                        >
                                          <AddIcon />
                                        </Fab>
                                      </Tooltip>
                                    </h5>
                                  </label>
                                ) : (
                                  <label>
                                    <h5>
                                      <Tooltip title="Ajouter article">
                                        <Fab
                                          disabled
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          aria-label="add"
                                          onClick={() =>
                                            this.setState({
                                              ligModalShow: true,
                                              rem: this.state.remiseg,
                                            })
                                          }
                                        >
                                          <AddIcon />
                                        </Fab>
                                      </Tooltip>
                                    </h5>
                                  </label>
                                )
                              ) : this.state.btnEnable ? (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        disabled
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )}
                            </>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      {/* <div className="lig-table"> */}
                      <div className="tabd28">
                        <table style={{ marginTop: "10px" }}>
                          <thead style={{ fontSize: "12px" }}>
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th>
                              <th>PU HT</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>PUTTCNet</th>
                              <th>TotalHT</th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              color: `${darck ? "" : "#fff"}`,
                            }}
                          >
                            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                            {this.state.tab.map((t, i) => (
                              <tr key={i}>
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="bc1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row Row style={{ marginTop: "10px" }}>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    {/* <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total Quantité
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>{this.state.totalqte}</p>
                    </Col> */}
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="b">
                      <Divider
                        style={{
                          marginTop: "-8px",
                          backgroundColor: `${darck ? "gery" : "#fff"}`,
                        }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        valeur de timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {/* this.state.tab.totalht */}

                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                          {/* {Number(this.state.tab.totalht).toFixed(3)} */}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                          {/* {Number(this.state.tab.totalht).toFixed(3)} */}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="dv2">
                <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`}}>
                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total HTBrut
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Remise Article
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Remise Globale
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="d">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="d">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowdv">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      {/* <p style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}>
                    Total Quantité
                  </p>
                  <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>{this.state.totalqte}</p> */}
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Valeur timbre
                      </p>

                      <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>{this.state.valtimbre}</p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "grey" : "#fff"}`,
                         marginBottom: "-5px" }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p  style={{ color: `${darck ? "black" : "#fff"}`,
                         }}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color:`${darck ?"black":"#fff"}`, fontWeight: "bold"}}>
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color:`${darck ?"black":"#fff"}`, fontWeight: "bold"}}>
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
        

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
               
                  {this.state.tab.length === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous 
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      type="submit"
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <LigBCArticle
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          tabArticle={this.state.tab}
          rem={rem}
          numfaccc={this.props.numfacbcs.numfacbcs.map((nu) =>
            parseInt(nu.valeur)
          )}
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          regimecli={this.state.regimecli}
          avance={this.state.avance}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    GetNumFacBC: (a, b, c, d, e) => dispatch(GetNumFacBC(a, b, c, d, e)),
    SelectBC: (a, b, c, d, e) => dispatch(SelectBC(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    numfacbcs: state.numfacbcs,
    articles: state.articles,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBCModal));
